/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { css } from "@emotion/react";
import { Selectable } from "Utilities";
import { Station } from "model/Model";
import { useReferenceData } from "contexts/ReferenceDataContext";

const styles = {
  country: css`
    display: inline-block;
    flex: 0 0 180px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const StationsDescription = ( { selectedCountries, warningMessage }: { selectedCountries: Array<{ id: number; name: string; allStationsSelected: boolean; stations: Array<Selectable<Station>>; selectedStations: Array<Selectable<Station>>; }>; warningMessage?: string; } ): JSX.Element => {
  const referenceData = useReferenceData();

  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    for ( const country of selectedCountries ) {
      const countryParts = [];

      if ( country.allStationsSelected ) {
        countryParts.push( "All stations" );
      } else {
        const stationGroups = country.selectedStations.map( s => s.optionGroup || "" ).filter( ( value, index, array ) => array.indexOf( value ) === index );
        stationGroups.forEach( group => {
          const stationsForGroup = country.stations.filter( m => m.optionGroup === group );
          const selectedStationsForGroup = country.selectedStations.filter( m => m.optionGroup === group );
          const allStationsForGroupSelected = selectedStationsForGroup.length === stationsForGroup.length;
          if ( allStationsForGroupSelected ) {
            countryParts.push( `All ${ group }` );
          } else {
            for ( const station of selectedStationsForGroup ) {
              countryParts.push( station.name );
            }
          }
        } );
      }

      if ( countryParts.length === 0 ) countryParts.push( "None" );

      const countryTag = selectedCountries.length > 1 ? ( <div css={ styles.country }>{ country.name + ( referenceData.includeIdInName && ` [${ country.id }]` ) }</div> ) : null;
      results.push( ( <div css={ css`display:flex;`} key={ country.id } >{ countryTag }<div>{ countryParts.join( ", " ) }</div></div> ) );
    }
    return results;
  }, [ referenceData.includeIdInName, selectedCountries ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <>None</>;
};
