import { getSessionCredentials } from "contexts/UserContext";
import { Params, useLoaderData } from "react-router";
import { Index } from "./Index";
import { ResultFile } from "./Model";
import { getJson } from "helpers/api";

type LoaderData = {
  resultFile?: ResultFile;
  applicationId?: number;
};

export const resultLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const url = new URL( request.url );
  const applicationId = url.searchParams.get( "applicationId" );
  if ( !applicationId ) return {};

  const path = url.searchParams.get( "path" );
  if ( !path ) throw new Error( "path is undefined" );

  const outputToken = url.searchParams.get( "token" );
  if ( !outputToken ) throw new Error( "token is undefined" );

  const contentType = url.searchParams.get( "contentType" );
  if ( !contentType ) throw new Error( "contentType is undefined" );

  const applicationNumber = parseInt( applicationId );
  const result = await getJson<ResultFile>( "/Result", userId, token, { applicationId: applicationNumber, path: path, contentType: contentType, token: outputToken, jwt: token } );

  return { resultFile: result, applicationId: parseInt( applicationId ) };
};

export const Result = () => {
  const loaderData = useLoaderData() as LoaderData;
  if ( !loaderData ) throw new Error();

  return (
    <Index resultFile={ loaderData.resultFile } applicationId={ loaderData.applicationId } />
  );
};