/** @jsxImportSource @emotion/react */
import React from "react";
import { QualifyFormatConstants, QualifyFormatOption, QualifyTypeOption } from "../../Model";
import { RadioGroupAndAllSelector } from "./RadioGroupAndAllSelector/RadioGroupAndAllSelector";

export const QualifySelector = ( { types, formats, onChangeQualifyTypeIsSelected, onChangeQualifyFormatIsSelected }: { types: Array<QualifyTypeOption>; formats: Array<QualifyFormatOption>; onChangeQualifyTypeIsSelected( value: string, isSelected: boolean ): void; onChangeQualifyFormatIsSelected( value: QualifyFormatConstants, isSelected: boolean ): void; } ): JSX.Element => {
  return ( <>
    <RadioGroupAndAllSelector label="Type" helpId="qualify_type" options={ types } onChange={ onChangeQualifyTypeIsSelected } />
    <RadioGroupAndAllSelector label="Format only" helpId="qualify_format_only" options={ formats } onChange={ onChangeQualifyFormatIsSelected } />
  </> );
};
