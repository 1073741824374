import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportTypeConstants } from "../../Model";

const reportTypeSlice = createSlice( {
  name: "reportType",
  initialState: ReportTypeConstants.ImportsAndLocal as ReportTypeConstants,
  reducers: {
    ChangeReportType( _, action: PayloadAction<ReportTypeConstants> ) { return action.payload; }
  }
} );

export const { ChangeReportType } = reportTypeSlice.actions;

export default reportTypeSlice.reducer;
