/** @jsxImportSource @emotion/react */
import React from "react";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { DropdownSelector } from "components/dropdown-selector/DropdownSelector";
import { AverageCollapsePeriodConstants, AverageTitleConstants, YesNoConstants } from "model/Model";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";

export const combineWithinItems = [
  { value: 2, name: "2 minutes" },
  { value: 3, name: "3 minutes" },
  { value: 4, name: "4 minutes" },
  { value: 5, name: "5 minutes" },
  { value: 1, name: "1 minute" },
  { value: 10, name: "10 minutes" },
  { value: 15, name: "15 minutes" },
  { value: 20, name: "20 minutes" },
  { value: 30, name: "30 minutes" },
  { value: 35, name: "35 minutes" },
  { value: 45, name: "45 minutes" },
  { value: 50, name: "50 minutes" },
  { value: 60, name: "1 hour" },
  { value: 90, name: "1 hour 30 minutes" },
  { value: 120, name: "2 hours" },
  { value: 150, name: "2 hours 30 minutes" },
  { value: 180, name: "3 hours" },
  { value: 210, name: "3 hours 30 minutes" },
  { value: 240, name: "4 hours" },
  { value: 270, name: "4 hours 30 minutes" },
  { value: 300, name: "5 hours" },
  { value: 360, name: "6 hours" },
  { value: 420, name: "7 hours" },
  { value: 480, name: "8 hours" },
  { value: 540, name: "9 hours" },
  { value: 600, name: "10 hours" },
  { value: 660, name: "11 hours" },
  { value: 720, name: "12 hours" },
  { value: 1440, name: "24 hours" }
];

const collapsePeriodItems = [
  { name: "Individual Airings", value: AverageCollapsePeriodConstants.IndividualAirings },
  { name: "Daily", value: AverageCollapsePeriodConstants.Daily },
  { name: "Weekly Mon-Sun", value: AverageCollapsePeriodConstants.WeeklyMonSun },
  { name: "Weekly Sun-Sat", value: AverageCollapsePeriodConstants.WeeklySunSat },
  { name: "Monthly", value: AverageCollapsePeriodConstants.Monthly },
  { name: "Period", value: AverageCollapsePeriodConstants.Period },
  { name: "Day of Week", value: AverageCollapsePeriodConstants.DayOfWeek }
];

const averageTitleItems = [
  { name: "Original then Local", value: AverageTitleConstants.OriginalThenLocal },
  { name: "Local Only", value: AverageTitleConstants.LocalOnly }
];

const breakoutSeasonsItems = [
  { name: "Yes", value: YesNoConstants.Yes },
  { name: "No", value: YesNoConstants.No }
];

const breakoutOriginalRepeatItems = [
  { name: "Yes", value: YesNoConstants.Yes },
  { name: "No", value: YesNoConstants.No }
];

const averageLocalRepeatItems = [
  { name: "Yes", value: YesNoConstants.Yes },
  { name: "No", value: YesNoConstants.No }
];

const sumThousandsItems = [
  { name: "Yes", value: YesNoConstants.Yes },
  { name: "No", value: YesNoConstants.No }
];

const minimumAveragedTransmissionItems = [ { name: "ALL", value: "ALL" } ];
for ( let i = 2; i <= 25; i++ ) {
  minimumAveragedTransmissionItems.push( { name: i.toString(), value: i.toString() } );
}
minimumAveragedTransmissionItems.push( { name: "30", value: "30" } );
minimumAveragedTransmissionItems.push( { name: "40", value: "40" } );
minimumAveragedTransmissionItems.push( { name: "50", value: "50" } );

export const AverageSelector = ( { isGermanySelected, collapsePeriod, combineWithin, title: averageTitle, breakoutSeasons, breakoutOriginalRepeat, localRepeat, minimumAveragedTransmissions, sumThousands, onChangeAverageCollapsePeriodItem, onChangeAverageTitle, onChangeAverageBreakoutSeasons, onChangeAverageBreakoutOriginalRepeat, onChangeAverageLocalRepeat, onChangeAverageCombineWithin, onChangeAverageMinimumAveragedTransmissions, onChangeAverageSumThousands }: { isGermanySelected: boolean; collapsePeriod: AverageCollapsePeriodConstants; combineWithin: number; title: AverageTitleConstants; breakoutSeasons: YesNoConstants; breakoutOriginalRepeat: YesNoConstants; localRepeat: YesNoConstants; minimumAveragedTransmissions: string; sumThousands: YesNoConstants; onChangeAverageCollapsePeriodItem: ( value: AverageCollapsePeriodConstants ) => void; onChangeAverageCombineWithin: ( value: number ) => void; onChangeAverageTitle: ( value: AverageTitleConstants ) => void; onChangeAverageBreakoutSeasons: ( value: YesNoConstants ) => void; onChangeAverageBreakoutOriginalRepeat: ( value: YesNoConstants ) => void; onChangeAverageLocalRepeat: ( value: YesNoConstants ) => void; onChangeAverageMinimumAveragedTransmissions: ( value: string ) => void; onChangeAverageSumThousands: ( value: YesNoConstants ) => void; } ): JSX.Element => {
  return ( <>
    <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Collapse" radioGroup="average_collapse" helpId="averageCollapse" value={ collapsePeriod } items={ collapsePeriodItems } onChangeItemSelected={ onChangeAverageCollapsePeriodItem } />
    <DropdownSelector label="Combine within" helpId="averageCombineWithin" isEnabled={ collapsePeriod !== AverageCollapsePeriodConstants.IndividualAirings } items={ combineWithinItems } value={ combineWithin } onChangeItemSelected={ onChangeAverageCombineWithin } ></DropdownSelector>
    <DropdownSelector label="Averaged TX >=" helpId="averageAveragedTX" isEnabled={ collapsePeriod !== AverageCollapsePeriodConstants.IndividualAirings } items={ minimumAveragedTransmissionItems } value={ minimumAveragedTransmissions } onChangeItemSelected={ onChangeAverageMinimumAveragedTransmissions } ></DropdownSelector>
    <RadioGroupSelector label="Sum (000)" radioGroup="average_sum_thousands" radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } helpId="" value={ sumThousands } items={ sumThousandsItems } onChangeItemSelected={ onChangeAverageSumThousands } />
    <RadioGroupSelector label="Average Titles" radioGroup="average_titles" radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } helpId="averageAverageTitles" isEnabled={ collapsePeriod !== AverageCollapsePeriodConstants.IndividualAirings } value={ averageTitle } items={ averageTitleItems } onChangeItemSelected={ onChangeAverageTitle } />
    <RadioGroupSelector label="Breakout Seasons" radioGroup="breakout_seasons" radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } helpId="" isEnabled={ averageTitle === AverageTitleConstants.OriginalThenLocal } value={ breakoutSeasons } items={ breakoutSeasonsItems } onChangeItemSelected={ onChangeAverageBreakoutSeasons } />
    <RadioGroupSelector label="Breakout Original/Repeat" radioGroup="breakout_original_repeat" radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } helpId="" isEnabled={ averageTitle === AverageTitleConstants.OriginalThenLocal } value={ breakoutOriginalRepeat } items={ breakoutOriginalRepeatItems } onChangeItemSelected={ onChangeAverageBreakoutOriginalRepeat } />
    <RadioGroupSelector label="Average Local Repeats" radioGroup="breakout_average_local_repeats" radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } helpId="averageAverageRepeats" isEnabled={ isGermanySelected && averageTitle === AverageTitleConstants.LocalOnly } value={ localRepeat } items={ averageLocalRepeatItems } onChangeItemSelected={ onChangeAverageLocalRepeat } />
  </> );
};
