/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { css } from "@emotion/react";
import { MinimumDurationConstants } from "../../Model";
import { Country, DayPart, DaysConstants } from "model/Model";
import { formatDateAsEnGb, Selectable } from "Utilities";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  country: css`
    display: inline-block;
    width: 180px;
  `
};

export const DateTimeDescription = ( { fromDate, toDate, minimumDuration, useDayParts, selectedCountries, fromTime, toTime, days, warningMessage }: { selectedCountries: Array<Country & { selectedDayParts: Array<Selectable<DayPart>>; }>; fromDate?: number; toDate?: number; days: Array<DaysConstants>; minimumDuration: MinimumDurationConstants; fromTime: string; toTime: string; useDayParts: boolean; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    toDate && results.push( ( <span css={ styles.displayGroupSpace } key="1" >Week ending { formatDateAsEnGb( toDate ) }</span> ) );
    // fromDate && toDate && results.push( ( <span css={ styles.displayGroupSpace } key="2" > - </span> ) );
    // toDate && results.push( ( <span css={ styles.displayGroupSpace } key="3" >{ formatDateAsEnGb( toDate ) }</span> ) );

    const daysDescription = [];
    if ( days.length === 7 ) {
      daysDescription.push( "[Mon-Sun]" );
    } else {
      for ( const day of days ) {
        switch ( day ) {
          case DaysConstants.monday: daysDescription.push( "Mon" ); break;
          case DaysConstants.tuesday: daysDescription.push( "Tue" ); break;
          case DaysConstants.wednesday: daysDescription.push( "Wed" ); break;
          case DaysConstants.thursday: daysDescription.push( "Thu" ); break;
          case DaysConstants.friday: daysDescription.push( "Fri" ); break;
          case DaysConstants.saturday: daysDescription.push( "Sat" ); break;
          case DaysConstants.sunday: daysDescription.push( "Sun" ); break;
        }
      }
    }

    results.push( ( <span css={ styles.displayGroupSpace } key={ "days" }>{ daysDescription.join( ", " ) }</span> ) );

    switch ( minimumDuration ) {
      case MinimumDurationConstants.All: results.push( ( <span css={ styles.displayGroupSpace } key={ "minLength" }>{ "All transmission lengths" }</span> ) ); break;
      case MinimumDurationConstants.TenMinutes: results.push( ( <span css={ styles.displayGroupSpace } key={ "minLength" }>{ "Transmission lengths >= 10 minutes" }</span> ) ); break;
      case MinimumDurationConstants.FifteenMinutes: results.push( ( <span css={ styles.displayGroupSpace } key={ "minLength" }>{ "Transmission lengths >= 15 minutes" }</span> ) ); break;
      case MinimumDurationConstants.ThirtyMinutes: results.push( ( <span css={ styles.displayGroupSpace } key={ "minLength" }>{ "Transmission lengths >= 30 minutes" }</span> ) ); break;
      case MinimumDurationConstants.SixtyMinutes: results.push( ( <span css={ styles.displayGroupSpace } key={ "minLength" }>{ "Transmission lengths >= 60 minutes" }</span> ) ); break;
    }

    if ( useDayParts ) {
      if ( selectedCountries.length === 1 ) {
        const country = selectedCountries[ 0 ];
        const dp = country.selectedDayParts.map( m => m.name );

        results.push( ( <span key={ `c_dp_${ country.id }` } css={ styles.displayGroupSpace }>{ dp.join( ", " ) }</span> ) );
      } else {
        for ( const country of selectedCountries ) {
          const dp = country.selectedDayParts.map( m => m.name );

          results.push( ( <div key={ `c_dp_${ country.id }` }><span css={ styles.country }>{ country.name }</span><span css={ styles.displayGroupSpace }>{ dp.join( ", " ) }</span></div> ) );
        }
      }
    } else {
      results.push( ( <span css={ styles.displayGroupSpace } key={ "times" }>{ fromTime } - { toTime }</span> ) );
    }
    return results;
  }, [ days, fromTime, minimumDuration, selectedCountries, toDate, toTime, useDayParts ] );

  if ( warningMessage ) return <span className="warning">{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
