import { Revenue } from "apps/ProgramPerformance/Model";
import { RevenueCalculationConstants } from "apps/ProgramPerformance/Model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const revenueSlice = createSlice( {
  name: "revenue",
  initialState: {
    revenueCalculation: RevenueCalculationConstants.None
  } as Revenue,
  reducers: {
    ChangeRevenueCalculation( state, action: PayloadAction<RevenueCalculationConstants> ) { state.revenueCalculation = action.payload; }
  }
} );

export const { ChangeRevenueCalculation } = revenueSlice.actions;

export default revenueSlice.reducer;