import { createAction, createReducer } from "@reduxjs/toolkit";
import { ChangeCountryIsSelected } from "./countriesSlice";

export const ChangeDayPartIsSelected = createAction<{ countryId: number; dayPartId: number; isSelected: boolean; }>( "new:ChangeDayPartIsSelected" );

const dayPartReducers = createReducer(
  [] as Array<{ id: number; dayParts: Array<number>; }>,
  builder => {
    builder.addCase( ChangeCountryIsSelected, ( state, action ) => {
      const { countryId, isSelected } = action.payload;
      if ( isSelected && !state.find( c => c.id === countryId ) ) {
        // a country is selected so ensure it has the defaults
        return [ ...state, { id: countryId, dayParts: [] } ];
      } else {
        return state;
      }
    } );

    builder.addCase( ChangeDayPartIsSelected, ( state, action ) => {
      const { countryId, dayPartId, isSelected } = action.payload;
      if ( isSelected ) {
        return state.map( c => c.id === countryId ? { ...c, dayParts: [ ...c.dayParts, dayPartId ] } : c );
      } else {
        return state.map( c => c.id === countryId ? { ...c, dayParts: c.dayParts.filter( dp => dp !== dayPartId ) } : c );
      }
    } );
  }
);

export default dayPartReducers;