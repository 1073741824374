import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { Model, DistributorFinderSourceModel } from "../Model";
import thunkMiddleware from "redux-thunk";
import { parseDateOrUndefined } from "Utilities";
import countriesSlice from "reducers/countriesSlice";
import outputSlice from "reducers/outputSlice";
import displaySlice from "../components/display-selector/displaySlice";
import qualifySlice from "../components/qualify-selector/qualifySlice";
import distributorSlice from "../components/distributor-selector/distributorSlice";
import dateTimeReducers from "../components/date-time-selector/dateTimeReducers";

const initializeModel = ( data: DistributorFinderSourceModel ): Model => {
  const model = {
    ...data,
    dateTime: {
      fromDate: parseDateOrUndefined( data.dateTime.fromDate ),
      toDate: parseDateOrUndefined( data.dateTime.toDate )
    }
  };

  // FUTURE: more validation
  if ( model.countries === undefined )                               /* */ throw new Error( "DistributorFinder.countries is undefined" );
  if ( model.dateTime === undefined )                                /* */ throw new Error( "DistributorFinder.dateTime is undefined" );
  if ( model.display === undefined )                                 /* */ throw new Error( "DistributorFinder.display is undefined" );
  if ( model.display.selectedDisplayColumns === undefined )          /* */ throw new Error( "DistributorFinder.display.selectedDisplayColumns is undefined" );
  if ( model.display.displaySortedBy === undefined )                 /* */ throw new Error( "DistributorFinder.display.displaySortedBy is undefined" );
  if ( model.distributor === undefined )                             /* */ throw new Error( "DistributorFinder.distributor is undefined" );
  if ( model.distributor.compareOperator === undefined )             /* */ throw new Error( "DistributorFinder.distributor.compareOperator is undefined" );
  if ( model.distributor.resultCount === undefined )                 /* */ throw new Error( "DistributorFinder.distributor.resultCount is undefined" );
  if ( model.distributor.distributorNames === undefined )            /* */ throw new Error( "DistributorFinder.distributor.distributorNames is undefined" );
  if ( model.output === undefined )                                  /* */ throw new Error( "DistributorFinder.output is undefined" );
  if ( model.output.format === undefined )                           /* */ throw new Error( "DistributorFinder.output.format is undefined" );
  if ( model.output.view === undefined )                             /* */ throw new Error( "DistributorFinder.output.view is undefined" );
  if ( model.qualify === undefined )                                 /* */ throw new Error( "DistributorFinder.qualify is undefined" );
  if ( model.qualify.selectedTypes === undefined )                   /* */ throw new Error( "DistributorFinder.qualify.selectedTypes is undefined" );
  if ( model.qualify.selectedFormats === undefined )                 /* */ throw new Error( "DistributorFinder.qualify.selectedFormats is undefined" );

  return model;
};

export const getStore = ( initialData: DistributorFinderSourceModel ) => configureStore( {
  preloadedState: initializeModel( initialData ),
  reducer: {
    countries: countriesSlice,
    dateTime: dateTimeReducers,
    output: outputSlice,
    display: displaySlice,
    qualify: qualifySlice,
    distributor: distributorSlice
  },
  middleware: [ thunkMiddleware ]
} );

type StoreType = ReturnType<typeof getStore>;

export type AppDispatch = StoreType[ "dispatch" ];
export type RootState = ReturnType<StoreType[ "getState" ]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
