import { Output } from "model/Model";
import { Selectable } from "Utilities";

export type Model = {
  countries: Array<number>;
  qualify: Qualify;
  display: Display;
  dateTime: DateTime;
  output: Output;
  distributor: Distributor;
};

export type DateTimeSource = {
  fromDate?: string;
  toDate?: string;
};

export type DistributorFinderSourceModel = {
  countries: Array<number>;
  qualify: Qualify;
  display: Display;
  dateTime: DateTimeSource;
  output: Output;
  distributor: Distributor;
};

export enum DisplaySortedByConstants {
  DistributorCountry = 10,
  CountryDistributor = 11
}

export enum QualifyFormatConstants {
  Missing = "-1", // TODO: check this is what we want to do here
  Adaptation = "L",
  Original = "O",
}

export enum DistributorCompareOperatorConstants {
  Equals = "equals",
  Contains = "contains",
  Begins = "begins"
}

export type DisplayColumn = { id: string; name: string; };
export type QualifyType = { value: string; name: string; };
export type QualifyFormat = { value: QualifyFormatConstants; name: string; };

export type QualifyTypeOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;
export type QualifyFormatOption = Selectable<{ value: QualifyFormatConstants; name: string; isEnabled?: boolean; }>;

export type Qualify = {
  selectedTypes: Array<string>;
  selectedFormats: Array<QualifyFormatConstants>;
};

export type Display = {
  selectedDisplayColumns: Array<string>;
  displaySortedBy: DisplaySortedByConstants;
};

export type Distributor = {
  compareOperator: DistributorCompareOperatorConstants;
  resultCount: number;
  distributorNames: Array<Selectable<{ distributor: string; }>>;
  searchTerm?: string;
};

export type DateTime = {
  fromDate?: number;
  toDate?: number;
};