/** @jsxImportSource @emotion/react */
import React, { createContext, useContext, useState } from "react";
import { InfoCircleFill } from "components/BootstrapIcons";
import { css } from "@emotion/react";

const styles = {
  helpIcon: css`
    color: #5db2e0;
  `,

  toolTip: css`
    transform: translate( 0px, 0%);
    width: 400px;
    margin-bottom: 20px;
    position: absolute;
    background-color: white;
    border: 1px solid silver;
    z-index: 500;
    box-shadow: 5px 5px 5px #666;
    padding: 10px;
  
    opacity: 0;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
  `,

  show: css`
    opacity: 1;
    line-height: normal;
    font-weight: normal;
  `
};

export const HelpContext = createContext<Record<string, { text: string; }>>( {} );

export const HelpText = ( { helpId }: { helpId?: string; } ): JSX.Element => {
  const helpContext = useContext( HelpContext );
  const helpText = helpId ? helpContext[ helpId ] : null;
  const [ includeToolTip, setIncludeShowToolTip ] = useState( false );
  const [ showToolTip, setShowToolTip ] = useState( false );

  if ( !helpText ) return <></>;

  return (
    <>
      <span css={ styles.helpIcon } onMouseEnter={ () => { setIncludeShowToolTip( true ); setTimeout( () => setShowToolTip( true ), 100 ); } } onMouseLeave={ () => { setShowToolTip( false ); setTimeout( () => setIncludeShowToolTip( false ), 200 ); } } > <InfoCircleFill /></span>
      <ToolTip includeToolTip={ includeToolTip } show={ showToolTip } text={ helpText.text } />
    </>
  );
};

const ToolTip = ( { includeToolTip, show, text }: { includeToolTip: boolean; show: boolean; text: string; } ): JSX.Element => {
  return ( <>
    { includeToolTip && <div css={ [ styles.toolTip, show ? styles.show : undefined ] } ><div><span dangerouslySetInnerHTML={ { __html: text } } /></div></div> }
  </> );
};
