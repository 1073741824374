/** @jsxImportSource @emotion/react */
import React from "react";
import { DatesSelector } from "components/dates-selector/DatesSelector";
import { DaysSelector } from "components/day-selector/DaySelector";
import { DayPartSelector } from "components/daypart-selector/DayPartSelector";
import { MinimumLengthRadioSelector } from "apps/ChannelView/components/minimum-length-radio-selector/MinimumLengthRadioSelector";
import { Country, DayPart, DaysConstants } from "model/Model";
import { MinimumDurationConstants } from "apps/ChannelView/Model";
import { Selectable } from "Utilities";
import { TimesSelector } from "components/times-selector/TimesSelector";

export const DateTimeSelector = ( { selectedCountries, fromDate, toDate, fromTime, toTime, days, useDayParts, minimumDuration, onChangeFromDate, onChangeToDate, onChangeDays, onChangeMinimumDuration, onChangeFromTime, onChangeToTime, onChangeUseDayParts, onChangeDayPartIsSelected }: { selectedCountries: Array<Country & { dayParts: Array<Selectable<DayPart>>; }>; fromDate?: number; toDate?: number; days: Array<DaysConstants>; minimumDuration: MinimumDurationConstants; fromTime: string; toTime: string; useDayParts: boolean; onChangeFromDate: ( fromDate: number ) => void; onChangeToDate: ( toDate: number ) => void; onChangeDays: ( days: Array<DaysConstants> ) => void; onChangeMinimumDuration: ( minimumDuration: MinimumDurationConstants ) => void; onChangeFromTime: ( fromTime: string ) => void; onChangeToTime: ( toTime: string ) => void; onChangeUseDayParts: ( useDayParts: boolean ) => void; onChangeDayPartIsSelected: ( countryId: number, dayPartId: number, isSelected: boolean ) => void; } ): JSX.Element => ( <>
  <DatesSelector helpId="dateDates" fromDate={ fromDate } toDate={ toDate } onChangeFromDate={ onChangeFromDate } onChangeToDate={ onChangeToDate } />
  <DaysSelector helpId="" days={ days } onChangeDays={ onChangeDays } />
  <TimesSelector helpId="" fromTime={ fromTime } toTime={ toTime } isSelected={ !useDayParts } onChangeUseDayParts={ onChangeUseDayParts } onChangeFromTime={ onChangeFromTime } onChangeToTime={ onChangeToTime } ></TimesSelector>
  <DayPartSelector isEnabled={ useDayParts } selectedCountries={ selectedCountries } onChangeDayPartIsSelected={ onChangeDayPartIsSelected } onChangeUseDayParts={ onChangeUseDayParts } />
  <MinimumLengthRadioSelector minimumDuration={ minimumDuration } onChangeMinimumDuration={ onChangeMinimumDuration }></MinimumLengthRadioSelector>
</> );
