/** @jsxImportSource @emotion/react */
import React from "react";
import { DemoGroupBySelector } from "components/demo-group-by-selector/DemoGroupBySelector";
import { DemoRankSelector } from "components/demo-rank-selector/DemoRankSelector";
import { DemoSelector } from "components/demo-selector/DemoSelector";
import { DemoTypeSelectorWithIsRanked } from "components/demo-type-selector-with-is-ranked/DemoTypeSelectorWithIsRanked";
import { Demo, DemoGroupByConstants, DemoRankConstants, DemoSelectionConstants, DemoTypeConstants, Measure } from "model/Model";
import { Selectable } from "Utilities";

export const ProgramAverageTimeSpentDemoSelector = ( { measures, demoGroupBy, demoSelection, selectedCountries, demoRank, onChangeDemoTypePosition, onChangeDemoTypeSelected, onChangeDemoTypeRanked, onChangeDemoGroupBy, onChangeDemoIsSelected, onChangeDemoSelection, onChangeDemoSelectedOrder, onChangeDemoRank }: { measures: Array<Selectable<Measure> & { isRanked: boolean; }>; demoGroupBy: DemoGroupByConstants; onChangeDemoTypePosition: ( srcValue: DemoTypeConstants, dstValue: DemoTypeConstants ) => void; onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => void; onChangeDemoTypeRanked: ( value: DemoTypeConstants, isRanked: boolean ) => void; onChangeDemoGroupBy: ( demoGroupBy: DemoGroupByConstants ) => void; onChangeDemoRank: ( value: DemoRankConstants ) => void; demoSelection: DemoSelectionConstants; demoRank: DemoRankConstants; selectedCountries: Array<{ id: number; name: string; demos: Array<Selectable<Demo>>; selectedDemos: Array<Demo>; allDemosSelected: boolean; noDemosSelected: boolean; }>; onChangeDemoIsSelected: ( countryId: number, demoId: number, isSelected: boolean ) => void; onChangeDemoSelection: ( demoSelection: DemoSelectionConstants ) => void; onChangeDemoSelectedOrder: ( countryId: number, srcDemoId: number, dstDemoId: number ) => void; } ): JSX.Element => ( <>
  <DemoTypeSelectorWithIsRanked helpId="demoType" measures={ measures } onChangeDemoTypePosition={ onChangeDemoTypePosition } onChangeDemoTypeSelected={ onChangeDemoTypeSelected } onChangeDemoTypeRanked={ onChangeDemoTypeRanked } />
  <DemoGroupBySelector helpId="demoGroupBy" demoGroupBy={ demoGroupBy } onChangeDemoGroupBy={ onChangeDemoGroupBy } />
  <DemoRankSelector helpId="demoRank" isEnabled={ measures.some( m => m.isSelected && m.isRanked ) } demoRank={ demoRank } onChangeDemoRank={ onChangeDemoRank } />
  <DemoSelector helpId="demo" demoSelection={ demoSelection } selectedCountries={ selectedCountries } onChangeDemoIsSelected={ onChangeDemoIsSelected } onChangeDemoSelection={ onChangeDemoSelection } onChangeDemoSelectedOrder={ onChangeDemoSelectedOrder } />
</> );
