import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DisplayTopConstants, DisplaySortedByConstants, DisplayTimeConstants, DisplayUniqueConstants, Display } from "../../Model";

const displaySlice = createSlice( {
  name: "display",
  initialState: {
    selectedDisplayColumns: [],
    displaySortedBy: DisplaySortedByConstants.CountryChannel,
    displayTop: DisplayTopConstants.All,
    displayUnique: DisplayUniqueConstants.All,
    displayTime: DisplayTimeConstants.TotalMinutes
  } as Display,
  reducers: {
    ChangeDisplayColumnIsSelected( state, action: PayloadAction<{ id: string; isSelected: boolean; }> ) {
      const { id, isSelected } = action.payload;
      if ( isSelected ) {
        state.selectedDisplayColumns = [ ...state.selectedDisplayColumns, id ];
      } else {
        state.selectedDisplayColumns = state.selectedDisplayColumns.filter( m => m !== id );
      }
    },

    ChangeDisplaySortedBy( state, action: PayloadAction<DisplaySortedByConstants> ) { state.displaySortedBy = action.payload; },
    ChangeDisplayTime( state, action: PayloadAction<DisplayTimeConstants> ) { state.displayTime = action.payload; },
    ChangeDisplayTop( state, action: PayloadAction<DisplayTopConstants> ) { state.displayTop = action.payload; },
    ChangeDisplayUnique( state, action: PayloadAction<DisplayUniqueConstants> ) { state.displayUnique = action.payload; }
  }
} );

export const { ChangeDisplayColumnIsSelected, ChangeDisplaySortedBy, ChangeDisplayTime, ChangeDisplayTop, ChangeDisplayUnique } = displaySlice.actions;

export default displaySlice.reducer;