import { QualifyFormatConstants } from "constants/QualifyFormatConstants";
import { Applications } from "constants/application";
import { CountryDto } from "model/InitialModel";
import { ReportTypeSummaryConstants } from "model/Model";

export const max = ( array: Array<number> ): number => {
  let result = 0;
  for ( const n of array ) {
    if ( n > result ) result = n;
  }

  return result;
};

export const sequence = ( max: number ): Array<number> => {
  const result = [];
  for ( let i = 0; i <= max; i++ ) {
    result.push( i );
  }

  return result;
};

export type Selectable<T> = T & { isSelected: boolean; };

export const isInvalidValidDate = ( value: number ): boolean => isNaN( new Date( value ).getUTCDate() );

export const formatDateAsIso8601 = ( value?: number | Date ): string => {
  if ( !value ) return "";

  const localeFormatterOptions: Intl.DateTimeFormatOptions = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
    hour12: false
  };

  const displayFormat = new Intl.DateTimeFormat( "sv-SE", localeFormatterOptions );
  const dateFormatted = displayFormat.format( new Date( value ) ).substring( 0, 10 ); // take the first 10 characters which will just be the date
  return dateFormatted;
};

export const formatDateTimeAsIso8601 = ( value?: number | Date ): string => {
  if ( !value ) return "";

  const localeFormatterOptions: Intl.DateTimeFormatOptions = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
    hour12: false
  };
  // FUTURE: is there a version required for en-US?
  const displayFormat = new Intl.DateTimeFormat( "sv-SE", localeFormatterOptions );

  const dateFormatted = displayFormat.format( new Date( value ) ).replace( ",", "." ).replace( " ", "T" );

  return dateFormatted;
};

export const formatDateAsEnGb = ( value?: number | Date | string ): string => {
  if ( !value ) return "";

  // FUTURE: is there a version required for en-US?
  const displayFormat = new Intl.DateTimeFormat( "en-GB", { day: "numeric", month: "short", year: "numeric" } );

  const dateFormatted = displayFormat.format( new Date( value ) );

  return dateFormatted;
};

export const parseTimeInMinutes = ( time: string ): number => {
  const parts = time.split( ":" );

  if ( parts.length !== 2 ) {
    console.log( `Unable to parse time in minutes ${ time }` );
    return 0;
  }

  return parseInt( parts[ 0 ] ) * 60 + parseInt( parts[ 1 ] );
};

export const parseDate = ( dateText?: string ): Date | null => {
  if ( dateText === undefined || dateText == null || dateText === "" ) return null;

  const dt = getUtcDateFromDate( new Date( dateText ) );
  dt.setHours( 0, 0, 0, 0 );
  return dt;
};

export const isDate = ( dateText?: string ): boolean =>
  dateText === undefined || dateText == null || dateText === "" ? false : true;

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const twoDigits = ( value: number ): string =>
  value < 10 ? `0${ value.toString() }` : value.toString();

// cspell:disable
export const formatDateDDmmmYYYY = ( date: Date | undefined | null ): string =>
  !date
    ? ""
    : `${ date.getUTCDate() } ${ months[ date.getUTCMonth() ] } ${ date.getUTCFullYear() }`;
// cspell:enable

export const chunk = <T>( source: Array<T>, chunkSize: number ): Array<Array<T>> => {
  const result = [];
  const sourceClone = [ ...source ];

  while ( sourceClone.length ) {
    result.push( sourceClone.splice( 0, chunkSize ) );
  }

  return result;
};

export type ReferenceData = {
  includeIdInName: boolean; // TODO: this is a slightly strange place for this to be included
  includeLocalGenreDisplayOptions: boolean;
  includeProgramRankingErrorReportType: boolean;
  includeProgramRankingQuarterHourReportType: boolean;
  switchUsers: undefined | Array<{ userId: number; companyId: number; display: string; }>;
  switchCompanies: undefined | Array<{ companyId: number; display: string; }>;
  countryGroups: Array<string>;
  displayColumns: Record<string, Array<{ id: string; name: string; }>>;
  benchmarkDemoTypes: Array<{ value: number; name: string; }>;
  etsTypes: Array<{ value: string; name: string; }>;
  classOnes: Array<{ value: string; name: string; }>;
  classTwos: Array<{ value: string; name: string; }>;
  formats: Array<{ value: QualifyFormatConstants; name: string; }>;
  distributors: Array<{ value: string; name: string; }>;
  productionCountries: Array<{ value: string; name: string; }>;
  productionCountryPositions: Array<{ value: number; name: string; }>;
  reportTypeSummaries: Array<{ id: ReportTypeSummaryConstants; name: string; toolTip: string; }>;
  countries: Array<CountryDto>;
  measures: Array<{ value: number; name: string; }>;
  sharingUsersAndGroups: Array<{ id: number; name: string; type: string; }>;
};

export type HelpText = Record<string, { text: string; width: number; }>;

// TODO: check this ok a non-UK machine
export const getUtcDateFromDate = ( dateAsDate: Date ) => {
  const utcDay = dateAsDate.getUTCDate();
  const utcMonth = dateAsDate.getUTCMonth();
  const utcYear = dateAsDate.getUTCFullYear();

  return new Date( utcYear, utcMonth, utcDay, 0, 0, 0, 0 );
};

export const parseDateOrUndefined = ( date?: string ): number | undefined => {
  if ( date ) {
    const dateAsDate = new Date( Date.parse( date ) );
    if ( isNaN( dateAsDate.getDate() ) ) return undefined;

    return getUtcDateFromDate( dateAsDate ).valueOf();
  }

  return undefined;
};

// TODO: check this on a non-UK machine
export const getFirstOfMonthWithFortyFiveDayOffset = (): Date => {
  const date = getUtcDateFromDate( new Date() );
  date.setDate( date.getUTCDate() - 45 );
  date.setDate( 1 );
  date.setHours( 0, 0, 0, 0 );
  return date;
};

// TODO: should these not come from the database?
export const getApplicationDisplayName = ( applicationId: number ) => {
  switch ( applicationId ) {
    case Applications.ChannelView: return "Channel View";
    case Applications.DistributorFinder: return "Distributor Finder";
    case Applications.ProgramFinder: return "Program Finder";
    case Applications.ProgramPerformance: return "Program Performance";
    case Applications.ProgramRanking: return "Program Ranking";
    case Applications.ProgramSearch: return "Program Search";
    case Applications.QuarterHour: return "Quarter Hour";
    case Applications.RevenueEstimator: return "Revenue Estimator";
    case Applications.WeeklyChannelView: return "Weekly Channel View";
    case Applications.ProgramAverageTimeSpent: return "Program ATS";
    default: return `Unknown application ${ applicationId }`;
  }
};