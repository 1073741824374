import { Title, TitleCompareOperatorConstants, TitleOriginalOrLocalConstants } from "model/Model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const titleSlice = createSlice( {
  name: "title",
  initialState: {
    compareOperator: TitleCompareOperatorConstants.Contains,
    originalOrLocal: TitleOriginalOrLocalConstants.Original,
    resultCount: 20,
    numberOfCharactersToCompare: 0,
    titleNames: [],
    searchTerm: "" // this is not populated when the search is performed it is used for the filter
  } as Title,
  reducers: {
    ChangeTitleCompareOperator( state, action: PayloadAction<TitleCompareOperatorConstants> ) { state.compareOperator = action.payload; },
    ChangeTitleResultCount( state, action: PayloadAction<number> ) { state.resultCount = action.payload; },
    ChangeTitleOriginalOrLocal( state, action: PayloadAction<TitleOriginalOrLocalConstants> ) { state.originalOrLocal = action.payload; },
    ChangeTitleNumberOfCharactersToCompare( state, action: PayloadAction<number> ) { state.numberOfCharactersToCompare = action.payload; },
    ChangeTitleNames( state, action: PayloadAction<Array<{ title: string; isSelected: boolean; }>> ) { state.titleNames = action.payload; }
  }
} );

export const { ChangeTitleCompareOperator, ChangeTitleResultCount, ChangeTitleOriginalOrLocal, ChangeTitleNumberOfCharactersToCompare, ChangeTitleNames } = titleSlice.actions;

export default titleSlice.reducer;