import { combineReducers } from "redux";
import { DateTime } from "../../Model";
import toTimesReducers from "reducers/toTimeReducer";
import fromTimesReducers from "reducers/fromTimeReducer";
import dayReducers from "reducers/daysReducer";
import useDayPartsReducers from "reducers/useDayPartsReducer";
import dayPartReducers from "reducers/dayPartReducer";
import transmissionDurationReducers from "reducers/transmissionDurationReducer";
import fromDateReducers from "reducers/fromDateReducers";
import toDateReducers from "reducers/toDateReducer";

const dateTimeReducers = combineReducers<DateTime>( {
  fromDate: fromDateReducers,
  toDate: toDateReducers,
  useDayParts: useDayPartsReducers,
  days: dayReducers,
  fromTime: fromTimesReducers,
  toTime: toTimesReducers,
  dayParts: dayPartReducers,
  transmissionDuration: transmissionDurationReducers
} );

export default dateTimeReducers;
