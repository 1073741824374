/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { Index } from "apps/RevenueEstimator/Index";
import { CountriesById, appendCountriesById, appendMeasuresById, appendStationsByCountryIdAndId, appendTemplateNameAndSharedWith, appendUsersAndGroupsById, enrichCountries, filterRevenueStations } from "model/InitialModel";
import { HelpContext } from "components/help-text/HelpText";
import { ApplicationContext, ApplicationContextBaseType } from "components/application-context/ApplicationContext";
import { HelpText, getApplicationDisplayName } from "Utilities";
import { getStore } from "apps/RevenueEstimator/app/store";
import { Applications } from "constants/application";
import { Params, useLoaderData } from "react-router";
import { getSessionCredentials } from "contexts/UserContext";
import { QualifyFormatConstants, RevenueEstimatorSourceModel } from "./Model";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { helpTextCache } from "constants/helpTextCache";
import { TemplateOrHistory } from "model/Model";
import { fetchHelpTextData, getJson } from "helpers/api";

const applicationId = Applications.RevenueEstimator;

export type ApplicationContextType = ApplicationContextBaseType
  &
{
  readonly countriesById: CountriesById;
  readonly etsTypes: Array<{ value: string; name: string; }>;
  readonly classOnes: Array<{ value: string; name: string; }>;
  readonly classTwos: Array<{ value: string; name: string; }>;
  readonly formats: Array<{ value: QualifyFormatConstants; name: string; }>;
  readonly distributors: Array<{ value: string; name: string; }>;
  readonly productionCountries: Array<{ value: string; name: string; }>;
  readonly productionCountryPositions: Array<{ value: number; name: string; }>;
};

type LoaderData = { id: number; helpText: HelpText; initialData: TemplateOrHistory<RevenueEstimatorSourceModel>; };

const filterCountryGroups = ( applicationContext: ApplicationContextType ): ApplicationContextType => {
  const availableCountryGroups: Array<string> = [];
  applicationContext.countries.forEach( ( c ) => { if ( !availableCountryGroups.includes( c.group ) ) availableCountryGroups.push( c.group ); } );

  return { ...applicationContext, countryGroups: availableCountryGroups };
};

export const revenueEstimatorLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const id = parseInt( params.id as string || "-1" );

  let helpText: HelpText = helpTextCache[ applicationId ];
  if ( !helpText ) {
    helpText = await fetchHelpTextData( { userId, token, applicationId } );
    helpTextCache[ applicationId ] = helpText;
  }

  const initialData = await getJson<TemplateOrHistory<RevenueEstimatorSourceModel>>( "/Apps/RevenueEstimator", userId, token, { id } );

  validateSourceModel( initialData.query );

  return { id, helpText, initialData };
};

const validateSourceModel = ( initialData: RevenueEstimatorSourceModel ) => {
  if ( !initialData ) throw new Error( "Source data has not been loaded" );

  if ( initialData.reportType === undefined ) throw new Error( "SourceData.reportType hasn't loaded correctly" );

  if ( initialData.countries === undefined ) throw new Error( "SourceData.countries hasn't loaded correctly" );
  if ( !Array.isArray( initialData.countries ) ) throw new Error( "SourceData.countries hasn't loaded correctly" );

  if ( initialData.stations === undefined ) throw new Error( "SourceData.stations hasn't loaded correctly" );
  if ( !Array.isArray( initialData.stations ) ) throw new Error( "SourceData.stations hasn't loaded correctly" );

  if ( initialData.dateTime === undefined ) throw new Error( "SourceData.dateTime hasn't loaded correctly" );
  if ( initialData.dateTime.dayParts === undefined ) throw new Error( "SourceData.dateTime.dayParts hasn't loaded correctly" );
  if ( initialData.dateTime.days === undefined ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( !Array.isArray( initialData.dateTime.days ) ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( initialData.dateTime.fromTime === undefined ) throw new Error( "SourceData.dateTime.fromTime hasn't loaded correctly" );
  if ( initialData.dateTime.toTime === undefined ) throw new Error( "SourceData.dateTime.toTime hasn't loaded correctly" );
  if ( initialData.dateTime.useDayParts === undefined ) throw new Error( "SourceData.dateTime.useDayParts hasn't loaded correctly" );

  if ( initialData.qualify === undefined ) throw new Error( "SourceData.qualify hasn't loaded correctly" );
  if ( initialData.qualify.selectedClassOnes === undefined ) throw new Error( "SourceData.qualify.selectedClassOnes hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedClassOnes ) ) throw new Error( "SourceData.qualify.selectedClassOnes hasn't loaded correctly" );
  if ( initialData.qualify.selectedClassTwos === undefined ) throw new Error( "SourceData.qualify.selectedClassTwos hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedClassTwos ) ) throw new Error( "SourceData.qualify.selectedClassTwos hasn't loaded correctly" );
  if ( initialData.qualify.selectedDistributors === undefined ) throw new Error( "SourceData.qualify.selectedDistributors hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedDistributors ) ) throw new Error( "SourceData.qualify.selectedDistributors hasn't loaded correctly" );
  if ( initialData.qualify.selectedFormats === undefined ) throw new Error( "SourceData.qualify.selectedFormats hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedFormats ) ) throw new Error( "SourceData.qualify.selectedFormats hasn't loaded correctly" );
  if ( initialData.qualify.selectedLocalGenres === undefined ) throw new Error( "SourceData.qualify.selectedLocalGenres hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedLocalGenres ) ) throw new Error( "SourceData.qualify.selectedLocalGenres hasn't loaded correctly" );
  if ( initialData.qualify.selectedProductionCountries === undefined ) throw new Error( "SourceData.qualify.selectedProductionCountries hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedProductionCountries ) ) throw new Error( "SourceData.qualify.selectedProductionCountries hasn't loaded correctly" );
  if ( initialData.qualify.selectedProductionCountryPositions === undefined ) throw new Error( "SourceData.qualify.selectedProductionCountryPositions hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedProductionCountryPositions ) ) throw new Error( "SourceData.qualify.selectedProductionCountryPositions hasn't loaded correctly" );
  if ( initialData.qualify.selectedTypes === undefined ) throw new Error( "SourceData.qualify.selectedTypes hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedTypes ) ) throw new Error( "SourceData.qualify.selectedTypes hasn't loaded correctly" );

  if ( initialData.display === undefined ) throw new Error( "SourceData.display hasn't loaded correctly" );
  if ( initialData.display.displayRankTitles === undefined ) throw new Error( "SourceData.display.displayRankTitles hasn't loaded correctly" );
  if ( initialData.display.displayTitlesDisplayed === undefined ) throw new Error( "SourceData.display.displayTitlesDisplayed hasn't loaded correctly" );

  if ( initialData.output === undefined ) throw new Error( "SourceData.output hasn't loaded correctly" );
  if ( initialData.output.format === undefined ) throw new Error( "SourceData.output.format hasn't loaded correctly" );
  if ( initialData.output.view === undefined ) throw new Error( "SourceData.output.view hasn't loaded correctly" );
};

export const RevenueEstimator = () => {
  const loaderData = useLoaderData() as LoaderData;
  const referenceData = useReferenceData();
  const store = getStore( loaderData.initialData.query );

  const applicationContext: ApplicationContextType = useMemo( () =>
    filterCountryGroups(
      appendTemplateNameAndSharedWith(
        loaderData.initialData.userId,
        loaderData.initialData.name,
        loaderData.initialData.sharedWith,
        appendCountriesById(
          appendStationsByCountryIdAndId(
            appendUsersAndGroupsById(
              appendMeasuresById(
                {
                  applicationId,
                  templateOrHistoryId: loaderData.id,
                  countryGroups: referenceData.countryGroups,
                  includeIdInName: referenceData.includeIdInName,
                  measures: referenceData.measures,
                  sharingUsersAndGroups: referenceData.sharingUsersAndGroups,
                  countries: filterRevenueStations( enrichCountries( referenceData.countries.filter( m => m.hasRevenue ) ) ),
                  name: getApplicationDisplayName( applicationId ),
                  etsTypes: referenceData.etsTypes,
                  classOnes: referenceData.classOnes,
                  classTwos: referenceData.classTwos,
                  formats: referenceData.formats,
                  distributors: referenceData.distributors,
                  productionCountries: referenceData.productionCountries,
                  productionCountryPositions: referenceData.productionCountryPositions
                }
              )
            )
          )
        )
      )
    ), [ loaderData.id, loaderData.initialData.name, loaderData.initialData.sharedWith, loaderData.initialData.userId, referenceData.classOnes, referenceData.classTwos, referenceData.countries, referenceData.countryGroups, referenceData.distributors, referenceData.etsTypes, referenceData.formats, referenceData.includeIdInName, referenceData.measures, referenceData.productionCountries, referenceData.productionCountryPositions, referenceData.sharingUsersAndGroups ] );

  return (
    <ApplicationContext.Provider value={ applicationContext } key={ loaderData.id }>
      <HelpContext.Provider value={ loaderData.helpText } >
        <Provider store={ store }>
          <Index />
        </Provider>
      </HelpContext.Provider>
    </ApplicationContext.Provider>
  );
};