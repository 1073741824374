/** @jsxImportSource @emotion/react */
import React from "react";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { CheckGroupSelector } from "components/check-group-selector/CheckGroupSelector";
import { CheckGroupWithUncheckOptionSelector } from "components/check-group-with-uncheck-option-selector/CheckGroupWithUncheckOptionSelector";
import { DisplayTypeOption, DisplayClassOneOption, DisplayClassTwoOption, DisplayDistributorOption, DisplayReportIncrementConstants, DisplayOption } from "../../Model";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";

const displayReportIncrementOptions = [
  { name: "1 Minute", value: DisplayReportIncrementConstants.OneMinute },
  { name: "5 Minutes", value: DisplayReportIncrementConstants.FiveMinutes },
  { name: "10 Minutes", value: DisplayReportIncrementConstants.TenMinutes },
  { name: "15 Minutes", value: DisplayReportIncrementConstants.FifteenMinutes },
  { name: "30 Minutes", value: DisplayReportIncrementConstants.ThirtyMinutes }
];

export const DisplaySelector = ( { reportIncrement, types, classOnes, classTwos, distributors, displayOptions, onChangeDisplayReportIncrement, onChangeDisplayTypeIsSelected, onChangeDisplayClassOneIsSelected, onChangeDisplayClassTwoIsSelected, onChangeDisplayDistributorIsSelected, onChangeDisplayOptionIsSelected }: { reportIncrement: DisplayReportIncrementConstants; types: Array<DisplayTypeOption>; classOnes: Array<DisplayClassOneOption>; classTwos: Array<DisplayClassTwoOption>; distributors: Array<DisplayDistributorOption>; displayOptions: Array<DisplayOption>; onChangeDisplayReportIncrement( value: DisplayReportIncrementConstants ): void; onChangeDisplayTypeIsSelected( value: string, isSelected: boolean ): void; onChangeDisplayClassOneIsSelected( value: string, isSelected: boolean ): void; onChangeDisplayClassTwoIsSelected( value: string, isSelected: boolean ): void; onChangeDisplayDistributorIsSelected( value: string, isSelected: boolean ): void; onChangeDisplayOptionIsSelected( value: string, isSelected: boolean ): void; } ): JSX.Element => {
  return ( <>
    <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Report increment" helpId="channelViewDisplayIncrement" radioGroup="display_report_increment" value={ reportIncrement } items={ displayReportIncrementOptions } onChangeItemSelected={ onChangeDisplayReportIncrement } />
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="None" label="Type" helpId="channelViewDisplayType" options={ types } onChange={ onChangeDisplayTypeIsSelected } />
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="None" label="Class" helpId="channelViewDisplayClass" options={ classOnes } onChange={ onChangeDisplayClassOneIsSelected } />
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="None" label="Class 2" helpId="display_class_two" options={ classTwos } onChange={ onChangeDisplayClassTwoIsSelected } />
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="None" label="Distributor" helpId="channelViewDisplayDistributor" isFixedHeight={ true } options={ distributors } onChange={ onChangeDisplayDistributorIsSelected } />
    <CheckGroupSelector label="Options" helpId="display_options" options={ displayOptions } onChange={ onChangeDisplayOptionIsSelected } />
  </> );
};
