/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from "react";
import { CountryDescription } from "components/country-selector/CountryDescription";
import { CountrySelector } from "components/country-selector/CountrySelector";
import { OutputDescription } from "components/output-selector/OutputDescription";
import { OutputSelector } from "../../components/output-selector/OutputSelector";
import { TitleSelector } from "components/title-selector/TitleSelector";
import { ExpanderPanel } from "components/expander-panel/ExpanderPanel";
import { Model, ProgramFinderSourceModel } from "./Model";
import { Country, OutputFormatConstants, OutputViewConstants, TitleCompareOperatorConstants, TitleOriginalOrLocalConstants } from "model/Model";
import { Selectable } from "Utilities";
import { TitleDescription } from "components/title-selector/TitleDescription";
import { ApplicationHeader } from "components/application-header/ApplicationHeader";
import { ChangeOutputFormat, ChangeOutputView } from "reducers/outputSlice";
import { ChangeTitleOriginalOrLocal, ChangeTitleResultCount, ChangeTitleCompareOperator, ChangeTitleNumberOfCharactersToCompare, ChangeTitleNames } from "reducers/titleReducer";
import { useAppDispatch, useAppState } from "./app/hooks";
import { ChangeCountryIsSelected } from "reducers/countriesSlice";
import { getSessionCredentials } from "contexts/UserContext";
import { useApplicationContext } from "components/application-context/ApplicationContext";
import { ApplicationContextType } from "./Loader";
import { deleteJson, postJson, putJson } from "helpers/api";
import { useNavigate } from "react-router";
import { useImmer } from "use-immer";
import { RunningState, downloadFile, emptyRunning, openLink } from "model/RunningState";
import { Runner } from "components/runner/Runner";
import { ReportProgressMessageArgs, useNotifications } from "contexts/NotificationsContext";

const applicationUrl = "/Apps/ProgramFinder";

type PanelState = {
  selectedPanel: string;
  country: boolean;
  title: boolean;
  output: boolean;
};

const validate = ( state: Model ) => {
  const validation: {
    isValidForSubmit: boolean;
    warnings: Array<string>;
    errors: Array<string>;
    country?: string;
    title?: string;
    output?: string;
  } = {
    isValidForSubmit: true,
    warnings: [],
    errors: []
  };

  if ( state.selectedCountries.length === 0 ) {
    validation.isValidForSubmit = false;
  }

  if ( state.title.titleNames.filter( m => m.isSelected ).length === 0 ) {
    validation.errors.push( "You must select at least one title before submitting." );
  }

  return validation;
};

const getDefaultPanelState = () => {
  const panelStateJson = localStorage.getItem( `ps:${ applicationUrl }` );
  if ( panelStateJson ) {
    return JSON.parse( panelStateJson ) as PanelState;
  } else {
    return { selectedPanel: "", country: false, title: false, output: false };
  }
};

export const Index = (): JSX.Element => {
  const notifications = useNotifications();
  const state = useAppState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const applicationContext = useApplicationContext<ApplicationContextType>();
  const { token, userId } = getSessionCredentials();

  const [ running, setRunning ] = useImmer<RunningState>( emptyRunning );
  const [ panels, setPanels ] = useImmer<PanelState>( getDefaultPanelState );
  const allExpanded = panels.country && panels.title && panels.output;

  useEffect( () => {
    // savePanelState
    localStorage.setItem( `ps:${ applicationUrl }`, JSON.stringify( panels ) );
  }, [ panels ] );

  const countries = useMemo( () => ( {
    countryGroups: applicationContext.countryGroups.map( group => {
      const countries: Array<Selectable<Country>> = applicationContext.countries.filter( m => m.group === group ).map( m => ( { ...m, isSelected: !!state.selectedCountries.find( n => n === m.id ) } ) );
      const selectedCountryCount = countries.filter( m => m.isSelected ).length;

      return ( {
        name: group,
        countries: countries,
        isSelected: countries.length === selectedCountryCount
      } );
    } ),
    countries: applicationContext.countries.map( m => ( { ...m, isSelected: !!state.selectedCountries.find( c => c === m.id ) } ) ),
    selectedCountries: applicationContext.countries.filter( m => state.selectedCountries.find( c => c === m.id ) ),
    selectedCountryIds: applicationContext.countries.filter( m => state.selectedCountries.find( c => c === m.id ) ).map( m => m.id ),
    hasSelectedCountries: state.selectedCountries.length > 0,
    isWorldSelected: applicationContext.countries.length === state.selectedCountries.length
  } ), [ applicationContext.countries, applicationContext.countryGroups, state.selectedCountries ] );

  const validation = useMemo( () => validate( state ), [ state ] );

  const countryEvents = useMemo( () => ( {
    onChangeCountryIsSelected: ( countryId: number, isSelected: boolean ) => {
      const country = applicationContext.countriesById[ countryId ];
      dispatch( ChangeCountryIsSelected( { countryId, isSelected, clearExisting: false, defaultStations: country.defaultStations, defaultDemos: country.defaultDemos } ) );
    }
  } ), [ applicationContext.countriesById, dispatch ] );

  const titleEvents = useMemo( () => ( {
    onChangeTitleOriginalOrLocal: ( value: TitleOriginalOrLocalConstants ) => dispatch( ChangeTitleOriginalOrLocal( value ) ),
    onChangeTitleResultCount: ( value: number ) => dispatch( ChangeTitleResultCount( value ) ),
    onChangeTitleCompareOperator: ( value: TitleCompareOperatorConstants ) => dispatch( ChangeTitleCompareOperator( value ) ),
    onChangeTitleNumberOfCharactersToCompare: ( value: number ) => dispatch( ChangeTitleNumberOfCharactersToCompare( value ) ),
    onChangeTitleNames: ( values: Array<Selectable<{ title: string; }>> ) => dispatch( ChangeTitleNames( values ) )
  } ), [ dispatch ] );

  const getSourceModel = useCallback( (): ProgramFinderSourceModel => {
    const model: ProgramFinderSourceModel =
    {
      selectedCountries: state.selectedCountries,
      title: state.title,
      output: state.output
    };
    return model;
  }, [ state.output, state.selectedCountries, state.title ] );

  const doSubmit = useCallback( async (): Promise<void> => {
    if ( validation.errors.length > 0 ) {
      const message = validation.errors.join( "\n" );
      alert( message );
      return;
    }

    if ( validation.warnings.length > 0 ) {
      const message = validation.warnings.join( "\n" );
      if ( !globalThis.window.confirm( message ) ) return;
    }

    setRunning( emptyRunning );

    const model = getSourceModel();

    const result = await postJson<{ id: number; }>( applicationUrl, userId, token, model );
    if ( result ) setRunning( m => { m.id = result.id; } );
  }, [ getSourceModel, setRunning, token, userId, validation.errors, validation.warnings ] );

  const doCancel = useCallback( async () => {
    if ( !running.id ) return;

    await deleteJson( applicationUrl, userId, token, { id: running.id } );

    setRunning( m => { m.cancellationRequested = true; } );
  }, [ running.id, setRunning, token, userId ] );

  const onSaveTemplate = useCallback( async ( templateName: string, sharedWith: Array<number> ) => {
    const payload = {
      templateName,
      sharedWith,
      model: getSourceModel()
    };

    // positive values are history rows so always create a new template from those
    if ( applicationContext.templateOrHistoryId >= 0 ) {
      const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
      navigate( `/ProgramFinder/${ response.itemId }` );
    }
    else {
      const updateExistingTemplate = applicationContext.templateName === templateName;
      if ( updateExistingTemplate ) {
        await putJson( `/UserTemplate/${ applicationContext.applicationId }/${ applicationContext.templateOrHistoryId }`, userId, token, payload );
      } else {
        const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
        navigate( `/ProgramFinder/${ response.itemId }` );
      }
    }

  }, [ applicationContext.applicationId, applicationContext.templateName, applicationContext.templateOrHistoryId, getSourceModel, navigate, token, userId ] );

  useEffect( () => {
    if ( applicationContext.templateOrHistoryId === 0 ) setPanels( m => { m.selectedPanel = ""; m.country = true; m.title = false; m.output = false; } );
    setRunning( emptyRunning );
  }, [ applicationContext.templateOrHistoryId, setPanels, setRunning ] );

  const reportProgressMessageHandler = useCallback( ( args: ReportProgressMessageArgs ) => {
    console.log( args );

    if ( !args ) return;
    if ( args.id !== running.id ) return;

    setRunning( m => {
      m.percentComplete = args.percentComplete;
      m.message = args.message;
      m.url = args.url;
      m.contentType = args.contentType;
      m.status = args.status;
      if ( args.status !== "Started" ) {
        m.id = undefined;
      }
    } );

    if ( args.status === "Completed" ) {
      if ( args.contentType !== "text/html" ) {
        downloadFile( args.url, token ); // perform a download
        setRunning( emptyRunning );
      } else if ( args.contentType === "text/html" ) {
        openLink( args.url, "ProgramFinder", state.output.view );
        // setRunning( emptyRunning ); can only do this if we know that the tab has been opened
      }
      return;
    }
  }, [ running.id, setRunning, state.output.view, token ] );

  useEffect( () => {
    notifications.setOnReportProgressMessageHandler( () => reportProgressMessageHandler );
  }, [ notifications, reportProgressMessageHandler ] );

  const toggleExpandedPanels = useCallback( (): void => {
    setPanels( m => {
      const isAllExpanded = allExpanded;
      m.country = !isAllExpanded;
      m.title = !isAllExpanded;
      m.output = !isAllExpanded;
    } );
  }, [ setPanels, allExpanded ] );

  return ( <>
    <ApplicationHeader isAllExpanded={ allExpanded } applicationId={ applicationContext.applicationId } title={ applicationContext.name } isEnabled={ countries.hasSelectedCountries } onToggleExpanded={ toggleExpandedPanels } />
    <ExpanderPanel isExpanded={ panels.country } onExpanded={ ( isExpanded ) => setPanels( m => { m.country = isExpanded; } ) } title="Country:" description={ <CountryDescription isWorldSelected={ countries.isWorldSelected } warningMessage={ validation.country } countryGroups={ countries.countryGroups } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }  >
      <CountrySelector canSelectAllCountriesInGroup={ true }  { ...countries } { ...countryEvents } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.title && countries.hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.title = isExpanded; } ) } title="Title:" isEnabled={ countries.hasSelectedCountries } description={ <TitleDescription { ...state.title } warningMessage={ validation.title } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <TitleSelector { ...state.title } { ...titleEvents } countries={ countries.selectedCountryIds } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.output && countries.hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.output = isExpanded; } ) } title="Output:" isEnabled={ countries.hasSelectedCountries } description={ <OutputDescription { ...state.output } warningMessage={ validation.output } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <OutputSelector includeXlsx={ true } includeCsv={ true } includeHtml={ true } format={ state.output.format } view={ state.output.view } isTemplateOrHistoryOwner={ applicationContext.templateOrHistoryOwnerId === userId } defaultFormat={ OutputFormatConstants.Html } onSaveTemplate={ onSaveTemplate } onChangeOutputFormat={ ( value: OutputFormatConstants ) => dispatch( ChangeOutputFormat( value ) ) } onChangeOutputView={ ( value: OutputViewConstants ) => dispatch( ChangeOutputView( value ) ) } />
    </ExpanderPanel>
    <Runner running={ running } isValidForSubmit={ validation.isValidForSubmit } doSubmit={ doSubmit } doCancel={ doCancel } view={ state.output.view } applicationWindow="ProgramFinder" />
  </> );
};
