import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DemoTypeConstants } from "model/Model";
import { Selectable } from "Utilities";

const measuresSlice = createSlice( {
  name: "measures",
  initialState: [] as Array<Selectable<{ value: DemoTypeConstants; }>>,
  reducers: {
    ChangeDemoTypePosition( state, action: PayloadAction<{ srcValue: DemoTypeConstants; dstValue: DemoTypeConstants; }> ) {
      const { srcValue, dstValue } = action.payload;
      const updated = [ ...state ];
      const srcIndex = updated.findIndex( m => m.value === srcValue );
      const dstIndex = updated.findIndex( m => m.value === dstValue );

      updated.splice( dstIndex, 0, updated.splice( srcIndex, 1 )[ 0 ] );
      return updated;
    },

    ChangeDemoTypeSelected( state, action: PayloadAction<{ value: DemoTypeConstants; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      return state.map( m => m.value === value ? { ...m, isSelected } : m );
    }
  }
} );

export const { ChangeDemoTypePosition, ChangeDemoTypeSelected } = measuresSlice.actions;

export default measuresSlice.reducer;