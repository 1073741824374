/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { Index } from "apps/QuarterHour/Index";
import { CountriesById, appendCountriesById, appendDayPartsByCountryIdAndId, appendDemosByCountryIdAndId, appendMeasuresById, appendStationsByCountryIdAndId, appendTemplateNameAndSharedWith, appendUsersAndGroupsById, enrichCountries } from "model/InitialModel";
import { HelpContext } from "components/help-text/HelpText";
import { ApplicationContext, ApplicationContextBaseType } from "components/application-context/ApplicationContext";
import { HelpText, getApplicationDisplayName } from "Utilities";
import { getStore } from "apps/QuarterHour/app/store";
import { Applications } from "constants/application";
import { Params, useLoaderData } from "react-router";
import { QuarterHourSourceModel } from "./Model";
import { getSessionCredentials } from "contexts/UserContext";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { helpTextCache } from "constants/helpTextCache";
import { DemoSelectionConstants, TemplateOrHistory } from "model/Model";
import { fetchHelpTextData, getJson } from "helpers/api";

const applicationId = Applications.QuarterHour;

export type ApplicationContextType = ApplicationContextBaseType
  &
{
  readonly countriesById: CountriesById;
};

type LoaderData = { id: number; helpText: HelpText; initialData: TemplateOrHistory<QuarterHourSourceModel>; };

export const quarterHourLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const id = parseInt( params.id as string || "-1" );

  let helpText: HelpText = helpTextCache[ applicationId ];
  if ( !helpText ) {
    helpText = await fetchHelpTextData( { userId, token, applicationId } );
    helpTextCache[ applicationId ] = helpText;
  }

  const initialData = await getJson<TemplateOrHistory<QuarterHourSourceModel>>( "/Apps/QuarterHour", userId, token, { id } );

  initialData.query.demoSelection = initialData.query.demoSelection || DemoSelectionConstants.selectedDemos; // default to selectedDemos for historical reports

  validateSourceModel( initialData.query );

  return { id, helpText, initialData };
};

const validateSourceModel = ( initialData: QuarterHourSourceModel ) => {
  if ( !initialData ) throw new Error( "Source data has not been loaded" );

  if ( initialData.countries === undefined ) throw new Error( "SourceData.countries hasn't loaded correctly" );
  if ( !Array.isArray( initialData.countries ) ) throw new Error( "SourceData.countries hasn't loaded correctly" );

  if ( initialData.stations === undefined ) throw new Error( "SourceData.stations hasn't loaded correctly" );
  if ( !Array.isArray( initialData.stations ) ) throw new Error( "SourceData.stations hasn't loaded correctly" );

  if ( initialData.demos === undefined ) throw new Error( "SourceData.demos hasn't loaded correctly" );
  if ( !Array.isArray( initialData.demos ) ) throw new Error( "SourceData.demos hasn't loaded correctly" );

  if ( initialData.demoGroupBy === undefined ) throw new Error( "SourceData.demoGroupBy hasn't loaded correctly" );
  if ( initialData.demoSelection === undefined ) throw new Error( "SourceData.demoSelection hasn't loaded correctly" );

  if ( initialData.dateTime === undefined ) throw new Error( "SourceData.dateTime hasn't loaded correctly" );
  if ( initialData.dateTime.dayParts === undefined ) throw new Error( "SourceData.dateTime.dayParts hasn't loaded correctly" );
  if ( initialData.dateTime.days === undefined ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( !Array.isArray( initialData.dateTime.days ) ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( initialData.dateTime.fromTime === undefined ) throw new Error( "SourceData.dateTime.fromTime hasn't loaded correctly" );
  if ( initialData.dateTime.toTime === undefined ) throw new Error( "SourceData.dateTime.toTime hasn't loaded correctly" );
  if ( initialData.dateTime.useDayParts === undefined ) throw new Error( "SourceData.dateTime.useDayParts hasn't loaded correctly" );

  if ( initialData.average === undefined ) throw new Error( "SourceData.average hasn't loaded correctly" );
  if ( initialData.average.averageDayAverage === undefined ) throw new Error( "SourceData.average.averageDayAverage hasn't loaded correctly" );
  if ( initialData.average.averageTimeAverage === undefined ) throw new Error( "SourceData.average.averageTimeAverage hasn't loaded correctly" );

  if ( initialData.output === undefined ) throw new Error( "SourceData.output hasn't loaded correctly" );
  if ( initialData.output.format === undefined ) throw new Error( "SourceData.output.format hasn't loaded correctly" );
  if ( initialData.output.view === undefined ) throw new Error( "SourceData.output.view hasn't loaded correctly" );
};

export const QuarterHour = () => {
  const loaderData = useLoaderData() as LoaderData;
  const referenceData = useReferenceData();
  const store = getStore( loaderData.initialData.query );

  const applicationContext: ApplicationContextType = useMemo( () =>
    appendTemplateNameAndSharedWith(
      loaderData.initialData.userId,
      loaderData.initialData.name,
      loaderData.initialData.sharedWith,
      appendCountriesById(
        appendStationsByCountryIdAndId(
          appendDemosByCountryIdAndId(
            appendDayPartsByCountryIdAndId(
              appendUsersAndGroupsById(
                appendMeasuresById(
                  {
                    applicationId,
                    templateOrHistoryId: loaderData.id,
                    countryGroups: referenceData.countryGroups,
                    includeIdInName: referenceData.includeIdInName,
                    measures: referenceData.measures,
                    sharingUsersAndGroups: referenceData.sharingUsersAndGroups,
                    countries: enrichCountries( referenceData.countries ),
                    name: getApplicationDisplayName( applicationId )
                  }
                )
              )
            )
          )
        )
      )
    ), [ loaderData.id, loaderData.initialData.name, loaderData.initialData.sharedWith, loaderData.initialData.userId, referenceData.countries, referenceData.countryGroups, referenceData.includeIdInName, referenceData.measures, referenceData.sharingUsersAndGroups ] );

  return (
    <ApplicationContext.Provider value={ applicationContext } key={ loaderData.id }>
      <HelpContext.Provider value={ loaderData.helpText } >
        <Provider store={ store }>
          <Index />
        </Provider>
      </HelpContext.Provider>
    </ApplicationContext.Provider>
  );
};