import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { Model, ProgramRankingSourceModel } from "../Model";
import thunkMiddleware from "redux-thunk";
import { getFirstOfMonthWithFortyFiveDayOffset, parseDate, parseDateOrUndefined } from "Utilities";
import countriesSlice from "reducers/countriesSlice";
import demoGroupBySlice from "reducers/demoGroupBySlice";
import demoRankSlice from "reducers/demoRankSlice";
import demoSelectionSlice from "reducers/demoSelectionSlice";
import measuresSliceWithIsRanked from "reducers/measuresWithIsRankedSlice";
import outputSlice from "reducers/outputSlice";
import demosSlice from "reducers/demosSlice";
import stationsSlice from "reducers/stationsSlice";
import revenueSlice from "./revenueSlice";
import reportTypeSlice from "../components/report-type-summary-selector/reportTypeSlice";
import averageSlice from "../components/average-selector/averageSlice";
import qualifySlice from "../components/qualify-selector/qualifySlice";
import benchmarkSlice from "../components/benchmark-selector/benchmarkSlice";
import displaySlice from "../components/display-selector/displaySlice";
import dateTimeReducers from "../components/date-time-selector/dateTimeReducers";

const initializeModel = ( data: ProgramRankingSourceModel ): Model => {
  const model = {
    ...data,
    dateTime: {
      ...data.dateTime,
      fromDate: parseDateOrUndefined( data.dateTime.fromDate ),
      toDate: parseDateOrUndefined( data.dateTime.toDate )
    },
    benchmark: {
      ...data.benchmark,
      fromDate: ( parseDate( data.benchmark.fromDate ) || getFirstOfMonthWithFortyFiveDayOffset() ).valueOf(),
      toDate: ( parseDate( data.benchmark.toDate ) || getFirstOfMonthWithFortyFiveDayOffset() ).valueOf()
    }
  };

  // FUTURE: more validation
  if ( model.average === undefined )                                              /* */ throw new Error( "ProgramRanking.average is undefined" );
  if ( model.average.collapsePeriod === undefined )                               /* */ throw new Error( "ProgramRanking.average.collapsePeriod is undefined" );
  if ( model.average.combineWithin === undefined )                                /* */ throw new Error( "ProgramRanking.average.combineWithin is undefined" );
  if ( model.average.minimumAveragedTransmissions === undefined )                 /* */ throw new Error( "ProgramRanking.average.minimumAveragedTransmissions is undefined" );
  if ( model.average.sumThousands === undefined )                                 /* */ throw new Error( "ProgramRanking.average.sumThousands is undefined" );
  if ( model.average.title === undefined )                                        /* */ throw new Error( "ProgramRanking.average.title is undefined" );
  if ( model.average.breakoutSeasons === undefined )                              /* */ throw new Error( "ProgramRanking.average.breakoutSeasons is undefined" );
  if ( model.average.breakoutOriginalRepeat === undefined )                       /* */ throw new Error( "ProgramRanking.average.breakoutOriginalRepeat is undefined" );
  if ( model.average.localRepeat === undefined )                                  /* */ throw new Error( "ProgramRanking.average.localRepeat is undefined" );
  if ( model.benchmark === undefined )                                            /* */ throw new Error( "ProgramRanking.benchmark is undefined" );
  if ( model.benchmark.demoCount === undefined )                                  /* */ throw new Error( "ProgramRanking.benchmark.demoCount is undefined" );
  if ( model.benchmark.demoTypes === undefined )                                  /* */ throw new Error( "ProgramRanking.benchmark.demoTypes is undefined" );
  if ( model.benchmark.dates === undefined )                                      /* */ throw new Error( "ProgramRanking.benchmark.dates is undefined" );
  if ( model.benchmark.selectedQuarters === undefined )                           /* */ throw new Error( "ProgramRanking.benchmark.selectedQuarters is undefined" );
  if ( model.benchmark.fromDate === undefined )                                   /* */ throw new Error( "ProgramRanking.benchmark.fromDate is undefined" );
  if ( model.benchmark.toDate === undefined )                                     /* */ throw new Error( "ProgramRanking.benchmark.toDate is undefined" );
  if ( model.benchmark.timePeriod === undefined )                                 /* */ throw new Error( "ProgramRanking.benchmark.timePeriod is undefined" );
  if ( model.benchmark.timePeriodFromTime === undefined )                         /* */ throw new Error( "ProgramRanking.benchmark.timePeriodFromTime is undefined" );
  if ( model.benchmark.timePeriodToTime === undefined )                           /* */ throw new Error( "ProgramRanking.benchmark.timePeriodToTime is undefined" );
  if ( model.benchmark.benchmarkDayOfWeekType === undefined )                     /* */ throw new Error( "ProgramRanking.benchmark.benchmarkDayOfWeekType is undefined" );
  if ( model.benchmark.benchmarkDays === undefined )                              /* */ throw new Error( "ProgramRanking.benchmark.benchmarkDays is undefined" );
  if ( model.countries === undefined )                                            /* */ throw new Error( "ProgramRanking.countries is undefined" );
  if ( model.dateTime === undefined )                                             /* */ throw new Error( "ProgramRanking.dateTime is undefined" );
  if ( model.dateTime.days === undefined )                                        /* */ throw new Error( "ProgramRanking.dateTime.days is undefined" );
  if ( model.dateTime.transmissionDuration === undefined )                        /* */ throw new Error( "ProgramRanking.dateTime.transmissionDuration is undefined" );
  if ( model.dateTime.fromTime === undefined )                                    /* */ throw new Error( "ProgramRanking.dateTime.fromTime is undefined" );
  if ( model.dateTime.toTime === undefined )                                      /* */ throw new Error( "ProgramRanking.dateTime.toTime is undefined" );
  if ( model.dateTime.useDayParts === undefined )                                 /* */ throw new Error( "ProgramRanking.dateTime.useDayParts is undefined" );
  if ( model.dateTime.dayParts === undefined )                                    /* */ throw new Error( "ProgramRanking.dateTime.dayParts is undefined" );
  if ( model.demoGroupBy === undefined )                                          /* */ throw new Error( "ProgramRanking.demoGroupBy is undefined" );
  if ( model.demoRank === undefined )                                             /* */ throw new Error( "ProgramRanking.demoRank is undefined" );
  if ( model.demoSelection === undefined )                                        /* */ throw new Error( "ProgramRanking.demoSelection is undefined" );
  if ( model.demos === undefined )                                                /* */ throw new Error( "ProgramRanking.demos is undefined" );
  if ( model.display === undefined )                                              /* */ throw new Error( "ProgramRanking.display is undefined" );
  if ( model.display.selectedDisplayColumns === undefined )                       /* */ throw new Error( "ProgramRanking.display.selectedDisplayColumns is undefined" );
  if ( model.display.displaySortedBy === undefined )                              /* */ throw new Error( "ProgramRanking.display.displaySortedBy is undefined" );
  if ( model.display.displayTop === undefined )                                   /* */ throw new Error( "ProgramRanking.display.displayTop is undefined" );
  if ( model.display.displayUnique === undefined )                                /* */ throw new Error( "ProgramRanking.display.displayUnique is undefined" );
  if ( model.display.displayTime === undefined )                                  /* */ throw new Error( "ProgramRanking.display.displayTime is undefined" );
  if ( model.measures === undefined )                                             /* */ throw new Error( "ProgramRanking.measures is undefined" );
  if ( model.output === undefined )                                               /* */ throw new Error( "ProgramRanking.output is undefined" );
  if ( model.output.format === undefined )                                        /* */ throw new Error( "ProgramRanking.output.format is undefined" );
  if ( model.output.view === undefined )                                          /* */ throw new Error( "ProgramRanking.output.view is undefined" );
  if ( model.qualify === undefined )                                              /* */ throw new Error( "ProgramRanking.qualify is undefined" );
  if ( model.qualify.selectedTypes === undefined )                                /* */ throw new Error( "ProgramRanking.qualify.selectedTypes is undefined" );
  if ( model.qualify.selectedClassOnes === undefined )                            /* */ throw new Error( "ProgramRanking.qualify.selectedClassOnes is undefined" );
  if ( model.qualify.selectedClassTwos === undefined )                            /* */ throw new Error( "ProgramRanking.qualify.selectedClassTwos is undefined" );
  if ( model.qualify.selectedFormats === undefined )                              /* */ throw new Error( "ProgramRanking.qualify.selectedFormats is undefined" );
  if ( model.qualify.selectedDistributors === undefined )                         /* */ throw new Error( "ProgramRanking.qualify.selectedDistributors is undefined" );
  if ( model.qualify.selectedProductionCountries === undefined )                  /* */ throw new Error( "ProgramRanking.qualify.selectedProductionCountries is undefined" );
  if ( model.qualify.selectedProductionCountryPositions === undefined )           /* */ throw new Error( "ProgramRanking.qualify.selectedProductionCountryPositions is undefined" );
  if ( model.qualify.selectedLocalGenres === undefined )                          /* */ throw new Error( "ProgramRanking.qualify.selectedLocalGenres is undefined" );
  if ( model.reportType === undefined )                                           /* */ throw new Error( "ProgramRanking.reportType is undefined" );
  if ( model.reportType.reportType === undefined )                                /* */ throw new Error( "ProgramRanking.reportType.reportType is undefined" );
  if ( model.reportType.selectedReportTypeSummaries === undefined )               /* */ throw new Error( "ProgramRanking.reportType.selectedReportTypeSummaries is undefined" );
  if ( model.reportType.isSummaryOnly === undefined )                             /* */ throw new Error( "ProgramRanking.reportType.isSummaryOnly is undefined" );
  if ( model.reportType.summaryCombineChannels === undefined )                    /* */ throw new Error( "ProgramRanking.reportType.summaryCombineChannels is undefined" );
  if ( model.reportType.summaryCombineSeries === undefined )                      /* */ throw new Error( "ProgramRanking.reportType.summaryCombineSeries is undefined" );
  if ( model.reportType.summaryCombineFilm === undefined )                        /* */ throw new Error( "ProgramRanking.reportType.summaryCombineFilm is undefined" );
  if ( model.revenue === undefined )                                              /* */ throw new Error( "ProgramRanking.revenue is undefined" );
  if ( model.revenue.revenueAmount === undefined )                                /* */ throw new Error( "ProgramRanking.revenue.revenueAmount is undefined" );
  if ( model.revenue.revenueDuration === undefined )                              /* */ throw new Error( "ProgramRanking.revenue.revenueDuration is undefined" );
  if ( model.revenue.revenueCollapse === undefined )                              /* */ throw new Error( "ProgramRanking.revenue.revenueCollapse is undefined" );

  if ( model.stations === undefined )                                             /* */ throw new Error( "ProgramRanking.stations is undefined" );

  return model;
};

export const getStore = ( initialData: ProgramRankingSourceModel ) => configureStore( {
  preloadedState: initializeModel( initialData ),
  reducer: {
    countries: countriesSlice,
    stations: stationsSlice,
    demos: demosSlice,
    measures: measuresSliceWithIsRanked,
    demoRank: demoRankSlice,
    demoGroupBy: demoGroupBySlice,
    demoSelection: demoSelectionSlice,
    dateTime: dateTimeReducers,
    reportType: reportTypeSlice,
    average: averageSlice,
    qualify: qualifySlice,
    revenue: revenueSlice,
    benchmark: benchmarkSlice,
    display: displaySlice,
    output: outputSlice
  },
  middleware: [ thunkMiddleware ]
} );

type StoreType = ReturnType<typeof getStore>;

export type AppDispatch = StoreType[ "dispatch" ];
export type RootState = ReturnType<StoreType[ "getState" ]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
