import { HelpText } from "Utilities";

const buildURLQuery = ( input: Record<string, string | number | boolean> ): string => {
  const asStrings: Record<string, string> = {};

  for ( const m of Object.getOwnPropertyNames( input ) ) {
    asStrings[ m ] = input[ m ].toString();
  }

  return "?" + new URLSearchParams( asStrings ).toString();
};

const apiUrl = ( process.env.REACT_APP_API?.trimEnd() ) || "/RemoteApp";
const defaultUserId = process.env.REACT_APP_DEFAULT_USER_ID || -1;

export const getJson = async <TResult>( url: string, userId: number, token: string, parameters?: Record<string, string | number | boolean> ): Promise<TResult> => {
  const fullUrl = `${ apiUrl }${ url }${ buildURLQuery( { ...parameters } ) }`;
  const response = await fetch(
    fullUrl,
    {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${ token }`,
        "X-UserId": ( userId || defaultUserId ).toString()
      }
    }
  );

  if ( !response.ok ) throw new Error( `Failed to GET: ${ fullUrl }` );

  try {
    const json = ( await response.json() ) as TResult;
    //console.log( `response ok: ${ JSON.stringify( json ) }` );
    return json;
  } catch ( e ) {
    console.error( "Failed to read JSON result", e );
    return {} as TResult;
  }
};

export const postAuthenticate = async ( username: string, password: string ): Promise<{ userId: number; jwt: string; username: string; name: string; } | null> => {
  const fullUrl = `${ apiUrl }/authenticate`;
  const response = await fetch( fullUrl, {
    method: "POST",
    body: JSON.stringify( { username, password } ),
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    }
  } );

  if ( !response.ok ) throw new Error( `Failed to POST: ${ fullUrl }` );

  try {
    const json = ( await response.json() ) as { userId: number; jwt: string; username: string; name: string; };
    //console.log( `response ok: ${ JSON.stringify( json ) }` );
    return json;
  } catch ( e ) {
    console.error( "Failed to read JSON result from POST", e );
    return null;
  }
};

export const postJson = async <TResult, TPayload = unknown>( url: string, userId: number, token: string, payload?: TPayload ): Promise<TResult> => {
  const fullUrl = `${ apiUrl }${ url }`;
  const response = await fetch( fullUrl, {
    method: "POST",
    body: payload ? JSON.stringify( payload ) : undefined,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${ token }`,
      "X-UserId": ( userId || defaultUserId ).toString()
    }
  } );

  if ( !response.ok ) throw new Error( `Failed to POST: ${ fullUrl }` );

  try {
    const json = ( await response.json() ) as TResult;
    //console.log( `response ok: ${ JSON.stringify( json ) }` );
    return json;
  } catch ( e ) {
    console.error( "Failed to read JSON result from POST", e );
    return {} as TResult;
  }
};

export const putJson = async <TResult, TPayload = unknown>( url: string, userId: number, token: string, payload?: TPayload ): Promise<TResult> => {
  const fullUrl = `${ apiUrl }${ url }`;
  const body = payload ? JSON.stringify( payload ) : undefined;
  const response = await fetch( fullUrl, {
    method: "PUT",
    body,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${ token }`,
      "X-UserId": ( userId || defaultUserId ).toString()
    }
  } );

  if ( !response.ok ) throw new Error( `Failed to PUT: ${ fullUrl }` );

  try {
    const json = ( await response.json() ) as TResult;
    return json;
  } catch ( e ) {
    console.error( "Failed to read JSON result from PUT", e );
    return {} as TResult;
  }
};

export const deleteJson = async ( url: string, userId: number, token: string, parameters?: Record<string, string | number | boolean> ): Promise<void> => {
  const fullUrl = `${ apiUrl }${ url }${ buildURLQuery( { ...parameters } ) }`;
  const response = await fetch( fullUrl, {
    method: "DELETE",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${ token }`,
      "X-UserId": ( userId || defaultUserId ).toString()
    }
  } );

  if ( !response.ok ) throw new Error( `Failed to DELETE: ${ fullUrl }` );
};

export const fetchHelpTextData = async ( { userId, token, applicationId }: { userId: number; token: string; applicationId: number; } ): Promise<HelpText> => {
  return await getJson<HelpText>( "/HelpTextData", userId, token, { applicationId: applicationId } );
};

export const getNotificationsUrl = ( token: string ) => `${ apiUrl }/Notification?bearer=${ token }`;