/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { BenchmarkQuarterOption } from "../../../Model";
import { DatePicker, DatePickerChangeTypeConstants } from "components/date-picker/DatePicker";
import { HelpText } from "components/help-text/HelpText";
import { BenchmarkDatesConstants } from "model/Model";
import { css } from "@emotion/react";
import { usePersonalization } from "contexts/PersonalizationContext";

const styles = {
  benchmarkDatesSelector: css`
  display: inline-block;
  vertical-align: top;

  fieldset {
    border: solid black 1px;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    display: inline;
    margin-left: 4px;
    margin-right: 4px;

    legend {
      width: auto;
      margin: 0;
      margin-left: 4px;
      padding-left: 4px;
      padding-right: 4px;

      &.clickable {
        cursor: pointer;
      }
    }
  }
  `,

  dateTypesWrapper: css`
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;

    label {
      padding: 0;
      margin: 0;
      padding-right: 10px;
      display: block;

      input {
        margin: 0 5px 0 0;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  `,

  quartersWrapper: css`
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
  `,

  quarters: css`
    display: block;

    label {
      padding: 0;
      margin: 0;
      padding-right: 10px;
    }

    input {
      vertical-align: middle;
      margin-right: 5px;
    }

     span {
      vertical-align: middle;
    }
  `,

  measure: css`
    vertical-align: top;
    user-select: none;
    background-color: white;
  `,

  dayPartsWrapper: css`
    vertical-align: top;
    display: inline-block;
  `,

  dayPartsRadio: css`
    display: inline-block;
    vertical-align: top;
    input {
      margin: 8px 5px 10px 0;
      vertical-align: middle;
    }
  `
};

export const BenchmarkDatesSelector = ( { helpId, isEnabled = true, dates, quarters, fromDate, toDate, onChangeDates, onChangeQuarterIsSelected, onChangeFromDate, onChangeToDate }: { helpId: string; isEnabled?: boolean; dates: BenchmarkDatesConstants; quarters: Array<BenchmarkQuarterOption>; fromDate: number; toDate: number; onChangeDates: ( value: BenchmarkDatesConstants ) => void; onChangeQuarterIsSelected: ( value: number, isSelected: boolean ) => void; onChangeFromDate: ( value: number ) => void; onChangeToDate: ( value: number ) => void; } ): JSX.Element => {
  const personalization = usePersonalization();

  useEffect( () => {
    const hasSelectedBenchmark = quarters.find( m => m.isSelected );
    if ( dates === BenchmarkDatesConstants.Quarters && quarters.length > 0 && !hasSelectedBenchmark ) {
      onChangeQuarterIsSelected( quarters[ 0 ].id, true );
    }
  }, [ dates, onChangeQuarterIsSelected, quarters ] );

  return ( <>
    <div css={ styles.benchmarkDatesSelector }>
      <fieldset>
        <legend><label>Dates <HelpText helpId={ helpId } /></label></legend>
        <div>
          <div css={ styles.dateTypesWrapper }>
            <label><input aria-label="dates" disabled={ !isEnabled } type="radio" radioGroup="benchmark_dates" checked={ dates === BenchmarkDatesConstants.BroadcastQuarter } onChange={ () => onChangeDates( BenchmarkDatesConstants.BroadcastQuarter ) } /><span>Broadcast Quarter</span></label>
            <label><input aria-label="dates" disabled={ !isEnabled } type="radio" radioGroup="benchmark_dates" checked={ dates === BenchmarkDatesConstants.PreviousFourWeeks } onChange={ () => onChangeDates( BenchmarkDatesConstants.PreviousFourWeeks ) } /><span>Previous 4 Weeks</span></label>
            <label><input aria-label="dates" disabled={ !isEnabled } type="radio" radioGroup="benchmark_dates" checked={ dates === BenchmarkDatesConstants.PreviousThirteenWeeks } onChange={ () => onChangeDates( BenchmarkDatesConstants.PreviousThirteenWeeks ) } /><span>Previous 13 Weeks</span></label>
            <label><input aria-label="dates" disabled={ !isEnabled } type="radio" radioGroup="benchmark_dates" checked={ dates === BenchmarkDatesConstants.PreviousFiftyTwoWeeks } onChange={ () => onChangeDates( BenchmarkDatesConstants.PreviousFiftyTwoWeeks ) } /><span>Previous 52 Weeks</span></label>
            <label><input aria-label="dates" disabled={ !isEnabled } type="radio" radioGroup="benchmark_dates" checked={ dates === BenchmarkDatesConstants.YearToDate } onChange={ () => onChangeDates( BenchmarkDatesConstants.YearToDate ) } /><span>Year To Date</span></label>
          </div>
          <div css={ styles.quartersWrapper }>
            <label><input aria-label="dates" disabled={ !isEnabled } type="radio" radioGroup="benchmark_dates" checked={ dates === BenchmarkDatesConstants.Quarters } onChange={ () => onChangeDates( BenchmarkDatesConstants.Quarters ) } /><span>Quarters</span></label>
            {
              quarters.map( m => {
                return ( <CheckSelector key={ m.id } value={ m.id } name={ m.name } isSelected={ m.isSelected } isEnabled={ dates === BenchmarkDatesConstants.Quarters } onChangeBenchmarkQuarterIsSelected={ onChangeQuarterIsSelected } /> );
              } )
            }
          </div>
          <div css={ styles.dayPartsWrapper }>
            <div css={ styles.dayPartsRadio }>
              <input aria-label="dates" disabled={ !isEnabled } type="radio" radioGroup="benchmark_dates" checked={ dates === BenchmarkDatesConstants.DateRange } onChange={ () => onChangeDates( BenchmarkDatesConstants.DateRange ) } />
            </div>
            <div css={ css`display:flex; flex-direction: column;` }>
              <DatePicker key={ `from${ fromDate.valueOf() }` } currentDate={ fromDate } displayFormat="dd MMM yyyy" onChangeDate={ ( dt, datePickerChangeType ) => { onChangeFromDate( dt ); personalization.syncCalendar && datePickerChangeType === DatePickerChangeTypeConstants.mouseClick && onChangeToDate( dt ); } } />
              <DatePicker key={ `to${ toDate.valueOf() }` } currentDate={ toDate } displayFormat="dd MMM yyyy" onChangeDate={ ( dt, _ ) => onChangeToDate( dt ) } />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </> );
};

const CheckSelector = ( { value, name, isSelected, isEnabled = true, onChangeBenchmarkQuarterIsSelected }: { value: number; name: string; isSelected: boolean; isEnabled?: boolean; onChangeBenchmarkQuarterIsSelected: ( value: number, isSelected: boolean ) => void; } ) => {
  return (
    <div data-key={ value } css={ styles.measure } >
      <div>
        <input aria-label={ name } type="checkbox" disabled={ !isEnabled } checked={ isSelected } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeBenchmarkQuarterIsSelected( value, e.currentTarget.checked ) } />
        <span onSelect={ ( e ) => { e.preventDefault(); return false; } }>{ name }</span>
      </div>
    </div>
  );
};
