import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const unselectedIndicesSlice = createSlice( {
  name: "unselectedIndices",
  initialState: [] as Array<number>,
  reducers: {
    ChangePreviewRowIsSelected( state, action: PayloadAction<{ id: number; isSelected: boolean; }> ) {
      const { id, isSelected } = action.payload;

      if ( isSelected ) {
        const revised = [ ...state ];
        const index = state.indexOf( id );
        if ( index >= 0 ) {
          revised.splice( index, 1 );
        }
        return revised;
      } else {
        return [ ...state, id ];
      }
    }
  }
} );

export const { ChangePreviewRowIsSelected } = unselectedIndicesSlice.actions;

export default unselectedIndicesSlice.reducer;