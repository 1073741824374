/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { BenchmarkDatesConstants, BenchmarkDayOfWeekTypeConstants, BenchmarkTimePeriodConstants, DaysConstants } from "model/Model";
import { formatDateAsEnGb, parseTimeInMinutes } from "Utilities";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const BenchmarkDescription = ( { demoCount, dates, fromDate, toDate, timePeriod, timePeriodFromTime, timePeriodToTime, benchmarkDayOfWeekType, benchmarkDays, warningMessage }: { demoCount: number; dates: BenchmarkDatesConstants; fromDate: number; toDate: number; timePeriod: BenchmarkTimePeriodConstants; timePeriodFromTime: string; timePeriodToTime: string; benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants; benchmarkDays: Array<DaysConstants>; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    if ( demoCount === 0 ) {
      results.push( ( <span key={ 0 } css={ styles.displayGroupSpace }>None.</span> ) );
    } else {
      switch ( dates ) {
        case BenchmarkDatesConstants.DateRange: {
          const fromDateFormatted = formatDateAsEnGb( fromDate );
          const fromToFormatted = formatDateAsEnGb( toDate );

          if ( fromDate.valueOf() > toDate.valueOf() ) {
            results.push( ( <span key={ 0 } css={ [ styles.displayGroupSpace, styles.warning ] }>Start Date cannot be after End Date.</span> ) );
          } else {
            results.push( ( <span key={ 0 } css={ styles.displayGroupSpace }>{ fromDateFormatted } to { fromToFormatted }</span> ) );
          }
          break;
        }
        case BenchmarkDatesConstants.BroadcastQuarter: results.push( ( <span key={ 0 } css={ styles.displayGroupSpace }>Broadcast Quarter.</span> ) ); break;
        case BenchmarkDatesConstants.PreviousFourWeeks: results.push( ( <span key={ 0 } css={ styles.displayGroupSpace }>Previous 4 Weeks.</span> ) ); break;
        case BenchmarkDatesConstants.PreviousThirteenWeeks: results.push( ( <span key={ 0 } css={ styles.displayGroupSpace }>Previous 13 Weeks.</span> ) ); break;
        case BenchmarkDatesConstants.PreviousFiftyTwoWeeks: results.push( ( <span key={ 0 } css={ styles.displayGroupSpace }>Previous 52 Weeks.</span> ) ); break;
        case BenchmarkDatesConstants.Quarters: results.push( ( <span key={ 0 } css={ styles.displayGroupSpace }>Quarters.</span> ) ); break;
        case BenchmarkDatesConstants.YearToDate: results.push( ( <span key={ 0 } css={ styles.displayGroupSpace }>Year To Date.</span> ) ); break;
      }

      switch ( timePeriod ) {
        case BenchmarkTimePeriodConstants.Daypart: results.push( ( <span key={ 1 } css={ styles.displayGroupSpace }>Daypart.</span> ) ); break;
        case BenchmarkTimePeriodConstants.TimePeriod: results.push( ( <span key={ 1 } css={ styles.displayGroupSpace }>Time Period.</span> ) ); break;
        case BenchmarkTimePeriodConstants.Times:
          {
            const benchmarkTimePeriodFromTimeInMinutes = parseTimeInMinutes( timePeriodFromTime );
            const benchmarkTimePeriodToTimeInMinutes = parseTimeInMinutes( timePeriodToTime );

            if ( benchmarkTimePeriodFromTimeInMinutes > benchmarkTimePeriodToTimeInMinutes ) {
              results.push( ( <span key={ 1 } css={ [ styles.displayGroupSpace, styles.warning ].join( " " ) }>End Time must be after Start Time.</span> ) );
            } else {
              results.push( ( <span key={ 1 } css={ styles.displayGroupSpace }>{ timePeriodFromTime } - { timePeriodToTime }</span> ) );
            }
            break;
          }
      }

      switch ( benchmarkDayOfWeekType ) {
        case BenchmarkDayOfWeekTypeConstants.SameAsSelectedDays: results.push( ( <span key={ 2 } css={ styles.displayGroupSpace }>Benchmarked on same days as selected days.</span> ) ); break;
        case BenchmarkDayOfWeekTypeConstants.TransmissionDay: results.push( ( <span key={ 2 } css={ styles.displayGroupSpace }>Benchmarked based on transmission day.</span> ) ); break;
        case BenchmarkDayOfWeekTypeConstants.SelectedDays:
          {
            const daysDescription = [];
            if ( benchmarkDays.length === 7 ) {
              daysDescription.push( "[Mon-Sun]" );
            } else {
              for ( const day of benchmarkDays ) {
                switch ( day ) {
                  case DaysConstants.monday: daysDescription.push( "Mon" ); break;
                  case DaysConstants.tuesday: daysDescription.push( "Tue" ); break;
                  case DaysConstants.wednesday: daysDescription.push( "Wed" ); break;
                  case DaysConstants.thursday: daysDescription.push( "Thu" ); break;
                  case DaysConstants.friday: daysDescription.push( "Fri" ); break;
                  case DaysConstants.saturday: daysDescription.push( "Sat" ); break;
                  case DaysConstants.sunday: daysDescription.push( "Sun" ); break;
                }
              }
            }

            results.push( ( <span css={ styles.displayGroupSpace } key={ 2 }>Benchmarked based on { daysDescription.join( ", " ) }</span> ) );
          }
      }
    }

    return results;
  }, [ benchmarkDayOfWeekType, benchmarkDays, dates, demoCount, fromDate, timePeriod, timePeriodFromTime, timePeriodToTime, toDate ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
