import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChangeUseDayParts } from "reducers/useDayPartsReducer";
import { Average, AverageTimeAverageConstants, AverageDayAverageConstants } from "../../Model";

const averageSlice = createSlice( {
  name: "average",
  initialState: {
    averageDayAverage: AverageDayAverageConstants.Daily,
    averageTimeAverage: AverageTimeAverageConstants.All
  } as Average,
  reducers: {
    ChangeAverageDayAverage( state, action: PayloadAction<AverageDayAverageConstants> ) { state.averageDayAverage = action.payload; },
    ChangeAverageTimeAverage( state, action: PayloadAction<AverageTimeAverageConstants> ) { state.averageTimeAverage = action.payload; }
  },
  extraReducers: builder => {
    builder.addCase( ChangeUseDayParts, ( state, action ) => {
      if ( !action.payload && state.averageTimeAverage === AverageTimeAverageConstants.Daypart ) {
        return { ...state, averageTimeAverage: AverageTimeAverageConstants.All };
      }

      return state;
    } );
  }
} );

export const { ChangeAverageDayAverage, ChangeAverageTimeAverage } = averageSlice.actions;

export default averageSlice.reducer;