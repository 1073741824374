/** @jsxImportSource @emotion/react */
import React, { useCallback } from "react";
import { HelpText } from "components/help-text/HelpText";
import { Check } from "components/BootstrapIcons";
import { DisplayColumn } from "../../../Model";
import { chunk, Selectable } from "Utilities";
import { CheckSelector } from "components/check-selector/CheckSelector";
import { css } from "@emotion/react";

const styles = {
  displayColumnSelector: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
    }
  `,

  displayColumn: css`
    display: block;
    float: left;
    margin-right: 4px;

    label {
      padding: 0;
      margin: 0;
      padding-right: 10px;
    }

    input {
      padding: 0;
      margin: 0;
      margin-right: 4px;
      width: auto;
    }
  `,

  check: css`
  &:hover {
    cursor: pointer;
  }
`
};

export const DisplayColumnSelector = ( { helpId, displayColumns, onChangeDisplayColumnIsSelected }: { helpId: string; displayColumns: Array<Selectable<DisplayColumn>>; onChangeDisplayColumnIsSelected: ( id: string, isSelected: boolean ) => void; } ): JSX.Element => {
  const handleTickClick = useCallback( () => {
    const allSelected = displayColumns.filter( m => m.isSelected ).length === displayColumns.length;
    for ( const col of displayColumns ) {
      if ( !allSelected ) {
        if ( !col.isSelected ) {
          onChangeDisplayColumnIsSelected( col.id, true );
        }
      } else {
        onChangeDisplayColumnIsSelected( col.id, false );
      }
    }
  }, [ displayColumns, onChangeDisplayColumnIsSelected ] );

  return (
    <div css={ styles.displayColumnSelector }>
      <fieldset>
        <legend><span css={ styles.check } onClick={ handleTickClick }><Check></Check></span><label>Columns <HelpText helpId={ helpId } /></label></legend>
        { chunk( displayColumns, displayColumns.length / 2 ).map( ( d, i ) => {
          return (
            <div key={ i } css={ styles.displayColumn } >
              {
                d.map( m => ( <CheckSelector key={ m.id } { ...m } onCheckChanged={ ( selected ) => onChangeDisplayColumnIsSelected( m.id, selected ) } /> ) )
              }
            </div>
          );
        } ) }
      </fieldset>
    </div>
  );
};
