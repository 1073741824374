/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { Index } from "apps/ChannelView/Index";
import { appendTemplateNameAndSharedWith, appendCountriesById, appendDayPartsByCountryIdAndId, appendDemosByCountryIdAndId, appendMeasuresById, appendStationsByCountryIdAndId, appendUsersAndGroupsById, enrichCountries } from "model/InitialModel";
import { HelpContext } from "components/help-text/HelpText";
import { ApplicationContext, ApplicationContextBaseType } from "components/application-context/ApplicationContext";
import { HelpText, getApplicationDisplayName } from "Utilities";
import { getStore } from "apps/ChannelView/app/store";
import { Applications } from "constants/application";
import { Params, useLoaderData } from "react-router";
import { ChannelViewSourceModel } from "./Model";
import { CountriesById } from "model/InitialModel";
import { getSessionCredentials } from "contexts/UserContext";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { helpTextCache } from "constants/helpTextCache";
import { DemoSelectionConstants, TemplateOrHistory } from "model/Model";
import { fetchHelpTextData, getJson } from "helpers/api";

const applicationId = Applications.ChannelView;

export type ApplicationContextType = ApplicationContextBaseType &
{
  readonly countriesById: CountriesById;
  readonly etsTypes: Array<{ value: string; name: string; }>;
  readonly classOnes: Array<{ value: string; name: string; }>;
  readonly classTwos: Array<{ value: string; name: string; }>;
  readonly distributors: Array<{ value: string; name: string; }>;
  readonly channelViewDisplayOptions: Array<{ value: string; name: string; }>;
};

type LoaderData = { id: number; helpText: HelpText; initialData: TemplateOrHistory<ChannelViewSourceModel>; };

export const channelViewLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const id = parseInt( params.id as string || "-1" );

  let helpText: HelpText = helpTextCache[ applicationId ];
  if ( !helpText ) {
    helpText = await fetchHelpTextData( { userId, token, applicationId } );
    helpTextCache[ applicationId ] = helpText;
  }

  const initialData = await getJson<TemplateOrHistory<ChannelViewSourceModel>>( "/Apps/ChannelView", userId, token, { id } );

  initialData.query.demoSelection = initialData.query.demoSelection || DemoSelectionConstants.selectedDemos; // default to selectedDemos for historical reports

  validateSourceModel( initialData.query );

  return { id, helpText, initialData };
};

const validateSourceModel = ( initialData: ChannelViewSourceModel ) => {
  if ( !initialData ) throw new Error( "Source data has not been loaded" );

  if ( initialData.countries === undefined ) throw new Error( "SourceData.countries hasn't loaded correctly" );
  if ( !Array.isArray( initialData.countries ) ) throw new Error( "SourceData.countries hasn't loaded correctly" );

  if ( initialData.stations === undefined ) throw new Error( "SourceData.stations hasn't loaded correctly" );
  if ( !Array.isArray( initialData.stations ) ) throw new Error( "SourceData.stations hasn't loaded correctly" );

  if ( initialData.demos === undefined ) throw new Error( "SourceData.demos hasn't loaded correctly" );
  if ( !Array.isArray( initialData.demos ) ) throw new Error( "SourceData.demos hasn't loaded correctly" );
  if ( initialData.demoSelection === undefined ) throw new Error( "SourceData.demoSelection hasn't loaded correctly" );

  if ( initialData.dateTime === undefined ) throw new Error( "SourceData.dateTime hasn't loaded correctly" );
  if ( initialData.dateTime.dayParts === undefined ) throw new Error( "SourceData.dateTime.dayParts hasn't loaded correctly" );
  if ( initialData.dateTime.days === undefined ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( !Array.isArray( initialData.dateTime.days ) ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( initialData.dateTime.fromTime === undefined ) throw new Error( "SourceData.dateTime.fromTime hasn't loaded correctly" );
  if ( initialData.dateTime.toTime === undefined ) throw new Error( "SourceData.dateTime.toTime hasn't loaded correctly" );
  if ( initialData.dateTime.useDayParts === undefined ) throw new Error( "SourceData.dateTime.useDayParts hasn't loaded correctly" );

  if ( initialData.display === undefined ) throw new Error( "SourceData.display hasn't loaded correctly" );
  if ( initialData.display.reportIncrement === undefined ) throw new Error( "SourceData.display.reportIncrement hasn't loaded correctly" );
  if ( initialData.display.selectedTypes === undefined ) throw new Error( "SourceData.display.selectedTypes hasn't loaded correctly" );
  if ( !Array.isArray( initialData.display.selectedTypes ) ) throw new Error( "SourceData.display.selectedTypes hasn't loaded correctly" );
  if ( initialData.display.selectedClassOnes === undefined ) throw new Error( "SourceData.display.selectedClassOnes hasn't loaded correctly" );
  if ( !Array.isArray( initialData.display.selectedClassOnes ) ) throw new Error( "SourceData.display.selectedClassOnes hasn't loaded correctly" );
  if ( initialData.display.selectedClassTwos === undefined ) throw new Error( "SourceData.display.selectedClassTwos hasn't loaded correctly" );
  if ( !Array.isArray( initialData.display.selectedClassTwos ) ) throw new Error( "SourceData.display.selectedClassTwos hasn't loaded correctly" );
  if ( initialData.display.selectedDistributors === undefined ) throw new Error( "SourceData.display.selectedDistributors hasn't loaded correctly" );
  if ( !Array.isArray( initialData.display.selectedDistributors ) ) throw new Error( "SourceData.display.selectedDistributors hasn't loaded correctly" );
  if ( initialData.display.selectedDisplayOptions === undefined ) throw new Error( "SourceData.display.selectedDisplayOptions hasn't loaded correctly" );
  if ( !Array.isArray( initialData.display.selectedDisplayOptions ) ) throw new Error( "SourceData.display.selectedDisplayOptions hasn't loaded correctly" );

  if ( initialData.output === undefined ) throw new Error( "SourceData.output hasn't loaded correctly" );
  if ( initialData.output.format === undefined ) throw new Error( "SourceData.output.format hasn't loaded correctly" );
  if ( initialData.output.view === undefined ) throw new Error( "SourceData.output.view hasn't loaded correctly" );
};

export const ChannelView = () => {
  const loaderData = useLoaderData() as LoaderData;
  const referenceData = useReferenceData();
  const store = getStore( loaderData.initialData.query );

  const applicationContext: ApplicationContextType = useMemo( () =>
    appendTemplateNameAndSharedWith(
      loaderData.initialData.userId,
      loaderData.initialData.name,
      loaderData.initialData.sharedWith,
      appendCountriesById(
        appendStationsByCountryIdAndId(
          appendDemosByCountryIdAndId(
            appendDayPartsByCountryIdAndId(
              appendUsersAndGroupsById(
                appendMeasuresById( {
                  applicationId,
                  templateOrHistoryId: loaderData.id,
                  benchmarkDemoTypes: referenceData.benchmarkDemoTypes,
                  measures: referenceData.measures,
                  includeIdInName: referenceData.includeIdInName,
                  countryGroups: referenceData.countryGroups,
                  sharingUsersAndGroups: referenceData.sharingUsersAndGroups,
                  etsTypes: referenceData.etsTypes,
                  classOnes: referenceData.classOnes,
                  classTwos: referenceData.classTwos,
                  distributors: referenceData.distributors,
                  countries: enrichCountries( referenceData.countries ),
                  name: getApplicationDisplayName( applicationId ),
                  channelViewDisplayOptions: [
                    { value: "Type", name: "Include Type" },
                    { value: "Class", name: "Include Class" },
                    { value: "Class2", name: "Include Class 2" },
                    { value: "Distributor", name: "Include Distributor" },
                    { value: "Wrap", name: "Wrap Text" }
                  ]
                } )
              )
            )
          )
        )
      )
    ), [ loaderData.id, loaderData.initialData.name, loaderData.initialData.sharedWith, loaderData.initialData.userId, referenceData.benchmarkDemoTypes, referenceData.classOnes, referenceData.classTwos, referenceData.countries, referenceData.countryGroups, referenceData.distributors, referenceData.etsTypes, referenceData.includeIdInName, referenceData.measures, referenceData.sharingUsersAndGroups ] );

  return (
    <ApplicationContext.Provider value={ applicationContext } key={ loaderData.id }>
      <HelpContext.Provider value={ loaderData.helpText } >
        <Provider store={ store } >
          <Index />
        </Provider>
      </HelpContext.Provider>
    </ApplicationContext.Provider>
  );
};