import { DemoRankConstants } from "model/Model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const demoRankSlice = createSlice( {
  name: "demoRank",
  initialState: DemoRankConstants.first as DemoRankConstants,
  reducers: {
    ChangeDemoRank( _, action: PayloadAction<DemoRankConstants> ) {
      return action.payload;
    }
  }
} );

export const { ChangeDemoRank } = demoRankSlice.actions;

export default demoRankSlice.reducer;