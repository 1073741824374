import { createAction, createReducer } from "@reduxjs/toolkit";
import { MinimumDurationConstants } from "../../Model";

export const ChangeMinimumDuration = createAction<MinimumDurationConstants>( "ChangeMinimumDuration" );

const minimumDurationReducers = createReducer(
  MinimumDurationConstants.All as MinimumDurationConstants,
  builder => builder.addCase( ChangeMinimumDuration, ( _, action ) => action.payload )
);

export default minimumDurationReducers;
