/** @jsxImportSource @emotion/react */
import React from "react";
import { HelpText } from "components/help-text/HelpText";
import { BenchmarkDemoTypeOption } from "apps/ProgramSearch/Model";
import { DemoTypeConstants, Measure } from "model/Model";
import { Selectable } from "Utilities";
import { css } from "@emotion/react";
import { CheckSelector } from "components/check-selector/CheckSelector";

const styles = {
  demoTypeSelector: css`
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
      }
  
      input {
        padding: 0;
        margin: 0;
        margin-right: 4px;
        width: auto;
      }
    }
  `,

  measure: css`
      display: inline-block;
      padding: 4px;
      margin: 10px;
      border: solid 1px black;
      vertical-align: top;
      user-select: none;
      background-color: white;
      
      input {
        padding: 0;
        margin: 0;
        margin-right: 10px;
        width: auto;
      }
  
      padding-left: 11px;
      padding-right: 11px;    
  `
};

export const BenchmarkDemoTypeSelector = ( { helpId, typeOptions, count, measures, onChangeDemoTypeSelected }: { helpId: string; typeOptions: Array<BenchmarkDemoTypeOption>; count: number; measures: Array<Selectable<Measure>>; onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => void; } ): JSX.Element => (
  <div css={ styles.demoTypeSelector }>
    <fieldset>
      <legend><label>Demo types <HelpText helpId={ helpId } /></label></legend>
      { typeOptions.map( m => {
        const isMeasureSelected = measures.find( n => n.value === m.value );
        const enableCheckSelector = count > 0 && ( isMeasureSelected?.isSelected ?? false );
        return ( <div key={ m.value } css={ styles.measure }><CheckSelector key={ m.value } isSelected={ m.isSelected } name={ m.name } isEnabled={ enableCheckSelector } onCheckChanged={ ( selected ) => onChangeDemoTypeSelected( m.value, selected ) } /> </div> );
      } )
      }
    </fieldset>
  </div>
);