/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { HelpText } from "components/help-text/HelpText";
import { DemoGroupByConstants } from "model/Model";
import React from "react";

const styles = {
  demoGroupBySelector: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;

        span {
          vertical-align: middle;  
        }
      }
  
      input {
        padding: 0;
        margin: 0;
        margin-right: 4px;
        width: auto;
        vertical-align: middle;
      }
    }
  `
};

export const DemoGroupBySelector = ( { helpId, demoGroupBy, onChangeDemoGroupBy }: { helpId: string; demoGroupBy: DemoGroupByConstants; onChangeDemoGroupBy: ( demoGroupBy: DemoGroupByConstants ) => void; } ): JSX.Element => (
  <div css={ styles.demoGroupBySelector }>
    <fieldset>
      <legend><label>Group by<HelpText helpId={ helpId } /></label></legend>
      <label><input type="radio" radioGroup="demoGroupBy" checked={ demoGroupBy === DemoGroupByConstants.demo } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeDemoGroupBy( e.currentTarget.checked ? DemoGroupByConstants.demo : DemoGroupByConstants.type ) } /><span>Demo</span></label>
      <label><input type="radio" radioGroup="demoGroupBy" checked={ demoGroupBy === DemoGroupByConstants.type } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeDemoGroupBy( e.currentTarget.checked ? DemoGroupByConstants.type : DemoGroupByConstants.demo ) } /><span>Type</span></label>
    </fieldset>
  </div>
);
