/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo } from "react";
import { MinimumDurationConstants, Model, WeeklyChannelViewSourceModel } from "./Model";
import { Country, DayPart, DaysConstants, Demo, DemoSelectionConstants, DemoTypeConstants, Measure, OutputFormatConstants, OutputViewConstants, Station } from "model/Model";
import { CountryDescription } from "components/country-selector/CountryDescription";
import { CountrySelector } from "components/country-selector/CountrySelector";
import { StationsDescription } from "components/station-selector/StationsDescription";
import { StationSelector } from "components/station-selector/StationSelector";
import { DemoSelector } from "components/demo-selector/DemoSelector";
import { ExpanderPanel } from "components/expander-panel/ExpanderPanel";
import { DemoTypeSelector } from "components/demo-type-selector/DemoTypeSelector";
import { OutputDescription } from "components/output-selector/OutputDescription";
import { OutputSelector } from "components/output-selector/OutputSelector";
import { HelpText } from "components/help-text/HelpText";
import { Selectable, formatDateAsIso8601 } from "Utilities";
import { DateTimeSelector } from "./components/date-time-selector/DateTimeSelector";
import { validateCanada70NoShareOrPutCutOff, validateCountryDemoDates, validateCountryStartDate, validateFromAndToTime, validateHaveDaypartInEachCountry, validateHaveDays, validateHaveDemoInEachCountry, validateHaveStationInEachCountry, validateJapan38DemosAndStations } from "ValidationHelpers";
import { CountryConstants } from "constants/CountryConstants";
import { ApplicationHeader } from "components/application-header/ApplicationHeader";
import { ChangeDemoSelection } from "reducers/demoSelectionSlice";
import { ChangeFromDate } from "reducers/fromDateReducers";
import { ChangeToDate } from "reducers/toDateReducer";
import { ChangeDemoTypePosition, ChangeDemoTypeSelected } from "reducers/measuresSlice";
import { ChangeOutputFormat, ChangeOutputView } from "reducers/outputSlice";
import { ChangeFromTime } from "reducers/fromTimeReducer";
import { ChangeToTime } from "reducers/toTimeReducer";
import { ChangeUseDayParts } from "reducers/useDayPartsReducer";
import { ChangeDaysSelected } from "reducers/daysReducer";
import { useAppDispatch, useAppState } from "./app/hooks";
import { ChangeMinimumDuration } from "./components/minimum-length-radio-selector/minimumDurationSlice";
import { ChangeCountryIsSelected } from "reducers/countriesSlice";
import { ChangeDemoIsSelected, ChangeDemoSelectedOrder } from "reducers/demosSlice";
import { ChangeStationIsSelected } from "reducers/stationsSlice";
import { ChangeDayPartIsSelected } from "reducers/dayPartReducer";
import { DemoDescription } from "./components/demo-description/DemoDescription";
import { DateTimeDescription } from "./components/date-time-selector/DateTimeDescription";
import { useApplicationContext } from "components/application-context/ApplicationContext";
import { ApplicationContextType } from "./Loader";
import { ReportProgressMessageArgs, useNotifications } from "contexts/NotificationsContext";
import { useImmer } from "use-immer";
import { getSessionCredentials } from "contexts/UserContext";
import { RunningState, downloadFile, emptyRunning } from "model/RunningState";
import { Runner } from "components/runner/Runner";
import { deleteJson, postJson, putJson } from "helpers/api";
import { useNavigate } from "react-router";
import { usePersonalization } from "contexts/PersonalizationContext";

const applicationUrl = "/Apps/WeeklyChannelView";

type PanelState = {
  selectedPanel: string;
  country: boolean;
  channel: boolean;
  demo: boolean;
  dateTime: boolean;
  output: boolean;
};

const validate = ( applicationContext: ApplicationContextType, state: Model, showDateValidation: boolean ) => {
  const validation: {
    isValidForSubmit: boolean;
    warnings: Array<string>;
    errors: Array<string>;
    country?: string;
    station?: string;
    demo?: string;
    dateTime?: string;
    output?: string;
  } = {
    isValidForSubmit: true,
    warnings: [],
    errors: []
  };

  if ( state.countries.length === 0 ) {
    validation.isValidForSubmit = false;
  }

  validateHaveStationInEachCountry( state.countries, state.stations, validation );

  validateHaveDemoInEachCountry( state.countries, state.demos, validation );

  // validateFromAndToDates( state.dateTime.fromDate, state.dateTime.toDate, validation );

  validateHaveDays( state.dateTime.days, validation );

  if ( state.dateTime.useDayParts ) {
    validateHaveDaypartInEachCountry( state.countries, state.dateTime.dayParts, validation );
  } else {
    validateFromAndToTime( state.dateTime.fromTime, state.dateTime.toTime, validation );
  }

  if ( showDateValidation ) {
    const selectedCountries = applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) );
    validateCountryStartDate( selectedCountries, state.dateTime.fromDate, validation );
    validateCountryDemoDates( selectedCountries, state.demos, state.dateTime.fromDate, state.dateTime.toDate, validation );
  }

  if ( state.countries.find( m => m === CountryConstants.Japan38 ) || state.countries.find( m => m === CountryConstants.Japan39 ) ) {
    validateJapan38DemosAndStations( applicationContext.countries, state.stations, state.demos, validation );
  }

  if ( state.countries.filter( m => m === CountryConstants.Canada70 ).length === 1 ) {
    validateCanada70NoShareOrPutCutOff( state.dateTime.toDate, state.measures, validation );
  }

  // NICE: validate display
  // NICE: validate output

  return validation;
};

const getDefaultPanelState = () => {
  const panelStateJson = localStorage.getItem( `ps:${ applicationUrl }` );
  if ( panelStateJson ) {
    return JSON.parse( panelStateJson ) as PanelState;
  } else {
    return { selectedPanel: "", country: false, channel: false, demo: false, dateTime: false, output: false };
  }
};

export const Index = (): JSX.Element => {
  const notifications = useNotifications();
  const state = useAppState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const applicationContext = useApplicationContext<ApplicationContextType>();
  const personalization = usePersonalization();

  const { token, userId } = getSessionCredentials();

  const [ running, setRunning ] = useImmer<RunningState>( emptyRunning );
  const [ panels, setPanels ] = useImmer<PanelState>( getDefaultPanelState() );
  const allExpanded = panels.country && panels.channel && panels.demo && panels.dateTime && panels.output;

  useEffect( () => {
    // savePanelState
    localStorage.setItem( `ps:${ applicationUrl }`, JSON.stringify( panels ) );
  }, [ panels ] );

  const missingStation: Selectable<Station> = useMemo( () => ( { name: "MISSING", id: -1, isTranslated: false, isSelected: false, hasRevenue: false } ), [] );
  const missingDemo: Selectable<Demo> = useMemo( () => ( { name: "MISSING", abbreviated: "MISSING", id: -1, isTotalAudienceDemo: false, isAllCommercialDemo: false, isSelected: false } ), [] );
  const missingDayPart: Selectable<DayPart> = useMemo( () => ( { time: "MISSING", name: "MISSING", id: -1, isSelected: false } ), [] );
  const missingMeasure: Measure = useMemo( () => ( { value: DemoTypeConstants.Put, name: "MISSING" } ), [] );

  const measures = useMemo( () => state.measures.map( m => ( { ...m, ...applicationContext.measures.find( n => n.value === m.value ) ?? missingMeasure } ) ), [ applicationContext.measures, missingMeasure, state.measures ] );

  const countries = useMemo( () => ( {
    demoSelection: state.demoSelection,
    measures,
    selectedMeasures: measures.filter( m => m.isSelected ),
    countryGroups: applicationContext.countryGroups.map( group => {
      const countries: Array<Selectable<Country>> = applicationContext.countries.filter( m => m.group === group ).map( m => ( { ...m, isSelected: !!state.countries.find( n => n === m.id ) } ) );
      const selectedCountryCount = countries.filter( m => m.isSelected ).length;

      return ( {
        name: group,
        countries: countries,
        isSelected: countries.length === selectedCountryCount
      } );
    } ),
    countries: applicationContext.countries.map( m => ( { ...m, isSelected: !!state.countries.find( c => c === m.id ) } ) ),
    selectedCountries: applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) ).map( country => {
      const selectedStationIds = state.stations.find( c => c.id === country.id )?.stations ?? [];
      const selectedDemoIds = state.demos.find( c => c.id === country.id )?.demos ?? [];
      const selectedDayPartIds = state.dateTime.dayParts.find( c => c.id === country.id )?.dayParts ?? [];

      const stations: Array<Selectable<Station>> = country.stations.map( m => ( { ...m, isSelected: !!selectedStationIds.find( n => n === m.id ) } ) );
      const demos: Array<Selectable<Demo>> = country.demos.map( m => ( { ...m, isSelected: !!selectedDemoIds.find( n => n === m.id ) } ) );
      const dayParts: Array<Selectable<DayPart>> = country.dayParts.map( m => ( { ...m, isSelected: !!selectedDayPartIds.find( n => n === m.id ) } ) );

      const selectedStations: Array<Selectable<Station>> = selectedStationIds.map( m => stations.find( n => n.id === m ) ?? missingStation );
      const selectedDemos: Array<Selectable<Demo>> = selectedDemoIds.map( m => demos.find( n => n.id === m ) ?? missingDemo );
      const selectedDayParts: Array<Selectable<DayPart>> = selectedDayPartIds.map( m => dayParts.find( n => n.id === m ) ?? missingDayPart );
      return ( {
        ...country,
        stations,
        demos,
        dayParts,
        selectedStations,
        selectedDemos,
        selectedDayParts,
        allStationsSelected: selectedStations.length === country.stations.length && country.stations.length !== 0,
        allDemosSelected: selectedDemos.length === country.demos.length && country.demos.length !== 0,
        noStationsSelected: selectedStations.length === 0 && country.stations.length !== 0,
        noDemosSelected: selectedDemos.length === 0 && country.demos.length !== 0
      } );
    } ),
    hasSelectedCountries: state.countries.length > 0,
    isWorldSelected: applicationContext.countries.length === state.countries.length,
    dateTime: state.dateTime,
    output: state.output
  } ), [ applicationContext.countries, applicationContext.countryGroups, measures, missingDayPart, missingDemo, missingStation, state.countries, state.dateTime, state.demoSelection, state.demos, state.output, state.stations ] );

  const validation = useMemo( () => validate( applicationContext, state, personalization.dateWarnings ), [ applicationContext, personalization.dateWarnings, state ] );

  const countryEvents = useMemo( () => ( {
    onChangeCountryIsSelected: ( countryId: number, isSelected: boolean ) => {
      const country = applicationContext.countriesById[ countryId ];
      dispatch( ChangeCountryIsSelected( { countryId, isSelected, clearExisting: false, defaultStations: country.defaultStations, defaultDemos: country.defaultDemos } ) );
    },
    onChangeStationIsSelected: ( countryId: number, stationId: number, isSelected: boolean ) => dispatch( ChangeStationIsSelected( { countryId, stationId, isSelected } ) )
  } ), [ applicationContext.countriesById, dispatch ] );

  const demoEvents = useMemo( () => ( {
    onChangeDemoSelection: ( demoSelection: DemoSelectionConstants ) => dispatch( ChangeDemoSelection( demoSelection ) ),
    onChangeDemoTypePosition: ( srcValue: DemoTypeConstants, dstValue: DemoTypeConstants ) => dispatch( ChangeDemoTypePosition( { srcValue, dstValue } ) ),
    onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => dispatch( ChangeDemoTypeSelected( { value, isSelected } ) ),
    onChangeDemoIsSelected: ( countryId: number, demoId: number, isSelected: boolean, clearExisting: boolean ) => dispatch( ChangeDemoIsSelected( { countryId, demoId, isSelected, clearExisting } ) ),
    onChangeDemoSelectedOrder: ( countryId: number, srcDemoId: number, dstDemoId: number ) => dispatch( ChangeDemoSelectedOrder( { countryId, srcDemoId, dstDemoId } ) )
  } ), [ dispatch ] );

  const dateTimeEvents = useMemo( () => ( {
    onChangeFromDate: ( fromDate: number ) => dispatch( ChangeFromDate( fromDate ) ),
    onChangeToDate: ( toDate: number ) => dispatch( ChangeToDate( toDate ) ),
    onChangeDays: ( days: Array<DaysConstants> ) => { dispatch( ChangeDaysSelected( days ) ); },
    onChangeMinimumDuration: ( minimumLength: MinimumDurationConstants ) => { dispatch( ChangeMinimumDuration( minimumLength ) ); },
    onChangeFromTime: ( fromTime: string ) => { dispatch( ChangeFromTime( fromTime ) ); },
    onChangeToTime: ( toTime: string ) => { dispatch( ChangeToTime( toTime ) ); },
    onChangeUseDayParts: ( useDayParts: boolean ) => { dispatch( ChangeUseDayParts( useDayParts ) ); },
    onChangeDayPartIsSelected: ( countryId: number, dayPartId: number, isSelected: boolean ) => dispatch( ChangeDayPartIsSelected( { countryId, dayPartId, isSelected } ) )
  } ), [ dispatch ] );

  const outputEvents = useMemo( () => ( {
    onChangeOutputFormat: ( value: OutputFormatConstants ) => dispatch( ChangeOutputFormat( value ) ),
    onChangeOutputView: ( value: OutputViewConstants ) => dispatch( ChangeOutputView( value ) )
  } ), [ dispatch ] );

  const getSourceModel = useCallback( (): WeeklyChannelViewSourceModel => {
    const model: WeeklyChannelViewSourceModel = {
      countries: state.countries,
      dateTime: {
        ...state.dateTime,
        fromDate: formatDateAsIso8601( state.dateTime.fromDate ),
        toDate: formatDateAsIso8601( state.dateTime.toDate )
      },
      demos: state.demos,
      demoSelection: state.demoSelection,
      // FUTURE: display: state.display,
      measures: state.measures,
      output: state.output,
      stations: state.stations
    };
    return model;
  }, [ state.countries, state.dateTime, state.demoSelection, state.demos, state.measures, state.output, state.stations ] );

  const doSubmit = useCallback( async () => {
    if ( validation.errors.length > 0 ) {
      const message = validation.errors.join( "\n" );
      alert( message );
      return;
    }

    if ( validation.warnings.length > 0 ) {
      const message = validation.warnings.join( "\n" );
      if ( !globalThis.window.confirm( message ) ) return;
    }

    setRunning( emptyRunning );

    const model = getSourceModel();

    const result = await postJson<{ id: number; }>( applicationUrl, userId, token, model );
    if ( result ) setRunning( m => { m.id = result.id; } );
  }, [ getSourceModel, setRunning, token, userId, validation.errors, validation.warnings ] );

  const doCancel = useCallback( async () => {
    if ( !running.id ) return;

    await deleteJson( applicationUrl, userId, token, { id: running.id } );

    setRunning( m => { m.cancellationRequested = true; } );
  }, [ running.id, setRunning, token, userId ] );

  useEffect( () => {
    if ( applicationContext.templateOrHistoryId === 0 ) setPanels( m => { m.selectedPanel = ""; m.country = true; m.channel = false; m.demo = false; m.dateTime = false; m.output = false; } );
    setRunning( emptyRunning );
  }, [ applicationContext.templateOrHistoryId, setPanels, setRunning ] );

  const reportProgressMessageHandler = useCallback( ( args: ReportProgressMessageArgs ) => {
    console.log( args );

    if ( !args ) return;
    if ( args.id !== running.id ) return;

    setRunning( m => {
      m.percentComplete = args.percentComplete;
      m.message = args.message;
      m.url = args.url;
      m.contentType = args.contentType;
      m.status = args.status;
      if ( args.status !== "Started" ) {
        m.id = undefined;
      }
    } );

    if ( args.status === "Completed" && args.contentType !== "text/html" ) {
      // perform a download
      downloadFile( args.url, token );
      setRunning( emptyRunning );
      return;
    }
  }, [ running.id, setRunning, token ] );

  useEffect( () => {
    notifications.setOnReportProgressMessageHandler( () => reportProgressMessageHandler );
  }, [ notifications, reportProgressMessageHandler ] );

  const toggleExpandedPanels = useCallback( (): void => {
    setPanels( m => {
      const isAllExpanded = allExpanded;
      m.country = !isAllExpanded;
      m.channel = !isAllExpanded;
      m.demo = !isAllExpanded;
      m.dateTime = !isAllExpanded;
      m.output = !isAllExpanded;
    } );
  }, [ allExpanded, setPanels ] );

  const onSaveTemplate = useCallback( async ( templateName: string, sharedWith: Array<number> ) => {
    const payload = {
      templateName,
      sharedWith,
      model: getSourceModel()
    };

    // positive values are history rows so always create a new template from those
    if ( applicationContext.templateOrHistoryId >= 0 ) {
      const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
      navigate( `/WeeklyChannelView/${ response.itemId }` );
    }
    else {
      const updateExistingTemplate = applicationContext.templateName === templateName;
      if ( updateExistingTemplate ) {
        await putJson( `/UserTemplate/${ applicationContext.applicationId }/${ applicationContext.templateOrHistoryId }`, userId, token, payload );
      } else {
        const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
        navigate( `/WeeklyChannelView/${ response.itemId }` );
      }
    }

  }, [ applicationContext.applicationId, applicationContext.templateName, applicationContext.templateOrHistoryId, getSourceModel, navigate, token, userId ] );

  return ( <>
    <ApplicationHeader applicationId={ applicationContext.applicationId } isAllExpanded={ allExpanded } title={ applicationContext.name } isEnabled={ countries.hasSelectedCountries } onToggleExpanded={ toggleExpandedPanels } />
    <ExpanderPanel title="Country:" isExpanded={ panels.country } onExpanded={ ( isExpanded ) => setPanels( m => { m.country = isExpanded; } ) } description={ <CountryDescription warningMessage={ validation.country } isWorldSelected={ countries.isWorldSelected } countryGroups={ countries.countryGroups } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <CountrySelector canSelectAllCountriesInGroup={ false } { ...countries } { ...countryEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Channel:" isExpanded={ panels.channel && countries.hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.channel = isExpanded; } ) } isEnabled={ countries.hasSelectedCountries } description={ <StationsDescription warningMessage={ validation.station } selectedCountries={ countries.selectedCountries } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <span css={ { fontWeight: "bold", marginLeft: "4px" } }>Channels <HelpText helpId="channel" /></span>
      <StationSelector canSelectAllCountriesInGroup={ true } { ...countries } { ...countryEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Demo:" isExpanded={ panels.demo && countries.hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.demo = isExpanded; } ) } isEnabled={ countries.hasSelectedCountries } description={ <DemoDescription warningMessage={ validation.demo } selectedMeasures={ countries.selectedMeasures } selectedCountries={ countries.selectedCountries } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <div>
        <DemoTypeSelector helpId="demoType" { ...countries } isEnabled={ false } { ...demoEvents } />
      </div>
      <DemoSelector helpId="demo" { ...countries } singleDemoOnly={ true } showDemoSelectionOptions={ false } { ...demoEvents } />
    </ExpanderPanel >
    <ExpanderPanel title="Date/Time:" isExpanded={ panels.dateTime && countries.hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.dateTime = isExpanded; } ) } isEnabled={ countries.hasSelectedCountries } description={ <DateTimeDescription warningMessage={ validation.dateTime } { ...countries.dateTime } selectedCountries={ countries.selectedCountries } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DateTimeSelector { ...countries.dateTime } selectedCountries={ countries.selectedCountries } { ...dateTimeEvents } />
    </ExpanderPanel>
    { /*
    FUTURE: include this
    <ExpanderPanel title="Display:" isExpanded={ panels.display && countries.hasSelectedCountries } onExpanded={ ( isExpanded ) => { setDisplayIsExpanded( isExpanded ); } } isEnabled={ countries.hasSelectedCountries } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DisplaySelector />
    </ExpanderPanel>
  */ }
    <ExpanderPanel title="Output:" isExpanded={ panels.output && countries.hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.output = isExpanded; } ) } isEnabled={ countries.hasSelectedCountries } description={ <OutputDescription warningMessage={ validation.output } { ...countries.output } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <OutputSelector includeCsv={ false } includeHtml={ false } includeOutputView={ false } { ...countries.output } defaultFormat={ OutputFormatConstants.Xlsx } isTemplateOrHistoryOwner={ applicationContext.templateOrHistoryOwnerId === userId } onSaveTemplate={ onSaveTemplate } { ...outputEvents } />
    </ExpanderPanel>
    <Runner running={ running } isValidForSubmit={ validation.isValidForSubmit } doSubmit={ doSubmit } doCancel={ doCancel } view={ state.output.view } applicationWindow="WeeklyChannelView" />
  </> );
};