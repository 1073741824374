/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from "react";
import { Action } from "redux";
import { Selectable, formatDateAsIso8601 } from "Utilities";
import { CountryConstants } from "constants/CountryConstants";
import { AverageCollapsePeriodConstants, AverageTitleConstants, BenchmarkDatesConstants, BenchmarkDayOfWeekTypeConstants, BenchmarkTimePeriodConstants, Country, DayPart, DaysConstants, Demo, DemoGroupByConstants, DemoSelectionConstants, DemoTypeConstants, Measure, OutputFormatConstants, OutputViewConstants, Station, TitleCompareOperatorConstants, TitleOriginalOrLocalConstants, YesNoConstants } from "model/Model";
import { CountryDescription } from "components/country-selector/CountryDescription";
import { CountrySelector } from "components/country-selector/CountrySelector";
import { StationsDescription } from "components/station-selector/StationsDescription";
import { StationSelector } from "components/station-selector/StationSelector";
import { ExpanderPanel } from "components/expander-panel/ExpanderPanel";
import { OutputDescription } from "components/output-selector/OutputDescription";
import { OutputSelector } from "components/output-selector/OutputSelector";
import { HelpText } from "components/help-text/HelpText";
import { AverageSelector } from "components/average-selector/AverageSelector";
import { TitleSelector } from "components/title-selector/TitleSelector";
import { BenchmarkQuarterOption, Display, DisplayCompetitionBreakoutConstants, DisplayDetailSheetConstants, DisplayDetailSheetOption, DisplayLeadInLeadOutBreakoutConstants, DisplayPrimarySheetConstants, DisplayPrimarySheetOption, DisplayTimeConstants, Model, ProgramPerformanceSourceModel, RevenueCalculationConstants } from "./Model";
import { RevenueSelector } from "./components/revenue-selector/RevenueSelector";
import { BenchmarkSelector } from "./components/benchmark-selector/BenchmarkSelector";
import { DisplaySelector } from "./components/display-selector/DisplaySelector";
import { Preview } from "./components/preview/Preview";
import { DateTimeSelector } from "./components/date-time-selector/DateTimeSelector";
import { DateTimeDescription } from "./components/date-time-selector/DateTimeDescription";
import { AverageDescription } from "components/average-selector/AverageDescription";
import { DisplayDescription } from "./components/display-selector/DisplayDescriptions";
import { BenchmarkDescription } from "./components/benchmark-selector/BenchmarkDescription";
import { RevenueDescription } from "./components/revenue-selector/RevenueDescription";
import { ProgramPerformanceDemoSelector } from "./components/program-performance-demo-selector/ProgramPerformanceDemoSelector";
import { ProgramPerformanceDemoDescription } from "./components/program-performance-demo-selector/ProgramPerformanceDemoDescription";
import { TitleDescription } from "components/title-selector/TitleDescription";
import { validateFromAndToDates, validateFromAndToTime, validateHaveDaypartInEachCountry, validateHaveDays, validateHaveDemoInEachCountry, validateHaveStationInEachCountry, validateBenchmarkQuarters, validateBenchmarkFromAndToDates, validateBenchmarkFromAndToTime, validateCanada70NoShareOrPutCutOff, validateJapan38DemosAndStations, validateCountryStartDate, validateCountryDemoDates, validateCountryStationsRevenueDates, validateBenchmarkDays } from "ValidationHelpers";
import { ApplicationHeader } from "components/application-header/ApplicationHeader";
import { ChangeAverageBreakoutOriginalRepeat, ChangeAverageBreakoutSeasons, ChangeAverageCollapsePeriod, ChangeAverageCombineWithin, ChangeAverageLocalRepeat, ChangeAverageTitle } from "reducers/averageSlice";
import { ChangeBenchmarkDates, ChangeBenchmarkDayOfWeekType, ChangeBenchmarkDaysSelected, ChangeBenchmarkFromDate, ChangeBenchmarkQuarterIsSelected, ChangeBenchmarkTimePeriod, ChangeBenchmarkTimePeriodFromTime, ChangeBenchmarkTimePeriodToTime, ChangeBenchmarkToDate } from "actions/benchmark/actions";
import { ChangeDemoGroupBy } from "reducers/demoGroupBySlice";
import { ChangeDemoSelection } from "reducers/demoSelectionSlice";
import { ChangeFromDate } from "reducers/fromDateReducers";
import { ChangeToDate } from "reducers/toDateReducer";
import { ChangeDemoTypePosition, ChangeDemoTypeSelected } from "reducers/measuresSlice";
import { ChangeOutputFormat, ChangeOutputView } from "reducers/outputSlice";
import { ChangeFromTime } from "reducers/fromTimeReducer";
import { ChangeToTime } from "reducers/toTimeReducer";
import { ChangeUseDayParts } from "reducers/useDayPartsReducer";
import { ChangeTitleOriginalOrLocal, ChangeTitleResultCount, ChangeTitleCompareOperator, ChangeTitleNumberOfCharactersToCompare, ChangeTitleNames } from "reducers/titleReducer";
import { ChangeDaysSelected } from "reducers/daysReducer";
import { ChangeTransmissionDuration } from "reducers/transmissionDurationReducer";
import { ChangeRevenueCalculation } from "./components/revenue-selector/revenueSlice";
import { ChangeDisplayCompetitionBreakout, ChangeDisplayDetailSheetIsSelected, ChangeDisplayLeadInLeadOutBreakout, ChangeDisplayPrimarySheetIsSelected, ChangeDisplayTime } from "./components/display-selector/displaySlice";
import { ChangePreviewIsOpen, GetPreview } from "./components/preview/previewSlice";
import { ChangeCountryIsSelected } from "reducers/countriesSlice";
import { ChangeDemoIsSelected, ChangeDemoSelectedOrder } from "reducers/demosSlice";
import { ChangeStationIsSelected } from "reducers/stationsSlice";
import { ChangeDayPartIsSelected } from "reducers/dayPartReducer";
import { useAppDispatch, useAppState } from "./app/hooks";
import { useApplicationContext } from "components/application-context/ApplicationContext";
import { ApplicationContextType } from "./Loader";
import { ChangePreviewRowIsSelected } from "./components/unselectedIndicesSlice";
import { ReportProgressMessageArgs, useNotifications } from "contexts/NotificationsContext";
import { RunningState, downloadFile, emptyRunning, openLink } from "model/RunningState";
import { useImmer } from "use-immer";
import { getSessionCredentials } from "contexts/UserContext";
import { Runner } from "components/runner/Runner";
import { deleteJson, postJson, putJson } from "helpers/api";
import { useNavigate } from "react-router";
import { usePersonalization } from "contexts/PersonalizationContext";

const applicationUrl = "/Apps/ProgramPerformance";

type PanelState = {
  selectedPanel: string;
  country: boolean;
  channel: boolean;
  title: boolean;
  dateTime: boolean;
  demo: boolean;
  average: boolean;
  revenue: boolean;
  benchmark: boolean;
  display: boolean;
  output: boolean;
  previewIsValid: boolean;
};

const validate = ( applicationContext: ApplicationContextType, state: Model, showDateValidation: boolean ) => {
  const validation: {
    isValidForSubmit: boolean;
    warnings: Array<string>;
    errors: Array<string>;
    country?: string;
    title?: string;
    station?: string;
    demo?: string;
    dateTime?: string;
    average?: string;
    revenue?: string;
    benchmark?: string;
    display?: string;
    output?: string;
  } = {
    isValidForSubmit: true,
    warnings: [],
    errors: []
  };

  if ( state.countries.length === 0 ) {
    validation.isValidForSubmit = false;
  }

  if ( state.title.titleNames.filter( m => m.isSelected ).length === 0 ) {
    validation.errors.push( "You  must select at least one title before submitting." );
  }

  validateHaveStationInEachCountry( state.countries, state.stations, validation );

  validateHaveDemoInEachCountry( state.countries, state.demos, validation );

  validateFromAndToDates( state.dateTime.fromDate, state.dateTime.toDate, validation );

  validateHaveDays( state.dateTime.days, validation );

  if ( state.dateTime.useDayParts ) {
    validateHaveDaypartInEachCountry( state.countries, state.dateTime.dayParts, validation );
  } else {
    validateFromAndToTime( state.dateTime.fromTime, state.dateTime.toTime, validation );
  }

  if ( state.benchmark.dates === BenchmarkDatesConstants.Quarters ) {
    validateBenchmarkQuarters( state.benchmark.selectedQuarters.length, validation );
  } else if ( state.benchmark.dates === BenchmarkDatesConstants.DateRange ) {
    validateBenchmarkFromAndToDates( state.benchmark.fromDate, state.benchmark.toDate, validation );
  }

  if ( state.benchmark.timePeriod === BenchmarkTimePeriodConstants.Times ) {
    validateBenchmarkFromAndToTime( state.benchmark.timePeriodFromTime, state.benchmark.timePeriodToTime, validation );
  }

  if ( state.benchmark.benchmarkDayOfWeekType === BenchmarkDayOfWeekTypeConstants.SelectedDays ) {
    validateBenchmarkDays( state.benchmark.benchmarkDays, validation );
  }

  // NICE: validate display
  // NICE: validate output
  if ( showDateValidation ) {
    const selectedCountries = applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) );
    validateCountryStartDate( selectedCountries, state.dateTime.fromDate, validation );
    validateCountryDemoDates( selectedCountries, state.demos, state.dateTime.fromDate, state.dateTime.toDate, validation );

    if ( state.revenue.revenueCalculation !== RevenueCalculationConstants.None ) {
      validateCountryStationsRevenueDates( selectedCountries, state.stations, state.dateTime.fromDate, state.dateTime.toDate, validation );
    }
  }

  if ( state.countries.find( m => m === CountryConstants.Japan38 ) || state.countries.find( m => m === CountryConstants.Japan39 ) ) {
    validateJapan38DemosAndStations( applicationContext.countries, state.stations, state.demos, validation );
  }

  if ( state.countries.filter( m => m === CountryConstants.Canada70 ).length === 1 ) {
    validateCanada70NoShareOrPutCutOff( state.dateTime.toDate, state.measures, validation );
  }

  return validation;
};
const getDefaultPanelState = () => {
  const panelStateJson = localStorage.getItem( `ps:${ applicationUrl }` );
  if ( panelStateJson ) {
    return JSON.parse( panelStateJson ) as PanelState;
  } else {
    return { selectedPanel: "", country: false, channel: false, title: false, dateTime: false, demo: false, average: false, revenue: false, benchmark: false, display: false, output: false, previewIsValid: false };
  }
};

export const Index = (): JSX.Element => {
  const notifications = useNotifications();
  const state = useAppState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const applicationContext = useApplicationContext<ApplicationContextType>();
  const personalization = usePersonalization();

  const { token, userId } = getSessionCredentials();

  const [ panels, setPanels ] = useImmer<PanelState>( getDefaultPanelState() );
  const [ running, setRunning ] = useImmer<RunningState>( emptyRunning );
  const allExpanded = panels.country && panels.channel && panels.title && panels.dateTime && panels.demo && panels.average && panels.revenue && panels.benchmark && panels.display && panels.output;

  useEffect( () => {
    // savePanelState
    localStorage.setItem( `ps:${ applicationUrl }`, JSON.stringify( panels ) );
  }, [ panels ] );

  const missingStation: Selectable<Station> = useMemo( () => ( { name: "MISSING", id: -1, isTranslated: false, isSelected: false, hasRevenue: false } ), [] );
  const missingDemo: Selectable<Demo> = useMemo( () => ( { name: "MISSING", abbreviated: "MISSING", id: -1, isTotalAudienceDemo: false, isAllCommercialDemo: false, isSelected: false } ), [] );
  const missingDayPart: Selectable<DayPart> = useMemo( () => ( { time: "MISSING", name: "MISSING", id: -1, isSelected: false } ), [] );
  const missingMeasure: Measure = useMemo( () => ( { value: DemoTypeConstants.Put, name: "MISSING" } ), [] );
  const missingBenchmarkQuarter: Selectable<BenchmarkQuarterOption> = useMemo( () => ( { id: -1, name: "MISSING", isSelected: false, isEnabled: false } ), [] );

  const measures = useMemo( () => state.measures.map( m => ( { ...m, ...applicationContext.measures.find( n => n.value === m.value ) ?? missingMeasure } ) ), [ applicationContext.measures, missingMeasure, state.measures ] );

  const benchmarkQuarters: Array<Selectable<BenchmarkQuarterOption>> = useMemo( () => {
    const results: Array<Selectable<BenchmarkQuarterOption>> = [];

    // add the current 8 quarters
    {
      const today = new Date();
      let year = today.getFullYear();
      let quarter = Math.floor( ( today.getUTCMonth() + 3 ) / 3 ); // is this correct?

      for ( let i = 0; i < 8; i++ ) {
        const id = year * 10 + quarter;
        results.push( { id, name: `${ year } Qtr ${ quarter }`, isSelected: !!state.benchmark.selectedQuarters.find( n => n === id ) } );
        quarter--;
        if ( quarter === 0 ) {
          quarter = 4;
          year--;
        }
      }
    }

    // add any others that have been selected but are no longer in the current 8 quarters
    const sorted = [ ...state.benchmark.selectedQuarters ].sort().reverse();
    for ( const id of sorted ) {
      const existing = results.find( n => n.id === id );
      if ( !existing ) {
        const quarter = id % 10;
        const year = ( id - quarter ) / 10;
        results.push( { id, name: `${ year } Qtr ${ quarter }`, isSelected: true } );
      }
    }

    return results;
  }, [ state.benchmark.selectedQuarters ] );

  const selectedBenchmarkQuarters: Array<Selectable<BenchmarkQuarterOption>> = useMemo( () => state.benchmark.selectedQuarters.map( m => ( { ...( benchmarkQuarters.find( n => n.id === m ) ?? missingBenchmarkQuarter ), isSelected: true } ) ), [ benchmarkQuarters, missingBenchmarkQuarter, state.benchmark.selectedQuarters ] );

  const countryGroups = useMemo( () => applicationContext.countryGroups.map( group => {
    const countries: Array<Selectable<Country>> = applicationContext.countries.filter( m => m.group === group ).map( m => ( { ...m, isSelected: !!state.countries.find( n => n === m.id ) } ) );
    const selectedCountryCount = countries.filter( m => m.isSelected ).length;

    return ( {
      name: group,
      countries: countries,
      isSelected: countries.length === selectedCountryCount
    } );
  } ), [ applicationContext.countries, applicationContext.countryGroups, state.countries ] );

  const countries = useMemo( () => applicationContext.countries.map( m => ( { ...m, isSelected: !!state.countries.find( c => c === m.id ) } ) ), [ applicationContext.countries, state.countries ] );
  const selectedCountryIds = useMemo( () => applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) ).map( m => m.id ), [ applicationContext.countries, state.countries ] );
  const selectedCountries = useMemo( () => applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) ).map( country => {
    const selectedStationIds = state.stations.find( c => c.id === country.id )?.stations ?? [];
    const selectedDemoIds = state.demos.find( c => c.id === country.id )?.demos ?? [];
    const selectedDayPartIds = state.dateTime.dayParts.find( c => c.id === country.id )?.dayParts ?? [];

    const stations: Array<Selectable<Station>> = country.stations.map( m => ( { ...m, isSelected: !!selectedStationIds.find( n => n === m.id ) } ) );
    const demos: Array<Selectable<Demo>> = country.demos.map( m => ( { ...m, isSelected: !!selectedDemoIds.find( n => n === m.id ) } ) );
    const dayParts: Array<Selectable<DayPart>> = country.dayParts.map( m => ( { ...m, isSelected: !!selectedDayPartIds.find( n => n === m.id ) } ) );

    const selectedStations: Array<Selectable<Station>> = selectedStationIds.map( m => stations.find( n => n.id === m ) ?? missingStation );
    const selectedDemos: Array<Selectable<Demo>> = selectedDemoIds.map( m => demos.find( n => n.id === m ) ?? missingDemo );
    const selectedDayParts: Array<Selectable<DayPart>> = selectedDayPartIds.map( m => dayParts.find( n => n.id === m ) ?? missingDayPart );
    const selectedRevenueStations: Array<Selectable<Station>> = stations.filter( m => m.hasRevenue && m.isSelected );

    return ( {
      ...country,
      stations,
      demos,
      dayParts,
      selectedStations,
      selectedDemos,
      selectedDayParts,
      allStationsSelected: selectedStations.length === country.stations.length && country.stations.length !== 0,
      allDemosSelected: selectedDemos.length === country.demos.length && country.demos.length !== 0,
      noStationsSelected: selectedStations.length === 0 && country.stations.length !== 0,
      noDemosSelected: selectedDemos.length === 0 && country.demos.length !== 0,
      hasSelectedRevenueStations: selectedRevenueStations.length > 0
    } );
  } ), [ applicationContext.countries, missingDayPart, missingDemo, missingStation, state.countries, state.dateTime.dayParts, state.demos, state.stations ] );

  const hasSelectedCountries = state.countries.length > 0;
  const isWorldSelected = applicationContext.countries.length === state.countries.length;
  const isGermanySelected = useMemo( () => ( !!state.countries.find( m => m === CountryConstants.Germany2 ) ), [ state.countries ] );
  const benchmark = useMemo( () => ( { ...state.benchmark, quarters: benchmarkQuarters, selectedQuarters: selectedBenchmarkQuarters } ), [ benchmarkQuarters, selectedBenchmarkQuarters, state.benchmark ] );
  const validation = useMemo( () => validate( applicationContext, state, personalization.dateWarnings ), [ applicationContext, personalization.dateWarnings, state ] );

  // TODO: these are constant values and could be put somewhere else
  const displayPrimarySheetOptions = useMemo( () => [
    { name: "Lead-In", value: DisplayPrimarySheetConstants.LeadIn },
    { name: "Lead-Out", value: DisplayPrimarySheetConstants.LeadOut },
    { name: "Competition", value: DisplayPrimarySheetConstants.Competition },
    { name: "Rank", value: DisplayPrimarySheetConstants.Rank },
    { name: "PercentageDifferenceVsTarget", value: DisplayPrimarySheetConstants.PercentageDifferenceVsTarget },
    { name: "PercentageDifferenceVsBenchmark", value: DisplayPrimarySheetConstants.PercentageDifferenceVsBenchmark }
  ], [] );

  // TODO: these are constant values and could be put somewhere else
  const displayDetailSheetOptions = useMemo( () => [
    { name: "LeadIn", value: DisplayDetailSheetConstants.LeadIn },
    { name: "leadOut", value: DisplayDetailSheetConstants.leadOut },
    { name: "Competition", value: DisplayDetailSheetConstants.Competition }
  ], [] );

  const displayPrimarySheet: Array<Selectable<DisplayPrimarySheetOption>> = useMemo( () => displayPrimarySheetOptions.map( m => ( { ...m, isSelected: !!state.display.selectedDisplayPrimarySheet.find( n => n === m.value ) } ) ), [ displayPrimarySheetOptions, state.display.selectedDisplayPrimarySheet ] );
  const displayDetailSheet: Array<Selectable<DisplayDetailSheetOption>> = useMemo( () => displayDetailSheetOptions.map( m => ( { ...m, isSelected: !!state.display.selectedDisplayDetailSheet.find( n => n === m.value ) } ) ), [ displayDetailSheetOptions, state.display.selectedDisplayDetailSheet ] );

  const display = useMemo( () => ( { ...state.display, displayPrimarySheet, displayDetailSheet } as Display ), [ displayDetailSheet, displayPrimarySheet, state.display ] );

  const onChangeCountryIsSelected = useCallback( ( countryId: number, isSelected: boolean, clearExisting?: boolean ) => {
    const country = applicationContext.countriesById[ countryId ];
    dispatch( ChangeCountryIsSelected( { countryId, isSelected, clearExisting: clearExisting ?? false, defaultStations: country.defaultStations, defaultDemos: country.defaultDemos } ) );
  }, [ applicationContext.countriesById, dispatch ] );

  const onChangeStationIsSelected = useCallback( ( countryId: number, stationId: number, isSelected: boolean ) => dispatch( ChangeStationIsSelected( { countryId, stationId, isSelected } ) ), [ dispatch ] );

  const titleEvents = useMemo( () => ( {
    onChangeTitleOriginalOrLocal: ( value: TitleOriginalOrLocalConstants ) => dispatch( ChangeTitleOriginalOrLocal( value ) ),
    onChangeTitleResultCount: ( value: number ) => dispatch( ChangeTitleResultCount( value ) ),
    onChangeTitleCompareOperator: ( value: TitleCompareOperatorConstants ) => dispatch( ChangeTitleCompareOperator( value ) ),
    onChangeTitleNumberOfCharactersToCompare: ( value: number ) => dispatch( ChangeTitleNumberOfCharactersToCompare( value ) ),
    onChangeTitleNames: ( values: Array<{ title: string; isSelected: boolean; }> ) => dispatch( ChangeTitleNames( values ) )
  } ), [ dispatch ] );

  const demoEvents = useMemo( () => ( {
    onChangeDemoGroupBy: ( demoGroupBy: DemoGroupByConstants ) => dispatch( ChangeDemoGroupBy( demoGroupBy ) ),
    onChangeDemoSelection: ( demoSelection: DemoSelectionConstants ) => dispatch( ChangeDemoSelection( demoSelection ) ),
    onChangeDemoTypePosition: ( srcValue: DemoTypeConstants, dstValue: DemoTypeConstants ) => dispatch( ChangeDemoTypePosition( { srcValue, dstValue } ) ),
    onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => dispatch( ChangeDemoTypeSelected( { value, isSelected } ) ),
    onChangeDemoIsSelected: ( countryId: number, demoId: number, isSelected: boolean ) => dispatch( ChangeDemoIsSelected( { countryId, demoId, isSelected, clearExisting: false } ) ),
    onChangeDemoSelectedOrder: ( countryId: number, srcDemoId: number, dstDemoId: number ) => dispatch( ChangeDemoSelectedOrder( { countryId, srcDemoId, dstDemoId } ) )
  } ), [ dispatch ] );

  const dateTimeEvents = useMemo( () => ( {
    onChangeDays: ( days: Array<DaysConstants> ) => { dispatch( ChangeDaysSelected( days ) ); },
    onChangeTransmissionDuration: ( transmissionDuration: number ) => { dispatch( ChangeTransmissionDuration( transmissionDuration ) ); },
    onChangeFromTime: ( fromTime: string ) => { dispatch( ChangeFromTime( fromTime ) ); },
    onChangeToTime: ( toTime: string ) => { dispatch( ChangeToTime( toTime ) ); },
    onChangeUseDayParts: ( useDayParts: boolean ) => { dispatch( ChangeUseDayParts( useDayParts ) ); },
    onChangeDayPartIsSelected: ( countryId: number, dayPartId: number, isSelected: boolean ) => dispatch( ChangeDayPartIsSelected( { countryId, dayPartId, isSelected } ) ),
    onChangeFromDate: ( fromDate: number ) => dispatch( ChangeFromDate( fromDate ) ),
    onChangeToDate: ( toDate: number ) => dispatch( ChangeToDate( toDate ) )
  } ), [ dispatch ] );

  const averageEvents = useMemo( () => ( {
    onChangeAverageCollapsePeriodItem: ( value: AverageCollapsePeriodConstants ) => dispatch( ChangeAverageCollapsePeriod( value ) ),
    onChangeAverageTitle: ( value: AverageTitleConstants ) => dispatch( ChangeAverageTitle( value ) ),
    onChangeAverageBreakoutSeasons: ( value: YesNoConstants ) => dispatch( ChangeAverageBreakoutSeasons( value ) ),
    onChangeAverageBreakoutOriginalRepeat: ( value: YesNoConstants ) => dispatch( ChangeAverageBreakoutOriginalRepeat( value ) ),
    onChangeAverageLocalRepeat: ( value: YesNoConstants ) => dispatch( ChangeAverageLocalRepeat( value ) ),
    onChangeAverageCombineWithin: ( value: number ) => dispatch( ChangeAverageCombineWithin( value ) )
  } ), [ dispatch ] );

  const revenueEvents = useMemo( () => ( {
    onChangeRevenueCalculation: ( value: RevenueCalculationConstants ) => dispatch( ChangeRevenueCalculation( value ) )
  } ), [ dispatch ] );

  const benchmarkEvents = useMemo( () => ( {
    onChangeBenchmarkDates: ( value: number ) => dispatch( ChangeBenchmarkDates( value ) ),
    onChangeBenchmarkQuarterIsSelected: ( value: number, isSelected: boolean ) => dispatch( ChangeBenchmarkQuarterIsSelected( { value, isSelected } ) ),
    onChangeBenchmarkFromDate: ( value: number ) => dispatch( ChangeBenchmarkFromDate( value ) ),
    onChangeBenchmarkToDate: ( value: number ) => dispatch( ChangeBenchmarkToDate( value ) ),
    onChangeBenchmarkTimePeriod: ( value: BenchmarkTimePeriodConstants ) => dispatch( ChangeBenchmarkTimePeriod( value ) ),
    onChangeBenchmarkTimePeriodFromTime: ( value: string ) => dispatch( ChangeBenchmarkTimePeriodFromTime( value ) ),
    onChangeBenchmarkTimePeriodToTime: ( value: string ) => dispatch( ChangeBenchmarkTimePeriodToTime( value ) ),
    onChangeBenchmarkDayOfWeekType: ( value: BenchmarkDayOfWeekTypeConstants ) => dispatch( ChangeBenchmarkDayOfWeekType( value ) ),
    onChangeBenchmarkDaysSelected: ( days: Array<DaysConstants> ) => dispatch( ChangeBenchmarkDaysSelected( days ) )
  } ), [ dispatch ] );

  const displayEvents = useMemo( () => ( {
    onChangeDisplayPrimarySheetIsSelected: ( value: DisplayPrimarySheetConstants, isSelected: boolean ) => dispatch( ChangeDisplayPrimarySheetIsSelected( { value, isSelected } ) ),
    onChangeDisplayCompetitionBreakout: ( value: DisplayCompetitionBreakoutConstants ) => dispatch( ChangeDisplayCompetitionBreakout( value ) ),
    onChangeDisplayLiLoBreakout: ( value: DisplayLeadInLeadOutBreakoutConstants ) => dispatch( ChangeDisplayLeadInLeadOutBreakout( value ) ),
    onChangeDisplayDetailSheetIsSelected: ( value: DisplayDetailSheetConstants, isSelected: boolean ) => dispatch( ChangeDisplayDetailSheetIsSelected( { value, isSelected } ) ),
    onChangeDisplayTime: ( value: DisplayTimeConstants ) => dispatch( ChangeDisplayTime( value ) )
  } ), [ dispatch ] );

  const outputEvents = useMemo( () => ( {
    onChangeOutputFormat: ( value: OutputFormatConstants ) => dispatch( ChangeOutputFormat( value ) ),
    onChangeOutputView: ( value: OutputViewConstants ) => dispatch( ChangeOutputView( value ) )
  } ), [ dispatch ] );

  const previewEvents = useMemo( () => ( {
    onChangePreviewIsOpen: ( value: boolean ) => dispatch( ChangePreviewIsOpen( value ) ),
    onChangePreviewRowIsSelected: ( id: number, isSelected: boolean ) => dispatch( ChangePreviewRowIsSelected( { id, isSelected } ) ),
    getPreview: () => dispatch( GetPreview() as unknown as Action<unknown> )
  } ), [ dispatch ] );

  const invalidatePreview = useCallback( ( isExpanded: boolean ) => {
    if ( !isExpanded ) return;
    if ( panels.previewIsValid ) return;
    console.log( "invalidate preview" );
    previewEvents.onChangePreviewIsOpen( false );
  }, [ panels.previewIsValid, previewEvents ] );

  const expandPreview = useCallback( ( isExpanded: boolean ) => {
    if ( !isExpanded ) return;
    setPanels( m => {
      m.country = false;
      m.channel = false;
      m.title = false;
      m.demo = false;
      m.dateTime = false;
      m.previewIsValid = false;
    } );
    previewEvents.onChangePreviewIsOpen( true );
    previewEvents.getPreview();
  }, [ previewEvents, setPanels ] );

  const getSourceModel = useCallback( (): ProgramPerformanceSourceModel => {
    const model: ProgramPerformanceSourceModel = {
      countries: state.countries,
      dateTime: {
        ...state.dateTime,
        fromDate: formatDateAsIso8601( state.dateTime.fromDate ),
        toDate: formatDateAsIso8601( state.dateTime.toDate )
      },
      demos: state.demos,
      display: state.display,
      measures: state.measures,
      output: state.output,
      stations: state.stations,
      average: state.average,
      benchmark: {
        ...state.benchmark,
        fromDate: formatDateAsIso8601( state.benchmark.fromDate ),
        toDate: formatDateAsIso8601( state.benchmark.toDate )
      },
      demoGroupBy: state.demoGroupBy,
      demoSelection: state.demoSelection,
      revenue: state.revenue,
      title: state.title,
      unselectedIndices: state.unselectedIndices
    };

    return model;
  }, [ state.average, state.benchmark, state.countries, state.dateTime, state.demoGroupBy, state.demoSelection, state.demos, state.display, state.measures, state.output, state.revenue, state.stations, state.title, state.unselectedIndices ] );

  const doSubmit = useCallback( async () => {
    if ( validation.errors.length > 0 ) {
      const message = validation.errors.join( "\n" );
      alert( message );
      return;
    }

    if ( validation.warnings.length > 0 ) {
      const message = validation.warnings.join( "\n" );
      if ( !globalThis.window.confirm( message ) ) return;
    }

    setRunning( emptyRunning );

    const model = getSourceModel();

    const result = await postJson<{ id: number; }>( applicationUrl, userId, token, model );
    if ( result ) setRunning( m => { m.id = result.id; } );

  }, [ getSourceModel, setRunning, token, userId, validation.errors, validation.warnings ] );

  const doCancel = useCallback( async () => {
    if ( !running.id ) return;

    await deleteJson( applicationUrl, userId, token, { id: running.id } );

    setRunning( m => { m.cancellationRequested = true; } );
  }, [ running.id, setRunning, token, userId ] );

  useEffect( () => {
    if ( applicationContext.templateOrHistoryId === 0 ) setPanels( m => { m.selectedPanel = ""; m.country = true; m.channel = false; m.title = false; m.dateTime = false; m.demo = false; m.average = false; m.revenue = false; m.benchmark = false; m.display = false; m.output = false; } );
    setRunning( emptyRunning );
  }, [ applicationContext.templateOrHistoryId, setPanels, setRunning ] );

  const reportProgressMessageHandler = useCallback( ( args: ReportProgressMessageArgs ) => {
    console.log( args );

    if ( !args ) return;
    if ( args.id !== running.id ) return;

    setRunning( m => {
      m.percentComplete = args.percentComplete;
      m.message = args.message;
      m.url = args.url;
      m.contentType = args.contentType;
      m.status = args.status;
      if ( args.status !== "Started" ) {
        m.id = undefined;
      }
    } );

    if ( args.status === "Completed" ) {
      if ( args.contentType !== "text/html" ) {
        downloadFile( args.url, token ); // perform a download
        setRunning( emptyRunning );
      } else if ( args.contentType === "text/html" ) {
        openLink( args.url, "ProgramPerformance", state.output.view );
        // setRunning( emptyRunning ); can only do this if we know that the tab has been opened
      }
      return;
    }
  }, [ running.id, setRunning, state.output.view, token ] );

  useEffect( () => {
    notifications.setOnReportProgressMessageHandler( () => reportProgressMessageHandler );
  }, [ notifications, reportProgressMessageHandler ] );

  const toggleExpandedPanels = useCallback( () => {
    setPanels( m => {
      const isAllExpanded = allExpanded;
      m.country = !isAllExpanded;
      m.channel = !isAllExpanded;
      m.title = !isAllExpanded;
      m.demo = !isAllExpanded;
      m.dateTime = !isAllExpanded;
      m.average = !isAllExpanded;
      m.revenue = !isAllExpanded;
      m.benchmark = !isAllExpanded;
      m.display = !isAllExpanded;
      m.output = !isAllExpanded;
    } );
  }, [ allExpanded, setPanels ] );

  const onSaveTemplate = useCallback( async ( templateName: string, sharedWith: Array<number> ) => {
    const payload = {
      templateName,
      sharedWith,
      model: getSourceModel()
    };

    // positive values are history rows so always create a new template from those
    if ( applicationContext.templateOrHistoryId >= 0 ) {
      const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
      navigate( `/ProgramPerformance/${ response.itemId }` );
    }
    else {
      const updateExistingTemplate = applicationContext.templateName === templateName;
      if ( updateExistingTemplate ) {
        await putJson( `/UserTemplate/${ applicationContext.applicationId }/${ applicationContext.templateOrHistoryId }`, userId, token, payload );
      }
      else {
        const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
        navigate( `/ProgramPerformance/${ response.itemId }` );
      }
    }

  }, [ applicationContext.applicationId, applicationContext.templateName, applicationContext.templateOrHistoryId, getSourceModel, navigate, token, userId ] );

  return ( <>
    <ApplicationHeader applicationId={ applicationContext.applicationId } isAllExpanded={ allExpanded } title={ applicationContext.name } isEnabled={ hasSelectedCountries } onToggleExpanded={ toggleExpandedPanels } />
    <ExpanderPanel title="Country:" isExpanded={ panels.country } selectedExpanderPanel={ panels.selectedPanel } description={ <CountryDescription warningMessage={ validation.country } isWorldSelected={ isWorldSelected } countryGroups={ countryGroups } /> } onExpanded={ ( isExpanded ) => { setPanels( m => { m.country = isExpanded; } ); invalidatePreview( isExpanded ); } } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <CountrySelector singleCountryOnly={ true } canSelectAllCountriesInGroup={ false } countries={ countries } countryGroups={ countryGroups } isWorldSelected={ isWorldSelected } onChangeCountryIsSelected={ onChangeCountryIsSelected } />
    </ExpanderPanel>
    <ExpanderPanel title="Title:" isExpanded={ panels.title } selectedExpanderPanel={ panels.selectedPanel } description={ <TitleDescription warningMessage={ validation.title } { ...state.title } /> } isEnabled={ hasSelectedCountries } onExpanded={ ( isExpanded ) => { setPanels( m => { m.title = isExpanded; } ); invalidatePreview( isExpanded ); } } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <TitleSelector { ...state.title } countries={ selectedCountryIds }  { ...titleEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Channel:" isExpanded={ panels.channel } selectedExpanderPanel={ panels.selectedPanel } isEnabled={ hasSelectedCountries } description={ <StationsDescription warningMessage={ validation.station } selectedCountries={ selectedCountries } /> } onExpanded={ ( isExpanded ) => { setPanels( m => { m.channel = isExpanded; } ); invalidatePreview( isExpanded ); } } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <span css={ { fontWeight: "bold", marginLeft: "4px" } }>Channels <HelpText helpId="channel" /></span>
      <StationSelector canSelectAllCountriesInGroup={ true } selectedCountries={ selectedCountries } onChangeStationIsSelected={ onChangeStationIsSelected } />
    </ExpanderPanel>
    <ExpanderPanel title="Demo:" isExpanded={ panels.demo } selectedExpanderPanel={ panels.selectedPanel } isEnabled={ hasSelectedCountries } description={ <ProgramPerformanceDemoDescription warningMessage={ validation.demo } demoGroupBy={ state.demoGroupBy } demoSelection={ state.demoSelection } selectedCountries={ selectedCountries } measures={ measures } /> } onExpanded={ ( isExpanded ) => { setPanels( m => { m.demo = isExpanded; } ); invalidatePreview( isExpanded ); } } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <ProgramPerformanceDemoSelector selectedCountries={ selectedCountries } measures={ measures } demoGroupBy={ state.demoGroupBy } demoSelection={ state.demoSelection } { ...demoEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Date/Time:" isExpanded={ panels.dateTime } selectedExpanderPanel={ panels.selectedPanel } isEnabled={ hasSelectedCountries } description={ <DateTimeDescription warningMessage={ validation.dateTime } { ...state.dateTime } selectedCountries={ selectedCountries } /> } onExpanded={ ( isExpanded ) => { setPanels( m => { m.dateTime = isExpanded; } ); invalidatePreview( isExpanded ); } } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DateTimeSelector { ...state.dateTime } selectedCountries={ selectedCountries } { ...dateTimeEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Target preview:" isExpanded={ state.preview.isOpen } selectedExpanderPanel={ panels.selectedPanel } isEnabled={ hasSelectedCountries } description={ state.preview.isOpen && !state.preview.isLoading ? `${ state.preview.rows.length - state.unselectedIndices.length } targets` : "" } onExpanded={ ( isExpanded ) => { setPanels( m => { m.dateTime = isExpanded; } ); expandPreview( isExpanded ); } } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <Preview { ...state.preview } { ...previewEvents } unselectedIndices={ state.unselectedIndices } onSelectItem={ previewEvents.onChangePreviewRowIsSelected } />
    </ExpanderPanel>
    <ExpanderPanel title="Average:" isExpanded={ panels.average } onExpanded={ ( isExpanded ) => { setPanels( m => { m.average = isExpanded; } ); } } isEnabled={ hasSelectedCountries } selectedExpanderPanel={ panels.selectedPanel } description={ <AverageDescription warningMessage={ validation.average } { ...state.average } /> } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <AverageSelector { ...state.average } { ...averageEvents } isGermanySelected={ isGermanySelected } />
    </ExpanderPanel>
    <ExpanderPanel title="Revenue:" isExpanded={ panels.revenue } onExpanded={ ( isExpanded ) => { setPanels( m => { m.revenue = isExpanded; } ); } } isEnabled={ hasSelectedCountries && selectedCountries.filter( m => m.hasSelectedRevenueStations ).length > 0 } selectedExpanderPanel={ panels.selectedPanel } description={ <RevenueDescription warningMessage={ validation.revenue } { ...state.revenue } /> } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <RevenueSelector { ...state.revenue } { ...revenueEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Benchmark:" isExpanded={ panels.benchmark } onExpanded={ ( isExpanded ) => { setPanels( m => { m.benchmark = isExpanded; } ); } } isEnabled={ hasSelectedCountries } selectedExpanderPanel={ panels.selectedPanel } description={ <BenchmarkDescription warningMessage={ validation.benchmark } { ...benchmark } /> } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <BenchmarkSelector { ...benchmark } { ...benchmarkEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Display:" isExpanded={ panels.display } onExpanded={ ( isExpanded ) => { setPanels( m => { m.display = isExpanded; } ); } } isEnabled={ hasSelectedCountries } selectedExpanderPanel={ panels.selectedPanel } description={ <DisplayDescription warningMessage={ validation.display } { ...display } /> } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DisplaySelector { ...display } { ...displayEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Output:" isExpanded={ panels.output } onExpanded={ ( isExpanded ) => { setPanels( m => { m.output = isExpanded; } ); } } isEnabled={ hasSelectedCountries } selectedExpanderPanel={ panels.selectedPanel } description={ <OutputDescription warningMessage={ validation.output } { ...state.output } /> } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <OutputSelector includeCsv={ false } { ...state.output } defaultFormat={ OutputFormatConstants.Xlsx } isTemplateOrHistoryOwner={ applicationContext.templateOrHistoryOwnerId === userId } onSaveTemplate={ onSaveTemplate } { ...outputEvents } />
    </ExpanderPanel>
    <Runner running={ running } isValidForSubmit={ validation.isValidForSubmit } doSubmit={ doSubmit } doCancel={ doCancel } view={ state.output.view } applicationWindow="ProgramPerformance" />
  </> );
};
