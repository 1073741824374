/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useMemo } from "react";
import { Selectable } from "Utilities";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const DistributorDescription = ( { distributorNames, warningMessage }: { distributorNames: Array<Selectable<{ distributor: string; }>>; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];
    const selectedDistributorNames = distributorNames.filter( m => m.isSelected );
    const description = selectedDistributorNames.length === 0 ? "None selected." : selectedDistributorNames.map( m => m.distributor ).join( ", " );
    results.push( ( <span key="distributors_selected" css={ styles.displayGroupSpace }>{ description }</span> ) );
    return results;
  }, [ distributorNames ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  return ( parts.length > 0 ? <>{ parts }</> : <></> );
};
