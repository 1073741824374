/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo } from "react";
import { Personalization } from "apps/ApplicationPanels/Model";
import { DropdownSelector } from "components/dropdown-selector/DropdownSelector";
import { HelpText } from "components/help-text/HelpText";
import { css } from "@emotion/react";
import { useUser } from "contexts/UserContext";
import { CheckSelector } from "components/check-selector/CheckSelector";
import { postJson } from "helpers/api";
import { usePersonalization } from "contexts/PersonalizationContext";
import { useImmer } from "use-immer";

const styles = {
  helpText: css`
    float: right; 
    background-color: white; 
    margin-right: 10px; 
    margin-top: 10px;
  `,

  panel: css`
    background-color: white; 
    height: 225px;
    padding-left: 5px;
  `
};

export const PersonalizationPanel = ( { helpId }: { helpId: string; } ): JSX.Element => {
  const user = useUser();
  const personalization = usePersonalization();
  const dateFormatItems = [ { name: "M/d/yyyy", value: "MM/dd/yyyy" }, { name: "d/M/yyyy", value: "dd/MM/yyyy" } ];
  const [ model, setModel ] = useImmer<Personalization>( personalization );

  const doSubmit = useCallback( async () => {
    const payload: Personalization = { ...model };

    // translate to the old format
    if ( payload.dateFormat === "MM/dd/yyyy" ) payload.dateFormat = "M/d/yyyy" as never;
    if ( payload.dateFormat === "dd/MM/yyyy" ) payload.dateFormat = "d/M/yyyy" as never;

    await postJson<Personalization, Personalization>( "/Personalization", user.userId, user.token, payload );

    // update the context - this might not be needed, as in theory the notifications will pass this around anyway
    personalization.setDateFormat( model.dateFormat );
    personalization.setDateWarnings( model.dateWarnings );
    personalization.setSyncCalendar( model.syncCalendar );
    personalization.setDisplayForeignTitle( model.displayForeignTitle );
    personalization.setAlternateRowColorInExcel( model.alternateRowColorInExcel );
  }, [ model, personalization, user.token, user.userId ] );

  useEffect( () => {
    // update the local model when the context changes (e.g. via a notification)
    setModel( personalization );
  }, [ personalization, setModel ] );

  const haveChanges = useMemo( () => {
    if ( personalization.alternateRowColorInExcel !== model.alternateRowColorInExcel ) return true;
    if ( personalization.dateFormat !== model.dateFormat ) return true;
    if ( personalization.dateWarnings !== model.dateWarnings ) return true;
    if ( personalization.displayForeignTitle !== model.displayForeignTitle ) return true;
    if ( personalization.syncCalendar !== model.syncCalendar ) return true;
    return false;
  }, [ model.alternateRowColorInExcel, model.dateFormat, model.dateWarnings, model.displayForeignTitle, model.syncCalendar, personalization.alternateRowColorInExcel, personalization.dateFormat, personalization.dateWarnings, personalization.displayForeignTitle, personalization.syncCalendar ] );

  return ( <>
    <div css={ styles.helpText }>
      <HelpText helpId={ helpId } />
    </div>
    <div css={ styles.panel }>
      <DropdownSelector value={ model.dateFormat } label="Date format" helpId="" items={ dateFormatItems } onChangeItemSelected={ ( value ) => setModel( e => { e.dateFormat = value as "dd/MM/yyyy" | "MM/dd/yyyy"; } ) } />
      <CheckSelector isEnabled={ true } isSelected={ model.syncCalendar } onCheckChanged={ ( isSelected ) => setModel( e => { e.syncCalendar = isSelected; } ) } name="Sync Calendar" />
      <CheckSelector isEnabled={ true } isSelected={ model.dateWarnings } onCheckChanged={ ( isSelected ) => setModel( e => { e.dateWarnings = isSelected; } ) } name="Date Warnings" />
      <hr />
      <CheckSelector isEnabled={ true } isSelected={ model.alternateRowColorInExcel } onCheckChanged={ ( isSelected ) => setModel( e => { e.alternateRowColorInExcel = isSelected; } ) } name="Alternate row color in excel?" />
      <hr />
      <CheckSelector isEnabled={ true } isSelected={ model.displayForeignTitle } onCheckChanged={ ( isSelected ) => setModel( e => { e.displayForeignTitle = isSelected; } ) } name="Display foreign title?" />
      <button type="button" disabled={ !haveChanges } onClick={ doSubmit }>Save</button>
    </div>
  </> );
};
