/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { DurationConstants } from "../../Model";

const styles = {
  durationSelector: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        display: block;
        margin-bottom: 0.1rem;
  
        input {
          margin: 0 5px 0 0;
          width: auto;
          vertical-align: middle;          
        }

        span {
          vertical-align: middle;
        }
      }
    }
  `
};

export const DurationSelector = ( { duration, onChangeDuration }: { duration: DurationConstants; onChangeDuration: ( duration: DurationConstants ) => void; } ): JSX.Element => (
  <div css={ styles.durationSelector } >
    <fieldset>
      <legend><label>Program Length</label></legend>
      <label><input type="radio" title="All transmission lengths." radioGroup="programLength" checked={ duration === DurationConstants.All } onChange={ () => onChangeDuration( DurationConstants.All ) } /><span>All (10+ Min)</span></label>
      <label><input type="radio" title="Transmissions ½ Hour (10-39 Min)." radioGroup="programLength" checked={ duration === DurationConstants.HalfHour } onChange={ () => onChangeDuration( DurationConstants.HalfHour ) } /><span>½ Hour (10-39 Min)</span></label>
      <label><input type="radio" title="Transmissions 1 Hour (40-79 Min)." radioGroup="programLength" checked={ duration === DurationConstants.Hour } onChange={ () => onChangeDuration( DurationConstants.Hour ) } /><span>1 Hour (40-79 Min)</span></label>
      <label><input type="radio" title="Transmissions ½ Hour - 1 Hour (10-79 Min)." radioGroup="programLength" checked={ duration === DurationConstants.HalfHourToHour } onChange={ () => onChangeDuration( DurationConstants.HalfHourToHour ) } /><span>½ Hour - 1 Hour (10-79 Min)</span></label>
      <label><input type="radio" title="Transmissions Film (80-120 Min)." radioGroup="programLength" checked={ duration === DurationConstants.Film80To120Minutes } onChange={ () => onChangeDuration( DurationConstants.Film80To120Minutes ) } /><span>Film (80-120 Min)</span></label>
      <label><input type="radio" title="Transmissions Film (120+ Min)." radioGroup="programLength" checked={ duration === DurationConstants.Film120MinutesPlus } onChange={ () => onChangeDuration( DurationConstants.Film120MinutesPlus ) } /><span>Film (120+ Min)</span></label>
      <label><input type="radio" title="Transmissions Film (80+ Min)." radioGroup="programLength" checked={ duration === DurationConstants.Film80MinutesPlus } onChange={ () => onChangeDuration( DurationConstants.Film80MinutesPlus ) } /><span>Film (80+ Min)</span></label>
    </fieldset>
  </div> );
