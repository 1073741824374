/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { AverageDayAverageConstants, AverageTimeAverageConstants } from "apps/QuarterHour/Model";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const AverageDescription = ( { averageDayAverage, averageTimeAverage, warningMessage }: { averageDayAverage: AverageDayAverageConstants; averageTimeAverage: AverageTimeAverageConstants; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    const dayAverageDescription = ( ( value ): string => {
      switch ( value ) {
        case AverageDayAverageConstants.Daily: return "Daily,";
        case AverageDayAverageConstants.DayOfWeek: return "Day of Week,";
        case AverageDayAverageConstants.WeeklyMonSun: return "Weekly Mon-Sun,";
        case AverageDayAverageConstants.WeeklySunSat: return "Weekly Sun-Sat,";
        case AverageDayAverageConstants.Monthly: return "Monthly,";
        case AverageDayAverageConstants.Quarterly: return "Quarterly,";
        case AverageDayAverageConstants.Yearly: return "Yearly,";
        case AverageDayAverageConstants.Period: return "Period,";

        default: return "";
      }
    } )( averageDayAverage );

    results.push( ( <span key="average_day_Average" css={ styles.displayGroupSpace }>Day Average: { dayAverageDescription }</span> ) );

    const timeAverageDescription = ( ( value ): string => {
      switch ( value ) {
        case AverageTimeAverageConstants.All: return "All.";
        case AverageTimeAverageConstants.QuarterHour: return "¼ Hour.";
        case AverageTimeAverageConstants.HalfHour: return "½ Hour.";
        case AverageTimeAverageConstants.Hour: return "1 Hour.";
        case AverageTimeAverageConstants.Daypart: return "Daypart.";
        default: return "";
      }
    } )( averageTimeAverage );

    results.push( ( <span key="average_time_Average" css={ styles.displayGroupSpace }>Time Average: { timeAverageDescription }</span> ) );
    return results;
  }, [ averageDayAverage, averageTimeAverage ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
