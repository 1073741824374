/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { css } from "@emotion/react";
import { Selectable } from "Utilities";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const QualifyDescription = ( { types, formats, warningMessage }: { types: Array<Selectable<{ name: string; }>>; formats: Array<Selectable<{ name: string; }>>; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];
    const selectedTypes = types.filter( m => m.isSelected );
    const qualifyTypeDescription = selectedTypes.length === 0 ? "All." : selectedTypes.map( m => m.name ).join( ", " );
    results.push( ( <span key="qualify_type" css={ styles.displayGroupSpace }>Type={ qualifyTypeDescription }</span> ) );

    const selectedFormats = formats.filter( m => m.isSelected );
    const qualifyFormatDescription = selectedFormats.length === 0 ? "All." : selectedFormats.map( m => m.name ).join( ", " );
    results.push( ( <span key="qualify_format" css={ styles.displayGroupSpace }>Format={ qualifyFormatDescription }</span> ) );
    return results;
  }, [ formats, types ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  return <>{ parts }</>;
};
