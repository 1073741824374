/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import logo from "./ets_logo_full.png";
import { Link, useNavigate } from "react-router-dom";
import { logoutFromSessionStorage, useUser } from "contexts/UserContext";
import { ChangeEvent, useCallback } from "react";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { putJson } from "helpers/api";

export const height = 40;

const styles = {
  header: css`
    height: ${ height }px;
    display: flex;
    justify-content: space-between;
    background-color: #e4e8ec;
  `,
  logo: css`
    height: 35px; 
    padding-left: 13px;
    padding-top: 3px;
  `,
  logout: css`
    color: #cc1313; 
    font-weight: bold;
    border: none;
    background-color: transparent;
    cursor: pointer;
  `,
  right: css`
    margin-top: 7px;
    margin-right: 22px;
  `
};

type SwitchResult = {
  userId: number;
  jwt: string;
  username: string;
  name: string;
};

export const PageHeader = () => {
  const user = useUser();
  const navigate = useNavigate();
  const referenceData = useReferenceData();

  const doLogout = useCallback( () => {
    if ( user && user.logout ) {
      user.logout();
    } else {
      logoutFromSessionStorage();
    }
    navigate( "/login", { replace: true } );
  }, [ navigate, user ] );

  const switchUser = useCallback( async ( e: ChangeEvent<HTMLSelectElement> ) => {
    const value = e.currentTarget.value;
    if ( !value ) return;

    const userId = parseInt( value );
    console.log( "Switching to user:", userId );

    const response = await putJson<SwitchResult>( "/authenticate", user.userId, user.token, { userId } );

    sessionStorage.setItem( "userId", response.userId.toString() );
    sessionStorage.setItem( "token", response.jwt );
    sessionStorage.setItem( "username", response.username );
    sessionStorage.setItem( "name", response.name );

    globalThis.window.location.href = globalThis.window.location.origin;
  }, [ user.token, user.userId ] );

  const haveSwitch = referenceData.switchCompanies && referenceData.switchUsers && referenceData.switchCompanies.length > 0;

  return ( <>
    <div css={ styles.header }>
      <div>
        <Link to="/"><img css={ styles.logo } alt="logo" src={ logo }></img></Link>
      </div>
      <div css={ styles.right }>
        { haveSwitch && <>
          <span>
            <select aria-label="Switch user" name="switchUser" defaultValue={ user.userId } onChange={ switchUser }>
              <option>Switch user</option>
              {
                referenceData.switchCompanies?.map( m => <optgroup key={ m.companyId } label={ m.display }>
                  { referenceData.switchUsers?.filter( n => n.companyId === m.companyId ).map( n =>
                    <option value={ n.userId } key={ n.userId }>{ n.display }</option>
                  ) }
                </optgroup> )
              }
            </select>
          </span>
        </> }
        { !haveSwitch && <span>{ user.name }</span> }
        <span><button css={ styles.logout } type="button" onClick={ doLogout } >Logout</button></span>
      </div>
    </div >
  </> );
};