export enum Applications {
  Result = -2,
  AvailableData = -1,
  Personalization = 0,
  ChannelView = 1,
  DistributorFinder = 8,
  ProgramFinder = 7,
  ProgramPerformance = 3,
  ProgramRanking = 4,
  ProgramSearch = 2,
  QuarterHour = 5,
  RevenueEstimator = 6,
  WeeklyChannelView = 9,
  ProgramAverageTimeSpent = 10
}
