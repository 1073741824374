import { QualifyFormatConstants } from "constants/QualifyFormatConstants";
import { AverageCollapsePeriodConstants, AverageTitleConstants, BenchmarkDatesConstants, BenchmarkTimePeriodConstants, DaysConstants, DemoGroupByConstants, DemoRankConstants, DemoSelectionConstants, DemoTypeConstants, Output, ReportTypeSummaryConstants, RevenueAmountConstants, RevenueCollapseConstants, RevenueDurationConstants, YesNoConstants, BenchmarkDayOfWeekTypeConstants } from "model/Model";
import { Selectable } from "Utilities";

export type Model = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  measures: Array<Selectable<{ value: DemoTypeConstants; isRanked: boolean; }>>;
  demoGroupBy: DemoGroupByConstants;
  demoRank: DemoRankConstants;
  demoSelection: DemoSelectionConstants;
  dateTime: DateTime;
  reportType: ReportType;
  average: Average;
  revenue: Revenue;
  benchmark: Benchmark;
  display: Display;
  qualify: Qualify;
  output: Output;
};

export type DateTimeSource = {
  fromDate?: string;
  toDate?: string;
  days: Array<DaysConstants>;
  transmissionDuration: number;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};

export type BenchmarkSource = {
  demoCount: number;
  demoTypes: Array<BenchmarkDemoTypeOption>;
  dates: BenchmarkDatesConstants;
  selectedQuarters: Array<number>;
  fromDate: string;
  toDate: string;
  timePeriod: BenchmarkTimePeriodConstants;
  timePeriodFromTime: string;
  timePeriodToTime: string;
  benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants;
  benchmarkDays: Array<DaysConstants>;
};

export type ProgramRankingSourceModel = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  measures: Array<Selectable<{ value: DemoTypeConstants; isRanked: boolean; }>>;
  demoGroupBy: DemoGroupByConstants;
  demoRank: DemoRankConstants;
  demoSelection: DemoSelectionConstants;
  dateTime: DateTimeSource;
  reportType: ReportType;
  average: Average;
  revenue: Revenue;
  benchmark: BenchmarkSource;
  display: Display;
  qualify: Qualify;
  output: Output;
};

export enum QualifyLocalGenreDisplayConstants {
  Description = 1,
  Id = 2,
  Raw = 3
}

export enum DisplaySortedByConstants {
  CountryChannel = 4,
  CountryDate = 0,
  Title = 1,
  StartDate = 2,
  Rank = 3,
  Distributor = 5,
  ShowType = 6,
  ProductionCountry = 8,
  Revenue = 7
}

export enum DisplayTimeConstants {
  TotalMinutes = 1,
  HoursMinutes = 2,
  HoursMinutesSeconds = 3
}

export enum DisplayUniqueConstants {
  All = 0,
  TopPerforming = 1,
  FirstBroadcast = 2
}

export enum DisplayTopConstants {
  All = 0,
  Five = 5,
  Ten = 10,
  Fifteen = 15,
  Twenty = 20,
  TwentyFive = 25,
  Fifty = 50,
  SeventyFive = 75,
  OneHundred = 100,
  TwoHundredAndFifty = 250,
  FiveHundred
}

export enum DisplayColumnConstants {
  Distributor = "distributor",
  Type = "type",
  ProdCo1 = "prodCo1"
}

export enum ReportTypeConstants {
  Imports = 1,
  ImportsAndLocal = 2,
  All = 3,
  Error = 4,
  QuarterHour = 5,
  ImportsAndFormats = 6,
  LocalOnly = 7
}

export type DisplayColumn = { id: string; name: string; };

export type QualifyTypeOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;
export type QualifyClassOneOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;
export type QualifyClassTwoOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;
export type QualifyFormatOption = Selectable<{ value: QualifyFormatConstants; name: string; isEnabled?: boolean; }>;
export type QualifyDistributorOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;
export type QualifyProductionCountryOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;
export type QualifyProductionCountryPositionOption = Selectable<{ value: number; name: string; isEnabled?: boolean; }>;
export type BenchmarkDemoTypeOption = Selectable<{ value: DemoTypeConstants; name: string; isEnabled?: boolean; }>;
export type BenchmarkQuarterOption = Selectable<{ id: number; name: string; isEnabled?: boolean; }>;
export type DisplayColumnOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;

export type ReportType = {
  reportType: ReportTypeConstants;
  selectedReportTypeSummaries: Array<ReportTypeSummaryConstants>;
  isSummaryOnly: boolean;
  summaryCombineChannels: boolean;
  summaryCombineSeries: boolean;
  summaryCombineFilm: boolean;
};

// TODO: minimumAveragedTransmissions is a string - should it be a number?
export type Average = {
  collapsePeriod: AverageCollapsePeriodConstants;
  combineWithin: number;
  minimumAveragedTransmissions: string;
  sumThousands: YesNoConstants;
  title: AverageTitleConstants;
  breakoutSeasons: YesNoConstants;
  breakoutOriginalRepeat: YesNoConstants;
  localRepeat: YesNoConstants;
};

export type Qualify = {
  selectedTypes: Array<string>;
  selectedClassOnes: Array<string>;
  selectedClassTwos: Array<string>;
  selectedFormats: Array<QualifyFormatConstants>;
  selectedDistributors: Array<string>;
  selectedProductionCountries: Array<string>;
  selectedProductionCountryPositions: Array<number>;
  selectedLocalGenres: Array<{ id: number; localGenres: Array<number>; }>;
  localGenreDisplay: QualifyLocalGenreDisplayConstants;
};

export type Revenue = {
  revenueAmount: RevenueAmountConstants;
  revenueDuration: RevenueDurationConstants;
  revenueCollapse: RevenueCollapseConstants;
};

export type Benchmark = {
  demoCount: number;
  demoTypes: Array<BenchmarkDemoTypeOption>;
  dates: BenchmarkDatesConstants;
  selectedQuarters: Array<number>;
  fromDate: number;
  toDate: number;
  timePeriod: BenchmarkTimePeriodConstants;
  timePeriodFromTime: string;
  timePeriodToTime: string;
  benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants;
  benchmarkDays: Array<DaysConstants>;
};

export type Display = {
  selectedDisplayColumns: Array<string>;
  displaySortedBy: DisplaySortedByConstants;
  displayTop: DisplayTopConstants;
  displayUnique: DisplayUniqueConstants;
  displayTime: DisplayTimeConstants;
};

export type DateTime = {
  fromDate?: number;
  toDate?: number;
  days: Array<DaysConstants>;
  transmissionDuration: number;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};