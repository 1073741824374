import { DaysConstants, DemoGroupByConstants, DemoSelectionConstants, DemoTypeConstants, Output } from "model/Model";

export type Model = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  measures: Array<{ value: DemoTypeConstants; isSelected: boolean; }>;
  demoGroupBy: DemoGroupByConstants;
  demoSelection: DemoSelectionConstants;
  dateTime: DateTime;
  average: Average;
  output: Output;
};

export type DateTime = {
  fromDate?: number;
  toDate?: number;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
  days: Array<DaysConstants>;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
};

export enum AverageDayAverageConstants {
  Daily = 0,
  DayOfWeek = 1,
  WeeklyMonSun = 2,
  WeeklySunSat = 6,
  Monthly = 3,
  Quarterly = 4,
  Yearly = 7,
  Period = 5
}

export enum AverageTimeAverageConstants {
  All = 0,
  QuarterHour = 1,
  HalfHour = 2,
  Hour = 3,
  Daypart = 4
}

export type Average = {
  averageDayAverage: AverageDayAverageConstants;
  averageTimeAverage: AverageTimeAverageConstants;
};

export type DateTimeSource = {
  fromDate?: string;
  toDate?: string;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
  days: Array<DaysConstants>;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
};

export type QuarterHourSourceModel = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  measures: Array<{ value: DemoTypeConstants; isSelected: boolean; }>;
  demoGroupBy: DemoGroupByConstants;
  demoSelection: DemoSelectionConstants;
  dateTime: DateTimeSource;
  average: Average;
  output: Output;
};