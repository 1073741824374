/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { AverageDayAverageConstants, AverageTimeAverageConstants } from "../../Model";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";

const dayAverageItems = [
  { name: "Daily", value: AverageDayAverageConstants.Daily },
  { name: "Day of Week", value: AverageDayAverageConstants.DayOfWeek },
  { name: "Weekly Mon-Sun", value: AverageDayAverageConstants.WeeklyMonSun },
  { name: "Weekly Sun-Sat", value: AverageDayAverageConstants.WeeklySunSat },
  { name: "Monthly", value: AverageDayAverageConstants.Monthly },
  { name: "Quarterly", value: AverageDayAverageConstants.Quarterly },
  { name: "Yearly", value: AverageDayAverageConstants.Yearly },
  { name: "Period", value: AverageDayAverageConstants.Period } ];

export const AverageSelector = ( { dayPartsIsEnabled, averageDayAverage, averageTimeAverage, onChangeAverageDayAverage, onChangeAverageTimeAverage }: { dayPartsIsEnabled: boolean; averageDayAverage: AverageDayAverageConstants; averageTimeAverage: AverageTimeAverageConstants; onChangeAverageDayAverage: ( value: AverageDayAverageConstants ) => void; onChangeAverageTimeAverage: ( value: AverageTimeAverageConstants ) => void; } ): JSX.Element => {
  const timeAverageTimes = useMemo( () => [
    { name: "All", value: AverageTimeAverageConstants.All },
    { name: "¼ Hour", value: AverageTimeAverageConstants.QuarterHour },
    { name: "½ Hour", value: AverageTimeAverageConstants.HalfHour },
    { name: "1 Hour", value: AverageTimeAverageConstants.Hour },
    { name: "Daypart", value: AverageTimeAverageConstants.Daypart, isEnabled: dayPartsIsEnabled }
  ], [ dayPartsIsEnabled ] );

  return ( <>
    <RadioGroupSelector label="Day Average" radioGroup="average_day_average" helpId="quarterHourAverageDayAverage" radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } value={ averageDayAverage } items={ dayAverageItems } onChangeItemSelected={ onChangeAverageDayAverage } />
    <RadioGroupSelector label="Time Average" radioGroup="average_time_average" helpId="average_time_average" radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } value={ averageTimeAverage } items={ timeAverageTimes } onChangeItemSelected={ onChangeAverageTimeAverage } />
  </> );
};
