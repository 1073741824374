import { createAction, createReducer } from "@reduxjs/toolkit";
import { ChangeCountryIsSelected } from "./countriesSlice";

export const ChangeLocalGenreIsSelected = createAction<{ countryId: number; localGenreId: number; isSelected: boolean; }>( "new:ChangeLocalGenreIsSelected" );

const localGenreReducers = createReducer(
  [] as Array<{ id: number; localGenres: Array<number>; }>,
  builder => {
    builder.addCase( ChangeCountryIsSelected, ( state, action ) => {
      const { countryId, isSelected } = action.payload;
      if ( isSelected && !state.find( c => c.id === countryId ) ) {
        // a country is selected so ensure it has the defaults
        return [ ...state, { id: countryId, localGenres: [] } ];
      }

      if ( !isSelected ) {
        return state.filter( c => c.id !== countryId );
      } else {
        return state;
      }
    } );

    builder.addCase( ChangeLocalGenreIsSelected, ( state, action ) => {
      const { countryId, localGenreId, isSelected } = action.payload;

      if ( isSelected ) {
        return state.map( c => c.id === countryId ? { ...c, localGenres: [ ...c.localGenres, localGenreId ] } : c );
      } else {
        return state.map( c => c.id === countryId ? { ...c, localGenres: c.localGenres.filter( m => m !== localGenreId ) } : c );
      }
    } );
  }
);

export default localGenreReducers;