/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { HelpText } from "components/help-text/HelpText";
import { DemoRankConstants } from "model/Model";
import React from "react";

const styles = {
  demoRankSelector: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;

        span {
          vertical-align: middle;
        }
      }
  
      input {
        padding: 0;
        margin: 0 5px 0 0;
        width: auto;
        vertical-align: middle;
      }
    }
  `
};

export const DemoRankSelector = ( { helpId, demoRank, isEnabled, onChangeDemoRank }: { helpId: string; demoRank: DemoRankConstants; isEnabled: boolean; onChangeDemoRank: ( demoRank: DemoRankConstants ) => void; } ): JSX.Element => (
  <div css={ styles.demoRankSelector }>
    <fieldset>
      <legend><label>Rank<HelpText helpId={ helpId } /></label></legend>
      <label><input disabled={!isEnabled} type="radio" radioGroup="demoRank" checked={ demoRank === DemoRankConstants.first } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeDemoRank( e.currentTarget.checked ? DemoRankConstants.first : DemoRankConstants.all ) } /><span>First demo</span></label>
      <label><input disabled={!isEnabled} type="radio" radioGroup="demoRank" checked={ demoRank === DemoRankConstants.all } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeDemoRank( e.currentTarget.checked ? DemoRankConstants.all : DemoRankConstants.first ) } /><span>All demos</span></label>
    </fieldset>
  </div>
);
