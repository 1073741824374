/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { css } from "@emotion/react";
import { Selectable } from "Utilities";
import { QualifyFormatOption } from "apps/ProgramRanking/Model";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `,

  country: css`
    display: inline-block;
    width: 180px;
  `
};

export const QualifyDescription = ( { types, classOnes, classTwos, formats, distributors, productionCountries, productionCountryPositions, selectedCountries, warningMessage }: { types: Array<Selectable<{ name: string; }>>; classOnes: Array<Selectable<{ name: string; }>>; classTwos: Array<Selectable<{ name: string; }>>; formats: Array<Selectable<QualifyFormatOption>>; distributors: Array<Selectable<{ name: string; }>>; productionCountries: Array<Selectable<{ name: string; }>>; productionCountryPositions: Array<Selectable<{ name: string; }>>; selectedCountries: Array<{ id: number; name: string; selectedLocalGenres: Array<{ name: string; }>; }>; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    const qualifyParts: Array<JSX.Element> = [];

    const selectedTypes = types.filter( m => m.isSelected );
    const qualifyTypeDescription = selectedTypes.length === 0 ? "All." : selectedTypes.map( m => m.name ).join( ", " );
    qualifyParts.push( ( <span key="qualify_type" css={ styles.displayGroupSpace }>Type: { qualifyTypeDescription }</span> ) );

    const selectedClassOnes = classOnes.filter( m => m.isSelected );
    const qualifyClassOneDescription = selectedClassOnes.length === 0 ? "All." : selectedClassOnes.map( m => m.name ).join( ", " );
    qualifyParts.push( ( <span key="qualify_class_one" css={ styles.displayGroupSpace }>Class: { qualifyClassOneDescription }</span> ) );

    const selectedClassTwos = classTwos.filter( m => m.isSelected );
    const qualifyClassTwoDescription = selectedClassTwos.length === 0 ? "All." : selectedClassTwos.map( m => m.name ).join( ", " );
    qualifyParts.push( ( <span key="qualify_class_two" css={ styles.displayGroupSpace }>Class 2: { qualifyClassTwoDescription }</span> ) );

    const selectedFormats = formats.filter( m => m.isSelected );
    const formatsDescription = selectedFormats.length === 0 ? "No." : `${ selectedFormats.map( m => m.name ).join( ", " ) }.`;
    qualifyParts.push( ( <span key="qualify_formats" css={ styles.displayGroupSpace }>Format: { formatsDescription }</span> ) );

    const selectedDistributors = distributors.filter( m => m.isSelected );
    const qualifyDistributorDescription = selectedDistributors.length === 0 ? "All Distributors." : `Distributors: ${ selectedDistributors.map( m => m.name ).join( ", " ) }.`;
    qualifyParts.push( ( <span key="qualify_distributors" css={ styles.displayGroupSpace }>{ qualifyDistributorDescription }</span> ) );

    const selectedProductionCountries = productionCountries.filter( m => m.isSelected );
    const productionCountriesDescription = selectedProductionCountries.length === 0 ? "All Countries" : `Countries: ${ selectedProductionCountries.map( m => m.name ).join( ", " ) }`;
    qualifyParts.push( ( <span key="qualify_production_countries" css={ styles.displayGroupSpace }>{ productionCountriesDescription }</span> ) );

    const selectedProductionCountryPositions = productionCountryPositions.filter( m => m.isSelected );
    const productionCountryPositionsDescription = selectedProductionCountryPositions.length === 0 ? "in all positions" : `in: ${ selectedProductionCountryPositions.map( m => m.name ).join( ", " ) } ${ selectedProductionCountryPositions.length === 1 ? "position." : "positions." }`;
    qualifyParts.push( ( <span key="qualify_production_country_positions" css={ styles.displayGroupSpace }>{ productionCountryPositionsDescription }</span> ) );

    results.push( ( <div key="qualify" >{ qualifyParts }</div> ) );

    const hasTwoOrMoreCountriesSelected = selectedCountries.length > 1;

    for ( const c of selectedCountries ) {
      const countryLocalGenreParts: Array<JSX.Element> = [];
      const description = c.selectedLocalGenres.length === 0 ? "ALL" : `${ c.selectedLocalGenres.map( m => m.name ).join( ", " ) }`;

      if ( hasTwoOrMoreCountriesSelected ) {
        countryLocalGenreParts.push( <span key={ `qualify_local_genre_country_${ c.id }` } css={ styles.country }>{ c.name }</span> );
      }

      countryLocalGenreParts.push( <span key={ `qualify_local_genre_${ c.id }` } css={ styles.displayGroupSpace }>{ description }</span> );
      results.push( ( <div key={ `qualify_local_genres_${ c.id }` } >{ countryLocalGenreParts }</div> ) );
    }
    return results;
  }, [ classOnes, classTwos, distributors, formats, productionCountries, productionCountryPositions, selectedCountries, types ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
