/** @jsxImportSource @emotion/react */
import React from "react";
import { Index } from "apps/AvailableData/Index";
import { AvailableDataSourceModel } from "./Model";
import { Params, useLoaderData } from "react-router";
import { getSessionCredentials } from "contexts/UserContext";
import { getJson } from "helpers/api";

type LoaderData = { initialData: AvailableDataSourceModel; };

export const availableDataLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const id = params.id as string || 0;

  const initialData = await getJson<AvailableDataSourceModel>( "/AvailableData", userId, token, { id } );
  return { initialData };
};

export const AvailableData = () => {
  const loader = useLoaderData() as LoaderData;

  return (
    <Index data={ loader.initialData } />
  );
};