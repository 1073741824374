/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";

const styles = {
  container: css`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;  
`,
  row: css`
      width: auto;
  `,
  loader: css`
    border-color: #f3f3f3;
    border-style:solid;
    border-top-color:#3498db;
    border-radius: 50%;
    margin: 0 auto;
  `,
  spain: keyframes`
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }`,
  description: css`
  text-align: center;
  `
};

export const LoadingDisplay = ( { text, fullScreen, height, width, borderWidth }: { text: string; fullScreen: boolean; height: number; width: number; borderWidth: number; } ): JSX.Element => {

  return ( <>
    <div css={ [ styles.container, fullScreen ? css`height: 100vh` : undefined ] }>
      <div css={ styles.row }>
        <div css={ [ styles.loader, css`animation: ${ styles.spain } 2s linear infinite; height: ${ height }px; width: ${ width }px; border-width: ${ borderWidth }px;` ] }></div>
        <h3 css={ styles.description }>{ text }</h3>
      </div>
    </div>
  </> );
};
