/** @jsxImportSource @emotion/react */
import { HelpText } from "components/help-text/HelpText";
import { css } from "@emotion/react";

const styles = {
  dropdownSelector: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      select {
        width: 100%;
        border:1px solid silver;

        &:focus {
          border:1px solid black;
        }
      }
    }
  `
};

export const DropdownSelector = <T extends { toString: () => string; }>( { label, isEnabled = true, value, items, helpId, onChangeItemSelected }: { label: string; isEnabled?: boolean; value?: T; items: Array<{ value: T; name: string; title?: string; }>; helpId: string; onChangeItemSelected?: ( value: T ) => void; } ): JSX.Element => {
  return (
    <div css={ styles.dropdownSelector } >
      <fieldset>
        <legend><label>{ label } <HelpText helpId={ helpId } /></label></legend>
        <select aria-label={ label } disabled={ !isEnabled } onChange={ ( e ) => onChangeItemSelected?.( items.find( m => m.value.toString() === e.target.value )?.value ?? items[ 0 ].value ) } value={ value?.toString() }>
          {
            items.map( ( item, i ) => (
              <option key={ i } value={ item.value.toString() } title={ item.title ?? item.name }>{ item.name }</option>
            ) ) }
        </select>
      </fieldset>
    </div>
  );
};
