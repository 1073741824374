import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChangeCountryIsSelected } from "./countriesSlice";

const demosSlice = createSlice( {
  name: "demos",
  initialState: [] as Array<{ id: number; demos: Array<number>; }>,
  reducers: {
    ChangeDemoIsSelected( state, action: PayloadAction<{ countryId: number; demoId: number; isSelected: boolean; clearExisting:boolean; }> ) {
      const { countryId, demoId, isSelected, clearExisting } = action.payload;

      if ( isSelected ) {
        if ( clearExisting ) return state.map( c => c.id === countryId ? { ...c, demos: [ demoId ] } : c );
        return state.map( c => c.id === countryId ? { ...c, demos: [ ...c.demos, demoId ] } : c );
      }
      return state.map( c => c.id === countryId ? { ...c, demos: c.demos.filter( d => d !== demoId ) } : c );
    },

    ChangeDemoSelectedOrder( state, action: PayloadAction<{ countryId: number; srcDemoId: number; dstDemoId: number; }> ) {
      const { countryId, srcDemoId, dstDemoId } = action.payload;
      const country = state.filter( c => c.id === countryId )[ 0 ];

      // get the selected demos in sorted order
      const demos = [ ...country.demos ];

      // move the required item to it's new index
      const srcIndex = demos.findIndex( m => m === srcDemoId );
      const dstIndex = demos.findIndex( m => m === dstDemoId );
      demos.splice( dstIndex, 0, demos.splice( srcIndex, 1 )[ 0 ] );

      const updatedCountries = state.map( c => c.id === countryId ? { ...c, demos } : c );
      return updatedCountries;
    }
  },
  extraReducers: builder => {
    builder.addCase( ChangeCountryIsSelected, ( state, action ) => {
      const { countryId, isSelected, defaultDemos } = action.payload;

      const countryDemos = state.find( c => c.id === countryId );

      if ( isSelected ) {
        if ( !countryDemos ) {
          // a country is selected so ensure it has the defaults
          return [ ...state, { id: countryId, demos: defaultDemos } ];
        } else if ( countryDemos.demos.length === 0 ) {
          // if the country is selected but has no demos selected then add the defaults
          const updated = state.map( c => c.id === countryId ? { ...c, demos: defaultDemos } : c );
          return updated;
        }
      }

      return state;
    } );
  }
} );

export const { ChangeDemoIsSelected, ChangeDemoSelectedOrder } = demosSlice.actions;

export default demosSlice.reducer;