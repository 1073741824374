import { CountryConstants } from "constants/CountryConstants";
import { formatDateDDmmmYYYY, parseDate } from "Utilities";
import { Country, DayPart, Demo, DemoTypeConstants, LocalGenre, Measure, Station, UserOrGroup } from "./Model";

export type CountryDto = {
  id: number;
  name: string;
  group: string;
  startDate?: string;
  endDate?: string;
  stations: Array<StationDto>;
  demos: Array<DemoDto>;
  dayParts: Array<DayPartDto>;
  localGenres: Array<LocalGenreDto>;
  hasRevenue: boolean;
  hasAverageTimeSpent: boolean;
};

type StationDto = {
  id: number;
  name: string;
  isTranslated?: boolean;
  startDate?: string;
  endDate?: string;
  hasRevenue?: boolean;
  revenueStartDate?: string;
  revenueEndDate?: string;
  optionGroup?: string;
  translationType?: string;
};

type DemoDto = {
  id: number;
  name: string;
  abbreviated: string;
  startDate?: string;
  endDate?: string;
  isTotalAudienceDemo?: boolean;
  isAllCommercialDemo?: boolean;
};

type DayPartDto = {
  id: number;
  name: string;
  time?: string;
};

type LocalGenreDto = {
  id: number;
  name: string;
};

export const enrichCountries = <T extends CountryDto>( countries: Array<T> ): Array<Country> => {
  return countries.map( m => enrichCountry( m ) );
};

const revenueSymbolForCountry = ( countryId: number ): string => {
  switch ( countryId ) {
    case CountryConstants.UK11:
    case CountryConstants.UKSat12:
      return "£";

    case CountryConstants.France1:
    case CountryConstants.Germany2:
    case CountryConstants.Spain5:
    case CountryConstants.Germany23:
    case CountryConstants.UK26:
    case CountryConstants.Italy33:
    case CountryConstants.Spain36:
    case CountryConstants.Spain80:
    case CountryConstants.Italy99:
      return "€";

    default: return "";
  }
};

export const filterRevenueStations = ( countries: Array<Country> ): Array<Country> => {
  return countries.map( m => {
    const revenueStations = m.stations.filter( m => m.hasRevenue );
    return ( { ...m, stations: revenueStations, defaultStations: revenueStations.map( m => m.id ) } );
  } );
};

// TODO: needs Rev start/end
const enrichCountry = <T extends CountryDto>( dto: T ): Country => {
  const result: Country = {
    ...dto,
    startDate: parseDate( dto.startDate ),
    endDate: parseDate( dto.endDate ),
    stations: dto.stations.map( m => enrichStation( m ) ),
    demos: dto.demos.map( m => enrichDemo( m ) ),
    dayParts: dto.dayParts.map( m => enrichDayPart( m ) ),
    localGenres: dto.localGenres.map( m => enrichLocalGenre( m ) ),
    defaultStations: getDefaultStationsForFirstStationGroup( dto.stations ),
    defaultDemos: [ dto.demos[ 0 ].id ],
    tooltip: `${ formatDateDDmmmYYYY( parseDate( dto.startDate ) ) } to ${ formatDateDDmmmYYYY( parseDate( dto.endDate ) ) }`,
    revenueSymbol: revenueSymbolForCountry( dto.id )
  };

  return result;
};

// note relies on the stations being in group order
const getDefaultStationsForFirstStationGroup = ( stations: StationDto[] ): Array<number> => {
  if ( !stations || stations.length < 1 ) return [];

  const firstStationOptionGroup = stations[0].optionGroup;
  if ( !firstStationOptionGroup ) return [];

  return stations.filter( m => m.optionGroup === firstStationOptionGroup ).map( s => s.id );
};

const enrichDemo = <T extends DemoDto>( dto: T ): Demo => {
  const result: Demo = {
    ...dto,
    startDate: parseDate( dto.startDate ),
    endDate: parseDate( dto.endDate ),
    isTotalAudienceDemo: dto.isTotalAudienceDemo ?? false,
    isAllCommercialDemo: dto.isAllCommercialDemo ?? false
  };

  return result;
};

const enrichStation = <T extends StationDto>( dto: T ): Station => {
  const result: Station = {
    ...dto,
    isTranslated: dto.isTranslated ?? false,
    startDate: parseDate( dto.startDate ),
    endDate: parseDate( dto.endDate ),
    revenueStartDate: parseDate( dto.revenueStartDate ),
    revenueEndDate: parseDate( dto.revenueEndDate ),
    hasRevenue: dto.hasRevenue ?? false
  };

  return result;
};

const enrichDayPart = <T extends DayPartDto>( dto: T ): DayPart => {
  const result: DayPart = {
    ...dto
  };

  return result;
};

const enrichLocalGenre = <T extends LocalGenreDto>( dto: T ): LocalGenre => {
  const result: LocalGenre = {
    ...dto
  };

  return result;
};

export type CountriesById = Record<number, Country>;

export const appendTemplateNameAndSharedWith = <T>( userId: number, templateName: string, sharedWith: Array<number>, model: T ): T & { templateOrHistoryOwnerId: number; templateName: string; sharedWith: Array<number>; } => {
  return {
    ...model,
    templateOrHistoryOwnerId: userId,
    templateName,
    sharedWith
  };
};

export const appendCountriesById = <T extends { countries: Array<Country>; }>( model: T ): T & { countriesById: CountriesById; } => {
  const map: CountriesById = {};
  for ( const m of model.countries ) {
    map[ m.id ] = m;
  }
  return { ...model, countriesById: map };
};

export type StationsByCountryIdAndId = Record<number, Record<number, Station>>;

export const appendStationsByCountryIdAndId = <T extends { countries: Array<Country>; }>( model: T ): T & { stationsByCountryIdAndId: StationsByCountryIdAndId; } => {
  const map: StationsByCountryIdAndId = {};
  for ( const c of model.countries ) {
    const innerMap: Record<number, Station> = {};
    for ( const m of c.stations ) {
      innerMap[ m.id ] = m;
    }
    map[ c.id ] = innerMap;
  }
  return { ...model, stationsByCountryIdAndId: map };
};

export type DemosByCountryIdAndId = Record<number, Record<number, Demo>>;

export const appendDemosByCountryIdAndId = <T extends { countries: Array<Country>; }>( model: T ): T & { demosByCountryIdAndId: DemosByCountryIdAndId; } => {
  const map: DemosByCountryIdAndId = {};
  for ( const c of model.countries ) {
    const innerMap: Record<number, Demo> = {};
    for ( const m of c.demos ) {
      innerMap[ m.id ] = m;
    }
    map[ c.id ] = innerMap;
  }
  return { ...model, demosByCountryIdAndId: map };
};

export type DayPartsByCountryIdAndId = Record<number, Record<number, DayPart>>;

export const appendDayPartsByCountryIdAndId = <T extends { countries: Array<Country>; }>( model: T ): T & { dayPartsByCountryIdAndId: DayPartsByCountryIdAndId; } => {
  const map: DayPartsByCountryIdAndId = {};
  for ( const c of model.countries ) {
    const innerMap: Record<number, DayPart> = {};
    for ( const m of c.demos ) {
      innerMap[ m.id ] = m;
    }
    map[ c.id ] = innerMap;
  }
  return { ...model, dayPartsByCountryIdAndId: map };
};
export type LocalGenresByCountryIdAndId = Record<number, Record<number, LocalGenre>>;

export const appendLocalGenresByCountryIdAndId = <T extends { countries: Array<Country>; }>( model: T ): T & { localGenresByCountryIdAndId: LocalGenresByCountryIdAndId; } => {
  const map: LocalGenresByCountryIdAndId = {};
  for ( const c of model.countries ) {
    const innerMap: Record<number, LocalGenre> = {};
    for ( const m of c.localGenres ?? [] ) {
      innerMap[ m.id ] = m;
    }
    map[ c.id ] = innerMap;
  }
  return { ...model, localGenresByCountryIdAndId: map };
};

export type MeasuresById = Record<DemoTypeConstants, Measure>;

export const appendMeasuresById = <T extends { measures: Array<Measure>; }>( model: T ): T & { measuresById: MeasuresById; } => {
  const map: MeasuresById = {} as unknown as MeasuresById;
  for ( const m of model.measures ) {
    map[ m.value ] = m;
  }
  return { ...model, measuresById: map };
};

export type UsersAndGroupsById = Record<number, UserOrGroup>;

export const appendUsersAndGroupsById = <T extends { sharingUsersAndGroups: Array<UserOrGroup>; }>( model: T ): T & { sharingUsersAndGroupsById: UsersAndGroupsById; } => {
  const map: UsersAndGroupsById = {};
  for ( const m of model.sharingUsersAndGroups ) {
    map[ m.id ] = m;
  }
  return { ...model, sharingUsersAndGroupsById: map };
};
