/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { HelpText } from "components/help-text/HelpText";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { Demo, DemoSelectionConstants } from "model/Model";
import React, { MutableRefObject, useCallback, useRef } from "react";
import { chunk, Selectable } from "Utilities";

// TODO: pick a default demo when activated

const styles = {
  demoSelector: css`
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
      }
  
      input {
        padding: 0;
        margin: 0 5px 0 0 ;
        width: auto;
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
      }
    }
  `,

  orderedDemoList: css`
    display: inline-block;
    vertical-align: top;
    width: 100px;
    margin-right: 20px;

    ul {
      border: solid black 1px;
      padding: 2px 2px 2px 2px;
      margin: 0;
      min-height: 20px;

      li {
        list-style-type: none;
        padding: 0;
        margin: 0;
        cursor: move;
        user-select: none;
        padding-top: 2px;
        padding-bottom: 2px;
        background-color: white;

        &:hover {
          background-color: orange;
        }

        &.dropAbove {
          border-top: solid red 12px;
          padding-top: 0px;

          * {
            pointer-events: none;
          }
        }

        &.dropBelow {
          border-bottom: solid blue 12px;
          padding-bottom: 0px;

          * {
            pointer-events: none;
          }
        }
      }
    }
  `,

  demoList: css`
    display: inline-block;
    vertical-align: top;
  `,

  demoColumn: css`
    display:block;
    float: left;

    label {
      display: block;
      padding: 0;
      margin: 0;
      padding-right: 10px;
    }
  `,

  demoSelectionDetail: css`
    margin-top: 0;
    margin-bottom: 0;
  `
};

export const DemoSelector = ( { helpId, demoSelection, selectedCountries, singleDemoOnly = false, showDemoSelectionOptions = true, onChangeDemoIsSelected, onChangeDemoSelection, onChangeDemoSelectedOrder }: { helpId: string; demoSelection: DemoSelectionConstants; selectedCountries: Array<{ id: number; name: string; demos: Array<Selectable<Demo>>; selectedDemos: Array<Demo>; allDemosSelected: boolean; noDemosSelected: boolean; }>; singleDemoOnly?: boolean; showDemoSelectionOptions?: boolean; onChangeDemoIsSelected: ( countryId: number, demoId: number, isSelected: boolean, clearExisting: boolean ) => void; onChangeDemoSelection: ( demoSelection: DemoSelectionConstants ) => void; onChangeDemoSelectedOrder: ( countryId: number, srcDemoId: number, dstDemoId: number ) => void; } ): JSX.Element => {
  const draggedItemIdRef = useRef<number | null>( null );
  const draggedItemIndexRef = useRef<number | null>( null );
  const referenceData = useReferenceData();

  const countrySpecialDemos = selectedCountries.map( m => ( {
    name: m.name + ( referenceData.includeIdInName && ` [${ m.id }]` ),
    allIndividuals: m.demos.filter( n => n.isTotalAudienceDemo ).map( n => n.name + ( referenceData.includeIdInName && ` [${ n.id }]` ) ).pop() || "n/a",
    allCommercials: m.demos.filter( n => n.isAllCommercialDemo ).map( n => n.name + ( referenceData.includeIdInName && ` [${ n.id }]` ) ).pop() || "n/a"
  } ) );

  return (
    <div css={ styles.demoSelector }>
      <fieldset>
        <legend><label>Demos<HelpText helpId={ helpId } /></label></legend>
        { showDemoSelectionOptions && <>
          <div>
            <label><input type="radio" name="demoSelection" radioGroup="demoSelection" checked={ demoSelection === DemoSelectionConstants.allIndividualsAndAllCommercials } onChange={ () => onChangeDemoSelection( DemoSelectionConstants.allIndividualsAndAllCommercials ) } value="1" /><span>All persons and commercial demo { countrySpecialDemos.length === 1 && <>({ countrySpecialDemos[ 0 ].allIndividuals } and { countrySpecialDemos[ 0 ].allCommercials })</> }</span></label>
            { countrySpecialDemos.length > 1 && <ul css={ styles.demoSelectionDetail }>
              { countrySpecialDemos.map( ( m, i ) => <li key={ i }>{ m.name } - { m.allIndividuals } and { m.allCommercials }</li> ) }
            </ul> }
          </div>
          <div>
            <label><input type="radio" name="demoSelection" radioGroup="demoSelection" checked={ demoSelection === DemoSelectionConstants.allIndividuals } onChange={ () => onChangeDemoSelection( DemoSelectionConstants.allIndividuals ) } value="2" /><span>All persons demo { countrySpecialDemos.length === 1 && <>({ countrySpecialDemos[ 0 ].allIndividuals })</> }</span></label>
            { countrySpecialDemos.length > 1 && <ul css={ styles.demoSelectionDetail }>
              { countrySpecialDemos.map( ( m, i ) => <li key={ i }>{ m.name } - { m.allIndividuals }</li> ) }
            </ul> }
          </div>
          <div>
            <label><input type="radio" name="demoSelection" radioGroup="demoSelection" checked={ demoSelection === DemoSelectionConstants.allCommercials } onChange={ () => onChangeDemoSelection( DemoSelectionConstants.allCommercials ) } value="3" /><span>Commercial demo { countrySpecialDemos.length === 1 && <>({ countrySpecialDemos[ 0 ].allCommercials })</> }</span></label>
            { countrySpecialDemos.length > 1 && <ul css={ styles.demoSelectionDetail }>
              { countrySpecialDemos.map( ( m, i ) => <li key={ i }>{ m.name } - { m.allCommercials }</li> ) }
            </ul> }
          </div>
          <div>
            <label><input type="radio" name="demoSelection" radioGroup="demoSelection" checked={ demoSelection === DemoSelectionConstants.selectedDemos } onChange={ () => onChangeDemoSelection( DemoSelectionConstants.selectedDemos ) } value="0" />Selected demos</label>
            { demoSelection === DemoSelectionConstants.selectedDemos && selectedCountries.map( country => ( <CountryGroup key={ country.id } { ...country } draggedItemIdRef={ draggedItemIdRef } draggedItemIndexRef={ draggedItemIndexRef } singleDemoOnly={ singleDemoOnly } onSelectDemoHandler={ onChangeDemoIsSelected } onChangeDemoSelectedOrder={ onChangeDemoSelectedOrder } /> ) ) }
          </div>
        </> }
        {
          !showDemoSelectionOptions && selectedCountries.map( country => ( <CountryGroup key={ country.id } { ...country } draggedItemIdRef={ draggedItemIdRef } draggedItemIndexRef={ draggedItemIndexRef } singleDemoOnly={ singleDemoOnly } onSelectDemoHandler={ onChangeDemoIsSelected } onChangeDemoSelectedOrder={ onChangeDemoSelectedOrder } /> ) )
        }
      </fieldset>
    </div>
  );
};

const CountryGroup = ( { id, name, allDemosSelected, demos, selectedDemos, draggedItemIdRef, draggedItemIndexRef, singleDemoOnly, onSelectDemoHandler, onChangeDemoSelectedOrder }: { id: number; name: string; allDemosSelected: boolean; demos: Array<Selectable<Demo>>; selectedDemos: Array<Demo>; draggedItemIdRef: MutableRefObject<number | null>; draggedItemIndexRef: MutableRefObject<number | null>; singleDemoOnly: boolean; onSelectDemoHandler: ( countryId: number, demoId: number, isSelected: boolean, clearExisting: boolean ) => void; onChangeDemoSelectedOrder: ( countryId: number, srcDemoId: number, dstDemoId: number ) => void; } ) => {
  const referenceData = useReferenceData();

  const onSelectAllDemosHandler = useCallback( () => {
    for ( const demo of demos ) {
      if ( demo.isSelected === allDemosSelected ) onSelectDemoHandler( id, demo.id, !allDemosSelected, false );
    }
  }, [ allDemosSelected, demos, id, onSelectDemoHandler ] );

  const noneSelected = selectedDemos.length === 0;

  return (
    <div>
      <fieldset>
        <legend>
          <label>
            <input type="checkbox" checked={ allDemosSelected } onChange={ onSelectAllDemosHandler } ref={ input => { if ( input ) input.indeterminate = !noneSelected && !allDemosSelected; } } />
            { name }{ referenceData.includeIdInName && ` [${ id }]` }
          </label>
        </legend>

        <div css={ styles.orderedDemoList }>
          <ul>
            { selectedDemos.map( demo => <SelectedDemo key={ demo.id } countryId={ id } { ...demo } draggedItemIdRef={ draggedItemIdRef } draggedItemIndexRef={ draggedItemIndexRef } onChangeDemoSelectedOrder={ onChangeDemoSelectedOrder } /> ) }
          </ul>
        </div>

        <div css={ styles.demoList }>
          { chunk( demos, 12 ).map( ( d, i ) => {
            return (
              <div key={ i } css={ styles.demoColumn } >
                {
                  d.map( demo => ( <DemoGroup key={ demo.id } countryId={ id }  { ...demo } singleDemoOnly={ singleDemoOnly } onSelectDemoHandler={ onSelectDemoHandler } /> ) )
                }
              </div>
            );
          } ) }
        </div>
      </fieldset>
    </div>
  );
};

const SelectedDemo = ( { countryId, id, name, draggedItemIdRef, draggedItemIndexRef, onChangeDemoSelectedOrder }: { countryId: number; id: number; name: string; draggedItemIdRef: MutableRefObject<number | null>; draggedItemIndexRef: MutableRefObject<number | null>; onChangeDemoSelectedOrder: ( countryId: number, srcDemoId: number, dstDemoId: number ) => void; } ) => {
  const onDragStart = useCallback( ( e: React.DragEvent<HTMLLIElement> ) => {
    const draggedItem = e.currentTarget;
    const demos = Array.from( draggedItem.parentElement?.children || [] );
    draggedItemIdRef.current = id;
    draggedItemIndexRef.current = demos.indexOf( draggedItem );
  }, [ draggedItemIdRef, draggedItemIndexRef, id ] );

  const onDragOver = useCallback( ( e: React.DragEvent<HTMLLIElement> ) => {
    const destinationId = parseInt( e.currentTarget.dataset.id || "", 10 );
    if ( destinationId === draggedItemIdRef.current ) return;

    e.preventDefault();
  }, [ draggedItemIdRef ] );

  const onDragEnter = useCallback( ( e: React.DragEvent<HTMLLIElement> ) => {
    const destinationId = parseInt( e.currentTarget.dataset.id || "", 10 );
    if ( destinationId === draggedItemIdRef.current ) return;
    if ( draggedItemIndexRef.current == null ) return;

    const destinationItemIndex = Array.from( e.currentTarget.parentElement?.children || [] ).indexOf( e.currentTarget );
    if ( destinationItemIndex < draggedItemIndexRef.current ) {
      e.currentTarget.classList.add( "dropAbove" );
    } else {
      e.currentTarget.classList.add( "dropBelow" );
    }
  }, [ draggedItemIdRef, draggedItemIndexRef ] );

  const onDragLeave = useCallback( ( e: React.DragEvent<HTMLLIElement> ) => {
    const destinationId = parseInt( e.currentTarget.dataset.id || "", 10 );
    if ( destinationId === draggedItemIdRef.current ) return;

    e.currentTarget.classList.remove( "dropAbove" );
    e.currentTarget.classList.remove( "dropBelow" );
  }, [ draggedItemIdRef ] );

  const onDrop = useCallback( ( e: React.DragEvent<HTMLLIElement> ) => {
    const destinationId = parseInt( e.currentTarget.dataset.id || "", 10 );

    e.preventDefault();

    if ( draggedItemIdRef.current == null ) return;
    if ( destinationId == null ) return;

    onChangeDemoSelectedOrder( countryId, draggedItemIdRef.current, destinationId );

    const demos = Array.from( e.currentTarget?.parentElement?.children ?? [] );
    for ( const m of demos ) {
      m.classList.remove( "dropAbove" );
      m.classList.remove( "dropBelow" );
    }

    draggedItemIdRef.current = null;
    draggedItemIndexRef.current = null;
  }, [ countryId, draggedItemIdRef, draggedItemIndexRef, onChangeDemoSelectedOrder ] );

  return (
    <li key={ id } draggable={ true } onDragOver={ onDragOver } onDragStart={ onDragStart } onDrop={ onDrop } onDragEnter={ onDragEnter } onDragLeave={ onDragLeave } >{ name }</li> );
};

const DemoGroup = ( { countryId, id, name, isSelected, tooltip, singleDemoOnly, onSelectDemoHandler }: { countryId: number; id: number; name: string; isSelected: boolean; tooltip?: string; singleDemoOnly?: boolean; onSelectDemoHandler: ( countryId: number, demoId: number, isSelected: boolean, clearExisting: boolean ) => void; } ) => {
  const referenceData = useReferenceData();

  return ( <label title={ tooltip }>
    { singleDemoOnly && <input key={ `${ countryId }_${ id }` } type="radio" checked={ isSelected } title={ tooltip } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onSelectDemoHandler( countryId, id, e.currentTarget.checked, true ) } /> }
    { !singleDemoOnly && <input key={ `${ countryId }_${ id }` } type="checkbox" checked={ isSelected } title={ tooltip } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onSelectDemoHandler( countryId, id, e.currentTarget.checked, false ) } /> }
    <span>{ name }{ referenceData.includeIdInName && ` [${ id }]` }</span>
  </label> );
};
