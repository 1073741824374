import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { parseDateOrUndefined } from "Utilities";
import { Model, WeeklyChannelViewSourceModel } from "../Model";
import thunkMiddleware from "redux-thunk";
import demoGroupBySlice from "reducers/demoGroupBySlice";
import demoRankSlice from "reducers/demoRankSlice";
import demoSelectionSlice from "reducers/demoSelectionSlice";
import measuresSlice from "reducers/measuresSlice";
import outputSlice from "reducers/outputSlice";
import demosSlice from "reducers/demosSlice";
import stationsSlice from "reducers/stationsSlice";
import countriesSlice from "reducers/countriesSlice";
import dateTimeReducers from "../components/date-time-selector/dateTimeReducers";

const initializeModel = ( data: WeeklyChannelViewSourceModel ): Model => {
  const model = {
    ...data,
    dateTime: {
      ...data.dateTime,
      fromDate: parseDateOrUndefined( data.dateTime.fromDate ),
      toDate: parseDateOrUndefined( data.dateTime.toDate )
    }
  };

  // FUTURE: more validation
  if ( model.countries === undefined )                                /* */ throw new Error( "WeeklyChannelView.countries is undefined" );
  if ( !Array.isArray( model.countries ) )                            /* */ throw new Error( "WeeklyChannelView.countries is not an array" );
  if ( model.dateTime === undefined )                                 /* */ throw new Error( "WeeklyChannelView.dateTime is undefined" );
  if ( model.dateTime.days === undefined )                            /* */ throw new Error( "WeeklyChannelView.dateTime.days is undefined" );
  if ( model.dateTime.dayParts === undefined )                        /* */ throw new Error( "WeeklyChannelView.dateTime.dayParts is undefined" );
  if ( model.dateTime.fromTime === undefined )                        /* */ throw new Error( "WeeklyChannelView.dateTime.fromTime is undefined" );
  if ( model.dateTime.toTime === undefined )                          /* */ throw new Error( "WeeklyChannelView.dateTime.toTime is undefined" );
  if ( model.dateTime.useDayParts === undefined )                     /* */ throw new Error( "WeeklyChannelView.dateTime.useDayParts is undefined" );
  if ( model.demos === undefined )                                    /* */ throw new Error( "WeeklyChannelView.demos is undefined" );
  if ( !Array.isArray( model.demos ) )                                /* */ throw new Error( "WeeklyChannelView.demos is not an array" );
  // FUTURE: display isn't implemented here yet
  if ( model.measures === undefined )                                 /* */ throw new Error( "WeeklyChannelView.measures is undefined" );
  if ( !Array.isArray( model.measures ) )                             /* */ throw new Error( "WeeklyChannelView.measures is not an array" );
  if ( model.output === undefined )                                   /* */ throw new Error( "WeeklyChannelView.output is undefined" );
  if ( model.output.format === undefined )                            /* */ throw new Error( "WeeklyChannelView.output.format is undefined" );
  if ( model.output.view === undefined )                              /* */ throw new Error( "WeeklyChannelView.output.view is undefined" );
  if ( model.stations === undefined )                                 /* */ throw new Error( "WeeklyChannelView.stations is undefined" );
  if ( !Array.isArray( model.stations ) )                             /* */ throw new Error( "WeeklyChannelView.stations is not an array" );

  return model;
};

export const getStore = ( initialData: WeeklyChannelViewSourceModel ) => configureStore( {
  preloadedState: initializeModel( initialData ),
  reducer: {
    countries: countriesSlice,
    stations: stationsSlice,
    demos: demosSlice,
    measures: measuresSlice,
    demoRank: demoRankSlice,
    demoGroupBy: demoGroupBySlice,
    demoSelection: demoSelectionSlice,
    dateTime: dateTimeReducers,
    output: outputSlice
  },
  middleware: [ thunkMiddleware ]
} );

type StoreType = ReturnType<typeof getStore>;

export type AppDispatch = StoreType[ "dispatch" ];
export type RootState = ReturnType<StoreType[ "getState" ]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
