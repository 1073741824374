/** @jsxImportSource @emotion/react */
import React, { MutableRefObject, useCallback, useRef } from "react";
import { HelpText } from "components/help-text/HelpText";
import { DemoTypeConstants, Measure } from "model/Model";
import { Selectable } from "Utilities";
import { css } from "@emotion/react";

const styles = {
  demoTypeSelectorWithIsRanked: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
      }
  
      input {
        padding: 0;
        margin: 0;
        margin-right: 4px;
        width: auto;
      }
    }
  `,

  measure: css`
    display: inline-block;
    padding: 4px;
    margin: 10px;
    border: solid 1px black;
    vertical-align: top;
    cursor: move;
    user-select: none;
    background-color: white;
    
    input {
      padding: 0;
      margin: 0;
      margin-right: 10px;
      width: auto;
    }

    padding-left: 11px;
    padding-right: 11px;

    &.dropLeft {
      border-left: solid red 12px;
      padding-left: 0px;

      * { 
        pointer-events: none;
      }    
    }

    &.dropRight {
      border-right: solid blue 12px;
      padding-right: 0px;
     
      * { 
        pointer-events: none;
      }
    }
  `
};

export const DemoTypeSelectorWithIsRanked = ( { helpId, measures, onChangeDemoTypePosition, onChangeDemoTypeSelected, onChangeDemoTypeRanked }: { helpId: string; measures: Array<Selectable<Measure> & { isRanked: boolean; }>; onChangeDemoTypePosition: ( srcValue: DemoTypeConstants, dstValue: DemoTypeConstants ) => void; onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => void; onChangeDemoTypeRanked: ( value: DemoTypeConstants, isRanked: boolean ) => void; } ): JSX.Element => {
  const draggedItemIdRef = useRef<DemoTypeConstants | null>( null );
  const draggedItemIndexRef = useRef<number | null>( null );

  return (
    <div css={ styles.demoTypeSelectorWithIsRanked }>
      <fieldset>
        <legend><label>Demo types<HelpText helpId={ helpId } /></label></legend>
        { measures.map( m => <MeasureSelector key={ m.value } { ...m } draggedItemIdRef={ draggedItemIdRef } draggedItemIndexRef={ draggedItemIndexRef } onChangeDemoTypePosition={ onChangeDemoTypePosition } onChangeDemoTypeSelected={ onChangeDemoTypeSelected } onChangeDemoTypeRanked={ onChangeDemoTypeRanked } /> ) }
      </fieldset>
    </div> );
};

const MeasureSelector = ( { name, value, isSelected, isRanked, draggedItemIdRef, draggedItemIndexRef, onChangeDemoTypePosition, onChangeDemoTypeSelected, onChangeDemoTypeRanked }: { value: DemoTypeConstants; name: string; isSelected: boolean; isRanked: boolean; draggedItemIdRef: MutableRefObject<DemoTypeConstants | null>; draggedItemIndexRef: MutableRefObject<number | null>; onChangeDemoTypePosition: ( srcValue: DemoTypeConstants, dstValue: DemoTypeConstants ) => void; onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => void; onChangeDemoTypeRanked: ( value: DemoTypeConstants, isRanked: boolean ) => void; } ) => {
  const onDragStart = useCallback( ( e: React.DragEvent<HTMLDivElement> ) => {
    const draggedItem = e.currentTarget;
    const measures = Array.from( draggedItem.parentElement?.children ?? [] );
    draggedItemIdRef.current = value;
    draggedItemIndexRef.current = measures.indexOf( draggedItem );
  }, [ draggedItemIdRef, draggedItemIndexRef, value ] );

  const translateKey = useCallback( ( key: string | undefined ): DemoTypeConstants | null => {
    if ( key === undefined ) return null;
    if ( key === DemoTypeConstants.Put.toString() ) return DemoTypeConstants.Put;
    if ( key === DemoTypeConstants.Shr.toString() ) return DemoTypeConstants.Shr;
    if ( key === DemoTypeConstants.Thos.toString() ) return DemoTypeConstants.Thos;
    if ( key === DemoTypeConstants.Rtg.toString() ) return DemoTypeConstants.Rtg;
    return null;
  }, [] );

  const onDragOver = useCallback( ( e: React.DragEvent<HTMLDivElement> ) => {
    const destinationKey = translateKey( e.currentTarget.dataset.key );
    if ( destinationKey === draggedItemIdRef.current ) return;

    e.preventDefault();
  }, [ draggedItemIdRef, translateKey ] );

  const onDragEnter = useCallback( ( e: React.DragEvent<HTMLDivElement> ) => {
    const destinationKey = translateKey( e.currentTarget.dataset.key );
    if ( destinationKey === draggedItemIdRef.current ) return;
    if ( draggedItemIndexRef.current == null ) return;

    const destinationItemIndex = Array.from( e.currentTarget.parentElement?.children ?? [] ).indexOf( e.currentTarget );
    if ( destinationItemIndex < draggedItemIndexRef.current ) {
      e.currentTarget.classList.add( "dropLeft" );
    } else {
      e.currentTarget.classList.add( "dropRight" );
    }
  }, [ draggedItemIdRef, draggedItemIndexRef, translateKey ] );

  const onDragLeave = useCallback( ( e: React.DragEvent<HTMLDivElement> ) => {
    const destinationKey = translateKey( e.currentTarget.dataset.key );
    if ( destinationKey === draggedItemIdRef.current ) return;

    e.currentTarget.classList.remove( "dropLeft" );
    e.currentTarget.classList.remove( "dropRight" );
  }, [ draggedItemIdRef, translateKey ] );

  const onDrop = useCallback( ( e: React.DragEvent<HTMLDivElement> ) => {
    const destinationId = translateKey( e.currentTarget.dataset.key );

    e.preventDefault();

    if ( draggedItemIdRef.current == null ) return;
    if ( destinationId == null ) return;

    onChangeDemoTypePosition( draggedItemIdRef.current, destinationId );

    const measures = Array.from( e.currentTarget?.parentElement?.children || [] );
    for ( const m of measures ) {
      m.classList.remove( "dropLeft" );
      m.classList.remove( "dropRight" );
    }

    draggedItemIdRef.current = null;
    draggedItemIndexRef.current = null;
  }, [ draggedItemIdRef, draggedItemIndexRef, onChangeDemoTypePosition, translateKey ] );

  return (
    <div data-key={ value.toString() } css={ styles.measure } draggable={ true } onDragOver={ onDragOver } onDragStart={ onDragStart } onDrop={ onDrop } onDragEnter={ onDragEnter } onDragLeave={ onDragLeave } onSelect={ () => false } >
      <div>
        <input aria-label="demo-type" type="checkbox" disabled={ value === DemoTypeConstants.Ats } checked={ isSelected } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeDemoTypeSelected( value, e.currentTarget.checked ) } />
        <span onSelect={ ( e ) => { e.preventDefault(); return false; } }>{ name }</span>
      </div>
      <div><input aria-label="demo-type" type="checkbox" checked={ isRanked } disabled={ !isSelected } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeDemoTypeRanked( value, e.currentTarget.checked ) } />Rank</div>
    </div>
  );
};
