import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { Model, ProgramSearchSourceModel } from "../Model";
import thunkMiddleware from "redux-thunk";
import { getFirstOfMonthWithFortyFiveDayOffset, parseDate, parseDateOrUndefined } from "Utilities";
import countriesSlice from "reducers/countriesSlice";
import demoGroupBySlice from "reducers/demoGroupBySlice";
import demoRankSlice from "reducers/demoRankSlice";
import demoSelectionSlice from "reducers/demoSelectionSlice";
import measuresSliceWithIsRanked from "reducers/measuresWithIsRankedSlice";
import averageSlice from "reducers/averageSlice";
import revenueSlice from "./revenueSlice";
import titleSlice from "reducers/titleReducer";
import outputSlice from "reducers/outputSlice";
import stationsSlice from "reducers/stationsSlice";
import demosSlice from "reducers/demosSlice";
import benchmarkSlice from "../components/benchmark-selector/benchmarkSlice";
import displaySlice from "../components/display-selector/displaySlice";
import dateTimeReducers from "../components/date-time-selector/dateTimeReducers";

const initializeModel = ( data: ProgramSearchSourceModel ): Model => {
  const model = {
    ...data,
    dateTime: {
      ...data.dateTime,
      fromDate: parseDateOrUndefined( data.dateTime.fromDate ),
      toDate: parseDateOrUndefined( data.dateTime.toDate )
    },
    benchmark: {
      ...data.benchmark,
      fromDate: ( parseDate( data.benchmark.fromDate ) ?? getFirstOfMonthWithFortyFiveDayOffset() ).valueOf(),
      toDate: ( parseDate( data.benchmark.toDate ) ?? getFirstOfMonthWithFortyFiveDayOffset() ).valueOf()
    }
  };

  // FUTURE: more validation
  if ( model.average === undefined )                                 /* */ throw new Error( "ProgramSearch.average is undefined" );
  if ( model.average.collapsePeriod === undefined )                  /* */ throw new Error( "ProgramSearch.average.collapsePeriod is undefined" );
  if ( model.average.combineWithin === undefined )                   /* */ throw new Error( "ProgramSearch.average.combineWithin is undefined" );
  if ( model.average.title === undefined )                           /* */ throw new Error( "ProgramSearch.average.title is undefined" );
  if ( model.average.breakoutSeasons === undefined )                 /* */ throw new Error( "ProgramSearch.average.breakoutSeasons is undefined" );
  if ( model.average.breakoutOriginalRepeat === undefined )          /* */ throw new Error( "ProgramSearch.average.breakoutOriginalRepeat is undefined" );
  if ( model.average.localRepeat === undefined )                     /* */ throw new Error( "ProgramSearch.average.localRepeat is undefined" );
  if ( model.benchmark === undefined )                               /* */ throw new Error( "ProgramSearch.benchmark is undefined" );
  if ( model.benchmark.demoCount === undefined )                     /* */ throw new Error( "ProgramSearch.benchmark.demoCount is undefined" );
  if ( model.benchmark.demoTypes === undefined )                     /* */ throw new Error( "ProgramSearch.benchmark.demoTypes is undefined" );
  if ( model.benchmark.dates === undefined )                         /* */ throw new Error( "ProgramSearch.benchmark.dates is undefined" );
  if ( model.benchmark.selectedQuarters === undefined )              /* */ throw new Error( "ProgramSearch.benchmark.selectedQuarters is undefined" );
  if ( model.benchmark.fromDate === undefined )                      /* */ throw new Error( "ProgramSearch.benchmark.fromDate is undefined" );
  if ( model.benchmark.toDate === undefined )                        /* */ throw new Error( "ProgramSearch.benchmark.toDate is undefined" );
  if ( model.benchmark.timePeriod === undefined )                    /* */ throw new Error( "ProgramSearch.benchmark.timePeriod is undefined" );
  if ( model.benchmark.timePeriodFromTime === undefined )            /* */ throw new Error( "ProgramSearch.benchmark.timePeriodFromTime is undefined" );
  if ( model.benchmark.timePeriodToTime === undefined )              /* */ throw new Error( "ProgramSearch.benchmark.timePeriodToTime is undefined" );
  if ( model.benchmark.benchmarkDayOfWeekType === undefined )        /* */ throw new Error( "ProgramSearch.benchmark.benchmarkDayOfWeekType is undefined" );
  if ( model.benchmark.benchmarkDays === undefined )                 /* */ throw new Error( "ProgramSearch.benchmark.benchmarkDays is undefined" );
  if ( model.countries === undefined )                               /* */ throw new Error( "ProgramSearch.countries is undefined" );
  if ( !Array.isArray( model.countries ) )                           /* */ throw new Error( "ProgramSearch.countries is not an array" );
  if ( model.dateTime === undefined )                                /* */ throw new Error( "ProgramSearch.dateTime is undefined" );
  if ( model.dateTime.days === undefined )                           /* */ throw new Error( "ProgramSearch.dateTime.days is undefined" );
  if ( model.dateTime.transmissionDuration === undefined )           /* */ throw new Error( "ProgramSearch.dateTime.transmissionDuration is undefined" );
  if ( model.dateTime.fromTime === undefined )                       /* */ throw new Error( "ProgramSearch.dateTime.fromTime is undefined" );
  if ( model.dateTime.toTime === undefined )                         /* */ throw new Error( "ProgramSearch.dateTime.toTime is undefined" );
  if ( model.dateTime.useDayParts === undefined )                    /* */ throw new Error( "ProgramSearch.dateTime.useDayParts is undefined" );
  if ( model.dateTime.dayParts === undefined )                       /* */ throw new Error( "ProgramSearch.dateTime.dayParts is undefined" );
  if ( model.demoGroupBy === undefined )                             /* */ throw new Error( "ProgramSearch.demoGroupBy is undefined" );
  if ( model.demoRank === undefined )                                /* */ throw new Error( "ProgramSearch.demoRank is undefined" );
  if ( model.demoSelection === undefined )                           /* */ throw new Error( "ProgramSearch.demoSelection is undefined" );
  if ( model.demos === undefined )                                   /* */ throw new Error( "ProgramSearch.demos is undefined" );
  if ( !Array.isArray( model.demos ) )                               /* */ throw new Error( "ProgramSearch.demos is not an array" );
  if ( model.display === undefined )                                 /* */ throw new Error( "ProgramSearch.display is undefined" );
  if ( model.display.selectedDisplayColumns === undefined )          /* */ throw new Error( "ProgramSearch.display.selectedDisplayColumns is undefined" );
  if ( model.display.displaySortedBy === undefined )                 /* */ throw new Error( "ProgramSearch.display.displaySortedBy is undefined" );
  if ( model.display.displayTime === undefined )                     /* */ throw new Error( "ProgramSearch.display.displayTime is undefined" );
  if ( model.measures === undefined )                                /* */ throw new Error( "ProgramSearch.measures is undefined" );
  if ( !Array.isArray( model.measures ) )                            /* */ throw new Error( "ProgramSearch.measures is not an array" );
  if ( model.output === undefined )                                  /* */ throw new Error( "ProgramSearch.output is undefined" );
  if ( model.output.format === undefined )                           /* */ throw new Error( "ProgramSearch.output.format is undefined" );
  if ( model.output.view === undefined )                             /* */ throw new Error( "ProgramSearch.output.view is undefined" );
  if ( model.revenue === undefined )                                 /* */ throw new Error( "ProgramSearch.revenue is undefined" );
  if ( model.revenue.revenueAmount === undefined )                   /* */ throw new Error( "ProgramSearch.revenue.revenueAmount is undefined" );
  if ( model.revenue.revenueDuration === undefined )                 /* */ throw new Error( "ProgramSearch.revenue.revenueDuration is undefined" );
  if ( model.revenue.revenueCollapse === undefined )                 /* */ throw new Error( "ProgramSearch.revenue.revenueCollapse is undefined" );
  if ( model.stations === undefined )                                /* */ throw new Error( "ProgramSearch.stations is undefined" );
  if ( !Array.isArray( model.stations ) )                            /* */ throw new Error( "ProgramSearch.stations is not an array" );
  if ( model.title === undefined )                                   /* */ throw new Error( "ProgramSearch.title is undefined" );
  if ( model.title.compareOperator === undefined )                   /* */ throw new Error( "ProgramSearch.title.compareOperator is undefined" );
  if ( model.title.resultCount === undefined )                       /* */ throw new Error( "ProgramSearch.title.resultCount is undefined" );
  if ( model.title.originalOrLocal === undefined )                   /* */ throw new Error( "ProgramSearch.title.originalOrLocal is undefined" );
  if ( model.title.numberOfCharactersToCompare === undefined )       /* */ throw new Error( "ProgramSearch.title.numberOfCharactersToCompare is undefined" );
  if ( model.title.searchTerm === undefined )                        /* */ throw new Error( "ProgramSearch.title.searchTerm is undefined" );
  if ( model.title.titleNames === undefined )                        /* */ throw new Error( "ProgramSearch.title.titleNames is undefined" );

  return model;
};

export const getStore = ( initialData: ProgramSearchSourceModel ) => configureStore( {
  preloadedState: initializeModel( initialData ),
  reducer: {
    countries: countriesSlice,
    stations: stationsSlice,
    demos: demosSlice,
    measures: measuresSliceWithIsRanked,
    demoRank: demoRankSlice,
    demoGroupBy: demoGroupBySlice,
    demoSelection: demoSelectionSlice,
    dateTime: dateTimeReducers,
    average: averageSlice,
    revenue: revenueSlice,
    benchmark: benchmarkSlice,
    display: displaySlice,
    title: titleSlice,
    output: outputSlice
  },
  middleware: [ thunkMiddleware ]
} );

type StoreType = ReturnType<typeof getStore>;

export type AppDispatch = StoreType[ "dispatch" ];
export type RootState = ReturnType<StoreType[ "getState" ]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
