/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from "react";
import { Country, OutputFormatConstants, OutputViewConstants } from "model/Model";
import { DisplayColumn, DisplaySortedByConstants, DistributorCompareOperatorConstants, DistributorFinderSourceModel, Model, QualifyFormat, QualifyFormatConstants, QualifyFormatOption, QualifyType, QualifyTypeOption } from "./Model";
import { ExpanderPanel } from "components/expander-panel/ExpanderPanel";
import { CountryDescription } from "components/country-selector/CountryDescription";
import { CountrySelector } from "components/country-selector/CountrySelector";
import { OutputDescription } from "components/output-selector/OutputDescription";
import { OutputSelector } from "components/output-selector/OutputSelector";
import { DisplaySelector } from "./components/display-selector/DisplaySelector";
import { QualifySelector } from "./components/qualify-selector/QualitySelector";
import { QualifyDescription } from "./components/qualify-selector/QualifyDescription";
import { DistributorSelector } from "./components/distributor-selector/DistributorSelector";
import { DistributorDescription } from "./components/distributor-selector/DistributorDescription";
import { DisplayDescription } from "./components/display-selector/DisplayDescription";
import { DateTimeSelector } from "./components/date-time-selector/DateTimeSelector";
import { DateTimeDescription } from "./components/date-time-selector/DateTimeDescription";
import { Selectable, formatDateAsIso8601 } from "Utilities";
import { validateCountryStartDate, validateFromAndToDates } from "ValidationHelpers";
import { ApplicationHeader } from "components/application-header/ApplicationHeader";
import { ChangeFromDate } from "reducers/fromDateReducers";
import { ChangeToDate } from "reducers/toDateReducer";
import { ChangeOutputFormat, ChangeOutputView } from "reducers/outputSlice";
import { ChangeDisplayColumnIsSelected, ChangeDisplaySortedBy } from "./components/display-selector/displaySlice";
import { ChangeDistributorCompareOperator, ChangeDistributorNames, ChangeDistributorResultCount } from "./components/distributor-selector/distributorSlice";
import { ChangeQualifyFormatIsSelected, ChangeQualifyTypeIsSelected } from "./components/qualify-selector/qualifySlice";
import { ChangeCountryIsSelected } from "reducers/countriesSlice";
import { useAppDispatch, useAppState } from "./app/hooks";
import { useApplicationContext } from "components/application-context/ApplicationContext";
import { ApplicationContextType } from "./Loader";
import { ReportProgressMessageArgs, useNotifications } from "contexts/NotificationsContext";
import { useImmer } from "use-immer";
import { getSessionCredentials } from "contexts/UserContext";
import { RunningState, downloadFile, emptyRunning, openLink } from "model/RunningState";
import { Runner } from "components/runner/Runner";
import { deleteJson, postJson, putJson } from "helpers/api";
import { useNavigate } from "react-router";
import { usePersonalization } from "contexts/PersonalizationContext";

const applicationUrl = "/Apps/DistributorFinder";

type PanelState = {
  selectedPanel: string;
  country: boolean;
  distributor: boolean;
  dateTime: boolean;
  qualify: boolean;
  display: boolean;
  output: boolean;
};

const validate = ( applicationContext: ApplicationContextType, state: Model, showDateValidation: boolean ) => {
  const validation: {
    isValidForSubmit: boolean;
    warnings: Array<string>;
    errors: Array<string>;
    country?: string;
    distributor?: string;
    dateTime?: string;
    qualify?: string;
    display?: string;
    output?: string;
  } = {
    isValidForSubmit: true,
    warnings: [],
    errors: []
  };

  if ( state.countries.length === 0 ) {
    validation.isValidForSubmit = false;
  }

  if ( state.distributor.distributorNames.filter( m => m.isSelected ).length === 0 ) {
    validation.errors.push( "You must select at least one distributor before submitting." );
    validation.isValidForSubmit = false;
  }

  if ( state.display.selectedDisplayColumns.length === 0 ) {
    validation.errors.push( "No display columns selected this will result in an empty report." );
    validation.isValidForSubmit = false;
  }

  validateFromAndToDates( state.dateTime.fromDate, state.dateTime.toDate, validation );
  // NICE: qualify should require either 'all' or one of the options
  // NICE: format should require either 'no' or one of the options

  if ( showDateValidation ) {
    const selectedCountries = applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) );
    validateCountryStartDate( selectedCountries, state.dateTime.fromDate, validation );
  }

  return validation;
};

const getDefaultPanelState = () => {
  const panelStateJson = localStorage.getItem( `ps:${ applicationUrl }` );
  if ( panelStateJson ) {
    return JSON.parse( panelStateJson ) as PanelState;
  } else {
    return { selectedPanel: "", country: false, distributor: false, dateTime: false, qualify: false, display: false, output: false };
  }
};

export const Index = (): JSX.Element => {
  const notifications = useNotifications();
  const state = useAppState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const applicationContext = useApplicationContext<ApplicationContextType>();
  const personalization = usePersonalization();

  const { token, userId } = getSessionCredentials();

  const [ running, setRunning ] = useImmer<RunningState>( emptyRunning );
  const [ panels, setPanels ] = useImmer<PanelState>( getDefaultPanelState() );
  const allExpanded = panels.country && panels.distributor && panels.dateTime && panels.qualify && panels.display && panels.output;

  useEffect( () => {
    // savePanelState
    localStorage.setItem( `ps:${ applicationUrl }`, JSON.stringify( panels ) );
  }, [ panels ] );

  const missingDisplayColumn: DisplayColumn = useMemo( () => ( { id: "-1", name: "MISSING" } ), [] );
  const missingQualifyType: QualifyType = useMemo( () => ( { value: "-1", name: "MISSING" } ), [] );
  const missingFormat: QualifyFormat = useMemo( () => ( { value: QualifyFormatConstants.Missing, name: "MISSING" } ), [] );

  const displayColumns: Array<Selectable<DisplayColumn>> = useMemo( () => applicationContext.displayColumns.map( m => ( { ...m, isSelected: !!state.display.selectedDisplayColumns.find( n => n === m.id ) } ) ), [ applicationContext.displayColumns, state.display.selectedDisplayColumns ] );
  const selectedDisplayColumns: Array<Selectable<DisplayColumn>> = useMemo( () => state.display.selectedDisplayColumns.map( m => ( { ...( applicationContext.displayColumns.find( n => n.id === m ) ?? missingDisplayColumn ), isSelected: true } ) ), [ applicationContext.displayColumns, missingDisplayColumn, state.display.selectedDisplayColumns ] );

  const types: Array<QualifyTypeOption> = useMemo( () => applicationContext.etsTypes.map( m => ( { ...m, isSelected: !!state.qualify.selectedTypes.find( n => n === m.value ) } ) ), [ applicationContext.etsTypes, state.qualify.selectedTypes ] );
  const selectedTypes: Array<QualifyTypeOption> = useMemo( () => state.qualify.selectedTypes.map( m => ( { ...( applicationContext.etsTypes.find( n => n.value === m ) ?? missingQualifyType ), isSelected: true } ) ), [ applicationContext.etsTypes, missingQualifyType, state.qualify.selectedTypes ] );

  const formats: Array<Selectable<QualifyFormatOption>> = useMemo( () => applicationContext.formats.map( m => ( { ...m, isSelected: !!state.qualify.selectedFormats.find( n => n === m.value ) } ) ), [ applicationContext.formats, state.qualify.selectedFormats ] );
  const selectedFormats: Array<Selectable<QualifyFormatOption>> = useMemo( () => state.qualify.selectedFormats.map( m => ( { ...( applicationContext.formats.find( n => n.value === m ) ?? missingFormat ), isSelected: true } ) ), [ applicationContext.formats, missingFormat, state.qualify.selectedFormats ] );

  const countryGroups = useMemo( () => applicationContext.countryGroups.map( group => {
    const countries: Array<Selectable<Country>> = applicationContext.countries.filter( m => m.group === group ).map( m => ( { ...m, isSelected: !!state.countries.find( n => n === m.id ) } ) );
    const selectedCountryCount = countries.filter( m => m.isSelected ).length;

    return ( {
      name: group,
      countries: countries,
      isSelected: countries.length === selectedCountryCount
    } );
  } ), [ applicationContext.countries, applicationContext.countryGroups, state.countries ] );

  const countries = useMemo( () => applicationContext.countries.map( m => ( { ...m, isSelected: !!state.countries.find( c => c === m.id ) } ) ), [ applicationContext.countries, state.countries ] );

  const selectedCountryIds = useMemo( () => applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) ).map( m => m.id ), [ applicationContext.countries, state.countries ] );
  const haveSelectedCountries = state.countries.length > 0;
  const isWorldSelected = applicationContext.countries.length === state.countries.length;
  const validation = useMemo( () => validate( applicationContext, state, personalization.dateWarnings ), [ applicationContext, personalization.dateWarnings, state ] );
  const display = useMemo( () => ( { ...state.display, selectedDisplayColumns, displayColumns } ), [ displayColumns, selectedDisplayColumns, state.display ] );
  const qualify = useMemo( () => ( { ...state.qualify, selectedTypes, types, selectedFormats, formats } ), [ formats, selectedFormats, selectedTypes, state.qualify, types ] );

  const onChangeCountryIsSelected = useCallback( ( countryId: number, isSelected: boolean ) => {
    const country = applicationContext.countriesById[ countryId ];
    dispatch( ChangeCountryIsSelected( { countryId, isSelected, clearExisting: false, defaultStations: country.defaultStations, defaultDemos: country.defaultDemos } ) );
  }, [ applicationContext.countriesById, dispatch ] );

  const outputEvents = useMemo( () => ( {
    onChangeOutputFormat: ( value: OutputFormatConstants ) => dispatch( ChangeOutputFormat( value ) ),
    onChangeOutputView: ( value: OutputViewConstants ) => dispatch( ChangeOutputView( value ) )
  } ), [ dispatch ] );

  const dateTimeEvents = useMemo( () => ( {
    onChangeFromDate: ( fromDate: number ) => dispatch( ChangeFromDate( fromDate ) ),
    onChangeToDate: ( fromDate: number ) => dispatch( ChangeToDate( fromDate ) )
  } ), [ dispatch ] );

  const displayEvents = useMemo( () => ( {
    onChangeDisplayColumnIsSelected: ( id: string, isSelected: boolean ) => dispatch( ChangeDisplayColumnIsSelected( { id, isSelected } ) ),
    onChangeDisplaySortedBy: ( value: DisplaySortedByConstants ) => dispatch( ChangeDisplaySortedBy( value ) )
  } ), [ dispatch ] );

  const qualifyEvents = useMemo( () => ( {
    onChangeQualifyTypeIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeQualifyTypeIsSelected( { value, isSelected } ) ),
    onChangeQualifyFormatIsSelected: ( value: QualifyFormatConstants, isSelected: boolean ) => dispatch( ChangeQualifyFormatIsSelected( { value, isSelected } ) )
  } ), [ dispatch ] );

  const distributorEvents = useMemo( () => ( {
    onChangeDistributorResultCount: ( value: number ) => dispatch( ChangeDistributorResultCount( value ) ),
    onChangeDistributorCompareOperator: ( value: DistributorCompareOperatorConstants ) => dispatch( ChangeDistributorCompareOperator( value ) ),
    onChangeDistributorNames: ( values: Array<{ distributor: string; isSelected: boolean; }> ) => dispatch( ChangeDistributorNames( values ) )
  } ), [ dispatch ] );

  const getSourceModel = useCallback( (): DistributorFinderSourceModel => {
    const model: DistributorFinderSourceModel =
    {
      countries: state.countries,
      dateTime: {
        ...state.dateTime,
        fromDate: formatDateAsIso8601( state.dateTime.fromDate ),
        toDate: formatDateAsIso8601( state.dateTime.toDate )
      },
      display: state.display,
      output: state.output,
      qualify: state.qualify,
      distributor: state.distributor
    };
    return model;
  }, [ state.countries, state.dateTime, state.display, state.distributor, state.output, state.qualify ] );

  const doSubmit = useCallback( async () => {
    if ( validation.errors.length > 0 ) {
      const message = validation.errors.join( "\n" );
      alert( message );
      return;
    }

    if ( validation.warnings.length > 0 ) {
      const message = validation.warnings.join( "\n" );
      if ( !globalThis.window.confirm( message ) ) return;
    }

    setRunning( emptyRunning );

    const model = getSourceModel();

    const result = await postJson<{ id: number; }>( applicationUrl, userId, token, model );
    if ( result ) setRunning( m => { m.id = result.id; } );
  }, [ getSourceModel, setRunning, token, userId, validation.errors, validation.warnings ] );

  const doCancel = useCallback( async () => {
    if ( !running.id ) return;

    await deleteJson( applicationUrl, userId, token, { id: running.id } );

    setRunning( m => { m.cancellationRequested = true; } );
  }, [ running.id, setRunning, token, userId ] );

  useEffect( () => {
    if ( applicationContext.templateOrHistoryId === 0 ) setPanels( m => { m.selectedPanel = ""; m.country = true; m.distributor = false; m.dateTime = false; m.qualify = false; m.display = false; m.output = false; } );
    setRunning( emptyRunning );
  }, [ applicationContext.templateOrHistoryId, setPanels, setRunning, state ] );

  const reportProgressMessageHandler = useCallback( ( args: ReportProgressMessageArgs ) => {
    console.log( args );

    if ( !args ) return;
    if ( args.id !== running.id ) return;

    setRunning( m => {
      m.percentComplete = args.percentComplete;
      m.message = args.message;
      m.url = args.url;
      m.contentType = args.contentType;
      m.status = args.status;
      if ( args.status !== "Started" ) {
        m.id = undefined;
      }
    } );

    if ( args.status === "Completed" ) {
      if ( args.contentType !== "text/html" ) {
        downloadFile( args.url, token ); // perform a download
        setRunning( emptyRunning );
      } else if ( args.contentType === "text/html" ) {
        openLink( args.url, "DistributorFinder", state.output.view );
        // setRunning( emptyRunning ); can only do this if we know that the tab has been opened
      }
      return;
    }
  }, [ running.id, setRunning, state.output.view, token ] );

  useEffect( () => {
    notifications.setOnReportProgressMessageHandler( () => reportProgressMessageHandler );
  }, [ notifications, reportProgressMessageHandler ] );

  const toggleExpandedPanels = useCallback( (): void => {
    setPanels( m => {
      const isAllExpanded = allExpanded;
      m.country = !isAllExpanded;
      m.distributor = !isAllExpanded;
      m.dateTime = !isAllExpanded;
      m.qualify = !isAllExpanded;
      m.display = !isAllExpanded;
      m.output = !isAllExpanded;
    } );
  }, [ allExpanded, setPanels ] );

  const onSaveTemplate = useCallback( async ( templateName: string, sharedWith: Array<number> ) => {
    const payload = {
      templateName,
      sharedWith,
      model: getSourceModel()
    };

    // positive values are history rows so always create a new template from those
    if ( applicationContext.templateOrHistoryId >= 0 ) {
      const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
      navigate( `/DistributorFinder/${ response.itemId }` );
    }
    else {
      const updateExistingTemplate = applicationContext.templateName === templateName;
      if ( updateExistingTemplate ) {
        await putJson( `/UserTemplate/${ applicationContext.applicationId }/${ applicationContext.templateOrHistoryId }`, userId, token, payload );
      } else {
        const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
        navigate( `/DistributorFinder/${ response.itemId }` );
      }
    }

  }, [ applicationContext.applicationId, applicationContext.templateName, applicationContext.templateOrHistoryId, getSourceModel, navigate, token, userId ] );

  return ( <>
    <ApplicationHeader applicationId={ applicationContext.applicationId } isAllExpanded={ allExpanded } title={ applicationContext.name } isEnabled={ haveSelectedCountries } onToggleExpanded={ toggleExpandedPanels } />
    <ExpanderPanel isExpanded={ panels.country } onExpanded={ ( isExpanded ) => setPanels( m => { m.country = isExpanded; } ) } title="Country:" description={ <CountryDescription warningMessage={ validation.country } isWorldSelected={ isWorldSelected } countryGroups={ countryGroups } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <CountrySelector canSelectAllCountriesInGroup={ true } countries={ countries } countryGroups={ countryGroups } isWorldSelected={ isWorldSelected } onChangeCountryIsSelected={ onChangeCountryIsSelected } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.distributor && haveSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.distributor = isExpanded; } ) } title="Distributor:" isEnabled={ haveSelectedCountries } description={ <DistributorDescription warningMessage={ validation.distributor } { ...state.distributor } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DistributorSelector { ...state.distributor } countries={ selectedCountryIds } { ...distributorEvents } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.dateTime && haveSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.dateTime = isExpanded; } ) } title="Date/Time:" isEnabled={ haveSelectedCountries } description={ <DateTimeDescription warningMessage={ validation.dateTime } { ...state.dateTime } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DateTimeSelector { ...state.dateTime } { ...dateTimeEvents } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.qualify && haveSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.qualify = isExpanded; } ) } title="Qualify:" isEnabled={ haveSelectedCountries } description={ <QualifyDescription warningMessage={ validation.qualify } { ...qualify } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <QualifySelector { ...qualify } { ...qualifyEvents } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.display && haveSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.display = isExpanded; } ) } title="Display:" isEnabled={ haveSelectedCountries } description={ <DisplayDescription warningMessage={ validation.display } { ...display } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DisplaySelector displayColumns={ display.displayColumns } displaySortedBy={ display.displaySortedBy } onChangeDisplayColumnIsSelected={ displayEvents.onChangeDisplayColumnIsSelected } onChangeDisplaySortedBy={ displayEvents.onChangeDisplaySortedBy } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.output && haveSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.output = isExpanded; } ) } title="Output:" isEnabled={ haveSelectedCountries } description={ <OutputDescription warningMessage={ validation.output } { ...state.output } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <OutputSelector includeCsv={ true } format={ state.output.format } view={ state.output.view } onSaveTemplate={ onSaveTemplate } defaultFormat={ OutputFormatConstants.Xlsx } isTemplateOrHistoryOwner={ applicationContext.templateOrHistoryOwnerId === userId } onChangeOutputFormat={ outputEvents.onChangeOutputFormat } onChangeOutputView={ outputEvents.onChangeOutputView } />
    </ExpanderPanel>
    <Runner running={ running } isValidForSubmit={ validation.isValidForSubmit } doSubmit={ doSubmit } doCancel={ doCancel } view={ state.output.view } applicationWindow="DistributorFinder" />
  </> );
};