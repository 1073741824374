/** @jsxImportSource @emotion/react */
import React, { createRef, useEffect, useState } from "react";
import { Alarm } from "../BootstrapIcons";
import { css } from "@emotion/react";
import { chunk } from "Utilities";

const styles = {
  timeSelector: css`
    display: inline-block;

    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;

      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;

        &.clickable {
          cursor: pointer;
        }
      }
    }
  `,

  timePickerWrapper: css`
  
  `,

  timePicker: css`
    border: 1px solid silver;
    padding: 0 10px;
    display: inline-block;
  `,

  disabled: css`
    background-color: #f2f2f2;
  `,

  timePickerIcon: css`
    margin-left: 10px;
    display: inline-block;
    cursor: pointer;
    padding: 3px;

    &:hover {
      background-color: silver;
    }
  `,

  timeListWrapper: css`
    z-index: 100;
    box-shadow: 5px 5px 5px #666;
    background-color: white;
    position: absolute;
    border: 1px solid silver;    
  `,

  timeHeading: css`
    text-align: center;
    background-color: #eeeeee;
  `,

  times: css`
    /* Clear floats after the columns */
    display: flex;
    align-content:space-between;
    
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  `,

  column: css`
    &:not(:last-child) {
      border-right: 1px solid silver;
    }
  `,

  chosenTime: css`
    background-color: #FFCC33;
  `,

  time: css`
    padding: 0 8px;

    &:hover {
      cursor: pointer;
      background-color: silver;
    }
  `
};

// TODO: warning message in summary

export const TimePicker = ( { heading, timesList, currentTime, isEnabled, onChangeTime }: { heading: string; currentTime: string; timesList: string[]; isEnabled: boolean; onChangeTime: ( time: string ) => void; /* event fired when the date changes */ } ): JSX.Element => {
  const [ timePickerVisible, setTimePickerVisible ] = useState( false );
  const [ pos, setPos ] = useState( { left: 0, top: 0 } );

  return ( <>
    <div css={ styles.timePickerWrapper }>
      <div css={ [ styles.timePicker, ( !isEnabled ? styles.disabled : undefined ) ] } onClick={ ( e ) => {
        if ( isEnabled ) {
          setTimePickerVisible( !timePickerVisible );
          const targetPosition = e.currentTarget.getBoundingClientRect();
          setPos( { left: targetPosition.x, top: targetPosition.y + window.scrollY } );
        }
      } }>{ currentTime }</div>
      <div css={ styles.timePickerIcon } onClick={ ( e ) => {
        if ( isEnabled ) {
          setTimePickerVisible( !timePickerVisible );
          const targetPosition = e.currentTarget.getBoundingClientRect();
          setPos( { left: targetPosition.x, top: targetPosition.y + window.scrollY } );
        }
      } }><Alarm /></div>
      { timePickerVisible && <TimeList heading={ heading } selectedTime={ currentTime } timesList={ timesList } pos={ pos } onHide={ () => setTimePickerVisible( false ) } onChangeTime={ ( t ) => { onChangeTime( t ); setTimePickerVisible( !timePickerVisible ); } }></TimeList> }
    </div>
  </> );
};

const TimeList = ( { heading, selectedTime, timesList, pos, onHide, onChangeTime }: { heading: string; selectedTime: string; timesList: string[]; pos: { left: number; top: number; }; onChangeTime: ( time: string ) => void; onHide: () => void; } ) => {
  const divRef = createRef<HTMLDivElement>();

  useEffect( () => {
    if ( !divRef.current ) return;

    const onKeyDown = ( e: KeyboardEvent ) => {
      if ( e.key === "Escape" ) onHide();
    };

    const onClick = ( e: MouseEvent ) => {
      if ( e.target === null ) return;
      if ( divRef.current === null ) return;
      if ( divRef.current.parentElement === null ) return;

      const target = e.target as HTMLElement;

      // if the e is outside of element then it's a click outside of the element
      if ( divRef.current.parentElement.contains( target ) ) return;

      onHide();

      // also need to cancel this event if we're handling it
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      return false;
    };

    globalThis.document.body.addEventListener( "click", onClick );
    globalThis.document.body.addEventListener( "keydown", onKeyDown );

    return () => {
      globalThis.document.body.removeEventListener( "click", onClick );
      globalThis.document.body.removeEventListener( "keydown", onKeyDown );
    };
  }, [ divRef, onHide ] );

  return (
    <div ref={ divRef }>
      <div css={ [ styles.timeListWrapper ] }>
        <div css={ styles.timeHeading }><span>{ heading }</span></div>
        { <div css={ styles.times }>
          { chunk( timesList, 15 ).map( ( value: Array<string>, index: number ) => (
            <div css={ styles.column } key={ index }>
              { value.map( ( value, index ) => (
                <div css={ [ styles.time, value === selectedTime ? styles.chosenTime : undefined ] } key={ index } onClick={ () => { onChangeTime( value ); } }>{ value }</div>
              ) ) }
            </div>
          ) ) }
        </div> }
      </div>
    </div>
  );
};
