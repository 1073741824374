import { Applications } from "constants/application";
import { Country, Measure, UserOrGroup } from "model/Model";
import { createContext, useContext } from "react";

export type ApplicationContextBaseType = {
  readonly applicationId: Applications;
  readonly name: string;
  readonly countries: Array<Country>;
  readonly countryGroups: Array<string>;
  readonly measures: Array<Measure>;
  readonly sharingUsersAndGroups: Array<UserOrGroup>;
  readonly templateOrHistoryId: number;
  readonly templateOrHistoryOwnerId: number;
  readonly templateName: string;
  readonly sharedWith: Array<number>;
};

export const ApplicationContext = createContext<ApplicationContextBaseType>( {} as ApplicationContextBaseType );

export const useApplicationContext = <TContext,>(): TContext => useContext( ApplicationContext ) as TContext;
