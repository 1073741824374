/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { HelpText } from "components/help-text/HelpText";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { Selectable } from "Utilities";
import { DistributorCompareOperatorConstants } from "../../Model";
import { css } from "@emotion/react";
import { useUser } from "contexts/UserContext";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";
import { postJson } from "helpers/api";
import { LoadingDisplay } from "components/loading-display/LoadingDisplay";

const styles = {
  distributorSearch: css`
    vertical-align: top;
    display: inline-block;

      input {
        width: 25px !important;  
    }
    `,
  searchSelector: css`
    display: inline-block;
    vertical-align: top;

    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;

      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;

        &.clickable {
          cursor: pointer;
        }
      }
    }
  `,

  resultsSelector: css`
    display: inline-block;
    vertical-align: top;
    max-width: 360px;
    width: 360px;
    select { 
      width: 300px;
       option { 
       overflow:hidden; 
       text-overflow: ellipsis;
      }
    }
    button {
      margin-left:5px;
    }
  `,

  searchResults: css`
  max-width: 297px;
  width: 297px;
  margin-top: 10px;
  select { 
    width: 300px;
     option { 
     overflow:hidden; 
     text-overflow: ellipsis;
    }
  }`,

  excessResults: css`
  font-weight: bold;`
};

const DistributorResults = ( { searchResults, onSelect }: { searchResults: Array<string>; onSelect: ( value: string ) => void; } ) => {
  return (
    <div css={ styles.searchResults }>
      <select aria-label="results" size={ 10 } onChange={ ( e ) => onSelect( e.currentTarget.value ) }>
        { searchResults.map( ( m, i ) => ( <option title={ m } css={ css`max-width: 300px; overflow:hidden; text-overflow: ellipsis;` } key={ i }>{ m }</option> ) ) }
      </select>
    </div>
  );
};

const getSearchResults = async ( user: { userId: number; token: string; }, searchText: string, countryIds: Array<number>, maxResultCount: number, compareOperator: DistributorCompareOperatorConstants ): Promise<Array<string>> => {
  console.log( `Searching for ${ searchText }` );

  const result = await postJson<Array<string>>( "/DistributorsSearch", user.userId, user.token, { searchText, countryIds, maxResultCount, compareOperator } );
  return result;
};

const DistributorSearch = ( { label, searchTerm, helpId, minLength = 2, countryIds, maxResultCount, compareOperator, onSelect }: { label: string; searchTerm?: string; helpId: string; minLength?: number; countryIds: Array<number>; maxResultCount: number; compareOperator: DistributorCompareOperatorConstants; onSelect: ( value: string ) => void; } ) => {
  const user = useUser();
  const [ searchText, setSearchText ] = useState( "" );
  const [ searchResults, setSearchResults ] = useState<Array<string>>( [] );
  const [ haveExcessResults, setHaveExcessResults ] = useState( false );
  const [ isSearching, setIsSearching ] = useState( false );
  const timer = useRef<number | undefined>( undefined );

  useEffect( () => {
    if ( searchTerm ) {
      setSearchText( searchTerm );
    }
  }, [ searchTerm ] );

  useEffect( () => {
    // TODO: implement cancellable pattern
    const getResults = async () => {
      try {
        setIsSearching( true );
        setSearchResults( [] );
        setHaveExcessResults( false );

        const text = searchText;
        const results = await getSearchResults( user, text, countryIds, maxResultCount, compareOperator );
        console.log( `Found ${ results.length } results for ${ text }` );
        setSearchResults( results );

        console.log( `Results ${ results.length > maxResultCount }` );
        if ( results.length > maxResultCount ) {
          setHaveExcessResults( true );
          setSearchResults( results.slice( 0, maxResultCount ) );
        }
      }
      finally {
        setIsSearching( false );
      }
    };

    const trimmed = searchText.trim();
    clearTimeout( timer.current );
    timer.current = window.setTimeout( () => {
      if ( trimmed.length <= minLength ) {
        setSearchResults( [] );
      } else {
        getResults();
      }
    }, 1000 );
  }, [ compareOperator, countryIds, maxResultCount, minLength, searchText, user ] );

  return (
    <div css={ styles.searchSelector }>
      <fieldset>
        <legend><label>{ label } <HelpText helpId={ helpId } /> { ( haveExcessResults ? <span css={ styles.excessResults }>(more results than shown)</span> : "" ) }</label></legend>
        <input css={ css`width: 290px;` } aria-label={ label } type="text" value={ searchText } onChange={ ( e ) => setSearchText( e.currentTarget.value ) } />

        { isSearching && <div css={ css`margin-top:10px;` }><LoadingDisplay text="Searching..." fullScreen={ false } height={ 15 } width={ 15 } borderWidth={ 10 } /></div> }
        { !isSearching && searchResults.length > 0 && <DistributorResults searchResults={ searchResults } onSelect={ onSelect } /> }
      </fieldset>
    </div>
  );
};

const SelectedResults = ( { label, helpId, results, onClearResults, onSetSelectedResults }: { label: string; helpId: string; results: Array<Selectable<{ distributor: string; }>>; onClearResults: () => void; onSetSelectedResults: ( results: Array<Selectable<{ distributor: string; }>> ) => void; } ) => {
  const updateSelected = useCallback( ( selectedOptions: HTMLCollectionOf<HTMLOptionElement> ) => {
    const selectedOptionsArray: Array<string> = [];
    const length = selectedOptions.length;
    for ( let i = 0; i < length; i++ ) {
      const s = selectedOptions.item( i );
      if ( s ) selectedOptionsArray.push( s.text );
    }
    onSetSelectedResults( results.map( m => ( { ...m, isSelected: selectedOptionsArray.indexOf( m.distributor ) >= 0 } ) ) );
  }, [ onSetSelectedResults, results ] );

  return (
    <div css={ styles.resultsSelector }>
      <fieldset>
        <legend><label>{ label } <HelpText helpId={ helpId } /></label></legend>
        <select aria-label={ label } size={ 12 } multiple={ true } value={ results.filter( m => m.isSelected ).map( m => m.distributor ) } onChange={ e => updateSelected( e.currentTarget.selectedOptions ) } onClick={ e => updateSelected( e.currentTarget.selectedOptions ) } >
          { results.map( ( m, i ) => <option title={ m.distributor } css={ css`max-width: 300px; overflow:hidden; text-overflow: ellipsis;` } key={ i }>{ m.distributor }</option> ) }
        </select>
        <button css={ css`vertical-align: top;` } onClick={ () => onClearResults() }>X</button>
      </fieldset>
    </div>
  );
};

const distributorResultCountItems = [ { value: 20, name: "20" }, { value: 50, name: "50" }, { value: 100, name: "100" } ];
const distributorCompareOperatorItems = [ { value: DistributorCompareOperatorConstants.Equals, name: "Equal to" }, { value: DistributorCompareOperatorConstants.Begins, name: "Beginning with" }, { value: DistributorCompareOperatorConstants.Contains, name: "Containing" } ];

export const DistributorSelector = ( { resultCount, compareOperator, searchTerm, distributorNames, countries, onChangeDistributorResultCount, onChangeDistributorCompareOperator, onChangeDistributorNames }: { resultCount: number; compareOperator: DistributorCompareOperatorConstants; searchTerm?: string; distributorNames: Array<Selectable<{ distributor: string; }>>; countries: Array<number>; onChangeDistributorResultCount: ( value: number ) => void; onChangeDistributorCompareOperator: ( value: DistributorCompareOperatorConstants ) => void; onChangeDistributorNames: ( value: Array<Selectable<{ distributor: string; }>> ) => void; } ): JSX.Element => {
  const addToAllResults = useCallback( ( value: string ) => {
    if ( distributorNames.find( m => m.distributor === value ) ) return;
    onChangeDistributorNames( [ ...distributorNames, { distributor: value, isSelected: true } ] );
  }, [ distributorNames, onChangeDistributorNames ] );

  return ( <>
    <div css={ styles.distributorSearch }>
      <div >
        <RadioGroupSelector label="Results" helpId="distributor_result_count" value={ resultCount } radioGroup="distributor-result-count" items={ distributorResultCountItems } onChangeItemSelected={ onChangeDistributorResultCount } />
      </div>
      <div>
        <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Operator" helpId="distributor_operator" value={ compareOperator } radioGroup="distributor-compare-operator" items={ distributorCompareOperatorItems } onChangeItemSelected={ onChangeDistributorCompareOperator } />
      </div>
    </div>
    <div css={ css`display: inline-block; vertical-align: top;` }>
      <DistributorSearch label="Search" searchTerm={ searchTerm } helpId="titleSearch" onSelect={ addToAllResults } compareOperator={ compareOperator } maxResultCount={ resultCount } countryIds={ countries } />
      { distributorNames.length > 0 && <SelectedResults label="Select" helpId="distributor_results" results={ distributorNames } onClearResults={ () => onChangeDistributorNames( distributorNames.filter( m => !m.isSelected ) ) } onSetSelectedResults={ onChangeDistributorNames } /> }
    </div>
  </> );
};
