import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Display, DisplaySortedByConstants, DisplayTimeConstants } from "../../Model";

const displaySlice = createSlice( {
  name: "display",
  initialState: {
    selectedDisplayColumns: [],
    displaySortedBy: DisplaySortedByConstants.CountryChannel,
    displayTime: DisplayTimeConstants.TotalMinutes
  } as Display,
  reducers: {
    ChangeDisplayColumnIsSelected( state, action: PayloadAction<{ id: string; isSelected: boolean; }> ) {
      const { id, isSelected } = action.payload;
      if ( isSelected ) {
        state.selectedDisplayColumns = [ ...state.selectedDisplayColumns, id ];
      } else {
        state.selectedDisplayColumns = state.selectedDisplayColumns.filter( m => m !== id );
      }
    },

    ChangeDisplaySortedBy( state, action: PayloadAction<DisplaySortedByConstants> ) { state.displaySortedBy = action.payload; },
    ChangeDisplayTime( state, action: PayloadAction<DisplayTimeConstants> ) { state.displayTime = action.payload; }
  }
} );

export const { ChangeDisplayTime, ChangeDisplaySortedBy, ChangeDisplayColumnIsSelected } = displaySlice.actions;

export default displaySlice.reducer;