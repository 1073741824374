import { DaysConstants } from "model/Model";
import { createAction, createReducer } from "@reduxjs/toolkit";

export const ChangeDaysSelected = createAction<Array<DaysConstants>>( "ChangeDaysSelected" );

const dayReducers = createReducer(
  [] as Array<DaysConstants>,
  builder => {
    builder.addCase( ChangeDaysSelected, ( state, action ) => state = action.payload );
  }
);

export default dayReducers;
