import { HelpText } from "Utilities";
import { HelpContext } from "components/help-text/HelpText";
import { Index } from "apps/ApplicationPanels/Index";
import { ApplicationPanel } from "./ApplicationPanel";

export const ApplicationPanels = ( { helpText, applications, applicationId }: { helpText: HelpText; applications: ApplicationPanel[]; applicationId: number; } ) => {
  return ( <>
    <HelpContext.Provider value={ helpText } >
      <Index applicationId={ applicationId } applications={ applications } />
    </HelpContext.Provider>
  </> );
};
