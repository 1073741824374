import { Qualify } from "../../Model";
import { QualifyFormatConstants } from "apps/DistributorFinder/Model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const qualifySlice = createSlice( {
  name: "qualify",
  initialState: {
    selectedTypes: [],
    selectedFormats: []
  } as Qualify,
  reducers: {
    ChangeQualifyTypeIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedTypes: [ ...state.selectedTypes, value ] };
      }
      return { ...state, selectedTypes: state.selectedTypes.filter( m => m !== value ) };
    },

    ChangeQualifyFormatIsSelected( state, action: PayloadAction<{ value: QualifyFormatConstants; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedFormats: [ ...state.selectedFormats, value ] };
      }
      return { ...state, selectedFormats: state.selectedFormats.filter( m => m !== value ) };
    }
  }
} );

export const { ChangeQualifyFormatIsSelected, ChangeQualifyTypeIsSelected } = qualifySlice.actions;

export default qualifySlice.reducer;