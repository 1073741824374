export enum ApplicationContextMenuActions {
  NewFolder = "newFolder",
  Rename = "rename",
  Delete = "delete",
  CopyToProgramFinder = "copyToProgramFinder",
  CopyToDistributorFinder = "copyToDistributorFinder",
  CopyToProgramSearch = "copyToProgramSearch",
  CopyToProgramPerformance = "copyToProgramPerformance",
  CopyToProgramRanking = "copyToProgramRanking",
  CopyToProgramAverageTimeSpent = "copyToProgramAverageTimeSpent",
  CopyToChannelView = "copyToChannelView",
  CopyToQuarterHour = "copyToQuarterHour",
  CopyToRevenueEstimator = "copyToRevenueEstimator"
}
