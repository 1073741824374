import { ReportTypeSummaryConstants } from "model/Model";
import { ReportType, ReportTypeConstants } from "apps/ProgramRanking/Model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const reportTypeSlice = createSlice( {
  name: "reportType",
  initialState: {
    reportType: ReportTypeConstants.ImportsAndLocal,
    selectedReportTypeSummaries: [],
    isSummaryOnly: false,
    summaryCombineChannels: false,
    summaryCombineSeries: false,
    summaryCombineFilm: false
  } as ReportType,
  reducers: {
    ChangeReportType( state, action: PayloadAction<ReportTypeConstants> ) { state.reportType = action.payload; },

    ChangeReportTypeSummaryIsSelected( state, action: PayloadAction<{ reportTypeSummaryId: ReportTypeSummaryConstants; isSelected: boolean; }> ) {
      const { reportTypeSummaryId, isSelected } = action.payload;

      if ( isSelected ) {
        state.selectedReportTypeSummaries = [ ...state.selectedReportTypeSummaries, reportTypeSummaryId ];
      } else {
        state.selectedReportTypeSummaries = state.selectedReportTypeSummaries.filter( m => m !== reportTypeSummaryId );
      }
    },

    ChangeReportTypeSummarySelectedOrder( state, action: PayloadAction<{ srcReportTypeSummary: ReportTypeSummaryConstants; dstReportTypeSummary: ReportTypeSummaryConstants; }> ) {
      const { srcReportTypeSummary, dstReportTypeSummary } = action.payload;

      // get the selected summaries in sorted order
      const selectedReportTypeSummaries = [ ...state.selectedReportTypeSummaries ];

      // move the required item to it's new index
      const srcIndex = selectedReportTypeSummaries.findIndex( m => m === srcReportTypeSummary );
      const dstIndex = selectedReportTypeSummaries.findIndex( m => m === dstReportTypeSummary );
      selectedReportTypeSummaries.splice( dstIndex, 0, selectedReportTypeSummaries.splice( srcIndex, 1 )[ 0 ] );

      state.selectedReportTypeSummaries = selectedReportTypeSummaries;
    },

    ChangeReportTypeNoneSelected( state ) { state.selectedReportTypeSummaries = []; },
    ChangeReportTypeSummaryIsSummaryOnly( state, action: PayloadAction<boolean> ) { state.isSummaryOnly = action.payload; },
    ChangeReportTypeSummaryCombineChannels( state, action: PayloadAction<boolean> ) { state.summaryCombineChannels = action.payload; },
    ChangeReportTypeSummaryCombineSeries( state, action: PayloadAction<boolean> ) { state.summaryCombineSeries = action.payload; },
    ChangeReportTypeSummaryCombineFilms( state, action: PayloadAction<boolean> ) { state.summaryCombineFilm = action.payload; }
  }
} );

export const { ChangeReportType, ChangeReportTypeSummaryIsSelected, ChangeReportTypeSummarySelectedOrder, ChangeReportTypeNoneSelected, ChangeReportTypeSummaryIsSummaryOnly, ChangeReportTypeSummaryCombineChannels, ChangeReportTypeSummaryCombineSeries, ChangeReportTypeSummaryCombineFilms } = reportTypeSlice.actions;

export default reportTypeSlice.reducer;