import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Distributor, DistributorCompareOperatorConstants } from "../../Model";

const distributorSlice = createSlice( {
  name: "distributor",
  initialState: {
    compareOperator: DistributorCompareOperatorConstants.Contains,
    distributorNames: [],
    resultCount: 20
  } as Distributor,
  reducers: {
    ChangeDistributorCompareOperator( state, action: PayloadAction<DistributorCompareOperatorConstants> ) { state.compareOperator = action.payload; },
    ChangeDistributorResultCount( state, action: PayloadAction<number> ) { state.resultCount = action.payload; },
    ChangeDistributorNames( state, action: PayloadAction<Array<{ distributor: string; isSelected: boolean; }>> ) { state.distributorNames = action.payload; }
  }
} );

export const { ChangeDistributorCompareOperator, ChangeDistributorNames, ChangeDistributorResultCount } = distributorSlice.actions;

export default distributorSlice.reducer;