import { createAction, createReducer } from "@reduxjs/toolkit";

export const ChangeTransmissionDuration = createAction<number>( "ChangeTransmissionDuration" );

const transmissionDurationReducers = createReducer(
  0,
  builder => {
    builder.addCase( ChangeTransmissionDuration, ( _, action ) => action.payload );
  }
);

export default transmissionDurationReducers;