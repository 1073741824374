/** @jsxImportSource @emotion/react */
import React from "react";
import { HelpText } from "components/help-text/HelpText";
import { RadioGroupDisplayConstants } from "./RadioGroupDisplayConstants";
import { css } from "@emotion/react";

const styles = {
  radioGroupSelector: css`
    line-height: 22px;
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
    }
  `,

  stacked: css`
    display: block;
    span {
      vertical-align: middle;
    }

    input {
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 4px;
      width: auto;
      vertical-align: middle;
    }
  `,

  inline: css`
    display: inline-block;
    padding-right: 10px;

    input {
      vertical-align: middle;
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 0;
      margin-right: 5px;
    }

    span {
      vertical-align: middle;
    }
  `
};

export const RadioGroupSelector = <T,>( { label, isEnabled = true, radioGroup, items, value, helpId, radioGroupDisplayOption = RadioGroupDisplayConstants.Inline, onChangeItemSelected }: { label: string; radioGroup: string; isEnabled?: boolean; value: T; items: Array<{ value: T; name: string; isEnabled?: boolean; }>; helpId: string; radioGroupDisplayOption?: RadioGroupDisplayConstants; onChangeItemSelected: ( value: T ) => void; } ): JSX.Element => (
  <div css={ styles.radioGroupSelector }>
    <fieldset>
      <legend><label>{ label } <HelpText helpId={ helpId } /> </label></legend>
      { items.map( ( item, i ) => (
        <label key={ i } css={ radioGroupDisplayOption === RadioGroupDisplayConstants.Stacked ? styles.stacked : styles.inline } >
          <input disabled={ !( isEnabled && ( item.isEnabled === undefined || item.isEnabled ) ) } type="radio" radioGroup={ radioGroup } checked={ item.value === value } onChange={ () => onChangeItemSelected( item.value ) } />
          <span>{ item.name }</span>
        </label> ) ) }
    </fieldset>
  </div>
);
