/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { DisplaySortedByConstants, DisplayTimeConstants, DisplayTopConstants, DisplayUniqueConstants } from "apps/ProgramAverageTimeSpent/Model";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const DisplayDescription = ( { displaySortedBy, displayUnique, displayTop, displayTime, warningMessage }: { displaySortedBy: DisplaySortedByConstants; displayUnique: DisplayUniqueConstants; displayTop: DisplayTopConstants; displayTime: DisplayTimeConstants; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [ ( <span key={ 0 } css={ styles.displayGroupSpace }>Sorted by</span> ) ];

    const sortedByText = ( ( value ): string => {
      switch ( value ) {
        case DisplaySortedByConstants.CountryChannel: return "Country/Channel,";
        case DisplaySortedByConstants.CountryDate: return "Country/Date,";
        case DisplaySortedByConstants.Title: return "Title,";
        case DisplaySortedByConstants.StartDate: return "Start Date,";
        case DisplaySortedByConstants.Rank: return "Rank";
        case DisplaySortedByConstants.Distributor: return "Distributor,";
        case DisplaySortedByConstants.ShowType: return "Show Type,";
        case DisplaySortedByConstants.ProductionCountry: return "Production Country,";
        default: return "";
      }
    } )( displaySortedBy );

    results.push( ( <span key="display_sorted_by_option" css={ styles.displayGroupSpace }>{ sortedByText }</span> ) );

    if ( displaySortedBy === DisplaySortedByConstants.Rank ) {
      results.push( ( <span key="display_top" css={ styles.displayGroupSpace }>{ ( displayTop === DisplayTopConstants.All ? "All -" : `Top ${ displayTop },` ) }</span> ) );
    }

    const displayUniqueTest = ( ( value ): string => {
      switch ( value ) {
        case DisplayUniqueConstants.All: return "";
        case DisplayUniqueConstants.FirstBroadcast: return "First Broadcast,";
        case DisplayUniqueConstants.TopPerforming: return "Top Performing,";
      }
    } )( displayUnique );

    if ( displayUnique !== DisplayUniqueConstants.All ) {
      results.push( ( <span key="display_Unique" css={ styles.displayGroupSpace }>{ displayUniqueTest }</span> ) );
    }

    const timeOptionText = ( ( value ): string => {
      switch ( value ) {
        case DisplayTimeConstants.TotalMinutes: return "Duration as Total Minutes";
        case DisplayTimeConstants.HoursMinutes: return "Duration as HH:MM.";
        case DisplayTimeConstants.HoursMinutesSeconds: return "Duration as HH:MM:SS.";
      }
    } )( displayTime );

    results.push( ( <span key="display_time_option" css={ styles.displayGroupSpace }>{ timeOptionText }</span> ) );

    return results;
  }, [ displaySortedBy, displayTime, displayTop, displayUnique ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
