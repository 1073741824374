/** @jsxImportSource @emotion/react */
import React, { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, ChevronDoubleLeft, ChevronDoubleRight, ChevronLeft, ChevronRight } from "../BootstrapIcons";
import { css } from "@emotion/react";
import { usePersonalization } from "contexts/PersonalizationContext";

const styles = {
  outer: css`
    margin-right: 10px;
    width: 150px;

    button {
      border: 0;
      padding: 0;
      margin: 0;
      cursor: pointer;
        
      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #FFCC33;
      }
    }
  `,

  current: css`
    font-weight: bold;
    background-color: #FFCC33;  
  `,

  grey: css`
    color: silver;
  `,

  calendarView: css`
    width: 260px;
    position: absolute;
    z-index: 100;
    box-shadow: 5px 5px 5px #666;
  `,

  header: css`
    background-color: #EEEFF1;
    text-align: center;

    button {
      background-color: #EEEFF1;

      svg {
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
      }
    }
  `,

  dayPicker: css`
    width: 100%;
    background-color: #DBDFE4;
    border-collapse: collapse;
    
    thead {
      th {
        font-weight: normal;
        background-color: #CCD2D9;
        text-align: center;
        width: 20px;
      }
    }

    tbody {
      td {
        text-align: center;
        width: 20px;

        &:hover {
          cursor: pointer;
          background-color: #FFCC33;
        }
      }
    }

    tfoot {
      text-align: center;

      button {
        font-size: small;
        border: 1px solid silver;
        margin-right: 3px;
        margin-left: 3px;
        background-color: #FFCC33;
        padding:2px;

        &:hover {
          background-color: #eee;
        }
      }
    }
  `,

  monthAndYearPicker: css`
    width: 100%;
    background-color: white;
    border-collapse: collapse;
  
    tbody {
      td {
        text-align: center;
        width: 20px;
  
        &:hover {
          cursor: pointer;
        }
      
        &:nth-of-type(even) {
          border-right: 1px solid silver;
        }
      }
    }
  
    tfoot {
      text-align: center;
  
      button {
        font-size: small;
        border: 1px solid silver;
        margin-right: 3px;
        margin-left: 3px;
        padding:2px;
        background-color: #FFCC33;

        &:hover {
          background-color: #eee;
        }
      }
    }
  `,

  placeholder: css`
    display: inline-block;
    width: 102px;
    border: 1px solid silver;
    line-height:24px;
    padding-left:4px;
    padding-right:4px;
    text-align: center;
  `,

  dateInput: css`
  display: inline-block;
  width: 104px;
  height:20px;
  font-size:12px;
  text-align: center;
`,

  y: css`
  input {
    width: 100px
  }
  `,

  chosenMonth: css`
    background-color: #FFCC33;
  `,

  chosenYear: css`
    background-color: #FFCC33;
  `
};

// TODO
// 2. styling
// 3. container events
// 4. min and max values
// 5. validation

const getDateFormatFromString = ( format: string ): Intl.DateTimeFormat => {
  switch ( format ) {
    case "dd/MM/yyyy": return new Intl.DateTimeFormat( "en-GB", { day: "numeric", month: "numeric", year: "numeric" } );
    case "MM/dd/yyyy": return new Intl.DateTimeFormat( "en-US", { day: "numeric", month: "numeric", year: "numeric" } );
    case "dd MMM yyyy": return new Intl.DateTimeFormat( "en-GB", { day: "numeric", month: "short", year: "numeric" } );
    default: throw new Error( "Unsupported date format: " + format );
  }
};

export enum DatePickerChangeTypeConstants {
  textChanged,
  mouseClick
}

export const DatePicker = ( { displayFormat, currentDate, children, onChangeDate, onCalendarViewYearChange, onCalendarViewMonthChange }: { currentDate: number; displayFormat: "dd/MM/yyyy" | "MM/dd/yyyy" | "dd MMM yyyy"; /* format of control when not editing */ children?: React.ReactNode; onChangeDate: ( date: number, datePickerChangeType: DatePickerChangeTypeConstants ) => void; /* event fired when the date changes */ onCalendarViewYearChange?: ( year: number ) => void; onCalendarViewMonthChange?: ( month: number ) => void; } ): JSX.Element => {
  const inputFormat = usePersonalization().dateFormat;
  const [ inputVisible, setInputVisible ] = useState( false );
  const [ calendarVisible, setCalendarVisible ] = useState( false );

  const formattedDisplayDate = useMemo( () => !currentDate || isNaN( new Date( currentDate ).getDate() ) ? "" : getDateFormatFromString( displayFormat ).format( new Date( currentDate ) ), [ currentDate, displayFormat ] );
  const formattedInputDate = useMemo( () => !currentDate || isNaN( new Date( currentDate ).getDate() ) ? "" : getDateFormatFromString( inputFormat ).format( new Date( currentDate ) ), [ currentDate, inputFormat ] );

  const onTextInputChange = useCallback( ( e: React.FormEvent<HTMLInputElement> ) => {
    if ( calendarVisible ) return;

    const text = e.currentTarget.value;

    try {
      switch ( inputFormat ) {
        case "dd/MM/yyyy":
          {
            const ukDateRegex = /^(\d{1,2})[-\\/](\d{1,2})[-\\/](\d{2}\d{2}?)$/;
            const ukRegexResult = text.match( ukDateRegex );
            if ( ukRegexResult ) {
              const year = parseInt( ukRegexResult[ 3 ], 10 );
              const month = parseInt( ukRegexResult[ 2 ], 10 ) - 1;
              const day = parseInt( ukRegexResult[ 1 ], 10 );
              onChangeDate( new Date( year, month, day ).valueOf(), DatePickerChangeTypeConstants.textChanged );
            } else {
              onChangeDate( new Date( text ).valueOf(), DatePickerChangeTypeConstants.textChanged );
            }
          }
          break;

        case "MM/dd/yyyy":
          {
            const usDateRegex = /^(\d{1,2})[-\\/](\d{1,2})[-\\/](\d{2}\d{2}?)$/;
            const usRegexResult = text.match( usDateRegex );
            if ( usRegexResult ) {
              const year = parseInt( usRegexResult[ 3 ], 10 );
              const month = parseInt( usRegexResult[ 1 ], 10 ) - 1;
              const day = parseInt( usRegexResult[ 2 ], 10 );
              onChangeDate( new Date( year, month, day ).valueOf(), DatePickerChangeTypeConstants.textChanged );
            } else {
              onChangeDate( new Date( text ).valueOf(), DatePickerChangeTypeConstants.textChanged );
            }
          }
          break;

        default: throw new Error( "Unsupported date format" );
      }

      setInputVisible( false );
    }
    catch ( ex ) {
      // ignore the error
      window.alert( "Invalid date format" );
    }
  }, [ calendarVisible, inputFormat, onChangeDate ] );

  return (
    <div css={ styles.outer }>
      {
        !inputVisible && <span tabIndex={ 0 } css={ styles.placeholder } onFocus={ () => { setInputVisible( true ); setCalendarVisible( false ); } } onClick={ () => { setInputVisible( true ); setCalendarVisible( false ); } } >{ formattedDisplayDate }</span>
      }
      {
        inputVisible && <input css={ styles.dateInput } aria-label="date" type="text" autoFocus onFocus={ e => e.currentTarget.select() } onBlur={ onTextInputChange } defaultValue={ formattedInputDate } />
      }
      <button css={ css`margin-left:10px !important;` } type="button" aria-label="calendar" onClick={ () => { setCalendarVisible( !calendarVisible ); setInputVisible( false ); } }><Calendar /></button>
      { calendarVisible && <CalendarView currentDate={ currentDate } onHide={ () => setCalendarVisible( false ) } onChangeDate={ ( dt ) => onChangeDate( dt, DatePickerChangeTypeConstants.mouseClick ) } onCalendarViewYearChange={ onCalendarViewYearChange } onCalendarViewMonthChange={ onCalendarViewMonthChange }>{ children }</CalendarView> }
    </div>
  );
};

const CalendarView = ( { currentDate, onChangeDate, children, onHide, onCalendarViewYearChange, onCalendarViewMonthChange }: { currentDate: number; onChangeDate: ( date: number ) => void; onHide: () => void; children: React.ReactNode; onCalendarViewYearChange?: ( year: number ) => void; onCalendarViewMonthChange?: ( month: number ) => void; } ) => {
  const divRef = createRef<HTMLDivElement>();
  const [ currentYear, setCurrentYear ] = useState( !currentDate || isNaN( new Date( currentDate ).getFullYear() ) ? 2020 : new Date( currentDate ).getFullYear() );
  const [ currentMonth, setCurrentMonth ] = useState( !currentDate || isNaN( new Date( currentDate ).getMonth() ) ? 1 : new Date( currentDate ).getMonth() + 1 );
  const [ monthsViewVisible, setMonthsViewVisible ] = useState( false );

  useEffect( () => {
    if ( !divRef.current ) return;

    const onKeyDown = ( e: KeyboardEvent ) => {
      if ( e.key === "Escape" ) onHide();
    };

    const onClick = ( e: MouseEvent ) => {
      if ( e.target === null ) return;
      if ( divRef.current === null ) return;
      if ( divRef.current.parentElement === null ) return;

      const target = e.target as HTMLElement;

      // if the e is outside of element then it's a click outside of the element
      if ( divRef.current.parentElement.contains( target ) ) return;

      onHide();

      // also need to cancel this event if we're handling it
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      return false;
    };

    globalThis.document.body.addEventListener( "click", onClick );
    globalThis.document.body.addEventListener( "keydown", onKeyDown );

    return () => {
      globalThis.document.body.removeEventListener( "click", onClick );
      globalThis.document.body.removeEventListener( "keydown", onKeyDown );
    };
  }, [ divRef, onHide ] );

  const addMonths = useCallback( ( months: number ) => {
    let month = currentMonth + months;
    let year = currentYear;
    if ( month < 0 ) {
      month += 12;
      year -= 1;
    } else if ( month > 12 ) {
      month -= 12;
      year += 1;
    }
    setCurrentYear( year );
    setCurrentMonth( month );
    onCalendarViewYearChange && onCalendarViewYearChange( year );
    onCalendarViewMonthChange && onCalendarViewMonthChange( month );
  }, [ currentMonth, currentYear, onCalendarViewMonthChange, onCalendarViewYearChange ] );

  const monthName = useCallback( ( month: number ) => {
    const dr = new Date( 2000, month - 1, 1 );
    return dr.toLocaleDateString( "en-UK", { month: "short" } );
  }, [] );

  const addDays = useCallback( ( date: Date, days: number ): Date => {
    const dt = new Date( date.getFullYear(), date.getMonth(), 1, 0, 0, 0 );
    dt.setDate( date.getDate() + days ); // the day prior to the required month
    return dt;
  }, [] );

  const weeks = useMemo( () => {
    let dt = addDays( new Date( currentYear, currentMonth - 1, 1 ), -1 ); // the day prior to the required month

    while ( dt.getDay() !== 1 ) { // go back until we hit a monday
      dt = addDays( dt, -1 );
    }

    const weeks = [];
    for ( let i = 0; i < 6; i++ ) {
      const week = [];
      for ( let j = 0; j < 7; j++ ) {
        week.push( dt );
        dt = addDays( dt, 1 );
      }
      weeks.push( week );
    }

    return weeks;
  }, [ addDays, currentMonth, currentYear ] );

  return (
    <div ref={ divRef } css={ styles.calendarView }>
      <div css={ styles.header }>
        <button type="button" aria-label="-3" onClick={ () => addMonths( -3 ) } ><ChevronDoubleLeft /></button>
        <button type="button" aria-label="-1" onClick={ () => addMonths( -1 ) } ><ChevronLeft /></button>
        <button type="button" aria-label="0" onClick={ () => setMonthsViewVisible( true ) }><span css={ css`display:inline-block;width:100px;` }>{ monthName( currentMonth ) } { currentYear }</span></button>
        <button type="button" aria-label="+1" onClick={ () => addMonths( 1 ) } ><ChevronRight /></button>
        <button type="button" aria-label="+3" onClick={ () => addMonths( 3 ) } ><ChevronDoubleRight /></button>
      </div>
      <table css={ styles.dayPicker }>
        <thead>
          <tr>
            <th>M</th>
            <th>T</th>
            <th>W</th>
            <th>T</th>
            <th>F</th>
            <th>S</th>
            <th>S</th>
          </tr>
        </thead>
        <tbody>
          { weeks.map( ( week, index ) => (
            <tr key={ index }>
              { week.map( ( day, index ) => {
                const isMonth = day.getMonth() === currentMonth - 1;
                const isCurrent = day.valueOf() === currentDate.valueOf();
                const tooltip = `${ new Intl.DateTimeFormat( "en-US", { weekday: "long" } ).format( day ) }, ${ new Intl.DateTimeFormat( "en-GB", { day: "numeric", month: "long", year: "numeric" } ).format( day ) }`;
                return ( <td onClick={ () => { console.log( day ); onChangeDate( day.valueOf() ); } } key={ index } title={ tooltip } css={ isCurrent ? styles.current : isMonth ? "" : styles.grey }>{ day.getDate() }</td> );
              } ) }
            </tr> ) )
          }
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={ 7 }>
              { children }
            </td>
          </tr>
        </tfoot>
      </table>

      { monthsViewVisible && <MonthsView currentDate={ currentDate } onClose={ () => setMonthsViewVisible( false ) } onSetMonthAndYear={ ( year, month ) => { setCurrentYear( year ); setCurrentMonth( month ); onCalendarViewYearChange && onCalendarViewYearChange( year ); onCalendarViewMonthChange && onCalendarViewMonthChange( month ); } } /> }
    </div>
  );
};

const MonthsView = ( { currentDate, onClose, onSetMonthAndYear }: { currentDate: number | Date; onClose: () => void; onSetMonthAndYear: ( year: number, month: number ) => void; } ) => {
  const [ currentYear, setCurrentYear ] = useState( new Date( currentDate ).getFullYear() );
  const [ currentMonth, setCurrentMonth ] = useState( new Date( currentDate ).getMonth() + 1 );
  const [ startYear, setStartYear ] = useState( new Date( currentDate ).getFullYear() - 4 );
  const today = useMemo( () => new Date(), [] );

  return ( <>
    <table css={ styles.monthAndYearPicker }>
      <tbody>
        <tr><MonthCell currentMonth={ currentMonth } month={ 1 } text="Jan" onChangeMonth={ setCurrentMonth } /><MonthCell currentMonth={ currentMonth } month={ 2 } text="Feb" onChangeMonth={ setCurrentMonth } /><YearCell currentYear={ currentYear } year={ startYear + 0 } onChangeYear={ setCurrentYear } /><YearCell currentYear={ currentYear } year={ startYear + 5 } onChangeYear={ setCurrentYear } /></tr>
        <tr><MonthCell currentMonth={ currentMonth } month={ 3 } text="Mar" onChangeMonth={ setCurrentMonth } /><MonthCell currentMonth={ currentMonth } month={ 4 } text="Apr" onChangeMonth={ setCurrentMonth } /><YearCell currentYear={ currentYear } year={ startYear + 1 } onChangeYear={ setCurrentYear } /><YearCell currentYear={ currentYear } year={ startYear + 6 } onChangeYear={ setCurrentYear } /></tr>
        <tr><MonthCell currentMonth={ currentMonth } month={ 5 } text="May" onChangeMonth={ setCurrentMonth } /><MonthCell currentMonth={ currentMonth } month={ 6 } text="Jun" onChangeMonth={ setCurrentMonth } /><YearCell currentYear={ currentYear } year={ startYear + 2 } onChangeYear={ setCurrentYear } /><YearCell currentYear={ currentYear } year={ startYear + 7 } onChangeYear={ setCurrentYear } /></tr>
        <tr><MonthCell currentMonth={ currentMonth } month={ 7 } text="Jul" onChangeMonth={ setCurrentMonth } /><MonthCell currentMonth={ currentMonth } month={ 8 } text="Aug" onChangeMonth={ setCurrentMonth } /><YearCell currentYear={ currentYear } year={ startYear + 3 } onChangeYear={ setCurrentYear } /><YearCell currentYear={ currentYear } year={ startYear + 8 } onChangeYear={ setCurrentYear } /></tr>
        <tr><MonthCell currentMonth={ currentMonth } month={ 9 } text="Sep" onChangeMonth={ setCurrentMonth } /><MonthCell currentMonth={ currentMonth } month={ 10 } text="Oct" onChangeMonth={ setCurrentMonth } /><YearCell currentYear={ currentYear } year={ startYear + 4 } onChangeYear={ setCurrentYear } /><YearCell currentYear={ currentYear } year={ startYear + 9 } onChangeYear={ setCurrentYear } /></tr>
        <tr><MonthCell currentMonth={ currentMonth } month={ 11 } text="Nov" onChangeMonth={ setCurrentMonth } /><MonthCell currentMonth={ currentMonth } month={ 12 } text="Dec" onChangeMonth={ setCurrentMonth } /><td><button aria-label="-10" onClick={ () => setStartYear( startYear - 10 ) } ><ChevronLeft /></button></td><td><button aria-label="+10" onClick={ () => setStartYear( startYear + 10 ) } ><ChevronRight /></button></td></tr>
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={ 4 }>
            <button type="button" aria-label="today" onClick={ () => onSetMonthAndYear( today.getFullYear(), today.getMonth() + 1 ) }>Today</button>
            <button type="button" aria-label="ok" onClick={ () => onSetMonthAndYear( currentYear, currentMonth ) }>OK</button>
            <button type="button" aria-label="cancel" onClick={ () => onClose() } >Cancel</button>
          </td>
        </tr>
      </tfoot>
    </table>
  </> );
};

const MonthCell = ( { currentMonth, month, text, onChangeMonth }: { currentMonth: number; month: number; text: string; onChangeMonth: ( month: number ) => void; } ) => (
  <td css={ currentMonth === month ? styles.chosenMonth : undefined } onClick={ () => onChangeMonth( month ) }>{ text }</td>
);

const YearCell = ( { currentYear, year, onChangeYear }: { currentYear: number; year: number; onChangeYear: ( year: number ) => void; } ) => (
  <td css={ currentYear === year ? styles.chosenYear : undefined } onClick={ () => onChangeYear( year ) }>{ year }</td>
);
