/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo } from "react";
import { Country } from "model/Model";
import { chunk, Selectable } from "Utilities";
import { css } from "@emotion/react";
import { useReferenceData } from "contexts/ReferenceDataContext";

const styles = {
  countrySelector: css`
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
        span {
          vertical-align: middle;
        }
      }
  
      input {
        padding: 0;
        margin: 0 5px;
        width: auto;
        vertical-align: middle;
      }
    }
  `,

  countryColumn: css`
    display: block;
    float: left;

    label {
      display: block;
    }
  `
};

export const CountrySelector = ( { singleCountryOnly = false, canSelectAllCountriesInGroup, onChangeCountryIsSelected: onCountrySelected, countryGroups, countries, isWorldSelected }: { singleCountryOnly?: boolean; canSelectAllCountriesInGroup: boolean; countries: Array<Selectable<Country>>; countryGroups: Array<{ name: string; isSelected: boolean; countries: Array<Selectable<Country>>; }>; isWorldSelected: boolean; onChangeCountryIsSelected: ( countryId: number, isSelected: boolean, clearExisting?: boolean ) => void; } ): JSX.Element => {
  if ( singleCountryOnly ) {
    // TODO: aren't props readonly?
    isWorldSelected = false;
    canSelectAllCountriesInGroup = false;
  }
  const countOfSelected = countries.filter( m => m.isSelected ).length;
  const noneSelected = countOfSelected === 0;

  const onSelectWorldHandler = useCallback( () => {
    for ( const country of countries ) {
      if ( country.isSelected === isWorldSelected ) onCountrySelected( country.id, !isWorldSelected );
    }
  }, [ countries, isWorldSelected, onCountrySelected ] );

  const inner = useMemo( () => countryGroups.map( ( group, index ) => ( <GroupItem key={ index } name={ group.name } singleCountryOnly={ singleCountryOnly } isGroupSelected={ group.isSelected } onSelected={ onCountrySelected } canSelectAll={ canSelectAllCountriesInGroup } countries={ group.countries } totalCountryCount={ countries.length } /> ) ), [ canSelectAllCountriesInGroup, countries.length, countryGroups, onCountrySelected, singleCountryOnly ] );

  return ( <>
    <div css={ styles.countrySelector }>
      { canSelectAllCountriesInGroup
        ? <fieldset>
          <legend>
            <label><input type="checkbox" checked={ isWorldSelected } onChange={ onSelectWorldHandler } ref={ input => { if ( input ) input.indeterminate = !noneSelected && !isWorldSelected; } } />World</label>
          </legend>
          { inner }
        </fieldset>
        : inner
      }
    </div>
  </> );
};

const GroupItem = ( { name, countries, canSelectAll, isGroupSelected, singleCountryOnly, totalCountryCount, onSelected }: { canSelectAll: boolean; name: string; isGroupSelected: boolean; singleCountryOnly: boolean; countries: Array<Selectable<Country>>; totalCountryCount: number; onSelected: ( countryId: number, isSelected: boolean, clearExisting?: boolean ) => void; } ) => {
  if ( singleCountryOnly ) {
    isGroupSelected = false;
    canSelectAll = false;
  }
  const countOfSelected = countries.filter( m => m.isSelected ).length;
  const noneSelected = countOfSelected === 0;

  const onSelectGroupHandler = useCallback( () => {
    const isSelected = !isGroupSelected;
    for ( const country of countries ) {
      if ( country.isSelected !== isSelected ) onSelected( country.id, isSelected );
    }
  }, [ countries, isGroupSelected, onSelected ] );

  const chunkSize = useMemo( () => {
    // FUTURE: duplicated the weird logic used in live can we improve this?
    if ( totalCountryCount > 20 ) {
      if ( totalCountryCount > 50 ) return 13;
      return 6;
    }

    return 5;
  }, [ totalCountryCount ] );

  return (
    <fieldset>
      <legend>
        { canSelectAll ? ( <label><input type="checkbox" checked={ isGroupSelected } onChange={ onSelectGroupHandler } ref={ input => { if ( input ) input.indeterminate = !noneSelected && !isGroupSelected; } } /> { name }</label> ) : name }
      </legend>
      {
        chunk( countries, chunkSize ).map( ( c, i ) => {
          return ( <div key={ i } css={ styles.countryColumn }>
            {
              c.map( ( country ) => ( <CountryItem key={ country.id } country={ country } singleCountryOnly={ singleCountryOnly } isSelected={ country.isSelected } onSelected={ onSelected } /> ) )
            }
          </div> );
        } ) }
    </fieldset>
  );
};

const CountryItem = ( { country, isSelected, singleCountryOnly, onSelected }: { country: Country; isSelected: boolean; singleCountryOnly: boolean; onSelected: ( countryId: number, isSelected: boolean, clearExisting?: boolean ) => void; } ) => {
  const referenceData = useReferenceData();

  return (
    <label title={ country.tooltip || "" }>
      { singleCountryOnly && <input type="radio" onChange={ ( e ) => onSelected( country.id, e.currentTarget.checked, true ) } checked={ isSelected } /> }
      { !singleCountryOnly && <input type="checkbox" onChange={ ( e ) => onSelected( country.id, e.currentTarget.checked ) } checked={ isSelected } /> }
      <span>{ country.name }{ referenceData.includeIdInName && ` [${ country.id.toString() }]` }</span>
    </label>
  );
};
