/** @jsxImportSource @emotion/react */
import { setSessionCredentials } from "contexts/UserContext";
import { postAuthenticate } from "helpers/api";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import logo from "./ets_logo_full.png";
import { css } from "@emotion/react";

// FUTURE: password reset

const styles = {
  login: css`
    padding: 11px;
    text-align: center;
  `,
  credentials: css`
    margin: 10px auto 0 auto;
    display: inline-flex;
    flex-direction: row;

    label { margin-right: 4px; }
    input { margin-right: 4px; }
  `,
  copyright: css`
    margin: 10px auto 0 auto;
    position: fixed;
    bottom: 10px;
    display: block;
    width: 100%;
  `,
  invalidLogin: css`
    color: red;
    font-weight: bold;
  `
};

export const Login = () => {
  const [ username, setUsername ] = useState( "" );
  const [ password, setPassword ] = useState( "" );
  const [ invalidLogin, setInvalidLogin ] = useState( false );
  const navigate = useNavigate();

  const doSubmit = useCallback( async ( e: React.FormEvent ) => {
    e.preventDefault();
    e.stopPropagation();

    setInvalidLogin( false );

    if ( username.trim() === "" ) return;
    if ( password.trim() === "" ) return;

    try {
      const result = await postAuthenticate( username, password );
      if ( !result ) {
        setInvalidLogin( true );
        return;
      }

      setSessionCredentials( result.userId, result.jwt, result.username, result.name );
      navigate( { pathname: "/" }, { replace: true } );
    } catch ( e ) {
      setInvalidLogin( true );
      return;
    }
  }, [ navigate, password, username ] );

  const year = new Date().getFullYear();

  return <>
    <form css={ styles.login } onSubmit={ doSubmit }>
      <div>
        <img alt="logo" src={ logo } height={ 100 } />
      </div>

      <div css={ styles.credentials }>
        <div>
          <label htmlFor="username">Username</label>
          <input id="username" autoComplete="username" required type="text" value={ username } onChange={ e => { setInvalidLogin( false ); setUsername( e.currentTarget.value ); } } />
        </div>

        <div>
          <label htmlFor="password">Password</label>
          <input id="password" autoComplete="current-password" required type="password" value={ password } onChange={ e => { setInvalidLogin( false ); setPassword( e.currentTarget.value ); } } />
        </div>

        <div>
          <button type="submit">Go</button>
        </div>
      </div>
      { invalidLogin && <p css={styles.invalidLogin}>Username or password is incorrect.</p> }

      <div css={ styles.copyright }>Copyright © { year } Essential Television Statistics</div>
    </form>
  </>;
};