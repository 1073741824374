import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { Model, QuarterHourSourceModel } from "../Model";
import thunkMiddleware from "redux-thunk";
import { parseDateOrUndefined } from "Utilities";
import countriesSlice from "reducers/countriesSlice";
import demoGroupBySlice from "reducers/demoGroupBySlice";
import demoSelectionSlice from "reducers/demoSelectionSlice";
import measuresSlice from "reducers/measuresSlice";
import outputSlice from "reducers/outputSlice";
import stationsSlice from "reducers/stationsSlice";
import demosSlice from "reducers/demosSlice";
import averageReducers from "../components/average-selector/averageSlice";
import dateTimeReducers from "../components/date-time-selector/dateTimeReducers";

const initializeModel = ( data: QuarterHourSourceModel ): Model => {
  const model = {
    ...data,
    dateTime: {
      ...data.dateTime,
      fromDate: parseDateOrUndefined( data.dateTime.fromDate ),
      toDate: parseDateOrUndefined( data.dateTime.toDate )
    }
  };

  // FUTURE: more validation
  if ( model.average === undefined )                                 /* */ throw new Error( "QuarterHour.average is undefined" );
  if ( model.average.averageDayAverage === undefined )               /* */ throw new Error( "QuarterHour.average.averageDayAverage is undefined" );
  if ( model.average.averageTimeAverage === undefined )              /* */ throw new Error( "QuarterHour.average.averageTimeAverage is undefined" );
  if ( model.countries === undefined )                               /* */ throw new Error( "QuarterHour.countries is undefined" );
  if ( model.dateTime === undefined )                                /* */ throw new Error( "QuarterHour.dateTime is undefined" );
  if ( model.dateTime.dayParts === undefined )                       /* */ throw new Error( "QuarterHour.dateTime.dayParts is undefined" );
  if ( model.dateTime.days === undefined )                           /* */ throw new Error( "QuarterHour.dateTime.days is undefined" );
  if ( model.dateTime.fromTime === undefined )                       /* */ throw new Error( "QuarterHour.dateTime.fromTime is undefined" );
  if ( model.dateTime.toTime === undefined )                         /* */ throw new Error( "QuarterHour.dateTime.toTime is undefined" );
  if ( model.dateTime.useDayParts === undefined )                    /* */ throw new Error( "QuarterHour.dateTime.useDayParts is undefined" );
  if ( model.demoGroupBy === undefined )                             /* */ throw new Error( "QuarterHour.demoGroupBy is undefined" );
  if ( model.demoSelection === undefined )                           /* */ throw new Error( "QuarterHour.demoSelection is undefined" );
  if ( model.demos === undefined )                                   /* */ throw new Error( "QuarterHour.demos is undefined" );
  if ( model.measures === undefined )                                /* */ throw new Error( "QuarterHour.measures is undefined" );
  if ( model.output === undefined )                                  /* */ throw new Error( "QuarterHour.output is undefined" );
  if ( model.output.format === undefined )                           /* */ throw new Error( "QuarterHour.output.format is undefined" );
  if ( model.output.view === undefined )                             /* */ throw new Error( "QuarterHour.output.view is undefined" );
  if ( model.stations === undefined )                                /* */ throw new Error( "QuarterHour.stations is undefined" );

  return model;
};

export const getStore = ( initialData: QuarterHourSourceModel ) => configureStore( {
  preloadedState: initializeModel( initialData ),
  reducer: {
    countries: countriesSlice,
    stations: stationsSlice,
    demos: demosSlice,
    measures: measuresSlice,
    demoGroupBy: demoGroupBySlice,
    demoSelection: demoSelectionSlice,
    dateTime: dateTimeReducers,
    average: averageReducers,
    output: outputSlice
  },
  middleware: [ thunkMiddleware ]
} );

type StoreType = ReturnType<typeof getStore>;

export type AppDispatch = StoreType[ "dispatch" ];
export type RootState = ReturnType<StoreType[ "getState" ]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
