import { Selectable } from "Utilities";

export enum DemoGroupByConstants {
  demo = "demo",
  type = "type"
}

export enum DemoRankConstants {
  first = "first",
  all = "all"
}

export enum DemoSelectionConstants {
  allIndividualsAndAllCommercials = "allIndividualsAndAllCommercials",
  allIndividuals = "allIndividuals",
  allCommercials = "allCommercials",
  selectedDemos = "selectedDemos"
}

export enum DaysConstants {
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6,
  sunday = 7
}

export enum ReportTypeSummaryConstants {
  Distributor = 1,
  Type = 2,
  Class1 = 3,
  ProductionCountry = 4,
  Region = 5,
  DayPart = 6,
  // FUTURE: where is 7
  CoProduction = 8,
  Year = 9,
  Source = 10,
  SeriesDuration = 11,
  // FUTURE: where is 12
  Class2 = 13
}

export enum AverageCollapsePeriodConstants {
  IndividualAirings = 0,
  Daily = 4,
  WeeklyMonSun = 1,
  WeeklySunSat = 6,
  Monthly = 2,
  Period = 3,
  DayOfWeek = 5
}

export enum AverageTitleConstants {
  OriginalThenLocal = 0,
  LocalOnly = 1,
  All = 2 // used in programPerformance
}

export enum YesNoConstants {
  No = 0,
  Yes = 1
}

export enum RevenueAmountConstants {
  None = 0,
  Total = 1,
  Detail = 2
}

export enum RevenueDurationConstants {
  None = 0,
  Total = 1,
  Detail = 2
}

export enum RevenueCollapseConstants {
  Sum = 1,
  Average = 2
}

export enum DemoTypeConstants {
  Rtg = 0,
  Shr = 1,
  Thos = 3,
  Put = 2,
  Ats = 4
}

export enum TitleOriginalOrLocalConstants {
  Original = "originalTitle",
  Local = "localTitle"
}

export enum TitleCompareOperatorConstants {
  Equals = "equals",
  Contains = "contains",
  Begins = "begins"
}

export enum BenchmarkDatesConstants {
  None = 0,
  BroadcastQuarter = 1,
  PreviousFourWeeks = 2,
  YearToDate = 3,
  Quarters = 4,
  DateRange = 5,
  PreviousThirteenWeeks = 6,
  PreviousFiftyTwoWeeks = 7,
  BroadcastYear = 8
}

export enum BenchmarkTimePeriodConstants {
  Daypart = 0,
  Times = 1,
  TimePeriod = 2
}

export enum BenchmarkDayOfWeekTypeConstants {
  SameAsSelectedDays = 0,
  TransmissionDay = 1,
  SelectedDays = 2
}

// TODO: these are user configured so move to the application context

export enum OutputFormatConstants {
  Xlsx = "xlsx",
  Html = "html",
  Csv = "csv"
}

export enum OutputViewConstants {
  ReportTab = "reportTab",
  ApplicationTab = "applicationTab",
  NewTab = "newTab"
}

export type QualifyTypeOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;
export type QualifyClassOneOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;
export type QualifyClassTwoOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;
export type QualifyDistributorOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;
export type QualifyDisplayOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;

export type UserOrGroup = { id: number; type: string | "company" | "user"; name: string; };

// TODO: a country needs to know if it has revenue or not

export type Country = { id: number; name: string; tooltip?: string; stations: Array<Station>; defaultStations: Array<number>; demos: Array<Demo>; defaultDemos: Array<number>; group: string; dayParts: Array<DayPart>; localGenres?: Array<LocalGenre>; revenueSymbol: string; startDate: Date; endDate: Date; };

export type Station = { id: number; name: string; tooltip?: string; isTranslated: boolean; hasRevenue: boolean; revenueStartDate?: Date; revenueEndDate?: Date; optionGroup?: string; translationType?: string; };

// TODO: might need isEnabled on here so that the apps that don't
export type Measure = { value: DemoTypeConstants; name: string; };

export type Demo = { id: number; name: string; abbreviated: string; tooltip?: string; isTotalAudienceDemo: boolean; isAllCommercialDemo: boolean; startDate?: Date; endDate?: Date; };

export type LocalGenre = {
  id: number;
  name: string;
  tooltip?: string;
};

export type DayPart = {
  id: number;
  name: string;
  time?: string;
  tooltip?: string;
};

export type ReportTypeSummary = {
  id: ReportTypeSummaryConstants;
  name: string;
  tooltip?: string;
};

export type Average = {
  collapsePeriod: AverageCollapsePeriodConstants;
  combineWithin: number;
  title: AverageTitleConstants;
  breakoutSeasons: YesNoConstants;
  breakoutOriginalRepeat: YesNoConstants;
  localRepeat: YesNoConstants;
};

export type Title = {
  compareOperator: TitleCompareOperatorConstants;
  resultCount: number;
  originalOrLocal: TitleOriginalOrLocalConstants;
  numberOfCharactersToCompare: number;
  searchTerm: string;
  titleNames: Array<{ title: string; isSelected: boolean; }>;
};

export type Output = {
  format: OutputFormatConstants;
  view: OutputViewConstants;
};

export type TemplateOrHistory<T> = {
  id: number;
  userId: number;
  name: string;
  sharedWith: Array<number>;
  query: T;
};