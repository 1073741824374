/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { HelpText } from "components/help-text/HelpText";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { TitleCompareOperatorConstants, TitleOriginalOrLocalConstants } from "model/Model";
import { useUser } from "contexts/UserContext";
import { postJson } from "helpers/api";
import { LoadingDisplay } from "components/loading-display/LoadingDisplay";

const styles = {
  titleSearch: css`
    vertical-align: top;
    display: inline-block;

      input {
        width: 25px !important;  
    }
  `,

  searchSelector: css`
    display: inline-block;
    vertical-align: top;
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;

      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;

        &.clickable {
          cursor: pointer;
        }
      }
    }
  `,

  resultsSelector: css`
    display: inline-block;
    vertical-align: top;
    max-width: 360px;
    width: 360px;
    select { 
      width: 300px;
       option { 
       overflow:hidden; 
       text-overflow: ellipsis;
      }
    }
    button {
      margin-left:5px;
    }
  `,

  searchResults: css`
  max-width: 297px;
  width: 297px;
  margin-top: 10px;
  select { 
    width: 300px;
     option { 
     overflow:hidden; 
     text-overflow: ellipsis;
    }
  }`,

  excessResults: css`
    font-weight: bold;
  `
};

const TitleResults = ( { searchResults, onSelect }: { searchResults: Array<SearchResult>; onSelect: ( value: string ) => void; } ) => {
  return (
    <div css={ styles.searchResults }>
      <select aria-label="Title" size={ 10 } onChange={ ( e ) => onSelect( e.currentTarget.value ) }>
        { searchResults.map( ( m, i ) => ( <option title={ m.text } css={ css`` } key={ i } value={ m.value }>{ m.text }</option> ) ) }
      </select>
    </div>
  );
};

type SearchResult = {
  text: string;
  value: string;
};

const getSearchResults = async ( user: { userId: number; token: string; }, title: string, countryIds: Array<number>, source: TitleOriginalOrLocalConstants, maxResultCount: number, compareOperator: TitleCompareOperatorConstants ): Promise<Array<SearchResult>> => {
  console.log( `Searching for ${ title }` );

  const result = await postJson<Array<{ text: string; value: string; }>>( "/TitlesSearch", user.userId, user.token, { title, countryIds, source, maxResultCount, compareOperator } );
  return result;
};

const TitleSearch = ( { label, searchTerm, helpId, minLength = 3, source, countryIds, maxResultCount, compareOperator, onSelect }: { label: string; searchTerm?: string; helpId: string; minLength?: number; source: TitleOriginalOrLocalConstants; countryIds: Array<number>; maxResultCount: number; compareOperator: TitleCompareOperatorConstants; onSelect: ( value: string ) => void; } ) => {
  const user = useUser();
  const [ searchText, setSearchText ] = useState( "" );
  const [ searchResults, setSearchResults ] = useState<Array<SearchResult>>( [] );
  const [ haveExcessResults, setHaveExcessResults ] = useState( false );
  const [ isSearching, setIsSearching ] = useState( false );
  const timer = useRef<number | undefined>( undefined );

  useEffect( () => {
    if ( searchTerm ) {
      setSearchText( searchTerm );
    }
  }, [ searchTerm ] );

  useEffect( () => {
    // TODO: use cancellation pattern
    const getResults = async () => {
      try {
        setIsSearching( true );
        setSearchResults( [] );
        setHaveExcessResults( false );

        const title = searchText;
        const results = await getSearchResults( user, title, countryIds, source, maxResultCount, compareOperator );
        console.log( `Found ${ results.length } results for ${ title }` );
        setSearchResults( results );

        console.log( `Results ${ results.length > maxResultCount }` );
        if ( results.length > maxResultCount ) {
          setHaveExcessResults( true );
          setSearchResults( results.slice( 0, maxResultCount ) );
        }
      } finally {
        setIsSearching( false );
      }
    };

    const trimmed = searchText.trim();
    clearTimeout( timer.current );
    timer.current = window.setTimeout( () => {
      if ( trimmed.length <= minLength ) {
        setSearchResults( [] );
      } else {
        getResults();
      }
    }, 1000 );
  }, [ compareOperator, countryIds, maxResultCount, minLength, searchText, source, timer, user ] );

  return (
    <div css={ styles.searchSelector }>
      <fieldset>
        <legend><label>{ label } <HelpText helpId={ helpId } /> { ( haveExcessResults ? <span css={ styles.excessResults }>(more results than shown)</span> : "" ) }</label></legend>
        <input css={ css`width: 290px;` } aria-label={ label } type="text" value={ searchText } onChange={ ( e ) => setSearchText( e.currentTarget.value ) } />

        { isSearching && <div css={ css`margin-top:10px;` }><LoadingDisplay text="Searching..." fullScreen={ false } height={ 15 } width={ 15 } borderWidth={ 10 } /></div> }
        { !isSearching && searchResults.length > 0 && <TitleResults searchResults={ searchResults } onSelect={ onSelect } /> }
      </fieldset>
    </div>
  );
};

const SelectedResults = ( { label, helpId, results, onClearResults, onSetSelectedResults }: { label: string; helpId: string; results: Array<{ title: string; isSelected: boolean; }>; onClearResults: () => void; onSetSelectedResults: ( results: Array<{ title: string; isSelected: boolean; }> ) => void; } ) => {
  const updateSelected = useCallback( ( selectedOptions: HTMLCollectionOf<HTMLOptionElement> ) => {
    const selectedOptionsArray: Array<string> = [];
    const length = selectedOptions.length;
    for ( let i = 0; i < length; i++ ) {
      const s = selectedOptions.item( i );
      if ( s ) selectedOptionsArray.push( s.text );
    }
    onSetSelectedResults( results.map( m => ( { ...m, isSelected: selectedOptionsArray.indexOf( m.title ) >= 0 } ) ) );
  }, [ onSetSelectedResults, results ] );

  return (
    <div css={ styles.resultsSelector }>
      <fieldset>
        <legend><label>{ label } <HelpText helpId={ helpId } /></label></legend>
        <select aria-label={ label } size={ 12 } multiple={ true } value={ results.filter( m => m.isSelected ).map( m => m.title ) } onChange={ e => updateSelected( e.currentTarget.selectedOptions ) } onClick={ e => updateSelected( e.currentTarget.selectedOptions ) } >
          { results.map( ( m, i ) => <option title={ m.title } key={ i }>{ m.title }</option> ) }
        </select>
        <button type="button" css={ css`vertical-align: top;` } onClick={ () => onClearResults() }>X</button>
      </fieldset>
    </div>
  );
};

const characterList: Array<{ value: number; name: string; }> = [ { value: 0, name: "All characters" } ];
for ( let i = 1; i <= 60; i++ ) {
  characterList.push( { value: i, name: i.toString() } );
}

const NumberOfCharacters = ( { label, helpId, value, onChange }: { label: string; helpId: string; value: number; onChange: ( value: number ) => void; } ) => {
  return (
    <div css={ css`display:inline-block;` }>
      <fieldset>
        <legend><label>{ label } <HelpText helpId={ helpId } /> </label></legend>
        <select aria-label={ label } value={ value } onChange={ e => onChange( parseInt( e.currentTarget.value, 10 ) ) } >
          { characterList.map( ( m, i ) => <option key={ i } value={ m.value }>{ m.name }</option> ) }
        </select>
      </fieldset>
    </div>
  );
};

const titleOriginalOrLocalItems = [
  { value: TitleOriginalOrLocalConstants.Original, name: "Original" },
  { value: TitleOriginalOrLocalConstants.Local, name: "Local" }
];

const titleResultCountItems = [
  { value: 20, name: "20" },
  { value: 50, name: "50" },
  { value: 100, name: "100" }
];

const titleCompareOperatorItems = [
  { value: TitleCompareOperatorConstants.Equals, name: "Equal to" },
  { value: TitleCompareOperatorConstants.Begins, name: "Beginning with" },
  { value: TitleCompareOperatorConstants.Contains, name: "Containing" }
];

export const TitleSelector = ( { numberOfCharactersToCompare, originalOrLocal, resultCount, compareOperator, searchTerm, titleNames, countries, onChangeTitleOriginalOrLocal, onChangeTitleResultCount, onChangeTitleCompareOperator, onChangeTitleNumberOfCharactersToCompare, onChangeTitleNames }: { numberOfCharactersToCompare: number; originalOrLocal: TitleOriginalOrLocalConstants; resultCount: number; compareOperator: TitleCompareOperatorConstants; searchTerm?: string; titleNames: Array<{ title: string; isSelected: boolean; }>; countries: Array<number>; onChangeTitleOriginalOrLocal: ( value: TitleOriginalOrLocalConstants ) => void; onChangeTitleResultCount: ( value: number ) => void; onChangeTitleCompareOperator: ( value: TitleCompareOperatorConstants ) => void; onChangeTitleNumberOfCharactersToCompare: ( value: number ) => void; onChangeTitleNames: ( value: Array<{ title: string; isSelected: boolean; }> ) => void; } ): JSX.Element => {
  const addToAllResults = useCallback( ( value: string ) => {
    if ( titleNames.find( m => m.title === value ) ) return;
    onChangeTitleNames( [ ...titleNames, { title: value, isSelected: true } ] );
  }, [ onChangeTitleNames, titleNames ] );

  return ( <>
    <div css={ styles.titleSearch }>
      <div >
        <RadioGroupSelector label="Title" helpId="title_original_local" value={ originalOrLocal } radioGroup="title-original-or-local" items={ titleOriginalOrLocalItems } onChangeItemSelected={ onChangeTitleOriginalOrLocal } />
      </div>
      <div>
        <RadioGroupSelector label="Results" helpId="title_result_count" value={ resultCount } radioGroup="title-result-count" items={ titleResultCountItems } onChangeItemSelected={ onChangeTitleResultCount } />
      </div>
      <div>
        <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Operator" helpId="title_operator" value={ compareOperator } radioGroup="title-compare-operator" items={ titleCompareOperatorItems } onChangeItemSelected={ onChangeTitleCompareOperator } />
      </div>
    </div>
    <div css={ css`display: inline-block; vertical-align: top;` }>
      <TitleSearch label="Search" searchTerm={ searchTerm } helpId="titleSearch" onSelect={ addToAllResults } compareOperator={ compareOperator } maxResultCount={ resultCount } source={ originalOrLocal } countryIds={ countries } />
      { titleNames.length > 0 && <SelectedResults label="Select" helpId="title_results" results={ titleNames } onClearResults={ () => onChangeTitleNames( titleNames.filter( m => !m.isSelected ) ) } onSetSelectedResults={ onChangeTitleNames } /> }
      { titleNames.length > 0 && <NumberOfCharacters label="Number of Characters to Compare" helpId="titleNOC" value={ numberOfCharactersToCompare } onChange={ onChangeTitleNumberOfCharactersToCompare } /> }
    </div>
  </> );
};
