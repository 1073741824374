import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Qualify, QualifyFormatConstants } from "../../Model";
import { ChangeLocalGenreIsSelected } from "reducers/localGenreReducer";
import { ChangeCountryIsSelected } from "reducers/countriesSlice";

const qualifySlice = createSlice( {
  name: "qualify",
  initialState: {
    selectedTypes: [],
    selectedClassOnes: [],
    selectedClassTwos: [],
    selectedFormats: [],
    selectedDistributors: [],
    selectedProductionCountries: [],
    selectedProductionCountryPositions: [],
    selectedLocalGenres: []
  } as Qualify,
  reducers: {
    ChangeQualifyTypeIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedTypes: [ ...state.selectedTypes, value ] };
      }
      return { ...state, selectedTypes: state.selectedTypes.filter( m => m !== value ) };
    },

    ChangeQualifyClassOneIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedClassOnes: [ ...state.selectedClassOnes, value ] };
      }
      return { ...state, selectedClassOnes: state.selectedClassOnes.filter( m => m !== value ) };
    },

    ChangeQualifyClassTwoIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedClassTwos: [ ...state.selectedClassTwos, value ] };
      }
      return { ...state, selectedClassTwos: state.selectedClassTwos.filter( m => m !== value ) };
    },

    ChangeQualifyFormatIsSelected( state, action: PayloadAction<{ value: QualifyFormatConstants; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedFormats: [ ...state.selectedFormats, value ] };
      }
      return { ...state, selectedFormats: state.selectedFormats.filter( m => m !== value ) };
    },

    ChangeQualifyDistributorIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedDistributors: [ ...state.selectedDistributors, value ] };
      }
      return { ...state, selectedDistributors: state.selectedDistributors.filter( m => m !== value ) };
    },

    ChangeQualifyProductionCountryIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedProductionCountries: [ ...state.selectedProductionCountries, value ] };
      }
      return { ...state, selectedProductionCountries: state.selectedProductionCountries.filter( m => m !== value ) };
    },

    ChangeQualifyProductionCountryPositionIsSelected( state, action: PayloadAction<{ value: number; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedProductionCountryPositions: [ ...state.selectedProductionCountryPositions, value ] };
      }
      return { ...state, selectedProductionCountryPositions: state.selectedProductionCountryPositions.filter( m => m !== value ) };
    }
  },
  extraReducers: builder => {
    builder.addCase( ChangeCountryIsSelected, ( state, action ) => {
      const { countryId, isSelected } = action.payload;
      if ( isSelected && !state.selectedLocalGenres.find( c => c.id === countryId ) ) {
        // a country is selected so ensure it has the defaults
        return { ...state, selectedLocalGenres: [ ...state.selectedLocalGenres, { id: countryId, localGenres: [] } ] };
      }

      if ( !isSelected ) {
        return { ...state, selectedLocalGenres: state.selectedLocalGenres.filter( m => m.id !== countryId ) };
      }
      return state;
    } );
    builder.addCase( ChangeLocalGenreIsSelected, ( state, action ) => {
      const { countryId, localGenreId, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedLocalGenres: state.selectedLocalGenres.map( c => c.id === countryId ? { ...c, localGenres: [ ...c.localGenres, localGenreId ] } : c ) };
      }
      return { ...state, selectedLocalGenres: state.selectedLocalGenres.map( c => c.id === countryId ? { ...c, localGenres: c.localGenres.filter( m => m !== localGenreId ) } : c ) };
    } );
  }
} );

export const { ChangeQualifyClassOneIsSelected, ChangeQualifyClassTwoIsSelected, ChangeQualifyDistributorIsSelected, ChangeQualifyFormatIsSelected, ChangeQualifyProductionCountryPositionIsSelected, ChangeQualifyProductionCountryIsSelected, ChangeQualifyTypeIsSelected } = qualifySlice.actions;

export default qualifySlice.reducer;