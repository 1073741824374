import { createAction, createReducer } from "@reduxjs/toolkit";

export const ChangeToTime = createAction<string>( "ChangeToTime" );

const toTimesReducers = createReducer(
  "24:00",
  builder => {
    builder.addCase( ChangeToTime, ( _, action ) => action.payload );
  }
);

export default toTimesReducers;