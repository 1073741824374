import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action, AnyAction } from "redux";
import { Model, ChannelViewSourceModel } from "../Model";
import thunkMiddleware from "redux-thunk";
import countriesSlice from "reducers/countriesSlice";
import outputSlice from "reducers/outputSlice";
import stationsSlice from "reducers/stationsSlice";
import demosSlice from "reducers/demosSlice";
import measuresSlice from "reducers/measuresSlice";
import displaySlice from "../components/display-selector/displaySlice";
import dateTimeSlice from "../components/date-time-selector/dateTimeSlice";
import { parseDateOrUndefined } from "Utilities";
import demoSelectionSlice from "reducers/demoSelectionSlice";

const initializeModel = ( data: ChannelViewSourceModel ): Model => {
  const model = {
    ...data,
    dateTime: {
      ...data.dateTime,
      fromDate: parseDateOrUndefined( data.dateTime.fromDate ),
      toDate: parseDateOrUndefined( data.dateTime.toDate )
    }
  };

  // FUTURE: more validation
  if ( model.countries === undefined )                      /* */ throw new Error( "ChannelView.countries is undefined" );
  if ( !Array.isArray( model.countries ) )                  /* */ throw new Error( "ChannelView.countries is not an array" );
  if ( model.dateTime === undefined )                       /* */ throw new Error( "ChannelView.dateTime is undefined" );
  if ( model.dateTime.days === undefined )                  /* */ throw new Error( "ChannelView.dateTime.days is undefined" );
  if ( model.dateTime.dayParts === undefined )              /* */ throw new Error( "ChannelView.dateTime.dayParts is undefined" );
  if ( model.dateTime.fromTime === undefined )              /* */ throw new Error( "ChannelView.dateTime.fromTime is undefined" );
  if ( model.dateTime.toTime === undefined )                /* */ throw new Error( "ChannelView.dateTime.toTime is undefined" );
  if ( model.dateTime.useDayParts === undefined )           /* */ throw new Error( "ChannelView.dateTime.useDayParts is undefined" );
  if ( model.dateTime.minimumDuration === undefined )       /* */ throw new Error( "ChannelView.dateTime.minimumDuration is undefined" );
  if ( model.demos === undefined )                          /* */ throw new Error( "ChannelView.demos is undefined" );
  if ( !Array.isArray( model.demos ) )                      /* */ throw new Error( "ChannelView.demos is not an array" );
  if ( model.display === undefined )                        /* */ throw new Error( "ChannelView.display is undefined" );
  if ( model.display.reportIncrement === undefined )        /* */ throw new Error( "ChannelView.display.reportIncrement is undefined" );
  if ( model.display.selectedTypes === undefined )          /* */ throw new Error( "ChannelView.display.selectedTypes is undefined" );
  if ( model.display.selectedClassOnes === undefined )      /* */ throw new Error( "ChannelView.display.selectedClassOnes is undefined" );
  if ( model.display.selectedClassTwos === undefined )      /* */ throw new Error( "ChannelView.display.selectedClassTwos is undefined" );
  if ( model.display.selectedDistributors === undefined )   /* */ throw new Error( "ChannelView.display.selectedDistributors is undefined" );
  if ( model.display.selectedDisplayOptions === undefined ) /* */ throw new Error( "ChannelView.display.selectedDisplayOptions is undefined" );
  if ( model.measures === undefined )                       /* */ throw new Error( "ChannelView.measures is undefined" );
  if ( !Array.isArray( model.measures ) )                   /* */ throw new Error( "ChannelView.measures is not an array" );
  if ( model.output === undefined )                         /* */ throw new Error( "ChannelView.output is undefined" );
  if ( model.output.format === undefined )                  /* */ throw new Error( "ChannelView.output.format is undefined" );
  if ( model.output.view === undefined )                    /* */ throw new Error( "ChannelView.output.view is undefined" );
  if ( model.stations === undefined )                       /* */ throw new Error( "ChannelView.stations is undefined" );
  if ( !Array.isArray( model.stations ) )                   /* */ throw new Error( "ChannelView.stations is not an array" );

  return model;
};

export const getStore = ( initialModel: ChannelViewSourceModel ) => configureStore<Model, AnyAction>( {
  preloadedState: initializeModel( initialModel ),
  reducer: {
    countries: countriesSlice,
    stations: stationsSlice,
    demos: demosSlice,
    demoSelection: demoSelectionSlice,
    measures: measuresSlice,
    output: outputSlice,
    display: displaySlice,
    dateTime: dateTimeSlice
  },
  middleware: [ thunkMiddleware ]
} );

type StoreType = ReturnType<typeof getStore>;

export type AppDispatch = StoreType[ "dispatch" ];
export type RootState = ReturnType<StoreType[ "getState" ]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
