/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { transmissionDurationItems } from "components/transmission-duration-selector/TransmissionDurationSelector";
import { Country, DayPart, DaysConstants } from "model/Model";
import { formatDateAsEnGb, Selectable } from "Utilities";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  country: css`
    display: inline-block;
    width: 180px;
  `,

  warning: css`
    color: #cc0000;
  `
};

const transmissionDurationByValue: Record<string, string> = {};
for ( const c of transmissionDurationItems ) {
  transmissionDurationByValue[ c.value ] = c.title;
}

export const DateTimeDescription = ( { fromDate, toDate, useDayParts, selectedCountries, fromTime, toTime, days, transmissionDuration, warningMessage }: { selectedCountries: Array<Country & { selectedDayParts: Array<Selectable<DayPart>>; }>; fromDate?: number; toDate?: number; days: Array<DaysConstants>; fromTime: string; toTime: string; useDayParts: boolean; transmissionDuration: number; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    fromDate && results.push( ( <span css={ styles.displayGroupSpace } key="1" >{ formatDateAsEnGb( fromDate ) }</span> ) );
    fromDate && toDate && results.push( ( <span css={ styles.displayGroupSpace } key="2" > - </span> ) );
    toDate && results.push( ( <span css={ styles.displayGroupSpace } key="3" >{ formatDateAsEnGb( toDate ) }</span> ) );

    const daysDescription = [];
    if ( days.length === 7 ) {
      daysDescription.push( "[Mon-Sun]" );
    } else {
      for ( const day of days ) {
        switch ( day ) {
          case DaysConstants.monday: daysDescription.push( "Mon" ); break;
          case DaysConstants.tuesday: daysDescription.push( "Tue" ); break;
          case DaysConstants.wednesday: daysDescription.push( "Wed" ); break;
          case DaysConstants.thursday: daysDescription.push( "Thu" ); break;
          case DaysConstants.friday: daysDescription.push( "Fri" ); break;
          case DaysConstants.saturday: daysDescription.push( "Sat" ); break;
          case DaysConstants.sunday: daysDescription.push( "Sun" ); break;
        }
      }
    }

    results.push( ( <span css={ styles.displayGroupSpace } key={ "days" }>{ daysDescription.join( ", " ) }</span> ) );

    if ( useDayParts ) {
      if ( selectedCountries.length === 1 ) {
        const country = selectedCountries[ 0 ];
        const dp = country.selectedDayParts.map( m => m.name );

        results.push( ( <span key={ `c_dp_${ country.id }` } css={ styles.displayGroupSpace }>{ dp.join( ", " ) }</span> ) );
      }
      else {
        for ( const country of selectedCountries ) {
          const dp = country.selectedDayParts.map( m => m.name );

          results.push( ( <div key={ `c_dp_${ country.id }` }><span css={ styles.country }>{ country.name }</span><span css={ styles.displayGroupSpace }>{ dp.join( ", " ) }</span></div> ) );
        }
      }
    } else {
      results.push( ( <span css={ styles.displayGroupSpace } key={ "times" }>{ fromTime } - { toTime }</span> ) );
    }

    results.push( ( <span css={ styles.displayGroupSpace } key={ "transmission_duration" }>{ transmissionDurationByValue[ transmissionDuration ] }</span> ) );

    return results;
  }, [ days, fromDate, fromTime, selectedCountries, toDate, toTime, transmissionDuration, useDayParts ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  return ( <>{ parts }</> );
};
