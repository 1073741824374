/** @jsxImportSource @emotion/react */
import React from "react";
import { Country, LocalGenre } from "model/Model";
import { QualifyClassOneOption, QualifyClassTwoOption, QualifyDistributorOption, QualifyFormatOption, QualifyLocalGenreDisplayConstants, QualifyProductionCountryOption, QualifyProductionCountryPositionOption, QualifyTypeOption } from "../../Model";
import { CheckGroupWithUncheckOptionSelector } from "components/check-group-with-uncheck-option-selector/CheckGroupWithUncheckOptionSelector";
import { Selectable } from "Utilities";
import { QualifyFormatConstants } from "constants/QualifyFormatConstants";
import { LocalGenreSelector } from "components/local-genre-selector/LocalGenreSelector";
import { useReferenceData } from "contexts/ReferenceDataContext";

export const QualifySelector = ( { types, classOnes, classTwos, formats, distributors, productionCountries, productionCountryPositions, localGenreDisplay, selectedCountries, onChangeQualifyTypeIsSelected, onChangeQualifyClassOneIsSelected, onChangeQualifyClassTwoIsSelected, onChangeQualifyFormatIsSelected, onChangeQualifyDistributorIsSelected, onChangeQualifyProductionCountryIsSelected, onChangeQualifyProductionCountryPositionIsSelected, onChangeQualifyLocalGenreDisplay, onChangeQualifyLocalGenreIsSelected }: { types: Array<QualifyTypeOption>; classOnes: Array<QualifyClassOneOption>; classTwos: Array<QualifyClassTwoOption>; formats: Array<QualifyFormatOption>; distributors: Array<QualifyDistributorOption>; productionCountries: Array<QualifyProductionCountryOption>; productionCountryPositions: Array<QualifyProductionCountryPositionOption>; localGenreDisplay: QualifyLocalGenreDisplayConstants; selectedCountries: Array<Country & { localGenres: Array<Selectable<LocalGenre>>; }>; onChangeQualifyTypeIsSelected( value: string, isSelected: boolean ): void; onChangeQualifyClassOneIsSelected( value: string, isSelected: boolean ): void; onChangeQualifyClassTwoIsSelected( value: string, isSelected: boolean ): void; onChangeQualifyFormatIsSelected( value: QualifyFormatConstants, isSelected: boolean ): void; onChangeQualifyDistributorIsSelected( value: string, isSelected: boolean ): void; onChangeQualifyProductionCountryIsSelected( value: string, isSelected: boolean ): void; onChangeQualifyProductionCountryPositionIsSelected( value: number, isSelected: boolean ): void; onChangeQualifyLocalGenreDisplay( value: QualifyLocalGenreDisplayConstants ): void; onChangeQualifyLocalGenreIsSelected( countryId: number, value: number, isSelected: boolean ): void; } ): JSX.Element => {
  const referenceData = useReferenceData();
  return ( <>
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="All" label="Type" helpId="qualifyType" options={ types } onChange={ onChangeQualifyTypeIsSelected } />
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="All" label="Class" helpId="qualify_class_one" options={ classOnes } onChange={ onChangeQualifyClassOneIsSelected } />
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="All" label="Class 2" helpId="qualify_class_two" options={ classTwos } onChange={ onChangeQualifyClassTwoIsSelected } />
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="No" label="Format only" helpId="qualifyFormat" options={ formats } onChange={ onChangeQualifyFormatIsSelected } />
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="All" label="Distributor" helpId="qualify_distributor" options={ distributors } isFixedHeight={ true } onChange={ onChangeQualifyDistributorIsSelected } />
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="All" label="Production Country" helpId="qualifyProdCo" options={ productionCountries } isFixedHeight={ true } onChange={ onChangeQualifyProductionCountryIsSelected } />
    <CheckGroupWithUncheckOptionSelector uncheckOptionText="All" label="Position" helpId="qualify_production_country_position" options={ productionCountryPositions } onChange={ onChangeQualifyProductionCountryPositionIsSelected } />
    <LocalGenreSelector includeLocalGenreDisplayOptions={ referenceData.includeLocalGenreDisplayOptions } localGenreDisplay={ localGenreDisplay } onChangeLocalGenreDisplay={ onChangeQualifyLocalGenreDisplay } selectedCountries={ selectedCountries } onChangeQualifyLocalGenreIsSelected={ onChangeQualifyLocalGenreIsSelected } />
  </> );
};
