import { Output, OutputFormatConstants, OutputViewConstants } from "model/Model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const outputSlice = createSlice( {
  name: "output",
  initialState: {
    format: OutputFormatConstants.Xlsx,
    view: OutputViewConstants.ReportTab
  } as Output,
  reducers: {
    ChangeOutputFormat( state, action: PayloadAction<OutputFormatConstants> ) { state.format = action.payload; },
    ChangeOutputView( state, action: PayloadAction<OutputViewConstants> ) { state.view = action.payload; }
  }
} );

export const { ChangeOutputFormat, ChangeOutputView } = outputSlice.actions;

export default outputSlice.reducer;