import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChangeCountryIsSelected } from "./countriesSlice";

const stationsSlice = createSlice( {
  name: "stations",
  initialState: [] as Array<{ id: number; stations: Array<number>; }>,
  reducers: {
    ChangeStationIsSelected( state, action: PayloadAction<{ countryId: number; stationId: number; isSelected: boolean; }> ) {
      const { countryId, stationId, isSelected } = action.payload;
      if ( state.find( c => c.id === countryId ) ) {
        if ( isSelected ) {
          return state.map( c => c.id === countryId ? { ...c, stations: [ ...c.stations, stationId ] } : c );
        }
        return state.map( c => c.id === countryId ? { ...c, stations: c.stations.filter( m => m !== stationId ) } : c );
      } else if ( isSelected ) {
        return [ ...state, { id: countryId, stations: [ stationId ] } ];
      } else {
        return state;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase( ChangeCountryIsSelected, ( state, action ) => {
      const { countryId, isSelected, defaultStations } = action.payload;

      const countryStations = state.find( c => c.id === countryId );
      console.log( `station reducer: found  countryStations ${ countryStations?.stations.length }` );
      if ( isSelected ) {
        if ( !countryStations ) {
          // a country is selected so ensure it has the defaults
          return [ ...state, { id: countryId, stations: defaultStations } ];
        } else if ( countryStations.stations.length === 0 ) {
          // if the country is selected but has no stations selected then add the defaults
          const updated = state.map( c => c.id === countryId ? { ...c, stations: defaultStations } : c );
          return updated;
        }
      }
      return state;
    } );
  }
} );

export const { ChangeStationIsSelected } = stationsSlice.actions;

export default stationsSlice.reducer;