/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { Index } from "apps/ProgramRanking/Index";
import { appendCountriesById, appendDayPartsByCountryIdAndId, appendDemosByCountryIdAndId, appendMeasuresById, appendStationsByCountryIdAndId, appendUsersAndGroupsById, enrichCountries, appendLocalGenresByCountryIdAndId, CountriesById, appendTemplateNameAndSharedWith } from "model/InitialModel";
import { HelpContext } from "components/help-text/HelpText";
import { ApplicationContext, ApplicationContextBaseType } from "components/application-context/ApplicationContext";
import { HelpText, getApplicationDisplayName } from "Utilities";
import { getStore } from "apps/ProgramRanking/app/store";
import { Applications } from "constants/application";
import { Params, useLoaderData } from "react-router";
import { DisplayColumn, ProgramRankingSourceModel } from "./Model";
import { DemoSelectionConstants, ReportTypeSummary, TemplateOrHistory } from "model/Model";
import { getSessionCredentials } from "contexts/UserContext";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { QualifyFormatConstants } from "constants/QualifyFormatConstants";
import { helpTextCache } from "constants/helpTextCache";
import { fetchHelpTextData, getJson } from "helpers/api";

const applicationId = Applications.ProgramRanking;

export type ApplicationContextType = ApplicationContextBaseType
  &
{
  readonly countriesById: CountriesById;
  readonly reportTypeSummaries: Array<ReportTypeSummary>;
  readonly displayColumns: Array<DisplayColumn>;
  readonly etsTypes: Array<{ value: string; name: string; }>;
  readonly classOnes: Array<{ value: string; name: string; }>;
  readonly classTwos: Array<{ value: string; name: string; }>;
  readonly formats: Array<{ value: QualifyFormatConstants.Original | QualifyFormatConstants.Adaptation; name: string; }>;
  readonly distributors: Array<{ value: string; name: string; }>;
  readonly productionCountries: Array<{ value: string; name: string; }>;
  readonly productionCountryPositions: Array<{ value: number; name: string; }>;
  readonly benchmarkDemoTypes: Array<{ value: number; name: string; }>;
};

type LoaderData = { id: number; helpText: HelpText; initialData: TemplateOrHistory<ProgramRankingSourceModel>; };

export const programRankingLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const id = parseInt( params.id as string || "-1" );

  let helpText: HelpText = helpTextCache[ applicationId ];
  if ( !helpText ) {
    helpText = await fetchHelpTextData( { userId, token, applicationId } );
    helpTextCache[ applicationId ] = helpText;
  }

  const initialData = await getJson<TemplateOrHistory<ProgramRankingSourceModel>>( "/Apps/ProgramRanking", userId, token, { id } );

  initialData.query.demoSelection = initialData.query.demoSelection || DemoSelectionConstants.selectedDemos; // default to selectedDemos for historical reports

  validateSourceModel( initialData.query );

  return { id, helpText, initialData };
};

const validateSourceModel = ( initialData: ProgramRankingSourceModel ) => {
  if ( !initialData ) throw new Error( "Source data has not been loaded" );

  if ( initialData.countries === undefined ) throw new Error( "SourceData.countries hasn't loaded correctly" );
  if ( !Array.isArray( initialData.countries ) ) throw new Error( "SourceData.countries hasn't loaded correctly" );

  if ( initialData.dateTime === undefined ) throw new Error( "SourceData.dateTime hasn't loaded correctly" );
  if ( initialData.dateTime.dayParts === undefined ) throw new Error( "SourceData.dateTime.dayParts hasn't loaded correctly" );
  if ( initialData.dateTime.days === undefined ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( !Array.isArray( initialData.dateTime.days ) ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( initialData.dateTime.fromTime === undefined ) throw new Error( "SourceData.dateTime.fromTime hasn't loaded correctly" );
  if ( initialData.dateTime.toTime === undefined ) throw new Error( "SourceData.dateTime.toTime hasn't loaded correctly" );
  if ( initialData.dateTime.transmissionDuration === undefined ) throw new Error( "SourceData.dateTime.transmissionDuration hasn't loaded correctly" );
  if ( initialData.dateTime.useDayParts === undefined ) throw new Error( "SourceData.dateTime.useDayParts hasn't loaded correctly" );

  if ( initialData.demoGroupBy === undefined ) throw new Error( "SourceData.demoGroupBy hasn't loaded correctly" );
  if ( initialData.demoRank === undefined ) throw new Error( "SourceData.demoRank hasn't loaded correctly" );
  if ( initialData.demoSelection === undefined ) throw new Error( "SourceData.demoSelection hasn't loaded correctly" );

  if ( initialData.demos === undefined ) throw new Error( "SourceData.demos hasn't loaded correctly" );
  if ( !Array.isArray( initialData.demos ) ) throw new Error( "SourceData.demos hasn't loaded correctly" );

  if ( initialData.display === undefined ) throw new Error( "SourceData.display hasn't loaded correctly" );
  if ( initialData.display.displaySortedBy === undefined ) throw new Error( "SourceData.display.displaySortedBy hasn't loaded correctly" );
  if ( initialData.display.displayTime === undefined ) throw new Error( "SourceData.display.displayTime hasn't loaded correctly" );
  if ( initialData.display.displayTop === undefined ) throw new Error( "SourceData.display.displayTop hasn't loaded correctly" );
  if ( initialData.display.displayUnique === undefined ) throw new Error( "SourceData.display.displayUnique hasn't loaded correctly" );
  if ( initialData.display.selectedDisplayColumns === undefined ) throw new Error( "SourceData.display.selectedDisplayColumns hasn't loaded correctly" );
  if ( !Array.isArray( initialData.display.selectedDisplayColumns ) ) throw new Error( "SourceData.display.selectedDisplayColumns hasn't loaded correctly" );

  if ( initialData.measures === undefined ) throw new Error( "SourceData.measures hasn't loaded correctly" );
  if ( !Array.isArray( initialData.measures ) ) throw new Error( "SourceData.measures hasn't loaded correctly" );

  if ( initialData.output === undefined ) throw new Error( "SourceData.output hasn't loaded correctly" );
  if ( initialData.output.format === undefined ) throw new Error( "SourceData.output.format hasn't loaded correctly" );
  if ( initialData.output.view === undefined ) throw new Error( "SourceData.output.view hasn't loaded correctly" );

  if ( initialData.qualify === undefined ) throw new Error( "SourceData.qualify hasn't loaded correctly" );
  if ( initialData.qualify.localGenreDisplay === undefined ) throw new Error( "SourceData.qualify.localGenreDisplay hasn't loaded correctly" );
  if ( initialData.qualify.selectedClassOnes === undefined ) throw new Error( "SourceData.qualify.selectedClassOnes hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedClassOnes ) ) throw new Error( "SourceData.qualify.selectedClassOnes hasn't loaded correctly" );
  if ( initialData.qualify.selectedClassTwos === undefined ) throw new Error( "SourceData.qualify.selectedClassTwos hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedClassTwos ) ) throw new Error( "SourceData.qualify.selectedClassTwos hasn't loaded correctly" );
  if ( initialData.qualify.selectedDistributors === undefined ) throw new Error( "SourceData.qualify.selectedDistributors hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedDistributors ) ) throw new Error( "SourceData.qualify.selectedDistributors hasn't loaded correctly" );
  if ( initialData.qualify.selectedFormats === undefined ) throw new Error( "SourceData.qualify.selectedFormats hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedFormats ) ) throw new Error( "SourceData.qualify.selectedFormats hasn't loaded correctly" );
  if ( initialData.qualify.selectedLocalGenres === undefined ) throw new Error( "SourceData.qualify.selectedLocalGenres hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedLocalGenres ) ) throw new Error( "SourceData.qualify.selectedLocalGenres hasn't loaded correctly" );
  if ( initialData.qualify.selectedProductionCountries === undefined ) throw new Error( "SourceData.qualify.selectedProductionCountries hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedProductionCountries ) ) throw new Error( "SourceData.qualify.selectedProductionCountries hasn't loaded correctly" );
  if ( initialData.qualify.selectedProductionCountryPositions === undefined ) throw new Error( "SourceData.qualify.selectedProductionCountryPositions hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedProductionCountryPositions ) ) throw new Error( "SourceData.qualify.selectedProductionCountryPositions hasn't loaded correctly" );
  if ( initialData.qualify.selectedTypes === undefined ) throw new Error( "SourceData.qualify.selectedTypes hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedTypes ) ) throw new Error( "SourceData.qualify.selectedTypes hasn't loaded correctly" );

  if ( initialData.reportType === undefined ) throw new Error( "SourceData.reportType hasn't loaded correctly" );
  if ( initialData.stations === undefined ) throw new Error( "SourceData.stations hasn't loaded correctly" );
  if ( !Array.isArray( initialData.stations ) ) throw new Error( "SourceData.stations hasn't loaded correctly" );

  if ( initialData.average === undefined ) throw new Error( "SourceData.average hasn't loaded correctly" );
  if ( initialData.average.breakoutOriginalRepeat === undefined ) throw new Error( "SourceData.average.breakoutOriginalRepeat hasn't loaded correctly" );
  if ( initialData.average.breakoutSeasons === undefined ) throw new Error( "SourceData.average.breakoutSeasons hasn't loaded correctly" );
  if ( initialData.average.collapsePeriod === undefined ) throw new Error( "SourceData.average.collapsePeriod hasn't loaded correctly" );
  if ( initialData.average.combineWithin === undefined ) throw new Error( "SourceData.average.combineWithin hasn't loaded correctly" );
  if ( initialData.average.localRepeat === undefined ) throw new Error( "SourceData.average.localRepeat hasn't loaded correctly" );
  if ( initialData.average.minimumAveragedTransmissions === undefined ) throw new Error( "SourceData.average.minimumAveragedTransmissions hasn't loaded correctly" );
  if ( initialData.average.sumThousands === undefined ) throw new Error( "SourceData.average.sumThousands hasn't loaded correctly" );
  if ( initialData.average.title === undefined ) throw new Error( "SourceData.average.title hasn't loaded correctly" );

  if ( initialData.revenue === undefined ) throw new Error( "SourceData.revenue hasn't loaded correctly" );
  if ( initialData.revenue.revenueAmount === undefined ) throw new Error( "SourceData.revenue.revenueAmount hasn't loaded correctly" );
  if ( initialData.revenue.revenueCollapse === undefined ) throw new Error( "SourceData.revenue.revenueCollapse hasn't loaded correctly" );
  if ( initialData.revenue.revenueDuration === undefined ) throw new Error( "SourceData.revenue.revenueDuration hasn't loaded correctly" );

  if ( initialData.benchmark === undefined ) throw new Error( "SourceData.benchmark hasn't loaded correctly" );
  if ( initialData.benchmark.benchmarkDayOfWeekType === undefined ) throw new Error( "SourceData.benchmark.benchmarkDayOfWeekType hasn't loaded correctly" );
  if ( initialData.benchmark.benchmarkDays === undefined ) throw new Error( "SourceData.benchmark.benchmarkDays hasn't loaded correctly" );
  if ( !Array.isArray( initialData.benchmark.benchmarkDays ) ) throw new Error( "SourceData.benchmark.benchmarkDays hasn't loaded correctly" );
  if ( initialData.benchmark.dates === undefined ) throw new Error( "SourceData.benchmark.dates hasn't loaded correctly" );
  if ( initialData.benchmark.demoCount === undefined ) throw new Error( "SourceData.benchmark.demoCount hasn't loaded correctly" );
  if ( initialData.benchmark.demoTypes === undefined ) throw new Error( "SourceData.benchmark.demoTypes hasn't loaded correctly" );
  if ( !Array.isArray( initialData.benchmark.demoTypes ) ) throw new Error( "SourceData.benchmark.demoTypes hasn't loaded correctly" );
  if ( initialData.benchmark.timePeriod === undefined ) throw new Error( "SourceData.benchmark.timePeriod hasn't loaded correctly" );
  if ( initialData.benchmark.timePeriodFromTime === undefined ) throw new Error( "SourceData.benchmark.timePeriodFromTime hasn't loaded correctly" );
  if ( initialData.benchmark.timePeriodToTime === undefined ) throw new Error( "SourceData.benchmark.timePeriodToTime hasn't loaded correctly" );

  if ( initialData.output === undefined ) throw new Error( "SourceData.output hasn't loaded correctly" );
  if ( initialData.output.format === undefined ) throw new Error( "SourceData.output.format hasn't loaded correctly" );
  if ( initialData.output.view === undefined ) throw new Error( "SourceData.output.view hasn't loaded correctly" );
};

export const ProgramRanking = () => {
  const loaderData = useLoaderData() as LoaderData;
  const referenceData = useReferenceData();
  const store = getStore( loaderData.initialData.query );

  const applicationContext: ApplicationContextType = useMemo( () =>
    appendTemplateNameAndSharedWith(
      loaderData.initialData.userId,
      loaderData.initialData.name,
      loaderData.initialData.sharedWith,
      appendCountriesById(
        appendStationsByCountryIdAndId(
          appendDemosByCountryIdAndId(
            appendDayPartsByCountryIdAndId(
              appendLocalGenresByCountryIdAndId(
                appendUsersAndGroupsById(
                  appendMeasuresById(
                    {
                      applicationId,
                      templateOrHistoryId: loaderData.id,
                      countryGroups: referenceData.countryGroups,
                      includeIdInName: referenceData.includeIdInName,
                      measures: referenceData.measures,
                      benchmarkDemoTypes: referenceData.benchmarkDemoTypes,
                      sharingUsersAndGroups: referenceData.sharingUsersAndGroups,
                      displayColumns: referenceData.displayColumns[ applicationId ],
                      countries: enrichCountries( referenceData.countries ),
                      name: getApplicationDisplayName( applicationId ),
                      etsTypes: referenceData.etsTypes,
                      classOnes: referenceData.classOnes,
                      classTwos: referenceData.classTwos,
                      reportTypeSummaries: referenceData.reportTypeSummaries,
                      formats: referenceData.formats,
                      distributors: referenceData.distributors,
                      productionCountries: referenceData.productionCountries,
                      productionCountryPositions: referenceData.productionCountryPositions
                    }
                  )
                )
              )
            )
          )
        )
      )
    ), [ loaderData.id, loaderData.initialData.name, loaderData.initialData.sharedWith, loaderData.initialData.userId, referenceData.benchmarkDemoTypes, referenceData.classOnes, referenceData.classTwos, referenceData.countries, referenceData.countryGroups, referenceData.displayColumns, referenceData.distributors, referenceData.etsTypes, referenceData.formats, referenceData.includeIdInName, referenceData.measures, referenceData.productionCountries, referenceData.productionCountryPositions, referenceData.reportTypeSummaries, referenceData.sharingUsersAndGroups ] );

  return (
    <ApplicationContext.Provider value={ applicationContext } key={ loaderData.id }>
      <HelpContext.Provider value={ loaderData.helpText } >
        <Provider store={ store }>
          <Index />
        </Provider>
      </HelpContext.Provider>
    </ApplicationContext.Provider>
  );
};