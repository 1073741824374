/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo } from "react";
import { HelpText } from "components/help-text/HelpText";
import { Selectable } from "Utilities";
import { css } from "@emotion/react";

const styles = {
  radioGroupAndAllSelector: css`
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;

      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;

        &.clickable {
          cursor: pointer;
        }
      }

      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
      }

      input {
        padding: 0;
        margin: 0;
        margin-right: 4px;
        width: auto;
      }
    }
  `
};

export const RadioGroupAndAllSelector = <T,>( { label, helpId, options, onChange }: { label: string; helpId: string; options: Array<Selectable<{ value: T; name: string; isEnabled?: boolean; }>>; onChange( value: T, isSelected: boolean ): void; } ): JSX.Element => {
  const hasSelectedTypes = useMemo( () => options.filter( m => m.isSelected ).length > 0, [ options ] );

  const uncheckAll = useCallback( (): void => {
    options.map( m => onChange( m.value, false ) );
  }, [ onChange, options ] );

  return (
    <div css={ styles.radioGroupAndAllSelector }>
      <fieldset>
        <legend><label>{ label } <HelpText helpId={ helpId } /></label></legend>
        <div><label><input type="radio" onChange={ () => uncheckAll() } checked={ !hasSelectedTypes }></input>All</label></div>
        {
          options.map( ( m, i ) => ( <CheckSelector key={ i } { ...m } isEnabled={ true } onChange={ onChange } /> ) )
        }
      </fieldset>
    </div>
  );
};

const CheckSelector = <T,>( { value, name, isSelected, isEnabled = true, onChange }: { value: T; name: string; isSelected: boolean; isEnabled?: boolean; onChange: ( value: T, isSelected: boolean ) => void; } ) => {
  return (
    <div data-key={ value }>
      <div>
        <input aria-label={ name } type="checkbox" disabled={ !isEnabled } checked={ isSelected } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChange( value, e.currentTarget.checked ) } />
        <span onSelect={ ( e ) => { e.preventDefault(); return false; } }>{ name }</span>
      </div>
    </div>
  );
};
