/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { Selectable } from "Utilities";
import { DisplayReportIncrementConstants } from "../../Model";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const DisplayDescription = ( { reportIncrement, types, classOnes, classTwos, distributors, displayOptions, warningMessage }: { reportIncrement: DisplayReportIncrementConstants; types: Array<Selectable<{ name: string; }>>; classOnes: Array<Selectable<{ name: string; }>>; classTwos: Array<Selectable<{ name: string; }>>; distributors: Array<Selectable<{ name: string; }>>; displayOptions: Array<Selectable<{ name: string; }>>; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    const reportIncrementText = ( ( value ): string => {
      switch ( value ) {
        case DisplayReportIncrementConstants.OneMinute: return "1 minute increment.";
        case DisplayReportIncrementConstants.FiveMinutes: return "5 minute increment.";
        case DisplayReportIncrementConstants.TenMinutes: return "10 minute increment.";
        case DisplayReportIncrementConstants.FifteenMinutes: return "15 minute increment.";
        case DisplayReportIncrementConstants.ThirtyMinutes: return "30 minute increment.";
      }
    } )( reportIncrement );
    results.push( ( <span key="display_increment" css={ styles.displayGroupSpace }>{ reportIncrementText }</span> ) );

    results.push( ( <span key="highlight:" css={ styles.displayGroupSpace }>Highlight: </span> ) );

    const selectedTypes = types.filter( m => m.isSelected );
    const selectedClassOnes = classOnes.filter( m => m.isSelected );
    const selectedClassTwos = classTwos.filter( m => m.isSelected );
    const selectedDistributors = distributors.filter( m => m.isSelected );

    if ( selectedTypes.length === 0 && selectedClassOnes.length === 0 && selectedClassTwos.length === 0 && selectedDistributors.length === 0 ) {
      results.push( ( <span key="display_none" css={ styles.displayGroupSpace }>None.</span> ) );
    } else {
      if ( selectedTypes.length > 0 ) results.push( ( <span key="display_type" css={ styles.displayGroupSpace }>Type: { selectedTypes.map( m => m.name ).join( ", " ) }.</span> ) );
      if ( selectedClassOnes.length > 0 ) results.push( ( <span key="display_class" css={ styles.displayGroupSpace }>Class: { selectedClassOnes.map( m => m.name ).join( ", " ) }.</span> ) );
      if ( selectedClassTwos.length > 0 ) results.push( ( <span key="display_class2" css={ styles.displayGroupSpace }>Class 2: { selectedClassTwos.map( m => m.name ).join( ", " ) }.</span> ) );
      if ( selectedDistributors.length > 0 ) results.push( ( <span key="display_distributor" css={ styles.displayGroupSpace }>Distributor: { selectedDistributors.map( m => m.name ).join( ", " ) }.</span> ) );
    }

    const selectedDisplayOptions = displayOptions.filter( m => m.isSelected );
    const displayOptionText = selectedDisplayOptions.length === 0 ? "No additional fields" : `Include ${ selectedDisplayOptions.map( m => m.name ).join( ", " ) }.`;
    results.push( ( <span key="display_options" css={ styles.displayGroupSpace }>{ displayOptionText }</span> ) );
    return results;
  }, [ classOnes, classTwos, displayOptions, distributors, reportIncrement, types ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <>None</>;
};
