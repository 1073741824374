/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { DisplayColumnSelector } from "./display-column-selector/DisplayColumnSelector";
import { DisplaySortedBySelector } from "./display-sorted-by-selector/DisplaySortedBySelector";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { DisplayColumn, DisplayColumnConstants, DisplaySortedByConstants, DisplayTimeConstants, DisplayTopConstants, DisplayUniqueConstants } from "../../Model";
import { Selectable } from "Utilities";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";

const displayTimeOptions = [
  { value: DisplayTimeConstants.TotalMinutes, name: "Total Minutes" },
  { value: DisplayTimeConstants.HoursMinutes, name: "Hours:Minutes" },
  { value: DisplayTimeConstants.HoursMinutesSeconds, name: "Hours:Minutes:Seconds" }
];

const displayTopOptions = [
  { value: DisplayTopConstants.All, name: "ALL" },
  { value: DisplayTopConstants.Five, name: "5" },
  { value: DisplayTopConstants.Ten, name: "10" },
  { value: DisplayTopConstants.Fifteen, name: "15" },
  { value: DisplayTopConstants.Twenty, name: "20" },
  { value: DisplayTopConstants.TwentyFive, name: "25" },
  { value: DisplayTopConstants.Fifty, name: "50" },
  { value: DisplayTopConstants.SeventyFive, name: "75" },
  { value: DisplayTopConstants.OneHundred, name: "100" },
  { value: DisplayTopConstants.TwoHundredAndFifty, name: "250" },
  { value: DisplayTopConstants.FiveHundred, name: "500" }
];

const displayUniqueOptions = [
  { value: DisplayUniqueConstants.All, name: "All" },
  { value: DisplayUniqueConstants.TopPerforming, name: "Top Performing" },
  { value: DisplayUniqueConstants.FirstBroadcast, name: "First Broadcast" }
];

export const DisplaySelector = ( { displayColumns, displaySortedBy, displayTop, displayUnique, revenueSelected, displayTime, onChangeDisplayColumnIsSelected, onChangeDisplaySortedBy, onChangeDisplayTop, onChangeDisplayUnique, onChangeDisplayTime }: { displayColumns: Array<Selectable<DisplayColumn>>; displaySortedBy: DisplaySortedByConstants; displayTop: DisplayTopConstants; displayUnique: DisplayUniqueConstants; revenueSelected: boolean; displayTime: DisplayTimeConstants; onChangeDisplayColumnIsSelected: ( id: string, isSelected: boolean ) => void; onChangeDisplaySortedBy: ( value: DisplaySortedByConstants ) => void; onChangeDisplayTop: ( value: DisplayTopConstants ) => void; onChangeDisplayUnique: ( value: DisplayUniqueConstants ) => void; onChangeDisplayTime: ( value: DisplayTimeConstants ) => void; } ): JSX.Element => {
  const distributorIsSelected = useMemo( () => displayColumns.find( m => m.id === DisplayColumnConstants.Distributor )?.isSelected ?? false, [ displayColumns ] );
  const typeIsSelected = useMemo( () => displayColumns.find( m => m.id === DisplayColumnConstants.Type )?.isSelected ?? false, [ displayColumns ] );
  const prodCo1IsSelected = useMemo( () => displayColumns.find( m => m.id === DisplayColumnConstants.ProdCo1 )?.isSelected ?? false, [ displayColumns ] );

  return ( <>
    <DisplayColumnSelector helpId="displayAntiColumn" displayColumns={ displayColumns } onChangeDisplayColumnIsSelected={ onChangeDisplayColumnIsSelected } />
    <DisplaySortedBySelector helpId="display_sorted_by" displaySortedBy={ displaySortedBy } onChangeDisplaySortedBy={ onChangeDisplaySortedBy } distributorIsEnabled={ distributorIsSelected } showTypeIsEnabled={ typeIsSelected } productionCountryIsEnabled={ prodCo1IsSelected } revenueIsEnabled={ revenueSelected } />
    <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Top" helpId="display_top" isEnabled={ displaySortedBy === DisplaySortedByConstants.Rank } radioGroup="display_top" value={ displayTop } items={ displayTopOptions } onChangeItemSelected={ onChangeDisplayTop } />
    <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Unique" helpId="displayUnique" radioGroup="display_unique" value={ displayUnique } items={ displayUniqueOptions } onChangeItemSelected={ onChangeDisplayUnique } />
    <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Time" helpId="display_time_period" radioGroup="display_time" value={ displayTime } items={ displayTimeOptions } onChangeItemSelected={ onChangeDisplayTime } />
  </> );
};
