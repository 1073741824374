/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { HelpText } from "components/help-text/HelpText";
import { DisplaySortedByConstants } from "../../../Model";
import { css } from "@emotion/react";

const styles = {
  displaySortedBySelector: css`
    display: inline-block;
    vertical-align: top;

    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;

      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;

        &.clickable {
          cursor: pointer;
        }
      }

      label {
        padding: 0;
        margin: 0;
       span {
        vertical-align: middle;
       } 
      }

      input {
        padding: 0;
        margin: 0 5px;
        width: auto;
        vertical-align: middle;
      }
    }
  `,

  stacked: css`
    display: block;
  `
};

export const DisplaySortedBySelector = ( { helpId, displaySortedBy, distributorIsEnabled, showTypeIsEnabled, productionCountryIsEnabled, onChangeDisplaySortedBy }: { helpId: string; displaySortedBy: DisplaySortedByConstants; distributorIsEnabled: boolean; showTypeIsEnabled: boolean; productionCountryIsEnabled: boolean; onChangeDisplaySortedBy: ( value: DisplaySortedByConstants ) => void; } ): JSX.Element => {
  const displaySortedByItems = useMemo( () => [
    { name: "Country/Channel", value: DisplaySortedByConstants.CountryChannel, isEnabled: true },
    { name: "Country/Date", value: DisplaySortedByConstants.CountryDate, isEnabled: true },
    { name: "Title", value: DisplaySortedByConstants.Title, isEnabled: true },
    { name: "Start Date", value: DisplaySortedByConstants.StartDate, isEnabled: true },
    { name: "Rank", value: DisplaySortedByConstants.Rank, isEnabled: true },
    { name: "Distributor", value: DisplaySortedByConstants.Distributor, isEnabled: distributorIsEnabled },
    { name: "Show Type", value: DisplaySortedByConstants.ShowType, isEnabled: showTypeIsEnabled },
    { name: "Production Country", value: DisplaySortedByConstants.ProductionCountry, isEnabled: productionCountryIsEnabled }
  ], [ distributorIsEnabled, productionCountryIsEnabled, showTypeIsEnabled ] );

  return ( <>
    <div css={ styles.displaySortedBySelector }>
      <fieldset>
        <legend><label>Sorted by <HelpText helpId={ helpId } /></label></legend>
        { displaySortedByItems.map( ( m, i ) => ( <label css={ styles.stacked } key={ i }><input disabled={ !m.isEnabled } type="radio" radioGroup="display_sorted_by" checked={ m.value === displaySortedBy } onChange={ () => onChangeDisplaySortedBy( m.value ) } /><span>{ m.name }</span></label> ) ) }
      </fieldset>
    </div>
  </> );
};
