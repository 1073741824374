/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from "react";
import { ApplicationPanel } from "./components/application-panel/ApplicationPanel";
import { ExpanderPanel } from "./components/expander-panel/ExpanderPanel";
import { PersonalizationPanel } from "./components/personalization-panel/PersonalizationPanel";
import { css } from "@emotion/react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Applications } from "constants/application";
import { SharedTemplateDeletedMessageArgs, UserTemplateCreatedMessageArgs, UserTemplateDeletedMessageArgs, UserTemplateRenamedMessageArgs, UserTemplateMovedMessageArgs, UserTemplateUpdatedMessageArgs, UserHistoryCreatedMessageArgs, useNotifications } from "contexts/NotificationsContext";
import { ApplicationPanel as ApplicationPanelModel } from "./ApplicationPanel";
import { useImmer } from "use-immer";

const styles = {
  emsPanelBar: css`
    height:100%;
    width: 250px;
    text-align: left;
    margin: 0;
    padding: 0;
    list-style-position: outside;
    list-style: none;
    background-color: #dbdfe4;
  `,

  rpRootGroup: css`
    height: calc(100vh - 42px);
    display:flex;
    flex-direction: column;
    border-width: 1px;
    border-style: solid;
    border-color: #dbdfe4;
    margin: 0;
    padding: 0;
    list-style-position: outside;
    list-style: none;
  `
};

export const Index = ( { applicationId, applications: initialApplications }: { applicationId: number; applications: ApplicationPanelModel[]; } ): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const notifications = useNotifications();
  const [ applications, setApplications ] = useImmer( initialApplications );
  const [ personalizationVisible, setPersonalizationVisible ] = useState( false );

  const onUserTemplateRenamedHandler = useCallback( ( args: UserTemplateRenamedMessageArgs ) => {
    setApplications( applications => {
      const application = applications.find( m => m.applicationId === args.applicationId );
      if ( !application ) return;

      const template = application.templateDataSource.find( m => m.id === args.templateId );
      if ( !template ) return;

      template.name = args.name;
    } );
  }, [ setApplications ] );

  const onUserTemplateMovedHandler = useCallback( ( args: UserTemplateMovedMessageArgs ) => {
    setApplications( applications => {
      const application = applications.find( m => m.applicationId === args.applicationId );
      if ( !application ) return;

      const template = application.templateDataSource.find( m => m.id === args.templateId );
      if ( !template ) return;

      template.parentId = args.parentId;
    } );
  }, [ setApplications ] );

  const onUserTemplateUpdatedMessageHandler = useCallback( ( args: UserTemplateUpdatedMessageArgs ) => {
    setApplications( applications => {
      const application = applications.find( m => m.applicationId === args.applicationId );
      if ( !application ) return;

      const template = application.templateDataSource.find( m => m.id === args.templateId );
      if ( !template ) return;

      template.name = args.name;
    } );
  }, [ setApplications ] );

  const onUserTemplateDeletedMessageHandler = useCallback( ( args: UserTemplateDeletedMessageArgs ) => {
    setApplications( applications => {
      const application = applications.find( m => m.applicationId === args.applicationId );
      if ( !application ) return;

      application.templateDataSource = application.templateDataSource.filter( m => m.id !== args.templateId );
    } );
  }, [ setApplications ] );

  const onUserTemplateCreatedMessageHandler = useCallback( ( args: UserTemplateCreatedMessageArgs ) => {
    setApplications( applications => {
      const application = applications.find( m => m.applicationId === args.applicationId );
      if ( !application ) return;

      application.templateDataSource.push( {
        id: args.templateId,
        isFolder: args.isFolder,
        name: args.name,
        parentId: args.parentId
      } );
    } );
  }, [ setApplications ] );

  const onUserHistoryCreatedMessageHandler = useCallback( ( args: UserHistoryCreatedMessageArgs ) => {
    setApplications( applications => {
      const application = applications.find( m => m.applicationId === args.applicationId );
      if ( !application ) return;

      application.historyDataSource.unshift( {
        id: args.historyId,
        name: args.name
      } );
    } );
  }, [ setApplications ] );

  const onSharedTemplateDeletedMessageHandler = useCallback( ( args: SharedTemplateDeletedMessageArgs ) => {
    setApplications( applications => {
      const application = applications.find( m => m.applicationId === args.applicationId );
      if ( !application ) return;

      for ( const owner of application.sharedDataSource ) {
        owner.templates = owner.templates.filter( m => m.id !== args.templateId );
      }
    } );
  }, [ setApplications ] );

  useEffect( () => {
    if ( !notifications ) return;

    console.log( "binding start setOnUserTemplate* events" );
    notifications.setTemplateMessageHandlers( {
      onUserTemplateRenamedMessageHandler: onUserTemplateRenamedHandler,
      onUserTemplateMovedMessageHandler: onUserTemplateMovedHandler,
      onUserTemplateDeletedMessageHandler: onUserTemplateDeletedMessageHandler,
      onUserTemplateCreatedMessageHandler: onUserTemplateCreatedMessageHandler,
      onSharedTemplateDeletedMessageHandler: onSharedTemplateDeletedMessageHandler,
      onUserHistoryCreatedMessageHandler: onUserHistoryCreatedMessageHandler,
      onUserTemplateUpdatedMessageHandler: onUserTemplateUpdatedMessageHandler
    } );
    console.log( "binding end setOnUserTemplate* events" );

    return () => {
      console.log( "unbinding start setOnUserTemplate* events" );
      notifications.setTemplateMessageHandlers( undefined );
      console.log( "unbinding end setOnUserTemplate* events" );
    };
  }, [ notifications, onUserTemplateRenamedHandler, onUserTemplateDeletedMessageHandler, onUserTemplateCreatedMessageHandler, onSharedTemplateDeletedMessageHandler, onUserHistoryCreatedMessageHandler, onUserTemplateUpdatedMessageHandler, onUserTemplateMovedHandler ] );

  const setActiveApplication = useCallback( ( applicationId: number ) => {
    setPersonalizationVisible( false );

    const id = 0;
    console.log( `switching to application: ${ applicationId }` );

    switch ( applicationId ) {
      case Applications.ProgramFinder: navigate( `/ProgramFinder/${ id }` ); break;
      case Applications.ProgramSearch: navigate( `/ProgramSearch/${ id }` ); break;
      case Applications.QuarterHour: navigate( `/QuarterHour/${ id }` ); break;
      case Applications.ProgramPerformance: navigate( `/ProgramPerformance/${ id }` ); break;
      case Applications.ProgramRanking: navigate( `/ProgramRanking/${ id }` ); break;
      case Applications.ProgramAverageTimeSpent: navigate( `/ProgramAverageTimeSpent/${ id }` ); break;
      case Applications.DistributorFinder: navigate( `/DistributorFinder/${ id }` ); break;
      case Applications.ChannelView: navigate( `/ChannelView/${ id }` ); break;
      case Applications.RevenueEstimator: navigate( `/RevenueEstimator/${ id }` ); break;
      case Applications.WeeklyChannelView: navigate( `/WeeklyChannelView/${ id }` ); break;
      case Applications.AvailableData: navigate( `/AvailableData/${ id }` ); break;
      default: break;
    }
  }, [ navigate ] );

  if ( location.pathname === "/" ) {
    // this is a duplicate of code from the ApplicationPanel
    const id = 0;
    switch ( applicationId ) {
      case Applications.ChannelView: return <Navigate to={ `/ChannelView/${ id }` } />;
      case Applications.DistributorFinder: return <Navigate to={ `/DistributorFinder/${ id }` } />;
      case Applications.ProgramFinder: return <Navigate to={ `/ProgramFinder/${ id }` } />;
      case Applications.ProgramPerformance: return <Navigate to={ `/ProgramPerformance/${ id }` } />;
      case Applications.ProgramRanking: return <Navigate to={ `/ProgramRanking/${ id }` } />;
      case Applications.ProgramAverageTimeSpent: return <Navigate to={ `/ProgramAverageTimeSpent/${ id }` } />;
      case Applications.ProgramSearch: return <Navigate to={ `/ProgramSearch/${ id }` } />;
      case Applications.QuarterHour: return <Navigate to={ `/QuarterHour/${ id }` } />;
      case Applications.RevenueEstimator: return <Navigate to={ `/RevenueEstimator/${ id }` } />;
      case Applications.WeeklyChannelView: return <Navigate to={ `/WeeklyChannelView/${ id }` } />;
      case Applications.AvailableData: return <Navigate to={ `/AvailableData/${ id }` } />;
      default: console.error( "unknown application type" );
    }
  }

  return (
    <div css={ styles.emsPanelBar } >
      <ul css={ styles.rpRootGroup }>
        { applications.map( ( application, i ) =>
          <ExpanderPanel key={ application.applicationId } applicationId={ application.applicationId } canExpand={ true } isExpanded={ !personalizationVisible && applicationId === application.applicationId } isFirst={ i === 0 } applicationsCount={ applications.length + 1 } onSelect={ () => setActiveApplication( application.applicationId ) } title={ application.title }>
            <ApplicationPanel applicationId={ application.applicationId } templateDataSource={ application.templateDataSource } sharedDataSource={ application.sharedDataSource } historyDataSource={ application.historyDataSource } availableApplicationIds={ application.availableApplicationIds } setActiveApplication={ setActiveApplication } />
          </ExpanderPanel>
        ) }

        <ExpanderPanel isFirst={ false } applicationId={ Applications.AvailableData } canExpand={ false } isExpanded={ applicationId === Applications.AvailableData } applicationsCount={ applications.length } onSelect={ () => setActiveApplication( Applications.AvailableData ) } title="Available Data" />

        <ExpanderPanel isFirst={ false } applicationId={ -3 } canExpand={ true } isExpanded={ personalizationVisible } applicationsCount={ applications.length } onSelect={ () => { setActiveApplication( -3 ); setPersonalizationVisible( true ); } } title="Personalization" >
          <PersonalizationPanel helpId={ "personalization" } />
        </ExpanderPanel>
      </ul>
    </div>
  );
};
