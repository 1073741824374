/** @jsxImportSource @emotion/react */
import React from "react";
import { HelpText } from "components/help-text/HelpText";
import { DisplayPrimarySheetConstants, DisplayPrimarySheetOption } from "apps/ProgramPerformance/Model";
import { css } from "@emotion/react";

const displayPrimarySheetSelector = css`
  display: inline-block;
  vertical-align: top;

  fieldset {
    border: solid black 1px;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    display: inline;
    margin-left: 4px;
    margin-right: 4px;

    legend {
      width: auto;
      margin: 0;
      margin-left: 4px;
      padding-left: 4px;
      padding-right: 4px;

      &.clickable {
        cursor: pointer;
      }
    }

    label {
      padding: 0;
      margin: 0;
      padding-right: 10px;
    }

    input {
      padding: 0;
      margin: 0 5px 0 0;
      width: auto;
      vertical-align: middle
    }

    span {
      vertical-align: middle
    }
  }
`;

export const DisplayPrimarySheetSelector = ( { helpId, displayPrimarySheetOptions, onChangeDisplayPrimarySheetIsSelected }: { helpId: string; displayPrimarySheetOptions: Array<DisplayPrimarySheetOption>; onChangeDisplayPrimarySheetIsSelected: ( value: DisplayPrimarySheetConstants, isSelected: boolean ) => void; } ): JSX.Element => (
  <div css={ displayPrimarySheetSelector }>
    <fieldset>
      <legend><label>Primary Sheet <HelpText helpId={ helpId } /></label></legend>
      {
        displayPrimarySheetOptions.map( ( m ) => {
          return <CheckSelector key={ m.value } { ...m } onChangeDisplayPrimarySheetIsSelected={ onChangeDisplayPrimarySheetIsSelected } />;
        } )
      }
    </fieldset>
  </div>
);

const CheckSelector = ( { value, name, isSelected, onChangeDisplayPrimarySheetIsSelected }: { value: DisplayPrimarySheetConstants; name: string; isSelected: boolean; isEnabled?: boolean; onChangeDisplayPrimarySheetIsSelected: ( value: DisplayPrimarySheetConstants, isSelected: boolean ) => void; } ) => {
  return (
    <div data-key={ value } >
      <input aria-label={ name } type="checkbox" checked={ isSelected } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeDisplayPrimarySheetIsSelected( value, e.currentTarget.checked ) } />
      <span onSelect={ ( e ) => { e.preventDefault(); return false; } }>{ name }</span>
    </div>
  );
};
