import { DemoSelectionConstants } from "model/Model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const demoSelectionSlice = createSlice( {
  name: "demoSelection",
  initialState: DemoSelectionConstants.selectedDemos as DemoSelectionConstants,
  reducers: {
    ChangeDemoSelection( _, action: PayloadAction<DemoSelectionConstants> ) {
      return action.payload;
    }
  }
} );

export const { ChangeDemoSelection } = demoSelectionSlice.actions;

export default demoSelectionSlice.reducer;