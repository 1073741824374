/** @jsxImportSource @emotion/react */
import React from "react";
import { HelpText } from "components/help-text/HelpText";
import { DaysSelector } from "components/day-selector/DaySelector";
import { BenchmarkDayOfWeekTypeConstants, DaysConstants } from "model/Model";
import { css } from "@emotion/react";

const styles = {
  benchmarkDatesSelector: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 6px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        input {
          margin-left: 10px;
          margin-right: 5px;
          height: 20px;
        }
        span {
          display:inline-block;
          vertical-align: top;
          padding-top:2px;
        }
    }
  `,

  itemGroup: css`
    display: block;    
  `
};

export const BenchmarkDaySelector = ( { helpId, isEnabled, benchmarkDayOfWeekType, benchmarkDays, onChangeBenchmarkDayOfWeekType, onChangeBenchmarkDaysSelected }: { helpId: string; isEnabled: boolean; benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants; benchmarkDays: Array<DaysConstants>; onChangeBenchmarkDayOfWeekType: ( value: BenchmarkDayOfWeekTypeConstants ) => void; onChangeBenchmarkDaysSelected: ( days: Array<DaysConstants> ) => void; } ): JSX.Element => {
  return ( <>
    <div css={ styles.benchmarkDatesSelector }>
      <fieldset>
        <legend><label>Dates <HelpText helpId={ helpId } /></label></legend>
        <div>
          <div css={ styles.itemGroup }>
            <div>
              <label><input disabled={ !isEnabled } type="radio" radioGroup="benchmark_day_of_week_type" checked={ benchmarkDayOfWeekType === BenchmarkDayOfWeekTypeConstants.SameAsSelectedDays } onChange={ () => onChangeBenchmarkDayOfWeekType( BenchmarkDayOfWeekTypeConstants.SameAsSelectedDays ) } /><span>Same as selected days</span></label>
            </div>
            <div>
              <label><input disabled={ !isEnabled } type="radio" radioGroup="benchmark_day_of_week_type" checked={ benchmarkDayOfWeekType === BenchmarkDayOfWeekTypeConstants.TransmissionDay } onChange={ () => onChangeBenchmarkDayOfWeekType( BenchmarkDayOfWeekTypeConstants.TransmissionDay ) } /><span>Benchmark based on transmission day</span></label>
            </div>
          </div>
          <div css={ styles.itemGroup }>
            <label><input disabled={ !isEnabled } type="radio" radioGroup="benchmark_day_of_week_type" checked={ benchmarkDayOfWeekType === BenchmarkDayOfWeekTypeConstants.SelectedDays } onChange={ () => onChangeBenchmarkDayOfWeekType( BenchmarkDayOfWeekTypeConstants.SelectedDays ) } /><span>Benchmark based on specific days</span></label>
            <div>
              <DaysSelector isEnabled={ benchmarkDayOfWeekType === BenchmarkDayOfWeekTypeConstants.SelectedDays } helpId="" days={ benchmarkDays } onChangeDays={ onChangeBenchmarkDaysSelected } />
            </div>
          </div>
        </div>
      </fieldset>
    </div >
  </> );
};
