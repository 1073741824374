/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import { AvailableDataRowModel, AvailableDataSourceModel } from "./Model";
import { css } from "@emotion/react";
import { formatDateAsEnGb } from "Utilities";
import { getIconById } from "components/application-icon/ApplicationIcon";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { CaretRight, CaretDown } from "components/BootstrapIcons";

const styles = {
  h1: css`
    padding-left: 10px;  
  `,

  table: css`
    min-width: 80%;
    margin-bottom: 20px;
    font: small Arial; 
    border-collapse: collapse;
    td {
      padding: 5px 5px 5px 5px;
      white-space: nowrap;
    }
  `,

  countryHead: css`
    text-align: center;
    background-color: #8CB2EF;
    color: white;
    font-weight: bold;
    padding: 5px;
  `,

  fromHead: css`
    padding: 5px 5px 5px 5px;
    text-align: center;
    color: White;
    font-weight: bold;
    width: 85px;
    background-color: #999999;
  `,

  toHead: css`
    padding: 5px 5px 5px 5px;
    text-align: center;
    color: White;
    font-weight: bold;
    background-color: #b6365d;
  `,

  dataWhite: `
    border-bottom: 1px solid silver;
  `,

  dataOther: `
    border-bottom: 1px solid silver;
    background-color: #F7F3F7;
  `,

  link: css`
    margin-left: 5px;
    color: black;
    text-decoration: none;

    &:visited {
      color: black;
    }
  `,

  stationName: css`
    padding-left: 30px;
  `
};

export const Index = ( { data }: { data: AvailableDataSourceModel; } ): JSX.Element => {
  return ( <>
    <h1 css={ styles.h1 }>EMS Data Availability</h1>
    <div>
      <hr />
      { data.weeklyList && data.weeklyList.length > 0 && <WeeklyTable rows={ data.weeklyList } /> }
      { data.notWeeklyList && data.notWeeklyList.length > 0 && <NotWeeklyListTable rows={ data.notWeeklyList } /> }
      { data.otherList && data.otherList.length > 0 && <OtherListTable rows={ data.otherList } /> }
    </div>
    <div css={ css`text-align: left; font-size: x-small; color: #999999; margin-left:10px` }>Essential Television Statistics</div>
  </> );
};

const WeeklyTable = ( { rows }: { rows: Array<AvailableDataRowModel>; } ): JSX.Element => {
  return ( <>
    <table css={ styles.table }>
      <thead>
        <tr>
          <td css={ css`font-size: x-large; text-align: left;` }>Weekly</td>
          <td colSpan={ 5 } />
          <td css={ css`font-size: medium; text-align: left;` }><b>From</b></td>
          <td colSpan={ 3 } />
          <td css={ css`font-size: medium; text-align: left;` }><b>To</b></td>
        </tr>
        <tr>
          <td />
          <td />
          <td css={ styles.countryHead }>Time Shift</td>
          <td css={ styles.countryHead }>Harmonization Detail</td>
          <td css={ styles.countryHead }>Raw Delivery</td>
          <td />
          <td css={ styles.fromHead }>Raw</td>
          <td css={ styles.fromHead }>Harmonized</td>
          <td css={ styles.fromHead }>Revenue</td>
          <td />
          <td css={ styles.toHead }>Raw</td>
          <td css={ styles.toHead }>Harmonized</td>
          <td css={ styles.toHead }>Revenue</td>
        </tr>
        <tr />
        <tr>
          <td colSpan={ 13 }>
            <br />
            <hr />
            <br />
          </td>
        </tr>
      </thead>
      <tbody>
        {
          rows.map( ( row, i ) => {
            const isExpanded = rows.length > i + 1 && !!rows[ i + 1 ].stationCode && !row.stationCode;
            const isContracted = !isExpanded && !row.stationCode;
            return ( <tr key={ i }>
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>
                <CountryInfo row={ row } isExpanded={ isExpanded } isContracted={ isContracted } />
              </td>
              <td />
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>{ row.activityTypes }</td>
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>{ row.coding }</td>
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>{ row.rawDelivery }</td>
              <td />
              <td css={ [ styles.dataWhite ] } >{ formatDateAsEnGb( row.rawFrom ) }</td>
              <td css={ [ styles.dataWhite ] } >{ formatDateAsEnGb( row.transFrom ) }</td>
              <td css={ [ styles.dataWhite ] } >{ formatDateAsEnGb( row.revFrom ) }</td>
              <td />
              <td css={ [ styles.dataOther ] }>{ formatDateAsEnGb( row.rawTo ) }</td>
              <td css={ [ styles.dataOther ] }>{ formatDateAsEnGb( row.transTo ) }</td>
              <td css={ [ styles.dataOther ] }>{ formatDateAsEnGb( row.revToApproved ) }</td>
            </tr>
            );
          } )
        }
      </tbody>
    </table>
  </> );
};

const NotWeeklyListTable = ( { rows }: { rows: Array<AvailableDataRowModel>; } ): JSX.Element => {
  return ( <>
    <table css={ styles.table }>
      <thead>
        <tr>
          <td css={ css`font-size: x-large; text-align: left;` }>Monthly</td>
          <td colSpan={ 5 } />
          <td css={ css`font-size: medium; text-align: left;` }><b>From</b></td>
          <td colSpan={ 3 } />
          <td css={ css`font-size: medium; text-align: left;` }><b>To</b></td>
        </tr>
        <tr>
          <td />
          <td />
          <td css={ styles.countryHead }>Time Shift</td>
          <td css={ styles.countryHead }>Harmonization Detail</td>
          <td css={ styles.countryHead }>Raw Delivery</td>
          <td />
          <td css={ styles.fromHead }>Raw</td>
          <td css={ styles.fromHead }>Harmonized</td>
          <td />
          <td />
          <td css={ styles.toHead }>Raw</td>
          <td css={ styles.toHead }>Harmonized</td>
          <td />
        </tr>
        <tr />
        <tr>
          <td colSpan={ 13 }>
            <br />
            <hr />
            <br />
          </td>
        </tr>
      </thead>
      <tbody>
        {
          rows.map( ( row, i ) => {
            const isExpanded = rows.length > i + 1 && !!rows[ i + 1 ].stationCode && !row.stationCode;
            const isContracted = !isExpanded && !row.stationCode;
            return ( <tr key={ i }>
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>
                <CountryInfo row={ row } isContracted={ isContracted } isExpanded={ isExpanded } />
              </td>
              <td />
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>{ row.activityTypes }</td>
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>{ row.coding }</td>
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>{ row.rawDelivery }</td>
              <td />
              <td css={ [ styles.dataWhite ] } >{ formatDateAsEnGb( row.rawFrom ) }</td>
              <td css={ [ styles.dataWhite ] } >{ formatDateAsEnGb( row.transFrom ) }</td>
              <td />
              <td />
              <td css={ [ styles.dataOther ] }>{ formatDateAsEnGb( row.rawTo ) }</td>
              <td css={ [ styles.dataOther ] }>{ formatDateAsEnGb( row.transTo ) }</td>
              <td />
            </tr>
            );
          } )
        }
      </tbody>
    </table>
  </> );
};

const OtherListTable = ( { rows }: { rows: Array<AvailableDataRowModel>; } ): JSX.Element => {
  return ( <>
    <table css={ styles.table }>
      <thead>
        <tr>
          <td css={ css`font-size: x-large; text-align: left;` }>Other</td>
          <td colSpan={ 5 } />
          <td css={ css`font-size: medium; text-align: left;` }><b>From</b></td>
          <td colSpan={ 3 } />
          <td css={ css`font-size: medium; text-align: left;` }><b>To</b></td>
        </tr>
        <tr>
          <td />
          <td />
          <td css={ styles.countryHead }>Time Shift</td>
          <td css={ styles.countryHead }>Harmonization Detail</td>
          <td css={ styles.countryHead }>Raw Delivery</td>
          <td />
          <td css={ styles.fromHead }>Raw</td>
          <td css={ styles.fromHead }>Harmonized</td>
          <td />
          <td />
          <td css={ styles.toHead }>Raw</td>
          <td css={ styles.toHead }>Harmonized</td>
          <td />
        </tr>
        <tr />
        <tr>
          <td colSpan={ 13 }>
            <br />
            <hr />
            <br />
          </td>
        </tr>
      </thead>
      <tbody>
        {
          rows.map( ( row, i ) => {
            const isExpanded = rows.length > i + 1 && !!rows[ i + 1 ].stationCode && !row.stationCode;
            const isContracted = !isExpanded && !row.stationCode;
            return ( <tr key={ i }>
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>
                <CountryInfo row={ row } isContracted={ isContracted } isExpanded={ isExpanded } />
              </td>
              <td />
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>{ row.activityTypes }</td>
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>{ row.coding }</td>
              <td css={ [ styles.dataWhite, css`text-align: left` ] }>{ row.rawDelivery }</td>
              <td />
              <td css={ [ styles.dataWhite ] } >{ formatDateAsEnGb( row.rawFrom ) }</td>
              <td css={ [ styles.dataWhite ] } >{ formatDateAsEnGb( row.transFrom ) }</td>
              <td />
              <td />
              <td css={ [ styles.dataOther ] }>{ formatDateAsEnGb( row.rawTo ) }</td>
              <td css={ [ styles.dataOther ] }>{ formatDateAsEnGb( row.transTo ) }</td>
              <td />
            </tr>
            );
          } )
        }
      </tbody>
    </table>
  </> );
};

const CountryInfo = ( { row, isExpanded, isContracted }: { row: AvailableDataRowModel; isExpanded: boolean; isContracted: boolean; } ): JSX.Element => {
  const referenceData = useReferenceData();

  return ( <>
    { !row.country && !!row.station && <span css={ styles.stationName }>{ row.station }{ referenceData.includeIdInName && <> [{ row.stationCode }]</> }</span> }
    { row.countryUrl && row.countryExternalUrl === "" && <>{ row.country }{ referenceData.includeIdInName && <> [{ row.countryId }</> }</> }
    { row.countryUrl && row.country && <Link css={ styles.link } relative="path" to={ row.countryUrl }>
      { isExpanded && <CaretDown /> }
      { isContracted && <CaretRight /> }
      { row.country }{ referenceData.includeIdInName && <> [{ row.countryId }]</> }
    </Link> }
    { row.countryImage && row.countryExternalUrl && <a css={ styles.link } target="_new" href={ row.countryExternalUrl }><img src={ getIconById( row.countryImage ) } height="10" alt={ row.country } /></a> }
  </> );
};