/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import { OutputFormatConstants, OutputViewConstants } from "model/Model";
import { ApplicationContext } from "components/application-context/ApplicationContext";
import { css } from "@emotion/react";

const styles = {
  warning: css`
    color: #cc0000;
  `
};

export const OutputDescription = ( { format, view, warningMessage }: { format: OutputFormatConstants; view: OutputViewConstants; warningMessage?: string; } ): JSX.Element => {
  const applicationContext = useContext( ApplicationContext );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;

  switch ( format ) {
    case OutputFormatConstants.Html:
      switch ( view ) {
        case OutputViewConstants.ReportTab: return <>Output as HTML to Report tab</>;
        case OutputViewConstants.NewTab: return <>Output as HTML to New tab</>;
        case OutputViewConstants.ApplicationTab: return ( <>Output as HTML to { applicationContext.name } tab</> );
        default: return <>Unsupported</>;
      }
    case OutputFormatConstants.Csv: return <>Output as CSV</>;
    case OutputFormatConstants.Xlsx: return <>Output as XLSX</>;
    default: return <>Unsupported</>;
  }
};
