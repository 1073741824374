/** @jsxImportSource @emotion/react */
import React from "react";
import { HelpText } from "components/help-text/HelpText";
import { ReportTypeConstants } from "../../Model";
import { css } from "@emotion/react";

const styles = {
  reportTypeSelector: css`
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
        span {
          vertical-align: middle;
        }
      }
  
      input {
        padding: 0;
        margin: 0 5px 0 0;
        width: auto;
        vertical-align: middle;
      }
    }
  `
};

export const ReportTypeSelector = ( { helpId, reportType, onChangeReportType }: { helpId: string; reportType: ReportTypeConstants; onChangeReportType: ( demoRank: ReportTypeConstants ) => void; } ): JSX.Element => (
  <div css={ styles.reportTypeSelector }>
    <fieldset>
      <legend><label>Type <HelpText helpId={ helpId } /></label></legend>
      <label><input type="radio" radioGroup="reportType" checked={ reportType === ReportTypeConstants.ImportsAndLocal } onChange={ () => onChangeReportType( ReportTypeConstants.ImportsAndLocal ) } title="Imports/Local" /><span>Imports/Local</span></label>
      <label><input type="radio" radioGroup="reportType" checked={ reportType === ReportTypeConstants.Imports } onChange={ () => onChangeReportType( ReportTypeConstants.Imports ) } title="Imports" /><span>Imports</span></label>
    </fieldset>
  </div>
);
