/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { RevenueAmountConstants, RevenueCollapseConstants, RevenueDurationConstants } from "model/Model";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const RevenueDescription = ( { revenueAmount, revenueDuration, revenueCollapse, warningMessage }: { revenueAmount: RevenueAmountConstants; revenueDuration: RevenueDurationConstants; revenueCollapse: RevenueCollapseConstants; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    if ( revenueAmount === RevenueAmountConstants.None && revenueDuration === RevenueDurationConstants.None ) {
      results.push( ( <span key={ 0 } css={ styles.displayGroupSpace }>None.</span> ) );
    } else {
      switch ( revenueAmount ) {
        case RevenueAmountConstants.None: results.push( ( <span key={ 1 } css={ styles.displayGroupSpace }>No Revenue,</span> ) ); break;
        case RevenueAmountConstants.Total: results.push( ( <span key={ 1 } css={ styles.displayGroupSpace }>Total Revenue,</span> ) ); break;
        case RevenueAmountConstants.Detail: results.push( ( <span key={ 1 } css={ styles.displayGroupSpace }>Revenue Detail,</span> ) ); break;
      }

      switch ( revenueDuration ) {
        case RevenueDurationConstants.None: results.push( ( <span key={ 2 } css={ styles.displayGroupSpace }>No Seconds,</span> ) ); break;
        case RevenueDurationConstants.Total: results.push( ( <span key={ 2 } css={ styles.displayGroupSpace }>Total Seconds,</span> ) ); break;
        case RevenueDurationConstants.Detail: results.push( ( <span key={ 2 } css={ styles.displayGroupSpace }>Seconds Detail,</span> ) ); break;
      }

      switch ( revenueCollapse ) {
        case RevenueCollapseConstants.Sum: results.push( ( <span key={ 3 } css={ styles.displayGroupSpace }>Collapse Sum.</span> ) ); break;
        case RevenueCollapseConstants.Average: results.push( ( <span key={ 3 } css={ styles.displayGroupSpace }>Collapse Average.</span> ) ); break;
      }
    }
    return results;
  }, [ revenueAmount, revenueCollapse, revenueDuration ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
