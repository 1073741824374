/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

const styles = {
  separator: css`
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 26px;
  border-bottom: 1px solid silver;
  line-height: 16px;
  vertical-align: top;
  list-style-type: none;
  padding: 3px 5px;
  
  &:hover {
    background-color: initial;
    cursor: default;
  }
  `,
  separatorWithTopBorder: css`
  list-style-type: none;
  padding: 3px 3px;
  margin-top: 3px;
  margin-left: 24px;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  line-height: 16px;
  vertical-align: top;

  &:hover {
    background-color: initial;
    cursor: default;
  }  
`,
  labelWWrapper: css`
    padding-bottom:3px;
  `
};

export const Separator = ( { label, topBorder }: { label?: string; topBorder: boolean; } ): JSX.Element => ( <>
  <li css={ topBorder ? styles.separatorWithTopBorder : styles.separator }><div css={ styles.labelWWrapper }>{ label }</div></li>
</> );