/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useMemo } from "react";
import { formatDateAsEnGb } from "Utilities";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  /*
  const country = css`
  display: inline-block;
  width: 180px;
  `;
  */

  warning: css`
    color: #cc0000;
  `
};

export const DateTimeDescription = ( { fromDate, toDate, warningMessage }: { fromDate?: number; toDate?: number; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    if ( !fromDate || !toDate || isNaN( new Date( fromDate ).getDate() ) || isNaN( new Date( toDate ).getDate() ) ) {
      results.push( ( <span css={ [ styles.displayGroupSpace, styles.warning ] } key="1" >Date cannot be empty</span> ) );
    }
    else {
      fromDate && results.push( ( <span css={ styles.displayGroupSpace } key="1" >{ formatDateAsEnGb( fromDate ) }</span> ) );
      fromDate && toDate && results.push( ( <span css={ styles.displayGroupSpace } key="2" > - </span> ) );
      toDate && results.push( ( <span css={ styles.displayGroupSpace } key="3" >{ formatDateAsEnGb( toDate ) }</span> ) );
    }

    return results;
  }, [ fromDate, toDate ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
