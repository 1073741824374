/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo } from "react";
import { HelpText } from "components/help-text/HelpText";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { QualifyLocalGenreDisplayConstants } from "constants/QualifyLocalGenreDisplayConstants";
import { Country, LocalGenre } from "model/Model";
import { Selectable, chunk } from "Utilities";
import { css } from "@emotion/react";

const styles = {
  localGenreSelector: css`
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
      }
  
      input {
        padding: 0;
        margin: 0 5px 0 0;
        width: auto;
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
      }
    }
  `,

  optionContainer: css`
    overflow-x: auto;
    height: 300px;
  `,

  localGenreColumn: css`
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
 `,

  measure: css`
   line-height: 20px;
  `
};

const displayAsOptions = [
  { value: QualifyLocalGenreDisplayConstants.Description, name: "Description" },
  { value: QualifyLocalGenreDisplayConstants.Id, name: "Id" },
  { value: QualifyLocalGenreDisplayConstants.Raw, name: "Raw" }
];

export const LocalGenreSelector = ( { localGenreDisplay, selectedCountries, includeLocalGenreDisplayOptions, onChangeLocalGenreDisplay, onChangeQualifyLocalGenreIsSelected }: { localGenreDisplay: QualifyLocalGenreDisplayConstants; includeLocalGenreDisplayOptions: boolean; selectedCountries: Array<Country & { localGenres: Array<Selectable<LocalGenre>>; }>; onChangeLocalGenreDisplay( value: QualifyLocalGenreDisplayConstants ): void; onChangeQualifyLocalGenreIsSelected( countryId: number, value: number, isSelected: boolean ): void; } ): JSX.Element => {
  return (
    <div css={ styles.localGenreSelector }>
      <fieldset>
        <legend><label>Local Genre</label></legend>
        { includeLocalGenreDisplayOptions && <RadioGroupSelector label="Display As" radioGroup="qualify_local_genre_display" helpId="qualify_local_genre_display" value={ localGenreDisplay } items={ displayAsOptions } onChangeItemSelected={ onChangeLocalGenreDisplay } ></RadioGroupSelector> }
        {
          ( selectedCountries ?? [] ).map( m => {
            const countryLocalGenreOptions: Array<{ countryId: number; value: number; name: string; isSelected: boolean; }> = [];
            for ( const lg of m.localGenres ) {
              countryLocalGenreOptions.push( { countryId: m.id, value: lg.id, name: lg.name, isSelected: lg.isSelected } );
            }

            return <CountryLocalGenresRadioGroupSelector key={ `country_local_genre_${ m.id }` } helpId="" label={ m.name } options={ countryLocalGenreOptions } onChange={ onChangeQualifyLocalGenreIsSelected } ></CountryLocalGenresRadioGroupSelector>;
          } )
        }
      </fieldset>
    </div>
  );
};

const CountryLocalGenresRadioGroupSelector = ( { label, helpId, options, onChange }: { label: string; helpId: string; options: Array<{ value: number; countryId: number; name: string; isSelected: boolean; isEnabled?: boolean; }>; onChange( countryId: number, value: number, isSelected: boolean ): void; } ): JSX.Element => {
  const hasSelectedTypes = options.filter( m => m.isSelected ).length > 0;

  const uncheckAll = useCallback( (): void => { options.map( m => onChange( m.countryId, m.value, false ) ); }, [ onChange, options ] );

  const chunkSize = useMemo( () => {
    if ( options.length > 70 ) {
      return Math.floor( ( options.length + 1 ) / 5 );
    }

    return Math.max( Math.floor( ( ( options.length + 1 ) / 7 ) + 1 ), 1 );
  }, [ options.length ] );

  return (
    <div>
      <fieldset>
        <legend><label>{ label } <HelpText helpId={ helpId } /></label></legend>
        <div><label><input type="radio" onChange={ () => uncheckAll() } checked={ !hasSelectedTypes }></input>All</label></div>
        {
          chunk( options, chunkSize ).map( ( m, i ) => {
            return <div key={ i } css={ styles.localGenreColumn }>
              {
                m.map( ( o, i ) => ( <CheckSelector key={ i } { ...o } onChange={ onChange } /> ) )
              }
            </div>;
          } )
        }
      </fieldset >
    </div >
  );
};

const CheckSelector = ( { value, countryId, name, isSelected, isEnabled = true, onChange }: { value: number; countryId: number; name: string; isSelected: boolean; isEnabled?: boolean; onChange: ( countryId: number, value: number, isSelected: boolean ) => void; } ) => {
  return (
    <div data-key={ value } css={ styles.measure } >
      <div>
        <input aria-label={ name } type="checkbox" disabled={ !isEnabled } checked={ isSelected } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => { onChange( countryId, value, e.currentTarget.checked ); } } />
        <span onSelect={ ( e ) => { e.preventDefault(); return false; } }>{ name }</span>
      </div>
    </div>
  );
};
