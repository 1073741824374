import { createContext, useCallback, useContext, useMemo, useState } from "react";

type UserContextType = {
  token: string;
  userId: number;
  username: string;
  name: string;
  logout: () => void;
};

const UserContext = createContext<UserContextType>( {} as UserContextType );

const getTokenFromSession = () => {
  return sessionStorage.getItem( "token" ) ?? "";
};

const getUserIdFromSession = () => {
  return parseInt( sessionStorage.getItem( "userId" ) ?? "-1" );
};

const getUsernameFromSession = () => {
  return sessionStorage.getItem( "username" ) ?? "unknown";
};

const getNameFromSession = () => {
  return sessionStorage.getItem( "name" ) ?? "unknown";
};

export const logoutFromSessionStorage = () => {
  sessionStorage.removeItem( "userId" );
  sessionStorage.removeItem( "token" );
  sessionStorage.removeItem( "username" );
  sessionStorage.removeItem( "name" );
};

export const UserProvider = ( { children }: { children: React.ReactNode; } ) => {
  const [ token, setToken ] = useState<string>( getTokenFromSession );
  const [ userId, setUserId ] = useState<number>( getUserIdFromSession );
  const [ username, setUsername ] = useState<string>( getUsernameFromSession );
  const [ name, setName ] = useState<string>( getNameFromSession );

  const logout = useCallback( () => {
    console.debug( "logout" );
    logoutFromSessionStorage();
    setToken( "" );
    setUserId( -1 );
    setUsername( "" );
    setName( "" );
  }, [] );

  const state = useMemo( () => {
    return ( {
      userId,
      token,
      username,
      name,
      logout
    } );
  }, [ logout, token, userId, username, name ] );

  return <UserContext.Provider value={ state }>{ children }</UserContext.Provider>;
};

export const useUser = () => useContext( UserContext );

export const getSessionCredentials = () => ( {
  token: getTokenFromSession(),
  userId: getUserIdFromSession(),
  username: getUsernameFromSession(),
  name: getNameFromSession()
} );

export const setSessionCredentials = ( userId: number, token: string, username: string, name: string ) => {
  sessionStorage.setItem( "userId", userId.toString() );
  sessionStorage.setItem( "token", token );
  sessionStorage.setItem( "username", username );
  sessionStorage.setItem( "name", name );
};
