import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { Model, RevenueEstimatorSourceModel } from "../Model";
import thunkMiddleware from "redux-thunk";
import { parseDateOrUndefined } from "Utilities";
import countriesSlice from "reducers/countriesSlice";
import outputSlice from "reducers/outputSlice";
import stationsSlice from "reducers/stationsSlice";
import reportTypeSlice from "../components/report-type-selector/reportTypeSlice";
import dateTimeReducers from "../components/date-time-selector/dateTimeReducers";
import qualifySlice from "../components/qualify-selector/qualifySlice";
import displaySlice from "../components/display-selector/displaySlice";

const initializeModel = ( data: RevenueEstimatorSourceModel ): Model => {
  const model = {
    ...data,
    dateTime: {
      ...data.dateTime,
      fromDate: parseDateOrUndefined( data.dateTime.fromDate ),
      toDate: parseDateOrUndefined( data.dateTime.toDate )
    }
  };

  // FUTURE: more validation
  if ( model.countries === undefined )                                  /* */ throw new Error( "RevenueEstimator.countries is undefined" );
  if ( model.dateTime === undefined )                                   /* */ throw new Error( "RevenueEstimator.dateTime is undefined" );
  if ( model.dateTime.duration === undefined )                          /* */ throw new Error( "RevenueEstimator.dateTime.duration is undefined" );
  if ( model.dateTime.days === undefined )                              /* */ throw new Error( "RevenueEstimator.dateTime.days is undefined" );
  if ( model.dateTime.fromTime === undefined )                          /* */ throw new Error( "RevenueEstimator.dateTime.fromTime is undefined" );
  if ( model.dateTime.toTime === undefined )                            /* */ throw new Error( "RevenueEstimator.dateTime.toTime is undefined" );
  if ( model.dateTime.useDayParts === undefined )                       /* */ throw new Error( "RevenueEstimator.dateTime.useDayParts is undefined" );
  if ( model.dateTime.dayParts === undefined )                          /* */ throw new Error( "RevenueEstimator.dateTime.dayParts is undefined" );
  if ( model.display === undefined )                                    /* */ throw new Error( "RevenueEstimator.display is undefined" );
  if ( model.display.displayRankTitles === undefined )                  /* */ throw new Error( "RevenueEstimator.display.displayRankTitles is undefined" );
  if ( model.display.displayTitlesDisplayed === undefined )             /* */ throw new Error( "RevenueEstimator.display.displayTitlesDisplayed is undefined" );
  if ( model.output === undefined )                                     /* */ throw new Error( "RevenueEstimator.output is undefined" );
  if ( model.output.format === undefined )                              /* */ throw new Error( "RevenueEstimator.output.format is undefined" );
  if ( model.output.view === undefined )                                /* */ throw new Error( "RevenueEstimator.output.view is undefined" );
  if ( model.qualify === undefined )                                    /* */ throw new Error( "RevenueEstimator.qualify is undefined" );
  if ( model.qualify.selectedTypes === undefined )                      /* */ throw new Error( "RevenueEstimator.qualify.selectedTypes is undefined" );
  if ( model.qualify.selectedClassOnes === undefined )                  /* */ throw new Error( "RevenueEstimator.qualify.selectedClassOnes is undefined" );
  if ( model.qualify.selectedClassTwos === undefined )                  /* */ throw new Error( "RevenueEstimator.qualify.selectedClassTwos is undefined" );
  if ( model.qualify.selectedFormats === undefined )                    /* */ throw new Error( "RevenueEstimator.qualify.selectedFormats is undefined" );
  if ( model.qualify.selectedDistributors === undefined )               /* */ throw new Error( "RevenueEstimator.qualify.selectedDistributors is undefined" );
  if ( model.qualify.selectedProductionCountries === undefined )        /* */ throw new Error( "RevenueEstimator.qualify.selectedProductionCountries is undefined" );
  if ( model.qualify.selectedProductionCountryPositions === undefined ) /* */ throw new Error( "RevenueEstimator.qualify.selectedProductionCountryPositions is undefined" );
  if ( model.qualify.selectedLocalGenres === undefined )                /* */ throw new Error( "RevenueEstimator.qualify.selectedLocalGenres is undefined" );
  if ( model.reportType === undefined )                                 /* */ throw new Error( "RevenueEstimator.reportType is undefined" );
  if ( model.stations === undefined )                                   /* */ throw new Error( "RevenueEstimator.stations is undefined" );

  return model;
};

export const getStore = ( initialData: RevenueEstimatorSourceModel ) => configureStore( {
  preloadedState: initializeModel( initialData ),
  reducer: {
    countries: countriesSlice,
    stations: stationsSlice,
    dateTime: dateTimeReducers,
    output: outputSlice,
    reportType: reportTypeSlice,
    qualify: qualifySlice,
    display: displaySlice
  },
  middleware: [ thunkMiddleware ]
} );

type StoreType = ReturnType<typeof getStore>;

export type AppDispatch = StoreType[ "dispatch" ];
export type RootState = ReturnType<StoreType[ "getState" ]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
