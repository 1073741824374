/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { css } from "@emotion/react";
import { ReportTypeConstants } from "apps/ProgramRanking/Model";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const ReportTypeDescription = ( { reportType, warningMessage }: { reportType: ReportTypeConstants; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    const reportTypeDescription = ( ( value ): string => {
      switch ( value ) {
        case ReportTypeConstants.Imports: return "Imports Only";
        case ReportTypeConstants.ImportsAndFormats: return "Imports and Formats";
        case ReportTypeConstants.ImportsAndLocal: return "Imports and Local";
        case ReportTypeConstants.LocalOnly: return "Local Only";
        case ReportTypeConstants.All: return "All";
        case ReportTypeConstants.QuarterHour: return "Quarter Hour";
        case ReportTypeConstants.Error: return "Error";
      }
    } )( reportType );

    results.push( ( <span css={ styles.displayGroupSpace } key={ 0 }>{ reportTypeDescription }</span> ) );

    return results;
  }, [ reportType ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  return ( <>{ parts }</> );
};
