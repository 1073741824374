/** @jsxImportSource @emotion/react */
import React from "react";
import { DisplayColumn, DisplaySortedByConstants } from "../../Model";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { DisplayColumnSelector } from "./display-column-selector/DisplayColumnSelector";
import { Selectable } from "Utilities";

const displaySortedByOptions = [
  { value: DisplaySortedByConstants.DistributorCountry, name: "Distributor/Country" },
  { value: DisplaySortedByConstants.CountryDistributor, name: "Country/Distributor" }
];

export const DisplaySelector = ( { displayColumns, displaySortedBy, onChangeDisplayColumnIsSelected, onChangeDisplaySortedBy }: { displayColumns: Array<Selectable<DisplayColumn>>; displaySortedBy: DisplaySortedByConstants; onChangeDisplayColumnIsSelected: ( id: string, isSelected: boolean ) => void; onChangeDisplaySortedBy: ( value: DisplaySortedByConstants ) => void; } ): JSX.Element => {
  return ( <>
    <DisplayColumnSelector helpId="display_column" displayColumns={ displayColumns } onChangeDisplayColumnIsSelected={ onChangeDisplayColumnIsSelected } />
    <RadioGroupSelector label="Sorted By" helpId="display_sorted_by" radioGroup="display_sorted_by" value={ displaySortedBy } items={ displaySortedByOptions } onChangeItemSelected={ onChangeDisplaySortedBy } />
  </> );
};
