/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { AverageCollapsePeriodConstants, AverageTitleConstants, YesNoConstants } from "model/Model";
import { combineWithinItems } from "./AverageSelector";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

const combineWithinNamesById: Record<number, string> = {};
for ( const c of combineWithinItems ) {
  combineWithinNamesById[ c.value ] = c.name;
}

export const AverageDescription = ( { collapsePeriod, title, breakoutSeasons, breakoutOriginalRepeat, combineWithin, warningMessage }: { collapsePeriod: AverageCollapsePeriodConstants; title: AverageTitleConstants; breakoutSeasons: YesNoConstants; breakoutOriginalRepeat: YesNoConstants; combineWithin: number; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    const averageCollapsePeriodLabel = ( ( collapsePeriod ) => {
      switch ( collapsePeriod ) {
        case AverageCollapsePeriodConstants.IndividualAirings: return "Individual Airings.";
        case AverageCollapsePeriodConstants.Daily: return "Daily -";
        case AverageCollapsePeriodConstants.WeeklyMonSun: return "Weekly Mon-Sun -";
        case AverageCollapsePeriodConstants.WeeklySunSat: return "Weekly Sun-Sat -";
        case AverageCollapsePeriodConstants.Monthly: return "Monthly -";
        case AverageCollapsePeriodConstants.DayOfWeek: return "Day of Week -";
        case AverageCollapsePeriodConstants.Period: return "Period -";
        default: return "";
      }
    } )( collapsePeriod );

    results.push( ( <span key="average_collapse_period" css={ styles.displayGroupSpace }>{ averageCollapsePeriodLabel }</span> ) );

    if ( collapsePeriod !== AverageCollapsePeriodConstants.IndividualAirings ) {
      switch ( title ) {
        case AverageTitleConstants.OriginalThenLocal: results.push( ( <span css={ styles.displayGroupSpace } key={ "average_title" } >Original then local titles</span> ) ); break;
        case AverageTitleConstants.LocalOnly: results.push( ( <span css={ styles.displayGroupSpace } key={ "average_title" } >Local titles</span> ) ); break;
        case AverageTitleConstants.All: results.push( ( <span css={ styles.displayGroupSpace } key={ "average_title" } >All titles</span> ) ); break;
      }

      results.push( ( <span css={ styles.displayGroupSpace } key={ "average_combine_within" } >combined within { combineWithinNamesById[ combineWithin ] }.</span> ) );
    }

    if ( title === AverageTitleConstants.OriginalThenLocal ) {
      if ( breakoutSeasons === YesNoConstants.Yes && breakoutOriginalRepeat === YesNoConstants.Yes ) {
        results.push( ( <span css={ styles.displayGroupSpace } key={ "breakout" } >Breakout Seasons, Original/Repeat.</span> ) );
      } else if ( breakoutSeasons === YesNoConstants.Yes && breakoutOriginalRepeat === YesNoConstants.No ) {
        results.push( ( <span css={ styles.displayGroupSpace } key={ "breakout" } >Breakout Seasons.</span> ) );
      } else if ( breakoutSeasons === YesNoConstants.No && breakoutOriginalRepeat === YesNoConstants.Yes ) {
        results.push( ( <span css={ styles.displayGroupSpace } key={ "breakout" } >Breakout Original/Repeat.</span> ) );
      }
    }
    return results;
  }, [ breakoutOriginalRepeat, breakoutSeasons, collapsePeriod, combineWithin, title ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
