/** @jsxImportSource @emotion/react */
import React from "react";
import { HelpText } from "components/help-text/HelpText";
import { DemoTypeConstants, Measure } from "model/Model";
import { Selectable } from "Utilities";
import { css } from "@emotion/react";
import { BenchmarkDemoTypeOption } from "../../../Model";

const styles = {
  demoTypeSelector: css`
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
      }
  
      input {
        padding: 0;
        margin: 0;
        margin-right: 4px;
        width: auto;
      }
    }
  `,

  measure: css`
    display: inline-block;
    padding: 4px;
    margin: 10px;
    border: solid 1px black;
    vertical-align: top;
    user-select: none;
    background-color: white;
      
    input {
      padding: 0;
      margin: 0;
      margin-right: 10px;
      width: auto;
    }
  
    padding-left: 11px;
    padding-right: 11px;    
  `
};

export const BenchmarkDemoTypeSelector = ( props: { helpId: string; typeOptions: Array<BenchmarkDemoTypeOption>; count: number; measures: Array<Selectable<Measure>>; onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => void; } ): JSX.Element => (
  <div css={ styles.demoTypeSelector }>
    <fieldset>
      <legend><label>Demo types <HelpText helpId={ props.helpId } /></label></legend>
      { props.typeOptions.map( m => {
        const isMeasureSelected = props.measures.find( n => n.value === m.value );
        const enableCheckSelector = props.count > 0 && ( isMeasureSelected?.isSelected ?? false );
        return ( <CheckSelector key={ m.value } { ...m } { ...props } isEnabled={ enableCheckSelector } /> );
      } )
      }
    </fieldset>
  </div>
);

const CheckSelector = ( { value, name, isSelected, isEnabled = true, onChangeDemoTypeSelected }: { value: DemoTypeConstants; name: string; isSelected: boolean; isEnabled?: boolean; onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => void; } ) => {
  return (
    <div data-key={ value } css={ styles.measure } >
      <div>
        <input aria-label={ name } type="checkbox" disabled={ !isEnabled } checked={ isSelected } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeDemoTypeSelected( value, e.currentTarget.checked ) } />
        <span onSelect={ ( e ) => { e.preventDefault(); return false; } }>{ name }</span>
      </div>
    </div>
  );
};
