import { DaysConstants, DemoSelectionConstants, DemoTypeConstants, Output } from "model/Model";
import { Selectable } from "Utilities";

export type Model = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demoSelection: DemoSelectionConstants;
  demos: Array<{ id: number; demos: Array<number>; }>;
  measures: Array<Selectable<{ value: DemoTypeConstants; }>>;
  dateTime: DateTime;
  // FUTURE: display: Display;
  output: Output;
};

export type DateTimeSource = {
  fromDate?: string;
  toDate?: string;
  days: Array<DaysConstants>;
  minimumDuration: MinimumDurationConstants;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};

export type WeeklyChannelViewSourceModel = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  demoSelection: DemoSelectionConstants;
  measures: Array<Selectable<{ value: DemoTypeConstants; }>>;
  dateTime: DateTimeSource;
  // FUTURE: display: Display;
  output: Output;
};

export enum MinimumDurationConstants {
  All = 4,
  TenMinutes = 10,
  FifteenMinutes = 15,
  ThirtyMinutes = 30,
  SixtyMinutes = 60
}

export type DateTime = {
  fromDate?: number;
  toDate?: number;
  days: Array<DaysConstants>;
  minimumDuration: MinimumDurationConstants;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};