/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { TitleOriginalOrLocalConstants } from "model/Model";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
  margin-right: 5px;
  `,

  warning: css`
  color: #cc0000;
  `
};

export const TitleDescription = ( { originalOrLocal, titleNames, numberOfCharactersToCompare, warningMessage }: { originalOrLocal: TitleOriginalOrLocalConstants; titleNames: Array<{ title: string; isSelected: boolean; }>; numberOfCharactersToCompare: number; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    const selectedTitles = titleNames?.filter( m => m.isSelected ).map( m => m.title );
    const originalOrLocalCaption = ( ( value: TitleOriginalOrLocalConstants, selectedTitleCount: number ): string => {
      switch ( value ) {
        case TitleOriginalOrLocalConstants.Local: return selectedTitleCount > 1 ? "Locals:" : "Local:";
        case TitleOriginalOrLocalConstants.Original: return selectedTitleCount > 1 ? "Originals:" : "Original:";
        default: return "";
      }
    } );

    if ( selectedTitles?.length === 0 ) {
      results.push( ( <span key={ 2 } css={ styles.displayGroupSpace }>None Selected,</span> ) );
    } else {

      results.push( ( <span key={ 2 } css={ styles.displayGroupSpace }>{ originalOrLocalCaption( originalOrLocal, selectedTitles?.length ) }</span> ) );
      results.push( ( <span key={ 3 } css={ styles.displayGroupSpace }>{ selectedTitles?.join( ", " ) },</span> ) );
    }

    results.push( ( <span key={ 4 } css={ styles.displayGroupSpace }> { numberOfCharactersToCompare === 0 ? "All characters." : `${ numberOfCharactersToCompare } characters.` }</span> ) );
    return results;
  }, [ numberOfCharactersToCompare, originalOrLocal, titleNames ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
