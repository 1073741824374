import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Display, DisplayRankTitlesConstants, DisplayTitlesDisplayedConstants } from "../../Model";

const displaySlice = createSlice( {
  name: "display",
  initialState: {
    displayRankTitles: DisplayRankTitlesConstants.Telecasts,
    displayTitlesDisplayed: DisplayTitlesDisplayedConstants.Top3
  } as Display,
  reducers: {
    ChangeDisplayRankTitles( state, action: PayloadAction<DisplayRankTitlesConstants> ) {
      state.displayRankTitles = action.payload;
    },

    ChangeDisplayTitlesDisplayed( state, action: PayloadAction<DisplayTitlesDisplayedConstants> ) {
      state.displayTitlesDisplayed = action.payload;
    }
  }
} );

export const { ChangeDisplayRankTitles, ChangeDisplayTitlesDisplayed } = displaySlice.actions;

export default displaySlice.reducer;