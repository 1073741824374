/** @jsxImportSource @emotion/react */
import React from "react";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { DisplayPrimarySheetSelector } from "./display-primary-sheet-selector/DisplayPrimarySheetSelector";
import { DisplayCompetitionBreakoutConstants, DisplayDetailSheetConstants, DisplayDetailSheetOption, DisplayLeadInLeadOutBreakoutConstants, DisplayPrimarySheetConstants, DisplayPrimarySheetOption, DisplayTimeConstants } from "../../Model";
import { DisplayDetailSheetSelector } from "./display-detail-sheet-selector/DisplayDetailSheetSelector";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";

const displayTimeOptions = [ { value: DisplayTimeConstants.TotalMinutes, name: "Total Minutes" }, { value: DisplayTimeConstants.HoursMinutes, name: "Hours:Minutes" }, { value: DisplayTimeConstants.HoursMinutesSeconds, name: "Hours:Minutes:Seconds" } ];

const displayCompetitionBreakoutOptions = [
  { value: DisplayCompetitionBreakoutConstants.None, name: "None" },
  { value: DisplayCompetitionBreakoutConstants.FiftyPercentOrGreater, name: "50% or >" },
  { value: DisplayCompetitionBreakoutConstants.FortyPercentOrGreater, name: "40% or >" },
  { value: DisplayCompetitionBreakoutConstants.ThirtyPercentOrGreater, name: "30% or >" },
  { value: DisplayCompetitionBreakoutConstants.TwentyPercentOrGreater, name: "20% or >" },
  { value: DisplayCompetitionBreakoutConstants.All, name: "All" }
];

const displayLiLoBreakoutOptions = [
  { value: DisplayLeadInLeadOutBreakoutConstants.None, name: "None" },
  { value: DisplayLeadInLeadOutBreakoutConstants.FiftyPercentOrGreater, name: "50% or >" },
  { value: DisplayLeadInLeadOutBreakoutConstants.FortyPercentOrGreater, name: "40% or >" },
  { value: DisplayLeadInLeadOutBreakoutConstants.ThirtyPercentOrGreater, name: "30% or >" },
  { value: DisplayLeadInLeadOutBreakoutConstants.TwentyPercentOrGreater, name: "20% or >" },
  { value: DisplayLeadInLeadOutBreakoutConstants.All, name: "All" }
];

export const DisplaySelector = ( { displayPrimarySheet, displayCompetitionBreakout, displayLiLoBreakout, displayDetailSheet, displayTime, onChangeDisplayPrimarySheetIsSelected, onChangeDisplayCompetitionBreakout, onChangeDisplayLiLoBreakout, onChangeDisplayDetailSheetIsSelected, onChangeDisplayTime }: { displayPrimarySheet: Array<DisplayPrimarySheetOption>; displayCompetitionBreakout: DisplayCompetitionBreakoutConstants; displayLiLoBreakout: DisplayLeadInLeadOutBreakoutConstants; displayDetailSheet: Array<DisplayDetailSheetOption>; displayTime: DisplayTimeConstants; onChangeDisplayPrimarySheetIsSelected: ( value: DisplayPrimarySheetConstants, isSelected: boolean ) => void; onChangeDisplayCompetitionBreakout: ( value: DisplayCompetitionBreakoutConstants ) => void; onChangeDisplayLiLoBreakout: ( value: DisplayLeadInLeadOutBreakoutConstants ) => void; onChangeDisplayDetailSheetIsSelected: ( value: DisplayDetailSheetConstants, isSelected: boolean ) => void; onChangeDisplayTime: ( value: DisplayTimeConstants ) => void; } ): JSX.Element => ( <>
  <DisplayPrimarySheetSelector helpId="" displayPrimarySheetOptions={ displayPrimarySheet } onChangeDisplayPrimarySheetIsSelected={ onChangeDisplayPrimarySheetIsSelected }></DisplayPrimarySheetSelector>
  <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Competition Breakout" helpId="displayCompetitionBreakout" radioGroup="display_competition_breakout" value={ displayCompetitionBreakout } items={ displayCompetitionBreakoutOptions } onChangeItemSelected={ onChangeDisplayCompetitionBreakout } />
  <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="LiLo Breakout" helpId="displayLILOBreakout" radioGroup="display_lilo_breakout" value={ displayLiLoBreakout } items={ displayLiLoBreakoutOptions } onChangeItemSelected={ onChangeDisplayLiLoBreakout } />
  <DisplayDetailSheetSelector helpId="" displayDetailSheetOptions={ displayDetailSheet } onChangeDisplayDetailSheetIsSelected={ onChangeDisplayDetailSheetIsSelected }></DisplayDetailSheetSelector>
  <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Time" helpId="" radioGroup="display_time" value={ displayTime } items={ displayTimeOptions } onChangeItemSelected={ onChangeDisplayTime } />
</> );
