import { createAction, createReducer } from "@reduxjs/toolkit";

export const ChangeUseDayParts = createAction<boolean>( "ChangeUseDayParts" );

const useDayPartsReducers = createReducer(
  false,
  builder => {
    builder.addCase( ChangeUseDayParts, ( _, action ) => action.payload );
  }
);

export default useDayPartsReducers;