/** @jsxImportSource @emotion/react */
import React from "react";
import { HelpText } from "components/help-text/HelpText";
import { css } from "@emotion/react";
import { ReportTypeConstants } from "apps/ProgramAverageTimeSpent/Model";

const styles = {
  reportTypeSelector: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
      }
  
      input {
        padding: 0;
        margin: 0;
        margin-right: 4px;
        width: auto;
      }
    }
  `
};

export const ReportTypeSelector = ( { helpId, reportType, onChangeReportType }: { helpId: string; reportType: ReportTypeConstants; onChangeReportType: ( demoRank: ReportTypeConstants ) => void; } ): JSX.Element => {

  return (
    <div css={ styles.reportTypeSelector }>
      <fieldset>
        <legend><label>Type <HelpText helpId={ helpId } /></label></legend>
        <label><input type="radio" radioGroup="reportType" checked={ reportType === ReportTypeConstants.Imports } onChange={ () => onChangeReportType( ReportTypeConstants.Imports ) } title="Imports only" />Imports</label>
        <label><input type="radio" radioGroup="reportType" checked={ reportType === ReportTypeConstants.ImportsAndFormats } onChange={ () => onChangeReportType( ReportTypeConstants.ImportsAndFormats ) } title="Imports and Formats" />Imports and Formats</label>
        <label><input type="radio" radioGroup="reportType" checked={ reportType === ReportTypeConstants.ImportsAndLocal } onChange={ () => onChangeReportType( ReportTypeConstants.ImportsAndLocal ) } title="Imports and Local" />Imports and Local</label>
        <label><input type="radio" radioGroup="reportType" checked={ reportType === ReportTypeConstants.LocalOnly } onChange={ () => onChangeReportType( ReportTypeConstants.LocalOnly ) } title="Local Only" />Local Only</label>
        <label><input type="radio" radioGroup="reportType" checked={ reportType === ReportTypeConstants.All } onChange={ () => onChangeReportType( ReportTypeConstants.All ) } title="All" />All</label>
      </fieldset>
    </div>
  );
};
