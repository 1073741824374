import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DemoGroupByConstants } from "model/Model";

const demoGroupBySlice = createSlice( {
  name: "demoGroupBy",
  initialState: DemoGroupByConstants.demo as DemoGroupByConstants,
  reducers: {
    ChangeDemoGroupBy( _, action: PayloadAction<DemoGroupByConstants> ) { return action.payload; }
  }
} );

export const { ChangeDemoGroupBy } = demoGroupBySlice.actions;

export default demoGroupBySlice.reducer;