/** @jsxImportSource @emotion/react */
import React from "react";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { RevenueAmountConstants, RevenueCollapseConstants, RevenueDurationConstants } from "model/Model";

const revenueAmountItems = [
  { name: "None", value: RevenueAmountConstants.None },
  { name: "Total", value: RevenueAmountConstants.Total },
  { name: "Detail", value: RevenueAmountConstants.Detail }
];

const revenueDurationItems = [
  { name: "None", value: RevenueDurationConstants.None },
  { name: "Total", value: RevenueDurationConstants.Total },
  { name: "Detail", value: RevenueDurationConstants.Detail }
];

const revenueCollapseItems = [
  { name: "Sum", value: RevenueCollapseConstants.Sum },
  { name: "Average", value: RevenueCollapseConstants.Average }
];

export const RevenueSelector = ( { revenueAmount, revenueDuration, revenueCollapse, onChangeRevenueAmount, onChangeRevenueDuration, onChangeRevenueCollapse }: { revenueAmount: RevenueAmountConstants; revenueDuration: RevenueDurationConstants; revenueCollapse: RevenueCollapseConstants; onChangeRevenueAmount: ( value: RevenueAmountConstants ) => void; onChangeRevenueDuration: ( value: RevenueDurationConstants ) => void; onChangeRevenueCollapse: ( value: RevenueCollapseConstants ) => void; } ): JSX.Element => {
  return ( <>
    <RadioGroupSelector label="Revenue" radioGroup="revenue" helpId="revenueRevenue" items={ revenueAmountItems } value={ revenueAmount } onChangeItemSelected={ onChangeRevenueAmount } />
    <RadioGroupSelector label="Seconds" radioGroup="second" helpId="" items={ revenueDurationItems } value={ revenueDuration } onChangeItemSelected={ onChangeRevenueDuration } />
    <RadioGroupSelector label="Collapse" radioGroup="revenue_collapse" helpId="" isEnabled={ revenueAmount !== RevenueAmountConstants.None || revenueDuration !== RevenueDurationConstants.None } items={ revenueCollapseItems } value={ revenueCollapse } onChangeItemSelected={ onChangeRevenueCollapse } />
  </> );
};
