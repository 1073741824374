import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import countriesSlice from "reducers/countriesSlice";
import demoGroupBySlice from "reducers/demoGroupBySlice";
import demoSelectionSlice from "reducers/demoSelectionSlice";
import measuresSlice from "reducers/measuresSlice";
import averageSlice from "reducers/averageSlice";
import titleSlice from "reducers/titleReducer";
import revenueSlice from "../components/revenue-selector/revenueSlice";
import benchmarkSlice from "../components/benchmark-selector/benchmarkSlice";
import displaySlice from "../components/display-selector/displaySlice";
import outputSlice from "reducers/outputSlice";
import stationsSlice from "reducers/stationsSlice";
import demosSlice from "reducers/demosSlice";
import previewSlice from "../components/preview/previewSlice";
import dateTimeReducers from "../components/date-time-selector/dateTimeReducers";
import { Model, ProgramPerformanceSourceModel } from "../Model";
import thunkMiddleware from "redux-thunk";
import { getFirstOfMonthWithFortyFiveDayOffset, parseDate, parseDateOrUndefined } from "Utilities";
import unselectedIndicesSlice from "../components/unselectedIndicesSlice";

const initializeModel = ( data: ProgramPerformanceSourceModel ): Model => {
  const model = {
    ...data,
    dateTime: {
      ...data.dateTime,
      fromDate: parseDateOrUndefined( data.dateTime.fromDate ),
      toDate: parseDateOrUndefined( data.dateTime.toDate )
    },
    benchmark: {
      ...data.benchmark,
      fromDate: ( parseDate( data.benchmark.fromDate ) ?? getFirstOfMonthWithFortyFiveDayOffset() ).valueOf(),
      toDate: ( parseDate( data.benchmark.toDate ) ?? getFirstOfMonthWithFortyFiveDayOffset() ).valueOf()
    },
    // TODO: preview object needs work
    preview: {
      isOpen: false,
      isLoading: false,
      rows: [],
      captions: [],
      demoTypes: [],
      demos: []
    }
  };

  // FUTURE: more validation
  if ( model.average === undefined )                                 /* */ throw new Error( "ProgramPerformance.average is undefined" );
  if ( model.average.collapsePeriod === undefined )                  /* */ throw new Error( "ProgramPerformance.average.collapsePeriod is undefined" );
  if ( model.average.combineWithin === undefined )                   /* */ throw new Error( "ProgramPerformance.average.combineWithin is undefined" );
  if ( model.average.title === undefined )                           /* */ throw new Error( "ProgramPerformance.average.title is undefined" );
  if ( model.average.breakoutSeasons === undefined )                 /* */ throw new Error( "ProgramPerformance.average.breakoutSeasons is undefined" );
  if ( model.average.breakoutOriginalRepeat === undefined )          /* */ throw new Error( "ProgramPerformance.average.breakoutOriginalRepeat is undefined" );
  if ( model.average.localRepeat === undefined )                     /* */ throw new Error( "ProgramPerformance.average.localRepeat is undefined" );
  if ( model.benchmark === undefined )                               /* */ throw new Error( "ProgramPerformance.benchmark is undefined" );
  if ( model.benchmark.dates === undefined )                         /* */ throw new Error( "ProgramPerformance.benchmark.dates is undefined" );
  if ( model.benchmark.selectedQuarters === undefined )              /* */ throw new Error( "ProgramPerformance.benchmark.selectedQuarters is undefined" );
  if ( model.benchmark.fromDate === undefined )                      /* */ throw new Error( "ProgramPerformance.benchmark.fromDate is undefined" );
  if ( model.benchmark.toDate === undefined )                        /* */ throw new Error( "ProgramPerformance.benchmark.toDate is undefined" );
  if ( model.benchmark.timePeriod === undefined )                    /* */ throw new Error( "ProgramPerformance.benchmark.timePeriod is undefined" );
  if ( model.benchmark.timePeriodFromTime === undefined )            /* */ throw new Error( "ProgramPerformance.benchmark.timePeriodFromTime is undefined" );
  if ( model.benchmark.timePeriodToTime === undefined )              /* */ throw new Error( "ProgramPerformance.benchmark.timePeriodToTime is undefined" );
  if ( model.benchmark.benchmarkDayOfWeekType === undefined )        /* */ throw new Error( "ProgramPerformance.benchmark.benchmarkDayOfWeekType is undefined" );
  if ( model.benchmark.benchmarkDays === undefined )                 /* */ throw new Error( "ProgramPerformance.benchmark.benchmarkDays is undefined" );
  if ( model.countries === undefined )                               /* */ throw new Error( "ProgramPerformance.countries is undefined" );
  if ( model.dateTime === undefined )                                /* */ throw new Error( "ProgramPerformance.dateTime is undefined" );
  if ( model.dateTime.days === undefined )                           /* */ throw new Error( "ProgramPerformance.dateTime.days is undefined" );
  if ( model.dateTime.transmissionDuration === undefined )           /* */ throw new Error( "ProgramPerformance.dateTime.transmissionDuration is undefined" );
  if ( model.dateTime.fromTime === undefined )                       /* */ throw new Error( "ProgramPerformance.dateTime.fromTime is undefined" );
  if ( model.dateTime.toTime === undefined )                         /* */ throw new Error( "ProgramPerformance.dateTime.toTime is undefined" );
  if ( model.dateTime.useDayParts === undefined )                    /* */ throw new Error( "ProgramPerformance.dateTime.useDayParts is undefined" );
  if ( model.dateTime.dayParts === undefined )                       /* */ throw new Error( "ProgramPerformance.dateTime.dayParts is undefined" );
  if ( model.demoGroupBy === undefined )                             /* */ throw new Error( "ProgramPerformance.demoGroupBy is undefined" );
  if ( model.demoSelection === undefined )                           /* */ throw new Error( "ProgramPerformance.demoSelection is undefined" );
  if ( model.demos === undefined )                                   /* */ throw new Error( "ProgramPerformance.demos is undefined" );
  if ( model.display === undefined )                                 /* */ throw new Error( "ProgramPerformance.display is undefined" );
  if ( model.display.selectedDisplayPrimarySheet === undefined )     /* */ throw new Error( "ProgramPerformance.display.displayPrimarySheet is undefined" );
  if ( model.display.displayCompetitionBreakout === undefined )      /* */ throw new Error( "ProgramPerformance.display.displayCompetitionBreakout is undefined" );
  if ( model.display.displayLiLoBreakout === undefined )             /* */ throw new Error( "ProgramPerformance.display.displayLiLoBreakout is undefined" );
  if ( model.display.selectedDisplayDetailSheet === undefined )      /* */ throw new Error( "ProgramPerformance.display.displayDetailSheet is undefined" );
  if ( model.display.displayTime === undefined )                     /* */ throw new Error( "ProgramPerformance.display.displayTime is undefined" );
  if ( model.measures === undefined )                                /* */ throw new Error( "ProgramPerformance.measures is undefined" );
  if ( model.output === undefined )                                  /* */ throw new Error( "ProgramPerformance.output is undefined" );
  if ( model.output.format === undefined )                           /* */ throw new Error( "ProgramPerformance.output.format is undefined" );
  if ( model.output.view === undefined )                             /* */ throw new Error( "ProgramPerformance.output.view is undefined" );
  if ( model.revenue === undefined )                                 /* */ throw new Error( "ProgramPerformance.revenue is undefined" );
  if ( model.revenue.revenueCalculation === undefined )              /* */ throw new Error( "ProgramPerformance.revenue.revenueCalculation is undefined" );
  if ( model.stations === undefined )                                /* */ throw new Error( "ProgramPerformance.stations is undefined" );
  if ( model.title === undefined )                                   /* */ throw new Error( "ProgramPerformance.title is undefined" );
  if ( model.title.compareOperator === undefined )                   /* */ throw new Error( "ProgramPerformance.title.compareOperator is undefined" );
  if ( model.title.resultCount === undefined )                       /* */ throw new Error( "ProgramPerformance.title.resultCount is undefined" );
  if ( model.title.originalOrLocal === undefined )                   /* */ throw new Error( "ProgramPerformance.title.originalOrLocal is undefined" );
  if ( model.title.numberOfCharactersToCompare === undefined )       /* */ throw new Error( "ProgramPerformance.title.numberOfCharactersToCompare is undefined" );
  if ( model.title.searchTerm === undefined )                        /* */ throw new Error( "ProgramPerformance.title.searchTerm is undefined" );
  if ( model.title.titleNames === undefined )                        /* */ throw new Error( "ProgramPerformance.title.titleNames is undefined" );
  if ( model.unselectedIndices === undefined )                           /* */ throw new Error( "ProgramPerformance.unselectedIndices is undefined" );
  if ( !Array.isArray( model.unselectedIndices ) )                       /* */ throw new Error( "ProgramPerformance.unselectedIndices is undefined" );

  return model;
};

export const getStore = ( initialData: ProgramPerformanceSourceModel ) => configureStore<Model>( {
  preloadedState: initializeModel( initialData ),
  reducer: {
    countries: countriesSlice,
    stations: stationsSlice,
    demos: demosSlice,
    measures: measuresSlice,
    demoGroupBy: demoGroupBySlice,
    demoSelection: demoSelectionSlice,
    dateTime: dateTimeReducers,
    average: averageSlice,
    preview: previewSlice,
    title: titleSlice,
    revenue: revenueSlice,
    benchmark: benchmarkSlice,
    display: displaySlice,
    output: outputSlice,
    unselectedIndices: unselectedIndicesSlice
  },
  middleware: [ thunkMiddleware ]
} );

type StoreType = ReturnType<typeof getStore>;

export type AppDispatch = StoreType[ "dispatch" ];
export type RootState = ReturnType<StoreType[ "getState" ]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
