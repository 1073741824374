/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { DisplaySortedByConstants } from "../../../Model";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";

export const DisplaySortedBySelector = ( { helpId, displaySortedBy, distributorIsEnabled, showTypeIsEnabled, productionCountryIsEnabled, revenueIsEnabled, onChangeDisplaySortedBy }: { helpId: string; displaySortedBy: DisplaySortedByConstants; distributorIsEnabled: boolean; showTypeIsEnabled: boolean; productionCountryIsEnabled: boolean; revenueIsEnabled: boolean; onChangeDisplaySortedBy: ( value: DisplaySortedByConstants ) => void; } ): JSX.Element => {
  const displaySortedByItems = useMemo( () => [
    { name: "Country/Channel", value: DisplaySortedByConstants.CountryChannel, isEnabled: true },
    { name: "Country/Date", value: DisplaySortedByConstants.CountryDate, isEnabled: true },
    { name: "Title", value: DisplaySortedByConstants.Title, isEnabled: true },
    { name: "Start Date", value: DisplaySortedByConstants.StartDate, isEnabled: true },
    { name: "Rank", value: DisplaySortedByConstants.Rank, isEnabled: true },
    { name: "Distributor", value: DisplaySortedByConstants.Distributor, isEnabled: distributorIsEnabled },
    { name: "Show Type", value: DisplaySortedByConstants.ShowType, isEnabled: showTypeIsEnabled },
    { name: "Production Country", value: DisplaySortedByConstants.ProductionCountry, isEnabled: productionCountryIsEnabled },
    { name: "Revenue", value: DisplaySortedByConstants.Revenue, isEnabled: revenueIsEnabled }
  ], [ distributorIsEnabled, productionCountryIsEnabled, revenueIsEnabled, showTypeIsEnabled ] );

  return ( <><RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label={ "Sorted by" } helpId={ helpId } radioGroup={ "display_sorted_by" } value={ displaySortedBy } items={ displaySortedByItems } onChangeItemSelected={ onChangeDisplaySortedBy } /></> );
};
