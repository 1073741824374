/** @jsxImportSource @emotion/react */
import React from "react";
import { DatesSelector } from "components/dates-selector/DatesSelector";
import { DaysSelector } from "components/day-selector/DaySelector";
import { DayPartSelector } from "components/daypart-selector/DayPartSelector";
import { TransmissionDurationSelector } from "components/transmission-duration-selector/TransmissionDurationSelector";
import { Country, DayPart, DaysConstants } from "model/Model";
import { Selectable } from "Utilities";
import { TimesSelector } from "components/times-selector/TimesSelector";

export const DateTimeSelector = ( { selectedCountries, fromDate, toDate, fromTime, toTime, days, useDayParts, transmissionDuration, onChangeFromDate, onChangeToDate, onChangeDays, onChangeFromTime, onChangeToTime, onChangeUseDayParts, onChangeDayPartIsSelected, onChangeTransmissionDuration }: { selectedCountries: Array<Country & { dayParts: Array<Selectable<DayPart>>; }>; fromDate?: number; toDate?: number; days: Array<DaysConstants>; fromTime: string; toTime: string; useDayParts: boolean; transmissionDuration: number; onChangeFromDate: ( fromDate: number ) => void; onChangeToDate: ( toDate: number ) => void; onChangeDays: ( days: Array<DaysConstants> ) => void; onChangeFromTime: ( fromTime: string ) => void; onChangeToTime: ( toTime: string ) => void; onChangeUseDayParts: ( useDayParts: boolean ) => void; onChangeDayPartIsSelected: ( countryId: number, dayPartId: number, isSelected: boolean ) => void; onChangeTransmissionDuration: ( value: number ) => void; } ): JSX.Element => ( <>
  <div>
    <DatesSelector helpId="dateDates" fromDate={ fromDate } toDate={ toDate } onChangeFromDate={ onChangeFromDate } onChangeToDate={ onChangeToDate } />
    <DaysSelector helpId="" days={ days } onChangeDays={ onChangeDays } />
  </div>
  <div>
    <TimesSelector helpId="" fromTime={ fromTime } toTime={ toTime } isSelected={ !useDayParts } onChangeUseDayParts={ onChangeUseDayParts } onChangeFromTime={ onChangeFromTime } onChangeToTime={ onChangeToTime } ></TimesSelector>
    <DayPartSelector isEnabled={ useDayParts } selectedCountries={ selectedCountries } onChangeDayPartIsSelected={ onChangeDayPartIsSelected } onChangeUseDayParts={ onChangeUseDayParts } />
    <TransmissionDurationSelector transmissionDuration={ transmissionDuration } onChangeTransmissionDuration={ onChangeTransmissionDuration } />
  </div>
</> );
