export enum CountryConstants {
  France1 = 1,
  Germany2 = 2,
  Spain5 = 5,
  UK11 = 11,
  UKSat12 = 12,
  Germany23 = 23,
  UK26 = 26,
  Italy33 = 33,
  Spain36 = 36,
  Japan38 = 38,
  Japan39 = 39,
  Canada70 = 70,
  Spain80 = 80,
  Italy99 = 99
}
