/** @jsxImportSource @emotion/react */
import React from "react";
import { DemoGroupBySelector } from "components/demo-group-by-selector/DemoGroupBySelector";
import { DemoSelector } from "components/demo-selector/DemoSelector";
import { DemoTypeSelector } from "components/demo-type-selector/DemoTypeSelector";
import { Demo, DemoGroupByConstants, DemoSelectionConstants, DemoTypeConstants, Measure } from "model/Model";
import { Selectable } from "Utilities";

export const QuarterHourDemoSelector = ( { measures, demoGroupBy, demoSelection, selectedCountries, onChangeDemoTypePosition, onChangeDemoTypeSelected, onChangeDemoGroupBy, onChangeDemoIsSelected, onChangeDemoSelection, onChangeDemoSelectedOrder }: { measures: Array<Selectable<Measure>>; demoGroupBy: DemoGroupByConstants; onChangeDemoTypePosition: ( srcValue: DemoTypeConstants, dstValue: DemoTypeConstants ) => void; onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => void; onChangeDemoGroupBy: ( demoGroupBy: DemoGroupByConstants ) => void; demoSelection: DemoSelectionConstants; selectedCountries: Array<{ id: number; name: string; demos: Array<Selectable<Demo>>; selectedDemos: Array<Demo>; allDemosSelected: boolean; noDemosSelected: boolean; }>; onChangeDemoIsSelected: ( countryId: number, demoId: number, isSelected: boolean ) => void; onChangeDemoSelection: ( demoSelection: DemoSelectionConstants ) => void; onChangeDemoSelectedOrder: ( countryId: number, srcDemoId: number, dstDemoId: number ) => void; } ): JSX.Element => ( <>
  <DemoTypeSelector helpId="demoType" measures={ measures } onChangeDemoTypePosition={ onChangeDemoTypePosition } onChangeDemoTypeSelected={ onChangeDemoTypeSelected } />
  <DemoGroupBySelector helpId="demoGroupBy" demoGroupBy={ demoGroupBy } onChangeDemoGroupBy={ onChangeDemoGroupBy } />
  <DemoSelector helpId="demo" demoSelection={ demoSelection } selectedCountries={ selectedCountries } onChangeDemoIsSelected={ onChangeDemoIsSelected } onChangeDemoSelection={ onChangeDemoSelection } onChangeDemoSelectedOrder={ onChangeDemoSelectedOrder } />
</> );
