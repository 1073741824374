import { DaysConstants, Output } from "model/Model";

export type Model = {
  countries: Array<number>;
  reportType: ReportTypeConstants;
  stations: Array<{ id: number; stations: Array<number>; }>;
  dateTime: DateTime;
  qualify: Qualify;
  display: Display;
  output: Output;
};

export type DateTimeSource = {
  fromDate?: string;
  toDate?: string;
  duration: DurationConstants;
  days: Array<DaysConstants>;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};

export type RevenueEstimatorSourceModel = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  reportType: ReportTypeConstants;
  dateTime: DateTimeSource;
  display: Display;
  qualify: Qualify;
  output: Output;
};

export enum QualifyFormatConstants {
  Adaptation = "L",
  Original = "O",
}

export enum ReportTypeConstants {
  Imports = 1,
  ImportsAndLocal = 2
}

// FUTURE: can these be cleaned up?
export enum DurationConstants {
  All = "10,999[All (10plus Min)]",
  HalfHour = "10,39[Half Hour (10-39 Min)]",
  Hour = "40,79[1 Hour (40-79 Min)]",
  HalfHourToHour = "10,79[1/2 Hour - 1 Hour (4-79 Min)]",
  Film80To120Minutes = "80,120[Film (80-120 Min)]",
  Film120MinutesPlus = "120,999[Film (120plus Min)]",
  Film80MinutesPlus = "80,999[Film (80plus Min)]"
}

// FUTURE: can these be cleaned up?
export enum DisplayRankTitlesConstants {
  Telecasts = "frequency[# Telecasts]",
  AverageRevenue = "revenue[Average Revenue]"
}

// FUTURE: can these be cleaned up?
export enum DisplayTitlesDisplayedConstants {
  All = "13131313[ALL]",
  Top3 = "3",
  Top5 = "5",
  Top10 = "10",
  Top25 = "25"
}

export type QualifyTypeOption = { value: string; name: string; isSelected: boolean; isEnabled?: boolean; };
export type QualifyClassOneOption = { value: string; name: string; isSelected: boolean; isEnabled?: boolean; };
export type QualifyClassTwoOption = { value: string; name: string; isSelected: boolean; isEnabled?: boolean; };
export type QualifyFormatOption = { value: QualifyFormatConstants; name: string; isSelected: boolean; isEnabled?: boolean; };
export type QualifyDistributorOption = { value: string; name: string; isSelected: boolean; isEnabled?: boolean; };
export type QualifyProductionCountryOption = { value: string; name: string; isSelected: boolean; isEnabled?: boolean; };
export type QualifyProductionCountryPositionOption = { value: number; name: string; isSelected: boolean; isEnabled?: boolean; };

export type Qualify = {
  //types: Array<QualifyTypeOption>;
  //classOnes: Array<QualifyClassOneOption>;
  //classTwos: Array<QualifyClassTwoOption>;
  //formats: Array<QualifyFormatOption>;
  //distributors: Array<QualifyDistributorOption>;
  //productionCountries: Array<QualifyProductionCountryOption>;
  //productionCountryPositions: Array<QualifyProductionCountryPositionOption>;
  selectedLocalGenres: Array<{ id: number; localGenres: Array<number>; }>;
  selectedTypes: Array<string>;
  selectedClassOnes: Array<string>;
  selectedClassTwos: Array<string>;
  selectedFormats: Array<QualifyFormatConstants>;
  selectedDistributors: Array<string>;
  selectedProductionCountries: Array<string>;
  selectedProductionCountryPositions: Array<number>;
};

export type DateTime = {
  fromDate?: number;
  toDate?: number;
  days: Array<DaysConstants>;
  duration: DurationConstants;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};

export type Display = {
  displayRankTitles: DisplayRankTitlesConstants;
  displayTitlesDisplayed: DisplayTitlesDisplayedConstants;
};
