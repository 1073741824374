import { CountryConstants } from "constants/CountryConstants";
import { DaysConstants, DemoTypeConstants } from "model/Model";
import { parseTimeInMinutes, formatDateDDmmmYYYY, Selectable } from "Utilities";

export const validateHaveStationInEachCountry = ( countries: Array<number>, stations: Array<{ id: number; stations: Array<number>; }>, validation: { errors: Array<string>; } ): void => {
  let haveStationInEachCountry = true;
  for ( const countryId of countries ) {
    const countryStations = stations.find( c => c.id === countryId );
    if ( !countryStations || countryStations?.stations.length === 0 ) {
      haveStationInEachCountry = false;
      break;
    }
  }

  if ( !haveStationInEachCountry ) {
    validation.errors.push( "You must select at least one channel per country before submitting." );
  }
};

export const validateHaveDemoInEachCountry = ( countries: Array<number>, demos: Array<{ id: number; demos: Array<number>; }>, validation: { errors: Array<string>; } ): void => {
  let haveDemoInEachCountry = true;
  for ( const countryId of countries ) {
    const countryDemos = demos.find( c => c.id === countryId );
    if ( !countryDemos || countryDemos?.demos.length === 0 ) {
      haveDemoInEachCountry = false;
      break;
    }
  }

  if ( !haveDemoInEachCountry ) {
    validation.errors.push( "You must select at least one demo per country before submitting." );
  }
};

export const validateFromAndToDates = ( fromDate: number | null | undefined, toDate: number | null | undefined, validation: { errors: Array<string>; dateTime?: string; isValidForSubmit: boolean; } ): void => {
  if ( !fromDate || !toDate || isNaN( new Date( fromDate ).getDate() ) || isNaN( new Date( toDate ).getDate() ) ) {
    validation.errors.push( "Please correct your date selections before submitting." );
    validation.dateTime = "Date cannot be empty";
    validation.isValidForSubmit = false;
  } else if ( fromDate > toDate ) {
    validation.errors.push( "Please correct your date selections before submitting." );
    validation.dateTime = "Start date cannot be after end date";
    validation.isValidForSubmit = false;
  }
};

export const validateHaveDaypartInEachCountry = ( countries: Array<number>, dayParts: Array<{ id: number; dayParts: Array<number>; }>, validation: { errors: Array<string>; } ): void => {
  let haveDaypartInEachCountry = true;
  for ( const countryId of countries ) {
    const countryDayparts = dayParts.find( c => c.id === countryId );
    if ( !countryDayparts || countryDayparts?.dayParts.length === 0 ) {
      haveDaypartInEachCountry = false;
      break;
    }
  }

  if ( !haveDaypartInEachCountry ) {
    validation.errors.push( "When using dayparts, you must select at least one per country before submitting." );
  }
};

export const validateHaveDays = ( days: Array<DaysConstants>, validation: { errors: Array<string>; } ): void => {
  if ( days.length === 0 ) {
    validation.errors.push( "You must include at least one day of the week before submitting" );
  }
};

export const validateFromAndToTime = ( fromTimeText: string, toTimeText: string, validation: { errors: Array<string>; dateTime?: string; } ): void => {
  const fromTime = parseTimeInMinutes( fromTimeText );
  const toTime = parseTimeInMinutes( toTimeText );
  if ( fromTime > toTime ) {
    validation.errors.push( "Please correct your time selections before submitting" );
    validation.dateTime = "End time must be after start time";
  }
};

export const validateBenchmarkQuarters = ( selectedQuarters: number, validation: { errors: Array<string>; benchmark?: string; } ): void => {
  if ( selectedQuarters === 0 ) {
    validation.errors.push( "You must select at least ibe quarter when using quarter benchmarks before submitting." );
  }
};

export const validateBenchmarkFromAndToDates = ( fromDate: number | null | undefined, toDate: number | null | undefined, validation: { errors: Array<string>; benchmark?: string; isValidForSubmit: boolean; } ): void => {
  if ( !fromDate || !toDate ) {
    validation.errors.push( "Please correct your benchmark date selections before submitting." );
    validation.benchmark = "Date cannot be empty";
    validation.isValidForSubmit = false;
  } else if ( fromDate > toDate ) {
    validation.errors.push( "Please correct your benchmark date selections before submitting." );
    validation.benchmark = "Start date cannot be after end date";
    validation.isValidForSubmit = false;
  }
};

export const validateBenchmarkFromAndToTime = ( fromTimeText: string, toTimeText: string, validation: { errors: Array<string>; benchmark?: string; } ): void => {
  const fromTime = parseTimeInMinutes( fromTimeText );
  const toTime = parseTimeInMinutes( toTimeText );
  if ( fromTime > toTime ) {
    validation.errors.push( "Please correct your benchmark time selections before submitting" );
    validation.benchmark = "End time must be after start time";
  }
};

export const validateBenchmarkDays = ( days: Array<DaysConstants>, validation: { errors: Array<string>; } ): void => {
  if ( days.length === 0 ) {
    validation.errors.push( "You must include at least one day of the week when benchmarking on selected days before submitting" );
  }
};

export const Canada70NoShrOrPutCutOff = new Date( "2019-08-25" );

export const validateCanada70NoShareOrPutCutOff = ( toDate: number | null | undefined, measures: Array<Selectable<{ value: DemoTypeConstants; }>>, validation: { errors: Array<string>; isValidForSubmit: boolean; } ): void => {
  if ( !toDate ) return;

  const toDateObject = new Date( toDate );

  const selectedMeasures = measures.filter( m => m.isSelected );

  for ( const selectedMeasure of selectedMeasures ) {
    if ( selectedMeasure.value === DemoTypeConstants.Put || selectedMeasure.value === DemoTypeConstants.Shr ) {
      if ( toDateObject > Canada70NoShrOrPutCutOff ) {
        validation.errors.push( `Canada does not have SHR or PUT available after ${ formatDateDDmmmYYYY( Canada70NoShrOrPutCutOff ) }. Please change your selections.` );
        //TODO: should this prevent submitting?;
        //validation.isValidForSubmit = false;
      }
      break;
    }
  }
};

export const validateCountryStartDate = ( countries: Array<{ name: string; startDate: Date; endDate: Date; }>, fromDate: number | null | undefined, validation: { warnings: Array<string>; isValidForSubmit: boolean; } ): void => {
  if ( !fromDate ) return;

  const fromDateObject = new Date( fromDate );

  countries.forEach( ( country ) => {
    if ( fromDateObject < country.startDate ) {
      validation.warnings.push( `${ country.name } data is available from ${ formatDateDDmmmYYYY( country.startDate ) } to ${ formatDateDDmmmYYYY( country.endDate ) }.` );
    }
  } );
};

export const validateCountryStationsRevenueDates = ( countries: Array<{ id: number; name: string; stations: Array<{ id: number; name: string; hasRevenue: boolean; revenueStartDate?: Date; revenueEndDate?: Date; }>; }>, selectedStationsByCountry: Array<{ id: number; stations: Array<number>; }>, fromDate: number | null | undefined, toDate: number | null | undefined, validation: { warnings: Array<string>; } ): void => {
  countries.forEach( ( country ) => {
    const selectedStationsForCountry = selectedStationsByCountry.find( m => m.id === country.id )?.stations;
    const selectedRevenueStations = country.stations.filter( m => selectedStationsForCountry?.find( s => s === m.id && m.hasRevenue ) );

    selectedRevenueStations.forEach( ( revenueStation ) => {
      if ( revenueStation.revenueStartDate && fromDate && new Date( fromDate ) < revenueStation.revenueStartDate ) validation.warnings.push( `${ country.name } ${ revenueStation.name } revenue is not available before ${ formatDateDDmmmYYYY( revenueStation.revenueStartDate ) }` );
      if ( revenueStation.revenueEndDate && toDate && new Date( toDate ) > revenueStation.revenueEndDate ) validation.warnings.push( `${ country.name } ${ revenueStation.name } revenue is  not available after ${ formatDateDDmmmYYYY( revenueStation.revenueEndDate ) }` );
    } );
  } );
};

export const validateCountryDemoDates = ( countries: Array<{ id: number; name: string; demos: Array<{ id: number; name: string; startDate?: Date; endDate?: Date; }>; }>, demosByCountry: Array<{ id: number; demos: Array<number>; }>, fromDate: number | null | undefined, toDate: number | null | undefined, validation: { warnings: Array<string>; } ): void => {
  const fromDateObject = fromDate ? new Date( fromDate ) : null;
  const toDateObject = toDate ? new Date( toDate ) : null;
  countries.forEach( ( country ) => {
    const countryDemos = demosByCountry.find( d => d.id === country.id )?.demos ?? [];
    if ( countryDemos.length === 0 ) return;
    const selectedDemos = country.demos.filter( m => countryDemos.find( d => d === m.id ) );
    selectedDemos.forEach( ( demo ) => {
      if ( demo.startDate && fromDateObject && fromDateObject < demo.startDate ) validation.warnings.push( `${ country.name } ${ demo.name } demo is not available before ${ formatDateDDmmmYYYY( demo.startDate ) }` );
      if ( demo.endDate && toDateObject && toDateObject > demo.endDate ) validation.warnings.push( `${ country.name } ${ demo.name } demo is  not available after ${ formatDateDDmmmYYYY( demo.endDate ) }` );
    } );
  } );
};

export const validateJapan38DemosAndStations = ( countries: Array<{ id: number; name: string; demos: Array<{ id: number; name: string; }>; stations: Array<{ id: number; name: string; }>; }>, stationsByCountry: Array<{ id: number; stations: Array<number>; }>, demosByCountry: Array<{ id: number; demos: Array<number>; }>, validation: { warnings: Array<string>; } ): void => {
  // particular stations only supplied a limited number of demos

  [ CountryConstants.Japan38, CountryConstants.Japan39 ].forEach( ( value ) => {
    const stations = stationsByCountry.find( c => c.id === value )?.stations ?? [];
    if ( stations.length === 0 ) return;

    const demos = demosByCountry.find( d => d.id === value )?.demos ?? [];
    if ( demos.length === 0 ) return;

    const japanRefData = countries.find( c => c.id === value );
    if ( !japanRefData ) return;

    const stationsAlert = value === CountryConstants.Japan38 ? [ 4, 9 ] : [ 6, 7 ];
    const demosAlert = [ 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 16, 21 ];

    for ( const stationAlert of stationsAlert ) {
      if ( stations.includes( stationAlert ) ) {
        for ( const demoAlert of demosAlert ) {
          if ( demos.includes( demoAlert ) ) {

            validation.warnings.push( `${ japanRefData.name } demo ${ japanRefData.demos.find( d => d.id === demoAlert )?.name } is not available for channel ${ japanRefData.stations.find( s => s.id === stationAlert )?.name }` );
          }
        }
      }
    }
  } );
};

