/** @jsxImportSource @emotion/react */
import React from "react";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { DisplayRankTitlesConstants, DisplayTitlesDisplayedConstants } from "apps/RevenueEstimator/Model";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";

const displayRankTitleItems = [
  { name: "# Telecasts", value: DisplayRankTitlesConstants.Telecasts },
  { name: "Average Revenue", value: DisplayRankTitlesConstants.AverageRevenue }
];

const displayTitlesDisplayedItems = [
  { name: "All", value: DisplayTitlesDisplayedConstants.All },
  { name: "Top 3", value: DisplayTitlesDisplayedConstants.Top3 },
  { name: "Top 5", value: DisplayTitlesDisplayedConstants.Top5 },
  { name: "Top 10", value: DisplayTitlesDisplayedConstants.Top10 },
  { name: "Top 25", value: DisplayTitlesDisplayedConstants.Top25 }
];

export const DisplaySelector = ( { displayRankTitles, displayTitlesDisplayed, onChangeDisplayRankTitles, onChangeDisplayTitlesDisplayed }: { displayRankTitles: DisplayRankTitlesConstants; displayTitlesDisplayed: DisplayTitlesDisplayedConstants; onChangeDisplayRankTitles: ( value: DisplayRankTitlesConstants ) => void; onChangeDisplayTitlesDisplayed: ( value: DisplayTitlesDisplayedConstants ) => void; } ): JSX.Element => ( <>
  <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Rank titles" radioGroup="display_rank_titles" helpId="display_rank_titles" value={ displayRankTitles } items={ displayRankTitleItems } onChangeItemSelected={ onChangeDisplayRankTitles } />
  <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Titles displayed" radioGroup="display_titles_displayed" helpId="display_titles_displayed" value={ displayTitlesDisplayed } items={ displayTitlesDisplayedItems } onChangeItemSelected={ onChangeDisplayTitlesDisplayed } />
</> );
