import { createAction } from "@reduxjs/toolkit";
import { BenchmarkDayOfWeekTypeConstants, BenchmarkTimePeriodConstants, DaysConstants, DemoTypeConstants } from "model/Model";

export const ChangeBenchmarkDates = createAction<number>( "new:benchmark:ChangeDates" );
export const ChangeBenchmarkFromDate = createAction<number>( "new:benchmark:ChangeFromDate" );
export const ChangeBenchmarkToDate = createAction<number>( "new:benchmark:ChangeToDate" );
export const ChangeBenchmarkTimePeriod = createAction<BenchmarkTimePeriodConstants>( "new:benchmark:ChangeTimePeriod" );
export const ChangeBenchmarkTimePeriodFromTime = createAction<string>( "new:benchmark:ChangeTimePeriodFromTime" );
export const ChangeBenchmarkTimePeriodToTime = createAction<string>( "new:benchmark:ChangeTimePeriodToTime" );
export const ChangeBenchmarkQuarterIsSelected = createAction<{ value: number; isSelected: boolean; }>( "new:benchmark:ChangeQuarterIsSelected" );
export const ChangeBenchmarkDemo = createAction<number>( "new:benchmark:ChangeDemo" );
export const ChangeBenchmarkDemoType = createAction<{ value: DemoTypeConstants; isSelected: boolean; }>( "new:benchmark:ChangeDemoType" );
export const ChangeBenchmarkDayOfWeekType = createAction<BenchmarkDayOfWeekTypeConstants>( "new:benchmark:ChangeBenchmarkDayOfWeekType" );
export const ChangeBenchmarkDaysSelected = createAction<Array<DaysConstants>>( "new:benchmark:ChangeBenchmarkDaysSelected" );