/** @jsxImportSource @emotion/react */
import React, { MutableRefObject, useCallback, useRef } from "react";
import { HelpText } from "components/help-text/HelpText";
import { ReportTypeSummaryConstants, ReportTypeSummary } from "model/Model";
import { chunk, Selectable } from "Utilities";
import { css } from "@emotion/react";

const styles = {
  reportTypeSummarySelector: css`
  display: inline-block;
  vertical-align: top;
 
  fieldset {
     border: solid black 1px;
     padding: 0;
     padding-left: 10px;
     padding-right: 10px;
     padding-bottom: 10px;
     display: inline;
     margin-left: 4px;
     margin-right: 4px;
 
     legend {
       width: auto;
       margin: 0;
       margin-left: 4px;
       padding-left: 4px;
       padding-right: 4px;
 
       &.clickable {
         cursor: pointer;
       }
     }

    label {
      padding: 0;
      margin: 0;      
    }

    input {
      padding: 0;
      margin: 0;
      margin-right: 4px;
      width: auto;
    }
  }
  `,

  reportSummaryColumn: css`
    display: block;
    float: left;
    margin-right: 4px;
  `,

  orderedReportTypeSummaryList: css`
    display: inline-block;
    vertical-align: top;
    width: 100px;
    margin-right: 20px;

    ul {
      border: solid black 1px;
      padding: 2px 2px 2px 2px;
      margin: 0;
      min-height: 20px;

      li {
        list-style-type: none;
        padding: 0 4px;
        margin: 0;
        cursor: move;
        user-select: none;
        background-color: white;

        &:hover {
          background-color: orange;
        }

        &.dropAbove {
          border-top: solid red 12px;
          padding-top: 0px;
         
          * {
           pointer-events: none;
          }
        }

        &.dropBelow {
          border-bottom: solid blue 12px;
          padding-bottom: 0px;
         
          * {
           pointer-events: none;
          }
        }
      }
    }
  `,

  reportTypeSummaryList: css`
    display: inline-block;
    vertical-align: top;
 `
};

export const ReportTypeSummarySelector = ( { helpId, reportTypeSummaries, selectedReportTypeSummaries, isSummaryOnly, summaryCombineChannels, summaryCombineSeries, summaryCombineFilm, onChangeReportTypeSummaryIsSummaryOnly, onChangeReportTypeNoneSelected, onChangeReportTypeSummaryIsSelected, onChangeReportTypeSummarySelectedOrder, onChangeReportTypeSummaryCombineChannels, onChangeReportTypeSummaryCombineSeries, onChangeReportTypeSummaryCombineFilms }: { helpId: string; reportTypeSummaries: Array<Selectable<ReportTypeSummary>>; selectedReportTypeSummaries: Array<Selectable<ReportTypeSummary>>; isSummaryOnly: boolean; summaryCombineChannels: boolean; summaryCombineSeries: boolean; summaryCombineFilm: boolean; onChangeReportTypeSummaryIsSummaryOnly: ( isSummaryOnly: boolean ) => void; onChangeReportTypeNoneSelected: () => void; onChangeReportTypeSummaryIsSelected: ( reportTypeSummaryId: ReportTypeSummaryConstants, isSelected: boolean ) => void; onChangeReportTypeSummarySelectedOrder: ( srcSummaryId: ReportTypeSummaryConstants, dstSummaryId: ReportTypeSummaryConstants ) => void; onChangeReportTypeSummaryCombineChannels: ( isSummaryOnly: boolean ) => void; onChangeReportTypeSummaryCombineSeries: ( isSummaryOnly: boolean ) => void; onChangeReportTypeSummaryCombineFilms: ( isSummaryOnly: boolean ) => void; } ): JSX.Element => {
  const draggedItemIdRef = useRef<number | null>( null );
  const draggedItemIndexRef = useRef<number | null>( null );

  return (
    <div css={ styles.reportTypeSummarySelector }>
      <fieldset>
        <legend><label>Summary <HelpText helpId={ helpId } /></label></legend>
        <div css={ styles.orderedReportTypeSummaryList }>
          <div><label><input type="radio" onChange={ () => onChangeReportTypeNoneSelected() } checked={ selectedReportTypeSummaries.length === 0 }></input>None</label></div>
          <ul>
            { selectedReportTypeSummaries.map( ( m ) => {
              return <SelectedSummary key={ m.id } { ...m } draggedItemIdRef={ draggedItemIdRef } draggedItemIndexRef={ draggedItemIndexRef } onChangeReportTypeSummarySelectedOrder={ onChangeReportTypeSummarySelectedOrder } />;
            } ) }
          </ul>
          <div><label><input type="checkbox" disabled={ selectedReportTypeSummaries.length === 0 } checked={ isSummaryOnly } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeReportTypeSummaryIsSummaryOnly( e.currentTarget.checked ) }></input>Summary Only</label></div>
        </div>
        <div css={ styles.reportTypeSummaryList }>
          { chunk( reportTypeSummaries, 6 ).map( ( r, i ) => {
            return (
              <div key={ i } css={ styles.reportSummaryColumn } >
                {
                  r.map( m => ( <CheckSelector key={ m.id } { ...m } value={ m.id } onChange={ onChangeReportTypeSummaryIsSelected } /> ) )
                }
              </div>
            );
          } ) }
        </div>

        <fieldset>
          <legend><label>Combine</label></legend>
          <div css={ styles.reportTypeSummaryList }>
            <div><label><input type="checkbox" disabled={ false } checked={ summaryCombineChannels } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeReportTypeSummaryCombineChannels( e.currentTarget.checked ) }></input>Channels</label></div>
            <div><label><input type="checkbox" disabled={ !selectedReportTypeSummaries.find( m => m.id === ReportTypeSummaryConstants.Type ) } checked={ summaryCombineSeries } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeReportTypeSummaryCombineSeries( e.currentTarget.checked ) }></input>Series Scripted - Miniseries</label></div>
            <div><label><input type="checkbox" disabled={ !selectedReportTypeSummaries.find( m => m.id === ReportTypeSummaryConstants.Type ) } checked={ summaryCombineFilm } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChangeReportTypeSummaryCombineFilms( e.currentTarget.checked ) }></input>Feature Film - TV Movie - ShortFilm</label></div>
          </div>
        </fieldset>
      </fieldset>
    </div>
  );
};

const CheckSelector = <T,>( { value, name, isSelected, isEnabled = true, onChange }: { value: T; name: string; isSelected: boolean; isEnabled?: boolean; onChange: ( value: T, isSelected: boolean ) => void; } ) => {
  return (
    <div data-key={ value } >
      <input aria-label={ name } type="checkbox" disabled={ !isEnabled } checked={ isSelected } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onChange( value, e.currentTarget.checked ) } />
      <span onSelect={ ( e ) => { e.preventDefault(); return false; } }>{ name }</span>
    </div>
  );
};

const SelectedSummary = ( { id, name, draggedItemIdRef, draggedItemIndexRef, onChangeReportTypeSummarySelectedOrder }: { id: number; name: string; draggedItemIdRef: MutableRefObject<number | null>; draggedItemIndexRef: MutableRefObject<number | null>; onChangeReportTypeSummarySelectedOrder: ( srcReportTypeSummaryId: ReportTypeSummaryConstants, dstReportTypeSummaryId: ReportTypeSummaryConstants ) => void; } ) => {
  const onDragStart = useCallback( ( e: React.DragEvent<HTMLLIElement> ) => {
    const draggedItem = e.currentTarget;
    const reportTypeSummaries = Array.from( draggedItem.parentElement?.children || [] );
    draggedItemIdRef.current = id;
    draggedItemIndexRef.current = reportTypeSummaries.indexOf( draggedItem );
  }, [ draggedItemIdRef, draggedItemIndexRef, id ] );

  const onDragOver = useCallback( ( e: React.DragEvent<HTMLLIElement> ) => {
    const destinationId = parseInt( e.currentTarget.dataset.id || "", 10 );
    if ( destinationId === draggedItemIdRef.current ) return;

    e.preventDefault();
  }, [ draggedItemIdRef ] );

  const onDragEnter = useCallback( ( e: React.DragEvent<HTMLLIElement> ) => {
    const destinationId = parseInt( e.currentTarget.dataset.id || "", 10 );
    if ( destinationId === draggedItemIdRef.current ) return;
    if ( draggedItemIndexRef.current == null ) return;

    const destinationItemIndex = Array.from( e.currentTarget.parentElement?.children ?? [] ).indexOf( e.currentTarget );
    if ( destinationItemIndex < draggedItemIndexRef.current ) {
      e.currentTarget.classList.add( "dropAbove" );
    } else {
      e.currentTarget.classList.add( "dropBelow" );
    }
  }, [ draggedItemIdRef, draggedItemIndexRef ] );

  const onDragLeave = useCallback( ( e: React.DragEvent<HTMLLIElement> ) => {
    const destinationId = parseInt( e.currentTarget.dataset.id || "", 10 );
    if ( destinationId === draggedItemIdRef.current ) return;

    e.currentTarget.classList.remove( "dropAbove" );
    e.currentTarget.classList.remove( "dropBelow" );
  }, [ draggedItemIdRef ] );

  const onDrop = useCallback( ( e: React.DragEvent<HTMLLIElement> ) => {
    const destinationId = parseInt( e.currentTarget.dataset.id || "", 10 );

    e.preventDefault();

    if ( draggedItemIdRef.current == null ) return;
    if ( destinationId == null ) return;

    onChangeReportTypeSummarySelectedOrder( draggedItemIdRef.current, destinationId );

    const reportTypeSummaries = Array.from( e.currentTarget?.parentElement?.children || [] );
    for ( const m of reportTypeSummaries ) {
      m.classList.remove( "dropAbove" );
      m.classList.remove( "dropBelow" );
    }

    draggedItemIdRef.current = null;
    draggedItemIndexRef.current = null;
  }, [ draggedItemIdRef, draggedItemIndexRef, onChangeReportTypeSummarySelectedOrder ] );

  return (
    <li key={ id } draggable={ true } onDragOver={ onDragOver } onDragStart={ onDragStart } onDrop={ onDrop } onDragEnter={ onDragEnter } onDragLeave={ onDragLeave }>{ name }</li> );
};
