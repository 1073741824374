/** @jsxImportSource @emotion/react */
import React from "react";
import { TimePicker } from "components/time-picker/TimePicker";
import { HelpText } from "components/help-text/HelpText";
import { BenchmarkTimePeriodConstants } from "model/Model";
import { css } from "@emotion/react";
import { twoDigits } from "Utilities";

const styles = {
  benchmarkTimePeriodSelector: css`
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }

      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
      }
  
      input {
        padding: 0;
        margin: 0;
        margin-right: 4px;
        width: auto;
      }
    }
  `,

  dayPartOrTimePeriod: css`
    display: inline-block;
    vertical-align: top;
  `,

  times: css`
    display: inline-block;
  
    label {
      padding: 0;
      margin: 0;
      padding-right: 10px;
    }

    input {
      padding: 0;
      margin: 0;
      margin-right: 4px;
      width: auto;
    }
  `
};

const getTimeList = ( startTimeInMinutes: number, endTimeInMinutes: number, increment: number ): string[] => {
  const result = [];
  for ( let t = startTimeInMinutes; t <= endTimeInMinutes; t += increment ) {
    result.push( `${ twoDigits( Math.floor( t / 60 ) ) }:${ twoDigits( t % 60 ) }` );
  }
  return result;
};

const fromTimeList = getTimeList( 0, 1770, 30 );
const toTimeList = getTimeList( 30, 1800, 30 );

export const BenchmarkTimePeriodSelector = ( { helpId, isEnabled, timePeriod, timePeriodFromTime, timePeriodToTime, onChangeTimePeriod, onChangeTimePeriodFromTime, onChangeTimePeriodToTime }: { helpId: string; isEnabled: boolean; timePeriod: BenchmarkTimePeriodConstants; timePeriodFromTime: string; timePeriodToTime: string; onChangeTimePeriod: ( value: BenchmarkTimePeriodConstants ) => void; onChangeTimePeriodFromTime: ( value: string ) => void; onChangeTimePeriodToTime: ( value: string ) => void; } ): JSX.Element => {
  return ( <>
    <div css={ styles.benchmarkTimePeriodSelector }>
      <fieldset>
        <legend><label>Times <HelpText helpId={ helpId } /></label></legend>
        <div>
          <div css={ styles.dayPartOrTimePeriod }>
            <label><input disabled={ !isEnabled } type="radio" radioGroup="benchmark_time_period" checked={ timePeriod === BenchmarkTimePeriodConstants.Daypart } onChange={ () => onChangeTimePeriod( BenchmarkTimePeriodConstants.Daypart ) } /><span>Daypart</span></label>
            <label><input disabled={ !isEnabled } type="radio" radioGroup="benchmark_time_period" checked={ timePeriod === BenchmarkTimePeriodConstants.TimePeriod } onChange={ () => onChangeTimePeriod( BenchmarkTimePeriodConstants.TimePeriod ) } /><span>Time Period</span></label>
          </div>
          <div css={ styles.times } >
            <label><input disabled={ !isEnabled } type="radio" radioGroup="benchmark_time_period" checked={ timePeriod === BenchmarkTimePeriodConstants.Times } onChange={ () => onChangeTimePeriod( BenchmarkTimePeriodConstants.Times ) } /><span>Times</span></label>
            <div>
              <TimePicker isEnabled={ isEnabled } heading={ "Start Time" } timesList={ fromTimeList } currentTime={ timePeriodFromTime ?? "00:00" } onChangeTime={ ( t ) => onChangeTimePeriodFromTime( t ) } ></TimePicker>
              <TimePicker isEnabled={ isEnabled } heading={ "End Time" } timesList={ toTimeList } currentTime={ timePeriodToTime ?? "24:00" } onChangeTime={ ( t ) => onChangeTimePeriodToTime( t ) } ></TimePicker>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </> );
};
