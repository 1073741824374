/** @jsxImportSource @emotion/react */
import { useMemo } from "react";
import { DatePicker } from "components/date-picker/DatePicker";
import { HelpText } from "components/help-text/HelpText";
import { css } from "@emotion/react";
import { isInvalidValidDate } from "Utilities";

const styles = {
  dateSelector: css`
    vertical-align: top;
    display: inline-block;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
    }`
};

export const DatesSelector = ( { fromDate, toDate, helpId, onChangeFromDate, onChangeToDate }: { fromDate?: number; toDate?: number; helpId: string; onChangeFromDate: ( fromDate: number ) => void; onChangeToDate: ( toDate: number ) => void; } ): JSX.Element => {
  const toDateValue = useMemo( () => !toDate || isInvalidValidDate( toDate ) ? new Date( 2020, 0, 1, 0, 0, 0 ) : new Date( toDate ), [ toDate ] );

  return ( <>
    <div css={ styles.dateSelector }>
      <fieldset>
        <legend><label>End day of week <HelpText helpId={ helpId } /></label></legend>
        <div css={ css`display:flex;` }>
          <DatePicker key={ `to${ toDateValue.valueOf() }` } currentDate={ toDateValue.valueOf() } displayFormat="dd MMM yyyy" onChangeDate={ ( dt, _ ) => { onChangeToDate( dt ); } } />
        </div>
      </fieldset>
    </div>
  </> );
};
