/** @jsxImportSource @emotion/react */
import React from "react";
import { MinimumDurationConstants } from "../../Model";
import { css } from "@emotion/react";

const styles = {
  minimumLengthSelector: css`
    display: inline-block;
    vertical-align: top;

    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;

      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;

        &.clickable {
          cursor: pointer;
        }
      }

      label {
        display: block;
        padding: 0;
        margin: 0;

        input {
          width: auto;
          padding: 0;
          margin: 0 5px 0 0;
          vertical-align: middle;
        }

        span {
          vertical-align: middle;
        }
      }
    }
  `
};

export const MinimumLengthRadioSelector = ( { minimumDuration, onChangeMinimumDuration }: { minimumDuration: MinimumDurationConstants; onChangeMinimumDuration: ( minimumLength: MinimumDurationConstants ) => void; } ): JSX.Element => (
  <div css={ styles.minimumLengthSelector } >
    <fieldset>
      <legend><label>Minimum length</label></legend>
      <label><input type="radio" radioGroup="minimumDuration" checked={ minimumDuration === MinimumDurationConstants.All } onChange={ () => onChangeMinimumDuration( MinimumDurationConstants.All ) } /><span>All (4+ Min)</span></label>
      <label><input type="radio" radioGroup="minimumDuration" checked={ minimumDuration === MinimumDurationConstants.TenMinutes } onChange={ () => onChangeMinimumDuration( MinimumDurationConstants.TenMinutes ) } /><span>10 minutes</span></label>
      <label><input type="radio" radioGroup="minimumDuration" checked={ minimumDuration === MinimumDurationConstants.FifteenMinutes } onChange={ () => onChangeMinimumDuration( MinimumDurationConstants.FifteenMinutes ) } /><span>15 minutes</span></label>
      <label><input type="radio" radioGroup="minimumDuration" checked={ minimumDuration === MinimumDurationConstants.ThirtyMinutes } onChange={ () => onChangeMinimumDuration( MinimumDurationConstants.ThirtyMinutes ) } /><span>30 minutes</span></label>
      <label><input type="radio" radioGroup="minimumDuration" checked={ minimumDuration === MinimumDurationConstants.SixtyMinutes } onChange={ () => onChangeMinimumDuration( MinimumDurationConstants.SixtyMinutes ) } /><span>60 minutes</span></label>
    </fieldset>
  </div> );
