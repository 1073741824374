import { DisplayCompetitionBreakoutConstants, DisplayDetailSheetConstants, DisplayLeadInLeadOutBreakoutConstants, DisplayPrimarySheetConstants, DisplaySource, DisplayTimeConstants } from "../../Model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const displaySlice = createSlice( {
  name: "display",
  initialState: {
    selectedDisplayPrimarySheet: [],
    displayCompetitionBreakout: DisplayCompetitionBreakoutConstants.None,
    displayLiLoBreakout: DisplayLeadInLeadOutBreakoutConstants.None,
    selectedDisplayDetailSheet: [],
    displayTime: DisplayTimeConstants.TotalMinutes
  } as DisplaySource,
  reducers: {
    ChangeDisplayPrimarySheetIsSelected( state, action: PayloadAction<{ value: DisplayPrimarySheetConstants; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        state.selectedDisplayPrimarySheet = [ ...state.selectedDisplayPrimarySheet, value ];
      } else {
        state.selectedDisplayPrimarySheet = state.selectedDisplayPrimarySheet.filter( m => m !== value );
      }
    },

    ChangeDisplayCompetitionBreakout( state, action: PayloadAction<DisplayCompetitionBreakoutConstants> ) { state.displayCompetitionBreakout = action.payload; },
    ChangeDisplayLeadInLeadOutBreakout( state, action: PayloadAction<DisplayLeadInLeadOutBreakoutConstants> ) { state.displayLiLoBreakout = action.payload; },
    ChangeDisplayTime( state, action: PayloadAction<DisplayTimeConstants> ) { state.displayTime = action.payload; },

    ChangeDisplayDetailSheetIsSelected( state, action: PayloadAction<{ value: DisplayDetailSheetConstants; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        state.selectedDisplayDetailSheet = [ ...state.selectedDisplayDetailSheet, value ];
      } else {
        state.selectedDisplayDetailSheet = state.selectedDisplayDetailSheet.filter( m => m !== value );
      }
    }
  }
} );

export const { ChangeDisplayCompetitionBreakout, ChangeDisplayDetailSheetIsSelected, ChangeDisplayLeadInLeadOutBreakout, ChangeDisplayPrimarySheetIsSelected, ChangeDisplayTime } = displaySlice.actions;

export default displaySlice.reducer;