import { createContext, useContext } from "react";

export type ApplicationPanelContextType = {
  selectedNodeId: number | undefined;
  setSelectedNodeId: ( id: number | undefined ) => void;

  renamingNodeId: number | undefined;
  setRenamingNodeId: ( id: number | undefined ) => void;

  availableApplicationIds: Array<number>;

  applicationNames: Record<number, string>;
};

export const ApplicationPanelContext = createContext<ApplicationPanelContextType>( {} as ApplicationPanelContextType );

export const useApplicationPanel = () => useContext( ApplicationPanelContext );
