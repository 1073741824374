/** @jsxImportSource @emotion/react */
import React from "react";
import { BenchmarkDatesConstants, BenchmarkDayOfWeekTypeConstants, BenchmarkTimePeriodConstants, DaysConstants, DemoTypeConstants, Measure } from "model/Model";
import { BenchmarkDemoTypeOption, BenchmarkQuarterOption } from "../../Model";
import { BenchmarkDaySelector } from "./benchmark-day-selector/BenchmarkDaySelector";
import { DropdownSelector } from "components/dropdown-selector/DropdownSelector";
import { BenchmarkDemoTypeSelector } from "./benchmark-demo-type-selector/BenchmarkDemoTypeSelector";
import { BenchmarkDatesSelector } from "./benchmark-dates-selector/BenchmarkDatesSelector";
import { BenchmarkTimePeriodSelector } from "./benchmark-time-period-selector/BenchmarkTimePeriodSelector";
import { Selectable } from "Utilities";
import { css } from "@emotion/react";

const styles = {
  benchmarkSelector: css``,

  stack: css`
    display: inline-block;
    vertical-align: top;
  `
};

export const BenchmarkSelector = ( { demoCount, demoItems, demoTypes, measures, dates, quarters, fromDate, toDate, timePeriod, timePeriodFromTime, timePeriodToTime, benchmarkDayOfWeekType, benchmarkDays, onChangeBenchmarkDemo, onChangeBenchmarkDemoType, onChangeBenchmarkDates, onChangeBenchmarkQuarterIsSelected, onChangeBenchmarkFromDate, onChangeBenchmarkToDate, onChangeBenchmarkTimePeriod, onChangeBenchmarkTimePeriodFromTime, onChangeBenchmarkTimePeriodToTime, onChangeBenchmarkDayOfWeekType, onChangeBenchmarkDaysSelected }: { demoCount: number; demoItems: Array<{ name: string; value: number; }>; demoTypes: Array<BenchmarkDemoTypeOption>; measures: Array<Selectable<Measure>>; dates: BenchmarkDatesConstants; quarters: Array<BenchmarkQuarterOption>; fromDate: number; toDate: number; timePeriod: BenchmarkTimePeriodConstants; timePeriodFromTime: string; timePeriodToTime: string; benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants; benchmarkDays: Array<DaysConstants>; onChangeBenchmarkDemo: ( value: number ) => void; onChangeBenchmarkDemoType: ( value: DemoTypeConstants, isSelected: boolean ) => void; onChangeBenchmarkDates: ( value: number ) => void; onChangeBenchmarkQuarterIsSelected: ( value: number, isSelected: boolean ) => void; onChangeBenchmarkFromDate: ( value: number ) => void; onChangeBenchmarkToDate: ( value: number ) => void; onChangeBenchmarkTimePeriod: ( value: BenchmarkTimePeriodConstants ) => void; onChangeBenchmarkTimePeriodFromTime: ( value: string ) => void; onChangeBenchmarkTimePeriodToTime: ( value: string ) => void; onChangeBenchmarkDayOfWeekType: ( value: BenchmarkDayOfWeekTypeConstants ) => void; onChangeBenchmarkDaysSelected: ( days: Array<DaysConstants> ) => void; } ): JSX.Element => ( <>
  <div css={ styles.benchmarkSelector }>
    <div css={ styles.stack }>
      <DropdownSelector label="Demos" helpId="benchmark" value={ demoCount } items={ demoItems } onChangeItemSelected={ onChangeBenchmarkDemo } />
      <BenchmarkDemoTypeSelector helpId="" typeOptions={ demoTypes } count={ demoCount } measures={ measures } onChangeDemoTypeSelected={ onChangeBenchmarkDemoType } />
    </div>
    <div css={ styles.stack }>
      <BenchmarkTimePeriodSelector helpId="" isEnabled={ demoCount > 0 } timePeriod={ timePeriod } timePeriodFromTime={ timePeriodFromTime } timePeriodToTime={ timePeriodToTime } onChangeTimePeriod={ onChangeBenchmarkTimePeriod } onChangeTimePeriodFromTime={ onChangeBenchmarkTimePeriodFromTime } onChangeTimePeriodToTime={ onChangeBenchmarkTimePeriodToTime } />
      <BenchmarkDatesSelector helpId="" isEnabled={ demoCount > 0 } dates={ dates } quarters={ quarters } fromDate={ fromDate } toDate={ toDate } onChangeDates={ onChangeBenchmarkDates } onChangeQuarterIsSelected={ onChangeBenchmarkQuarterIsSelected } onChangeFromDate={ onChangeBenchmarkFromDate } onChangeToDate={ onChangeBenchmarkToDate } />
    </div>
    <div css={ styles.stack }>
      <BenchmarkDaySelector helpId="" isEnabled={ demoCount > 0 } benchmarkDayOfWeekType={ benchmarkDayOfWeekType } benchmarkDays={ benchmarkDays } onChangeBenchmarkDayOfWeekType={ onChangeBenchmarkDayOfWeekType } onChangeBenchmarkDaysSelected={ onChangeBenchmarkDaysSelected } />
    </div>
  </div>
</> );
