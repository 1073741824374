/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { css } from "@emotion/react";
import { ReportTypeConstants } from "../../Model";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  country: css`
    display: inline-block;
    width: 180px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const ReportTypeDescription = ( { reportType, warningMessage }: { reportType: ReportTypeConstants; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    switch ( reportType ) {
      case ReportTypeConstants.Imports: results.push( ( <span css={ styles.displayGroupSpace } key={ "reportType" }>{ "Imports." }</span> ) ); break;
      case ReportTypeConstants.ImportsAndLocal: results.push( ( <span css={ styles.displayGroupSpace } key={ "reportType" }>{ "Imports / Local." }</span> ) ); break;
    }

    return results;
  }, [ reportType ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
