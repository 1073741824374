import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";

type Point = {
  x: number;
  y: number;
};

type ContextType = {
  visible: boolean;
  location: Point;
  menu: ReactNode | undefined;
  show: ( x: number, y: number, menu: ReactNode ) => void;
  hide: () => void;
};

const ContextMenuContext = createContext<ContextType>( {} as ContextType );

export const ContextMenuProvider = ( { children }: { children: React.ReactNode; } ) => {
  const [ location, setLocation ] = useState<Point>( { x: 0, y: 0 } );
  const [ visible, setVisible ] = useState<boolean>( false );
  const [ menu, setMenu ] = useState<ReactNode | undefined>( undefined );

  const show = useCallback( ( x: number, y: number, menu: ReactNode ) => {
    setLocation( { x, y } );
    setMenu( menu );
    setVisible( true );
  }, [] );

  const hide = useCallback( () => {
    setMenu( undefined );
    setVisible( false );
  }, [] );

  const state = useMemo( () => {
    return ( {
      visible,
      location,
      menu,
      show,
      hide
    } );
  }, [ location, menu, visible, show, hide ] );

  useEffect( () => {
    if ( !visible ) return;

    const handleClick = ( e: MouseEvent ) => {
      e.preventDefault();
      e.stopPropagation();
      setVisible( false );
    };

    const preventClick = ( e: MouseEvent | KeyboardEvent ) => {
      e.preventDefault();
      e.stopPropagation();
    };

    globalThis.window.addEventListener( "click", handleClick );
    globalThis.window.addEventListener( "contextmenu", preventClick );
    globalThis.window.addEventListener( "keydown", preventClick );

    return () => {
      globalThis.window.removeEventListener( "click", handleClick );
      globalThis.window.removeEventListener( "contextmenu", preventClick );
      globalThis.window.removeEventListener( "keydown", preventClick );
    };
  }, [ visible ] );

  return <ContextMenuContext.Provider value={ state }>
    { children }
    { visible && createPortal( menu, document.body ) }
  </ContextMenuContext.Provider>;
};

export const useContextMenu = () => useContext( ContextMenuContext );
