import { createAction, createReducer } from "@reduxjs/toolkit";

export const ChangeFromTime = createAction<string>( "ChangeFromTime" );

const fromTimesReducers = createReducer(
  "00:00",
  builder => {
    builder.addCase( ChangeFromTime, ( _, action ) => action.payload );
  }
);

export default fromTimesReducers;