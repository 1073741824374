/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { DisplaySortedByConstants, DisplayTimeConstants } from "../../Model";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { DisplayColumn } from "../../Model";
import { Selectable } from "Utilities";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";
import { DisplayColumnSelector } from "./display-column-selector/DisplayColumnSelector";

const displayTimeOptions = [
  { value: DisplayTimeConstants.TotalMinutes, name: "Total Minutes" },
  { value: DisplayTimeConstants.HoursMinutes, name: "Hours:Minutes" },
  { value: DisplayTimeConstants.HoursMinutesSeconds, name: "Hours:Minutes:Seconds" } ];

export const DisplaySelector = ( { displayColumns, displaySortedBy, revenueSelected, displayTime, onChangeDisplayColumnIsSelected, onChangeDisplaySortedBy, onChangeDisplayTime }: { displayColumns: Array<Selectable<DisplayColumn>>; displaySortedBy: DisplaySortedByConstants; revenueSelected: boolean; displayTime: DisplayTimeConstants; onChangeDisplayColumnIsSelected: ( id: string, isSelected: boolean ) => void; onChangeDisplaySortedBy: ( value: DisplaySortedByConstants ) => void; onChangeDisplayTime: ( value: DisplayTimeConstants ) => void; } ): JSX.Element => {
  const displaySortedByItems = useMemo( () => [
    { name: "Country/Channel", value: DisplaySortedByConstants.CountryChannel, isEnabled: true },
    { name: "Country/Date", value: DisplaySortedByConstants.CountryDate, isEnabled: true },
    { name: "Title", value: DisplaySortedByConstants.Title, isEnabled: true },
    { name: "Start Date", value: DisplaySortedByConstants.StartDate, isEnabled: true },
    { name: "Rank", value: DisplaySortedByConstants.Rank, isEnabled: true },
    { name: "Distributor", value: DisplaySortedByConstants.Distributor, isEnabled: true },
    { name: "Show Type", value: DisplaySortedByConstants.ShowType, isEnabled: true },
    { name: "Production Country", value: DisplaySortedByConstants.ProductionCountry, isEnabled: true },
    { name: "Revenue", value: DisplaySortedByConstants.Revenue, isEnabled: revenueSelected }
  ], [ revenueSelected ] );

  return ( <>
    <DisplayColumnSelector helpId="displayAntiColumn" displayColumns={ displayColumns } onChangeDisplayColumnIsSelected={ onChangeDisplayColumnIsSelected } />
    <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Sorted by" helpId="display_sorted_by" radioGroup="display_sorted_by" value={ displaySortedBy } items={ displaySortedByItems } onChangeItemSelected={ onChangeDisplaySortedBy } />
    <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Time" helpId="display_time_period" radioGroup="display_time" value={ displayTime } items={ displayTimeOptions } onChangeItemSelected={ onChangeDisplayTime } />
  </> );
};
