/** @jsxImportSource @emotion/react */
import React from "react";
import { DropdownSelector } from "components/dropdown-selector/DropdownSelector";

export const transmissionDurationItems = [
  { value: 0, name: "All ", title: "All transmission lengths." },
  { value: 60, name: "1 minute", title: "Transmissions >= 1 minute." },
  { value: 120, name: "2 minutes", title: "Transmissions >= 2 minutes." },
  { value: 180, name: "3 minutes", title: "Transmissions >= 3 minutes." },
  { value: 240, name: "4 minutes", title: "Transmissions >= 4 minutes." },
  { value: 300, name: "5 minutes", title: "Transmissions >= 5 minutes." },
  { value: 600, name: "10 minutes", title: "Transmissions >= 10 minutes." },
  { value: 900, name: "15 minutes", title: "Transmissions >= 15 minutes." },
  { value: 1200, name: "20 minutes", title: "Transmissions >= 20 minutes." },
  { value: 1800, name: "30 minutes", title: "Transmissions >= 30 minutes." },
  { value: 2100, name: "35 minutes", title: "Transmissions >= 35 minutes." },
  { value: 2700, name: "45 minutes", title: "Transmissions >= 45 minutes." },
  { value: 3000, name: "50 minutes", title: "Transmissions >= 50 minutes." },
  { value: 3600, name: "1 hour", title: "Transmissions >= 1 hour." },
  { value: 5400, name: "1 hour 30 minutes", title: "Transmissions >= 1 hour 30 minutes." },
  { value: 7200, name: "2 hours", title: "Transmissions >= 2 hours." },
  { value: 9000, name: "2 hours 30 minutes", title: "Transmissions >= 2 hours 30 minutes." },
  { value: 10800, name: "3 hours", title: "Transmissions >= 3 hours." }
];

export const TransmissionDurationSelector = ( { transmissionDuration, onChangeTransmissionDuration }: { transmissionDuration: number; onChangeTransmissionDuration: ( value: number ) => void; } ): JSX.Element => (
  <DropdownSelector label="Minimum length" helpId="datetime_minimum_transmission_length" isEnabled={ true } items={ transmissionDurationItems } value={ transmissionDuration } onChangeItemSelected={ onChangeTransmissionDuration } />
);
