import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { Model, ProgramFinderSourceModel } from "../Model";
import countriesSlice from "reducers/countriesSlice";
import titleSlice from "reducers/titleReducer";
import outputSlice from "reducers/outputSlice";

const initializeModel = ( data: ProgramFinderSourceModel ): Model => {
  const model = {
    ...data
  };

  // FUTURE: more validation
  if ( model.output === undefined )                                  /* */ throw new Error( "ProgramFinder.output is undefined" );
  if ( model.output.format === undefined )                           /* */ throw new Error( "ProgramFinder.output.format is undefined" );
  if ( model.output.view === undefined )                             /* */ throw new Error( "ProgramFinder.output.view is undefined" );
  if ( model.selectedCountries === undefined )                       /* */ throw new Error( "ProgramFinder.selectedCountries is undefined" );
  if ( model.title === undefined )                                   /* */ throw new Error( "ProgramFinder.title is undefined" );
  if ( model.title.compareOperator === undefined )                   /* */ throw new Error( "ProgramFinder.title.compareOperator is undefined" );
  if ( model.title.resultCount === undefined )                       /* */ throw new Error( "ProgramFinder.title.resultCount is undefined" );
  if ( model.title.originalOrLocal === undefined )                   /* */ throw new Error( "ProgramFinder.title.originalOrLocal is undefined" );
  if ( model.title.numberOfCharactersToCompare === undefined )       /* */ throw new Error( "ProgramFinder.title.numberOfCharactersToCompare is undefined" );
  if ( model.title.searchTerm === undefined )                        /* */ throw new Error( "ProgramFinder.title.searchTerm is undefined" );
  if ( model.title.titleNames === undefined )                        /* */ throw new Error( "ProgramFinder.title.titleNames is undefined" );

  return model;
};

export const getStore = ( initialData: ProgramFinderSourceModel ) => configureStore( {
  preloadedState: initializeModel( initialData ),
  reducer: {
    selectedCountries: countriesSlice,
    title: titleSlice,
    output: outputSlice
  }
} );

type StoreType = ReturnType<typeof getStore>;

export type AppDispatch = StoreType[ "dispatch" ];
export type RootState = ReturnType<StoreType[ "getState" ]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
