import { OutputViewConstants } from "./Model";

export type RunningState = {
  id: number | undefined;
  percentComplete: number | undefined;
  status: "" | "Started" | "Completed" | "Failed" | "Cancelled" | "EmptyResult";
  message: string;
  url: string;
  contentType: string;
  cancellationRequested: boolean;
};

export const emptyRunning: RunningState = { message: "", url: "", status: "", id: undefined, percentComplete: undefined, contentType: "", cancellationRequested: false };

export const downloadFile = ( url: string, token: string ) => {
  const link = document.createElement( "a" );
  link.setAttribute( "download", "" );
  link.href = url + "&jwt=" + token;
  document.body.appendChild( link );
  link.click();
  link.remove();
};

export const openLink = ( url: string, applicationWindow: string, view: OutputViewConstants ) => {
  console.log( url );

  switch ( view ) {
    case OutputViewConstants.ReportTab:
      window.open( url, "ReportTab" )?.focus();
      break;
    case OutputViewConstants.ApplicationTab:
      window.open( url, applicationWindow )?.focus();
      break;
    case OutputViewConstants.NewTab:
      window.open( url, "_blank" )?.focus();
      break;
  }
};