import { ReferenceData } from "Utilities";
import { createContext, useContext } from "react";

const ReferenceDataContext = createContext<ReferenceData>( {} as ReferenceData );

export const ReferenceDataProvider = ( { referenceData, children }: { referenceData: ReferenceData; children: React.ReactNode; } ) => {
  return <ReferenceDataContext.Provider value={ referenceData }>{ children }</ReferenceDataContext.Provider>;
};

export const useReferenceData = () => useContext( ReferenceDataContext );
