/** @jsxImportSource @emotion/react */
import { RunningState } from "model/RunningState";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { OutputViewConstants } from "model/Model";
import { useCallback } from "react";

const styles = {
  block: css`
    margin-left: 5px; 
    margin-top: 2px; 
    height: 30px; 
    line-height: 30px;
  `,

  button: css`
    margin-right: 5px;
  `,

  graph: css`
    width: 200px;
    height: 20px;
    top: 6px;
    border: 1px solid #888;
    position: relative;
    display: inline-block;
    margin-right: 5px;
  `,

  progressBar: css`
    position: absolute;
    top: 0;
    background: -webkit-linear-gradient(
      top,
      rgba(255, 197, 120, 1) 0%,
      rgba(244, 128, 38, 1) 100%
    );
    border-top: 1px solid #fceabb;
    height: 20px;
  `,

  percentage: css`
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    line-height: 19px;
    `,

  message: css`
    display: inline-block;
    line-height: 22px;
    `,
  linkButtonWrapper: css`
      border: 1px solid #4f4f4f;  
      border-radius: 2px;
      background-color: buttonface;
    `
  ,
  linkButton: css`
    display:block;
    text-decoration: none;
    line-height: 18px;
    
     padding-left: 6px;
     padding-right: 6px;
    
    &:visited {
      color: black;
    }
    
    &:hover {
      cursor: default; 
      background-color: #e5e5e5;
    }

    span {
      display:inline-block;
      color: #000000;
      font-family: "Arial";
      font-size: 13.33333px;
    }
  `
};

export const Runner = ( { running, isValidForSubmit, doSubmit, doCancel, view, applicationWindow }: { running: RunningState; isValidForSubmit: boolean; doSubmit: () => void; doCancel?: () => void; view: OutputViewConstants; applicationWindow?: string; } ) => {
  const translateViewIntoTarget = useCallback( ( view: OutputViewConstants | undefined ) => {
    switch ( view ) {
      case OutputViewConstants.ReportTab: return "ReportTab";
      case OutputViewConstants.ApplicationTab: return applicationWindow || "_blank";
      case OutputViewConstants.NewTab: return "_blank";
      default: return "_blank";
    }
  }, [ applicationWindow ] );

  return ( <>
    <div css={ styles.block }>
      <div>
        <button css={ styles.button } type="button" disabled={ running.status === "Started" || !isValidForSubmit } onClick={ doSubmit }>Submit report</button>
        <button css={ styles.button } type="button" disabled={ running.cancellationRequested || running.status !== "Started" } onClick={ doCancel }>Cancel</button>

        { running.status === "Started" && <>
          <div css={ styles.graph }>
            <div css={ [ styles.progressBar, css`width: ${ running.percentComplete }%` ] }></div>
            <div css={ styles.percentage }>{ running.percentComplete }%</div>
          </div>
          <div css={ styles.message }>{ running.message }</div>
        </> }
        { running.status === "Completed" && <div css={ [ styles.message, styles.linkButtonWrapper ] }>{ !running.url && running.message } { running.url && <Link css={ styles.linkButton } to={ running.url } target={ translateViewIntoTarget( view ) }><span>View report</span></Link> }</div> }
        { running.status === "Failed" && <div css={ styles.message }>{ running.message }</div> }
        { running.status === "EmptyResult" && <div css={ styles.message }>{ running.message }</div> }
      </div>
    </div>
  </> );
};