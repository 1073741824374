/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { DemoGroupByConstants, DemoRankConstants, DemoSelectionConstants } from "model/Model";
import { Selectable } from "Utilities";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const ProgramSearchDemoDescription = ( { demoSelection, selectedCountries, measures, demoGroupBy, demoRank, warningMessage }: { demoSelection: DemoSelectionConstants; demoGroupBy: DemoGroupByConstants; demoRank: DemoRankConstants; selectedCountries: Array<{ id: number; name: string; allDemosSelected: boolean; selectedDemos: Array<{ name: string; }>; }>; measures: Array<Selectable<{ name: string; isRanked: boolean; }>>; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    switch ( demoSelection ) {
      case DemoSelectionConstants.allIndividualsAndAllCommercials: results.push( <div key={ "demoSelection" }>All individuals and all commercials demos</div> ); break;
      case DemoSelectionConstants.allIndividuals: results.push( <div key={ "demoSelection" }>All individuals demo</div> ); break;
      case DemoSelectionConstants.allCommercials: results.push( <div key={ "demoSelection" }>All commercials demo</div> ); break;
      case DemoSelectionConstants.selectedDemos:
        if ( selectedCountries.length === 0 ) {
          results.push( ( <div key={ "none" } ><span>{ "None" }</span></div> ) );
        }
        else {
          for ( const country of selectedCountries ) {
            const demoParts = [];

            if ( country.allDemosSelected ) {
              demoParts.push( "All demos" );
            }
            else {
              for ( const demo of country.selectedDemos ) {
                demoParts.push( demo.name );
              }
            }

            if ( demoParts.length === 0 ) {
              demoParts.push( "None" );
            }

            const demoTag = selectedCountries.length > 1 ? ( <span css={ styles.displayGroupSpace }>{ country.name }</span> ) : null;
            results.push( ( <div key={ country.id } >{ demoTag }<span>{ demoParts.join( ", " ) }</span></div> ) );
          }
        }
        break;
    }

    const measureParts: Array<string> = [];
    const rankParts: Array<string> = [];
    for ( const measure of measures ) {
      if ( measure.isSelected ) {
        measureParts.push( measure.name );
        if ( measure.isRanked )
          rankParts.push( measure.name );
      }
    }

    const hasSelectedMeasures = measureParts.length > 0;
    const hasSelectedRankedMeasures = rankParts.length > 0;

    if ( hasSelectedMeasures ) {
      const groupByTag = ( <span css={ styles.displayGroupSpace }><span>grouped by</span> <span>{ demoGroupBy }.</span></span> );
      const demoTag = ( <span ><span css={ styles.displayGroupSpace }>{ measureParts.join( ", " ) }</span>{ groupByTag }</span> );
      const rankedTag = hasSelectedMeasures && hasSelectedRankedMeasures ? ( <span css={ styles.displayGroupSpace }><span css={ styles.displayGroupSpace }>Rank</span><span>{ rankParts.join( ", " ) }</span></span> ) : null;
      const demoRankTag = hasSelectedMeasures && hasSelectedRankedMeasures ? ( <span css={ styles.displayGroupSpace }><span css={ styles.displayGroupSpace }>for</span>{ demoRank === "first" ? "First demo" : "All demos" }.</span> ) : null;

      results.push( ( <div key={ "demoDisplay" }><span css={ styles.displayGroupSpace }>Display</span>{ demoTag }{ rankedTag }{ demoRankTag }</div> ) );
    }
    else {
      results.push( ( <div key={ "demoDisplay" }>&nbsp;</div> ) );
    }
    return results;
  }, [ demoGroupBy, demoRank, demoSelection, measures, selectedCountries ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  return ( <>{ parts }</> );
};
