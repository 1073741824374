/** @jsxImportSource @emotion/react */
import React from "react";
import { Country, DayPart } from "model/Model";
import { Selectable } from "Utilities";
import { css } from "@emotion/react";

const styles = {
  dayPartSelector: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }

        input {
          margin-top: 0;
          margin-left: 0;
          vertical-align: middle;

          span {
            vertical-align: middle;
          }
        }
      }
  
      label {
        display: block;
        
        input{
          margin: 0 5px 0 0;
          vertical-align: middle;
        }

        span {
          vertical-align: middle;
        }
      }
    }
  `
};

export const DayPartSelector = ( { isEnabled = true, selectedCountries, onChangeUseDayParts, onChangeDayPartIsSelected }: { isEnabled?: boolean; selectedCountries: Array<Country & { dayParts: Array<Selectable<DayPart>>; }>; onChangeUseDayParts( useDayParts: boolean ): void; onChangeDayPartIsSelected: ( countryId: number, dayPartId: number, isSelected: boolean ) => void; } ): JSX.Element => ( <>
  <div css={ styles.dayPartSelector }>
    <fieldset>
      <legend><label><input type="radio" checked={ isEnabled } onChange={ () => onChangeUseDayParts( true ) }></input><span>Dayparts</span></label></legend>
      { selectedCountries.map( ( country ) => ( <CountryDayParts isEnabled={ isEnabled } key={ country.id } { ...country } onSelectDayPartHandler={ onChangeDayPartIsSelected }></CountryDayParts> ) ) }
    </fieldset>
  </div>
</> );

const CountryDayParts = ( { isEnabled = true, id, name, dayParts, onSelectDayPartHandler }: { isEnabled: boolean; id: number; name: string; dayParts: Array<Selectable<DayPart>>; onSelectDayPartHandler: ( countryId: number, dayPartId: number, isSelected: boolean ) => void; } ): JSX.Element => ( <>
  <div css={ styles.dayPartSelector }>
    <fieldset>
      <legend> <label><span>{ name }</span></label></legend>
      { dayParts.map( ( dayPart ) => ( <label key={ `${ id }_${ dayPart.id }` } title={ dayPart.tooltip }><input disabled={ !isEnabled } type="checkbox" onChange={ ( e ) => onSelectDayPartHandler( id, dayPart.id, e.currentTarget.checked ) } checked={ dayPart.isSelected } /><span>{ `${ dayPart.time } ${ dayPart.name }` }</span></label> ) ) }
    </fieldset>
  </div>
</> );
