import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Average, AverageCollapsePeriodConstants, AverageTitleConstants, YesNoConstants } from "model/Model";

const averageSlice = createSlice( {
  name: "average",
  initialState: {
    collapsePeriod: AverageCollapsePeriodConstants.IndividualAirings,
    combineWithin: 30,
    title: AverageTitleConstants.OriginalThenLocal,
    breakoutSeasons: YesNoConstants.No,
    breakoutOriginalRepeat: YesNoConstants.No,
    localRepeat: YesNoConstants.No
  } as Average,
  reducers: {
    ChangeAverageBreakoutOriginalRepeat( state, action: PayloadAction<YesNoConstants> ) { state.breakoutOriginalRepeat = action.payload; },
    ChangeAverageBreakoutSeasons( state, action: PayloadAction<YesNoConstants> ) { state.breakoutSeasons = action.payload; },
    ChangeAverageCollapsePeriod( state, action: PayloadAction<AverageCollapsePeriodConstants> ) { state.collapsePeriod = action.payload; },
    ChangeAverageCombineWithin( state, action: PayloadAction<number> ) { state.combineWithin = action.payload; },
    ChangeAverageLocalRepeat( state, action: PayloadAction<YesNoConstants> ) { state.localRepeat = action.payload; },
    ChangeAverageTitle( state, action: PayloadAction<AverageTitleConstants> ) { state.title = action.payload; }
  }
} );

export const { ChangeAverageBreakoutOriginalRepeat, ChangeAverageBreakoutSeasons, ChangeAverageCollapsePeriod, ChangeAverageCombineWithin, ChangeAverageLocalRepeat, ChangeAverageTitle } = averageSlice.actions;

export default averageSlice.reducer;