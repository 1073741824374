import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { Model, ProgramAverageTimeSpentSourceModel } from "../Model";
import thunkMiddleware from "redux-thunk";
import { parseDateOrUndefined } from "Utilities";
import countriesSlice from "reducers/countriesSlice";
import demoGroupBySlice from "reducers/demoGroupBySlice";
import demoRankSlice from "reducers/demoRankSlice";
import demoSelectionSlice from "reducers/demoSelectionSlice";
import measuresWithIsRankedSlice from "reducers/measuresWithIsRankedSlice";
import outputSlice from "reducers/outputSlice";
import demosSlice from "reducers/demosSlice";
import stationsSlice from "reducers/stationsSlice";
import reportTypeSlice from "../components/report-type-selector/reportTypeSlice";
import qualifySlice from "../components/qualify-selector/qualifySlice";
import displaySlice from "../components/display-selector/displaySlice";
import dateTimeReducers from "../components/date-time-selector/dateTimeReducers";

const initializeModel = ( data: ProgramAverageTimeSpentSourceModel ): Model => {
  const model = {
    ...data,
    dateTime: {
      ...data.dateTime,
      fromDate: parseDateOrUndefined( data.dateTime.fromDate ),
      toDate: parseDateOrUndefined( data.dateTime.toDate )
    }
  };

  // FUTURE: more validation
  if ( model.countries === undefined )                                            /* */ throw new Error( "ProgramAverageTimeSpent.countries is undefined" );
  if ( model.dateTime === undefined )                                             /* */ throw new Error( "ProgramAverageTimeSpent.dateTime is undefined" );
  if ( model.dateTime.days === undefined )                                        /* */ throw new Error( "ProgramAverageTimeSpent.dateTime.days is undefined" );
  if ( model.dateTime.transmissionDuration === undefined )                        /* */ throw new Error( "ProgramAverageTimeSpent.dateTime.transmissionDuration is undefined" );
  if ( model.dateTime.fromTime === undefined )                                    /* */ throw new Error( "ProgramAverageTimeSpent.dateTime.fromTime is undefined" );
  if ( model.dateTime.toTime === undefined )                                      /* */ throw new Error( "ProgramAverageTimeSpent.dateTime.toTime is undefined" );
  if ( model.dateTime.useDayParts === undefined )                                 /* */ throw new Error( "ProgramAverageTimeSpent.dateTime.useDayParts is undefined" );
  if ( model.dateTime.dayParts === undefined )                                    /* */ throw new Error( "ProgramAverageTimeSpent.dateTime.dayParts is undefined" );
  if ( model.demoGroupBy === undefined )                                          /* */ throw new Error( "ProgramAverageTimeSpent.demoGroupBy is undefined" );
  if ( model.demoRank === undefined )                                             /* */ throw new Error( "ProgramAverageTimeSpent.demoRank is undefined" );
  if ( model.demoSelection === undefined )                                        /* */ throw new Error( "ProgramAverageTimeSpent.demoSelection is undefined" );
  if ( model.demos === undefined )                                                /* */ throw new Error( "ProgramAverageTimeSpent.demos is undefined" );
  if ( model.display === undefined )                                              /* */ throw new Error( "ProgramAverageTimeSpent.display is undefined" );
  if ( model.display.selectedDisplayColumns === undefined )                       /* */ throw new Error( "ProgramAverageTimeSpent.display.selectedDisplayColumns is undefined" );
  if ( model.display.displaySortedBy === undefined )                              /* */ throw new Error( "ProgramAverageTimeSpent.display.displaySortedBy is undefined" );
  if ( model.display.displayTop === undefined )                                   /* */ throw new Error( "ProgramAverageTimeSpent.display.displayTop is undefined" );
  if ( model.display.displayUnique === undefined )                                /* */ throw new Error( "ProgramAverageTimeSpent.display.displayUnique is undefined" );
  if ( model.display.displayTime === undefined )                                  /* */ throw new Error( "ProgramAverageTimeSpent.display.displayTime is undefined" );
  if ( model.measures === undefined )                                             /* */ throw new Error( "ProgramAverageTimeSpent.measures is undefined" );
  if ( model.output === undefined )                                               /* */ throw new Error( "ProgramAverageTimeSpent.output is undefined" );
  if ( model.output.format === undefined )                                        /* */ throw new Error( "ProgramAverageTimeSpent.output.format is undefined" );
  if ( model.output.view === undefined )                                          /* */ throw new Error( "ProgramAverageTimeSpent.output.view is undefined" );
  if ( model.qualify === undefined )                                              /* */ throw new Error( "ProgramAverageTimeSpent.qualify is undefined" );
  if ( model.qualify.selectedTypes === undefined )                                /* */ throw new Error( "ProgramAverageTimeSpent.qualify.selectedTypes is undefined" );
  if ( model.qualify.selectedClassOnes === undefined )                            /* */ throw new Error( "ProgramAverageTimeSpent.qualify.selectedClassOnes is undefined" );
  if ( model.qualify.selectedClassTwos === undefined )                            /* */ throw new Error( "ProgramAverageTimeSpent.qualify.selectedClassTwos is undefined" );
  if ( model.qualify.selectedFormats === undefined )                              /* */ throw new Error( "ProgramAverageTimeSpent.qualify.selectedFormats is undefined" );
  if ( model.qualify.selectedDistributors === undefined )                         /* */ throw new Error( "ProgramAverageTimeSpent.qualify.selectedDistributors is undefined" );
  if ( model.qualify.selectedProductionCountries === undefined )                  /* */ throw new Error( "ProgramAverageTimeSpent.qualify.selectedProductionCountries is undefined" );
  if ( model.qualify.selectedProductionCountryPositions === undefined )           /* */ throw new Error( "ProgramAverageTimeSpent.qualify.selectedProductionCountryPositions is undefined" );
  if ( model.qualify.selectedLocalGenres === undefined )                          /* */ throw new Error( "ProgramAverageTimeSpent.qualify.selectedLocalGenres is undefined" );
  if ( model.reportType === undefined )                                           /* */ throw new Error( "ProgramAverageTimeSpent.reportType is undefined" );
  if ( model.reportType.reportType === undefined )                                /* */ throw new Error( "ProgramAverageTimeSpent.reportType.reportType is undefined" );

  if ( model.stations === undefined )                                             /* */ throw new Error( "ProgramAverageTimeSpent.stations is undefined" );

  return model;
};

export const getStore = ( initialData: ProgramAverageTimeSpentSourceModel ) => configureStore( {
  preloadedState: initializeModel( initialData ),
  reducer: {
    countries: countriesSlice,
    stations: stationsSlice,
    demos: demosSlice,
    measures: measuresWithIsRankedSlice,
    demoRank: demoRankSlice,
    demoGroupBy: demoGroupBySlice,
    demoSelection: demoSelectionSlice,
    dateTime: dateTimeReducers,
    reportType: reportTypeSlice,
    qualify: qualifySlice,
    display: displaySlice,
    output: outputSlice
  },
  middleware: [ thunkMiddleware ]
} );

type StoreType = ReturnType<typeof getStore>;

export type AppDispatch = StoreType[ "dispatch" ];
export type RootState = ReturnType<StoreType[ "getState" ]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
