/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { DisplayRankTitlesConstants, DisplayTitlesDisplayedConstants } from "apps/RevenueEstimator/Model";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const DisplayDescription = ( { displayRankTitles, displayTitlesDisplayed, warningMessage }: { displayRankTitles: DisplayRankTitlesConstants; displayTitlesDisplayed: DisplayTitlesDisplayedConstants; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    const displayRankTitlesText = ( ( value ): string => {
      switch ( value ) {
        case DisplayRankTitlesConstants.Telecasts: return "Rank titles by number of telecasts.";
        case DisplayRankTitlesConstants.AverageRevenue: return "Rank titles by average revenue.";
      }
    } )( displayRankTitles );

    results.push( ( <span key={ 1 } css={ styles.displayGroupSpace }>{ displayRankTitlesText }</span> ) );

    const displayTitlesDisplayedText = ( ( value ): string => {
      switch ( value ) {
        case DisplayTitlesDisplayedConstants.All: return "All titles displayed.";
        case DisplayTitlesDisplayedConstants.Top3: return "Top 3 titles displayed.";
        case DisplayTitlesDisplayedConstants.Top5: return "Top 5 titles displayed.";
        case DisplayTitlesDisplayedConstants.Top10: return "Top 10 titles displayed.";
        case DisplayTitlesDisplayedConstants.Top25: return "Top 25 titles displayed.";
      }
    } )( displayTitlesDisplayed );

    results.push( ( <span key={ 2 } css={ styles.displayGroupSpace }>{ displayTitlesDisplayedText }</span> ) );
    return results;
  }, [ displayRankTitles, displayTitlesDisplayed ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
