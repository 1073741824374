/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { Index } from "apps/ProgramAverageTimeSpent/Index";
import { appendCountriesById, appendDayPartsByCountryIdAndId, appendDemosByCountryIdAndId, appendMeasuresById, appendStationsByCountryIdAndId, appendUsersAndGroupsById, enrichCountries, appendLocalGenresByCountryIdAndId, CountriesById, appendTemplateNameAndSharedWith } from "model/InitialModel";
import { HelpContext } from "components/help-text/HelpText";
import { ApplicationContext, ApplicationContextBaseType } from "components/application-context/ApplicationContext";
import { HelpText, getApplicationDisplayName } from "Utilities";
import { getStore } from "apps/ProgramAverageTimeSpent/app/store";
import { Applications } from "constants/application";
import { Params, useLoaderData } from "react-router";
import { DisplayColumn, ProgramAverageTimeSpentSourceModel } from "./Model";
import { Country, DemoSelectionConstants, DemoTypeConstants, ReportTypeSummary, Station, TemplateOrHistory } from "model/Model";
import { getSessionCredentials } from "contexts/UserContext";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { QualifyFormatConstants } from "constants/QualifyFormatConstants";
import { helpTextCache } from "constants/helpTextCache";
import { fetchHelpTextData, getJson } from "helpers/api";

const applicationId = Applications.ProgramAverageTimeSpent;

export type ApplicationContextType = ApplicationContextBaseType
  &
{
  readonly countriesById: CountriesById;
  readonly reportTypeSummaries: Array<ReportTypeSummary>;
  readonly displayColumns: Array<DisplayColumn>;
  readonly etsTypes: Array<{ value: string; name: string; }>;
  readonly classOnes: Array<{ value: string; name: string; }>;
  readonly classTwos: Array<{ value: string; name: string; }>;
  readonly formats: Array<{ value: QualifyFormatConstants.Original | QualifyFormatConstants.Adaptation; name: string; }>;
  readonly distributors: Array<{ value: string; name: string; }>;
  readonly productionCountries: Array<{ value: string; name: string; }>;
  readonly productionCountryPositions: Array<{ value: number; name: string; }>;
  readonly benchmarkDemoTypes: Array<{ value: number; name: string; }>;
};

type LoaderData = { id: number; helpText: HelpText; initialData: TemplateOrHistory<ProgramAverageTimeSpentSourceModel>; };

export const programAverageTimeSpentLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const id = parseInt( params.id as string || "-1" );

  let helpText: HelpText = helpTextCache[ applicationId ];
  if ( !helpText ) {
    helpText = await fetchHelpTextData( { userId, token, applicationId } );
    helpTextCache[ applicationId ] = helpText;
  }

  const initialData = await getJson<TemplateOrHistory<ProgramAverageTimeSpentSourceModel>>( "/Apps/ProgramAverageTimeSpent", userId, token, { id } );

  initialData.query.demoSelection = initialData.query.demoSelection || DemoSelectionConstants.selectedDemos; // default to selectedDemos for historical reports

  validateSourceModel( initialData.query );

  return { id, helpText, initialData };
};

const validateSourceModel = ( initialData: ProgramAverageTimeSpentSourceModel ) => {
  if ( !initialData ) throw new Error( "Source data has not been loaded" );

  if ( initialData.countries === undefined ) throw new Error( "SourceData.countries hasn't loaded correctly" );
  if ( !Array.isArray( initialData.countries ) ) throw new Error( "SourceData.countries hasn't loaded correctly" );

  if ( initialData.dateTime === undefined ) throw new Error( "SourceData.dateTime hasn't loaded correctly" );
  if ( initialData.dateTime.dayParts === undefined ) throw new Error( "SourceData.dateTime.dayParts hasn't loaded correctly" );
  if ( initialData.dateTime.days === undefined ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( !Array.isArray( initialData.dateTime.days ) ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( initialData.dateTime.fromTime === undefined ) throw new Error( "SourceData.dateTime.fromTime hasn't loaded correctly" );
  if ( initialData.dateTime.toTime === undefined ) throw new Error( "SourceData.dateTime.toTime hasn't loaded correctly" );
  if ( initialData.dateTime.transmissionDuration === undefined ) throw new Error( "SourceData.dateTime.transmissionDuration hasn't loaded correctly" );
  if ( initialData.dateTime.useDayParts === undefined ) throw new Error( "SourceData.dateTime.useDayParts hasn't loaded correctly" );

  if ( initialData.demoGroupBy === undefined ) throw new Error( "SourceData.demoGroupBy hasn't loaded correctly" );
  if ( initialData.demoRank === undefined ) throw new Error( "SourceData.demoRank hasn't loaded correctly" );
  if ( initialData.demoSelection === undefined ) throw new Error( "SourceData.demoSelection hasn't loaded correctly" );

  if ( initialData.demos === undefined ) throw new Error( "SourceData.demos hasn't loaded correctly" );
  if ( !Array.isArray( initialData.demos ) ) throw new Error( "SourceData.demos hasn't loaded correctly" );

  if ( initialData.display === undefined ) throw new Error( "SourceData.display hasn't loaded correctly" );
  if ( initialData.display.displaySortedBy === undefined ) throw new Error( "SourceData.display.displaySortedBy hasn't loaded correctly" );
  if ( initialData.display.displayTime === undefined ) throw new Error( "SourceData.display.displayTime hasn't loaded correctly" );
  if ( initialData.display.displayTop === undefined ) throw new Error( "SourceData.display.displayTop hasn't loaded correctly" );
  if ( initialData.display.displayUnique === undefined ) throw new Error( "SourceData.display.displayUnique hasn't loaded correctly" );
  if ( initialData.display.selectedDisplayColumns === undefined ) throw new Error( "SourceData.display.selectedDisplayColumns hasn't loaded correctly" );
  if ( !Array.isArray( initialData.display.selectedDisplayColumns ) ) throw new Error( "SourceData.display.selectedDisplayColumns hasn't loaded correctly" );

  if ( initialData.measures === undefined ) throw new Error( "SourceData.measures hasn't loaded correctly" );
  if ( !Array.isArray( initialData.measures ) ) throw new Error( "SourceData.measures hasn't loaded correctly" );

  if ( initialData.output === undefined ) throw new Error( "SourceData.output hasn't loaded correctly" );
  if ( initialData.output.format === undefined ) throw new Error( "SourceData.output.format hasn't loaded correctly" );
  if ( initialData.output.view === undefined ) throw new Error( "SourceData.output.view hasn't loaded correctly" );

  if ( initialData.qualify === undefined ) throw new Error( "SourceData.qualify hasn't loaded correctly" );
  if ( initialData.qualify.localGenreDisplay === undefined ) throw new Error( "SourceData.qualify.localGenreDisplay hasn't loaded correctly" );
  if ( initialData.qualify.selectedClassOnes === undefined ) throw new Error( "SourceData.qualify.selectedClassOnes hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedClassOnes ) ) throw new Error( "SourceData.qualify.selectedClassOnes hasn't loaded correctly" );
  if ( initialData.qualify.selectedClassTwos === undefined ) throw new Error( "SourceData.qualify.selectedClassTwos hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedClassTwos ) ) throw new Error( "SourceData.qualify.selectedClassTwos hasn't loaded correctly" );
  if ( initialData.qualify.selectedDistributors === undefined ) throw new Error( "SourceData.qualify.selectedDistributors hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedDistributors ) ) throw new Error( "SourceData.qualify.selectedDistributors hasn't loaded correctly" );
  if ( initialData.qualify.selectedFormats === undefined ) throw new Error( "SourceData.qualify.selectedFormats hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedFormats ) ) throw new Error( "SourceData.qualify.selectedFormats hasn't loaded correctly" );
  if ( initialData.qualify.selectedLocalGenres === undefined ) throw new Error( "SourceData.qualify.selectedLocalGenres hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedLocalGenres ) ) throw new Error( "SourceData.qualify.selectedLocalGenres hasn't loaded correctly" );
  if ( initialData.qualify.selectedProductionCountries === undefined ) throw new Error( "SourceData.qualify.selectedProductionCountries hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedProductionCountries ) ) throw new Error( "SourceData.qualify.selectedProductionCountries hasn't loaded correctly" );
  if ( initialData.qualify.selectedProductionCountryPositions === undefined ) throw new Error( "SourceData.qualify.selectedProductionCountryPositions hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedProductionCountryPositions ) ) throw new Error( "SourceData.qualify.selectedProductionCountryPositions hasn't loaded correctly" );
  if ( initialData.qualify.selectedTypes === undefined ) throw new Error( "SourceData.qualify.selectedTypes hasn't loaded correctly" );
  if ( !Array.isArray( initialData.qualify.selectedTypes ) ) throw new Error( "SourceData.qualify.selectedTypes hasn't loaded correctly" );

  if ( initialData.reportType === undefined ) throw new Error( "SourceData.reportType hasn't loaded correctly" );
  if ( initialData.stations === undefined ) throw new Error( "SourceData.stations hasn't loaded correctly" );
  if ( !Array.isArray( initialData.stations ) ) throw new Error( "SourceData.stations hasn't loaded correctly" );
};

const filterCountryGroups = ( applicationContext: ApplicationContextType ): ApplicationContextType => {
  const availableCountryGroups: Array<string> = [];
  applicationContext.countries.forEach( ( c ) => { if ( !availableCountryGroups.includes( c.group ) ) availableCountryGroups.push( c.group ); } );

  return { ...applicationContext, countryGroups: availableCountryGroups };
};

const removeQuartHourStations = ( applicationContext: ApplicationContextType ): ApplicationContextType => {
  const filteredCountriesAndStations: Array<Country> = [];
  for ( const c of applicationContext.countries ) {
    const stations: Array<Station> = [];
    for ( const m of c.stations ) {
      if ( m.optionGroup?.toUpperCase() === "QTR" ) continue;

      stations.push( m );
    }

    filteredCountriesAndStations.push( { ...c, stations } );
  }

  return { ...applicationContext, countries: filteredCountriesAndStations };
};

export const ProgramAverageTimeSpent = () => {
  const loaderData = useLoaderData() as LoaderData;
  const referenceData = useReferenceData();
  const store = getStore( loaderData.initialData.query );

  const applicationContext: ApplicationContextType = useMemo( () =>
    filterCountryGroups(
      removeQuartHourStations(
        appendTemplateNameAndSharedWith(
          loaderData.initialData.userId,
          loaderData.initialData.name,
          loaderData.initialData.sharedWith,
          appendCountriesById(
            appendStationsByCountryIdAndId(
              appendDemosByCountryIdAndId(
                appendDayPartsByCountryIdAndId(
                  appendLocalGenresByCountryIdAndId(
                    appendUsersAndGroupsById(
                      appendMeasuresById(
                        {
                          applicationId,
                          templateOrHistoryId: loaderData.id,
                          countryGroups: referenceData.countryGroups,
                          includeIdInName: referenceData.includeIdInName,
                          measures: [ { value: DemoTypeConstants.Ats, name: "ATS" }, ...referenceData.measures ],
                          benchmarkDemoTypes: referenceData.benchmarkDemoTypes,
                          sharingUsersAndGroups: referenceData.sharingUsersAndGroups,
                          displayColumns: referenceData.displayColumns[ applicationId ],
                          countries: enrichCountries( referenceData.countries.filter( m => m.hasAverageTimeSpent ) ),
                          name: getApplicationDisplayName( applicationId ),
                          etsTypes: referenceData.etsTypes,
                          classOnes: referenceData.classOnes,
                          classTwos: referenceData.classTwos,
                          reportTypeSummaries: referenceData.reportTypeSummaries,
                          formats: referenceData.formats,
                          distributors: referenceData.distributors,
                          productionCountries: referenceData.productionCountries,
                          productionCountryPositions: referenceData.productionCountryPositions
                        }
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    ), [ loaderData.id, loaderData.initialData.name, loaderData.initialData.sharedWith, loaderData.initialData.userId, referenceData.benchmarkDemoTypes, referenceData.classOnes, referenceData.classTwos, referenceData.countries, referenceData.countryGroups, referenceData.displayColumns, referenceData.distributors, referenceData.etsTypes, referenceData.formats, referenceData.includeIdInName, referenceData.measures, referenceData.productionCountries, referenceData.productionCountryPositions, referenceData.reportTypeSummaries, referenceData.sharingUsersAndGroups ] );

  return (
    <ApplicationContext.Provider value={ applicationContext } key={ loaderData.id }>
      <HelpContext.Provider value={ loaderData.helpText } >
        <Provider store={ store }>
          <Index />
        </Provider>
      </HelpContext.Provider>
    </ApplicationContext.Provider>
  );
};