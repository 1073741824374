/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { Index } from "apps/ProgramSearch/Index";
import { CountriesById, appendCountriesById, appendDayPartsByCountryIdAndId, appendDemosByCountryIdAndId, appendMeasuresById, appendStationsByCountryIdAndId, appendTemplateNameAndSharedWith, appendUsersAndGroupsById, enrichCountries } from "model/InitialModel";
import { HelpContext } from "components/help-text/HelpText";
import { ApplicationContext, ApplicationContextBaseType } from "components/application-context/ApplicationContext";
import { HelpText, getApplicationDisplayName } from "Utilities";
import { Applications } from "constants/application";
import { getStore } from "apps/ProgramSearch/app/store";
import { Params, useLoaderData } from "react-router";
import { DisplayColumn, ProgramSearchSourceModel } from "./Model";
import { getSessionCredentials } from "contexts/UserContext";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { helpTextCache } from "constants/helpTextCache";
import { DemoSelectionConstants, TemplateOrHistory } from "model/Model";
import { fetchHelpTextData, getJson } from "helpers/api";

const applicationId = Applications.ProgramSearch;

export type ApplicationContextType = ApplicationContextBaseType
  &
{
  readonly countriesById: CountriesById;
  readonly displayColumns: Array<DisplayColumn>;
  readonly benchmarkDemoTypes: Array<{ value: number; name: string; }>;
};

type LoaderData = { id: number; helpText: HelpText; initialData: TemplateOrHistory<ProgramSearchSourceModel>; };

export const programSearchLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const id = parseInt( params.id as string || "-1" );

  let helpText: HelpText = helpTextCache[ applicationId ];
  if ( !helpText ) {
    helpText = await fetchHelpTextData( { userId, token, applicationId } );
    helpTextCache[ applicationId ] = helpText;
  }

  const initialData = await getJson<TemplateOrHistory<ProgramSearchSourceModel>>( "/Apps/ProgramSearch", userId, token, { id } );

  initialData.query.demoSelection = initialData.query.demoSelection || DemoSelectionConstants.selectedDemos; // default for historical reports

  validateSourceModel( initialData.query );

  return { id, helpText, initialData };
};

const validateSourceModel = ( initialData: ProgramSearchSourceModel ) => {
  if ( !initialData ) throw new Error( "Source data has not been loaded" );

  if ( !initialData.title ) throw new Error( "SourceData.title has not been loaded" );
  if ( initialData.title.compareOperator === undefined ) throw new Error( "SourceData.title.compareOperator has not been loaded" );
  if ( initialData.title.numberOfCharactersToCompare === undefined ) throw new Error( "SourceData.title.numberOfCharactersToCompare has not been loaded" );
  if ( initialData.title.originalOrLocal === undefined ) throw new Error( "SourceData.title.originalOrLocal has not been loaded" );
  if ( initialData.title.titleNames === undefined ) throw new Error( "SourceData.title.titleNames has not been loaded" );
  if ( !Array.isArray( initialData.title.titleNames ) ) throw new Error( "SourceData.title.titleNames has not been loaded" );
  if ( initialData.title.searchTerm === undefined ) throw new Error( "SourceData.title.searchTerm has not been loaded" );

  if ( initialData.countries === undefined ) throw new Error( "SourceData.countries hasn't loaded correctly" );
  if ( !Array.isArray( initialData.countries ) ) throw new Error( "SourceData.countries hasn't loaded correctly" );

  if ( initialData.stations === undefined ) throw new Error( "SourceData.stations hasn't loaded correctly" );
  if ( !Array.isArray( initialData.stations ) ) throw new Error( "SourceData.stations hasn't loaded correctly" );

  if ( initialData.demoGroupBy === undefined ) throw new Error( "SourceData.demoGroupBy hasn't loaded correctly" );
  if ( initialData.demoRank === undefined ) throw new Error( "SourceData.demoRank hasn't loaded correctly" );
  if ( initialData.demoSelection === undefined ) throw new Error( "SourceData.demoSelection hasn't loaded correctly" );

  if ( initialData.demos === undefined ) throw new Error( "SourceData.demos hasn't loaded correctly" );
  if ( !Array.isArray( initialData.demos ) ) throw new Error( "SourceData.demos hasn't loaded correctly" );

  if ( initialData.dateTime === undefined ) throw new Error( "SourceData.dateTime hasn't loaded correctly" );
  if ( initialData.dateTime.dayParts === undefined ) throw new Error( "SourceData.dateTime.dayParts hasn't loaded correctly" );
  if ( initialData.dateTime.days === undefined ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( !Array.isArray( initialData.dateTime.days ) ) throw new Error( "SourceData.dateTime.days hasn't loaded correctly" );
  if ( initialData.dateTime.fromTime === undefined ) throw new Error( "SourceData.dateTime.fromTime hasn't loaded correctly" );
  if ( initialData.dateTime.toTime === undefined ) throw new Error( "SourceData.dateTime.toTime hasn't loaded correctly" );
  if ( initialData.dateTime.transmissionDuration === undefined ) throw new Error( "SourceData.dateTime.transmissionDuration hasn't loaded correctly" );
  if ( initialData.dateTime.useDayParts === undefined ) throw new Error( "SourceData.dateTime.useDayParts hasn't loaded correctly" );

  if ( initialData.average === undefined ) throw new Error( "SourceData.average hasn't loaded correctly" );
  if ( initialData.average.breakoutOriginalRepeat === undefined ) throw new Error( "SourceData.average.breakoutOriginalRepeat hasn't loaded correctly" );
  if ( initialData.average.breakoutSeasons === undefined ) throw new Error( "SourceData.average.breakoutSeasons hasn't loaded correctly" );
  if ( initialData.average.collapsePeriod === undefined ) throw new Error( "SourceData.average.collapsePeriod hasn't loaded correctly" );
  if ( initialData.average.combineWithin === undefined ) throw new Error( "SourceData.average.combineWithin hasn't loaded correctly" );
  if ( initialData.average.localRepeat === undefined ) throw new Error( "SourceData.average.localRepeat hasn't loaded correctly" );
  if ( initialData.average.title === undefined ) throw new Error( "SourceData.average.title hasn't loaded correctly" );

  if ( initialData.revenue === undefined ) throw new Error( "SourceData.revenue hasn't loaded correctly" );
  if ( initialData.revenue.revenueAmount === undefined ) throw new Error( "SourceData.revenue.revenueAmount hasn't loaded correctly" );
  if ( initialData.revenue.revenueCollapse === undefined ) throw new Error( "SourceData.revenue.revenueCollapse hasn't loaded correctly" );
  if ( initialData.revenue.revenueDuration === undefined ) throw new Error( "SourceData.revenue.revenueDuration hasn't loaded correctly" );

  if ( initialData.benchmark === undefined ) throw new Error( "SourceData.benchmark hasn't loaded correctly" );
  if ( initialData.benchmark.benchmarkDayOfWeekType === undefined ) throw new Error( "SourceData.benchmark.benchmarkDayOfWeekType hasn't loaded correctly" );
  if ( initialData.benchmark.benchmarkDays === undefined ) throw new Error( "SourceData.benchmark.benchmarkDays hasn't loaded correctly" );
  if ( !Array.isArray( initialData.benchmark.benchmarkDays ) ) throw new Error( "SourceData.benchmark.benchmarkDays hasn't loaded correctly" );
  if ( initialData.benchmark.dates === undefined ) throw new Error( "SourceData.benchmark.dates hasn't loaded correctly" );
  if ( initialData.benchmark.demoCount === undefined ) throw new Error( "SourceData.benchmark.demoCount hasn't loaded correctly" );
  if ( initialData.benchmark.demoTypes === undefined ) throw new Error( "SourceData.benchmark.demoTypes hasn't loaded correctly" );
  if ( !Array.isArray( initialData.benchmark.demoTypes ) ) throw new Error( "SourceData.benchmark.demoTypes hasn't loaded correctly" );
  if ( initialData.benchmark.selectedQuarters === undefined ) throw new Error( "SourceData.benchmark.quarters hasn't loaded correctly" );
  if ( !Array.isArray( initialData.benchmark.selectedQuarters ) ) throw new Error( "SourceData.benchmark.quarters hasn't loaded correctly" );
  if ( initialData.benchmark.timePeriod === undefined ) throw new Error( "SourceData.benchmark.timePeriod hasn't loaded correctly" );
  if ( initialData.benchmark.timePeriodFromTime === undefined ) throw new Error( "SourceData.benchmark.timePeriodFromTime hasn't loaded correctly" );
  if ( initialData.benchmark.timePeriodToTime === undefined ) throw new Error( "SourceData.benchmark.timePeriodToTime hasn't loaded correctly" );

  if ( initialData.display === undefined ) throw new Error( "SourceData.display hasn't loaded correctly" );
  if ( initialData.display.selectedDisplayColumns === undefined ) throw new Error( "SourceData.display.selectedDisplayColumns hasn't loaded correctly" );
  if ( !Array.isArray( initialData.display.selectedDisplayColumns ) ) throw new Error( "SourceData.display.selectedDisplayColumns hasn't loaded correctly" );
  if ( initialData.display.displaySortedBy === undefined ) throw new Error( "SourceData.display.displaySortedBy hasn't loaded correctly" );
  if ( initialData.display.displayTime === undefined ) throw new Error( "SourceData.display.displayTime hasn't loaded correctly" );

  if ( initialData.output === undefined ) throw new Error( "SourceData.output hasn't loaded correctly" );
  if ( initialData.output.format === undefined ) throw new Error( "SourceData.output.format hasn't loaded correctly" );
  if ( initialData.output.view === undefined ) throw new Error( "SourceData.output.view hasn't loaded correctly" );
};

export const ProgramSearch = () => {
  const loaderData = useLoaderData() as LoaderData;
  const referenceData = useReferenceData();
  const store = getStore( loaderData.initialData.query );

  if ( !referenceData.displayColumns[ applicationId ] ) throw new Error( `No referenceData.displayColumns for application ${ applicationId }` );

  const applicationContext: ApplicationContextType = useMemo( () =>
    appendTemplateNameAndSharedWith(
      loaderData.initialData.userId,
      loaderData.initialData.name,
      loaderData.initialData.sharedWith,
      appendCountriesById(
        appendStationsByCountryIdAndId(
          appendDemosByCountryIdAndId(
            appendDayPartsByCountryIdAndId(
              appendUsersAndGroupsById(
                appendMeasuresById(
                  {
                    applicationId,
                    templateOrHistoryId: loaderData.id,
                    benchmarkDemoTypes: referenceData.benchmarkDemoTypes,
                    countryGroups: referenceData.countryGroups,
                    includeIdInName: referenceData.includeIdInName,
                    measures: referenceData.measures,
                    sharingUsersAndGroups: referenceData.sharingUsersAndGroups,
                    displayColumns: referenceData.displayColumns[ applicationId ],
                    countries: enrichCountries( referenceData.countries ),
                    name: getApplicationDisplayName( applicationId )
                  }
                )
              )
            )
          )
        )
      )
    ), [ loaderData.id, loaderData.initialData.name, loaderData.initialData.sharedWith, loaderData.initialData.userId, referenceData.benchmarkDemoTypes, referenceData.countries, referenceData.countryGroups, referenceData.displayColumns, referenceData.includeIdInName, referenceData.measures, referenceData.sharingUsersAndGroups ] );

  return (
    <ApplicationContext.Provider value={ applicationContext } key={ loaderData.id } >
      <HelpContext.Provider value={ loaderData.helpText } >
        <Provider store={ store }>
          <Index />
        </Provider>
      </HelpContext.Provider>
    </ApplicationContext.Provider >
  );
};