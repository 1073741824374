/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { css } from "@emotion/react";
import { useContextMenu } from "../../contexts/ContextMenuContext";
import styled from "@emotion/styled";

const StyledUl = styled.ul`
  padding: 0;
  margin: 0;
`;

const styles = {
  contextMenu: css`
    font: normal 12px/24px "Segoe UI", Arial, sans-serif;
    border: 1px solid #9aa0a6;
    position: fixed;
    background-color: white;      
  `,
  verticalLine: css`
    background: silver;
    z-index: -1;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 26px;
  `
};

export const Menu = ( { children }: { children: ReactNode; } ): JSX.Element => {
  const { location } = useContextMenu();

  return (
    <div css={ [ styles.contextMenu, css`left: ${ location.x }px; top: ${ location.y + 1 }px; opacity: 1;` ] }>
      <div css={ styles.verticalLine } />
      <StyledUl>{ children }</StyledUl>
    </div>
  );
};
