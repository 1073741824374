import { DaysConstants, DemoGroupByConstants, DemoRankConstants, DemoSelectionConstants, Average, RevenueCollapseConstants, RevenueAmountConstants, RevenueDurationConstants, DemoTypeConstants, BenchmarkDatesConstants, Title, BenchmarkTimePeriodConstants, Output, BenchmarkDayOfWeekTypeConstants } from "model/Model";

export type Model = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  measures: Array<{ value: DemoTypeConstants; isSelected: boolean; isRanked: boolean; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  demoGroupBy: DemoGroupByConstants;
  demoRank: DemoRankConstants;
  demoSelection: DemoSelectionConstants;
  dateTime: DateTime;
  average: Average;
  revenue: Revenue;
  benchmark: Benchmark;
  display: Display;
  title: Title;
  output: Output;
};

export type DateTimeSource = {
  fromDate?: string;
  toDate?: string;
  days: Array<DaysConstants>;
  transmissionDuration: number;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};

export type BenchmarkSource = {
  demoCount: number;
  demoTypes: Array<BenchmarkDemoTypeOption>;
  dates: BenchmarkDatesConstants;
  selectedQuarters: Array<number>;
  fromDate: string;
  toDate: string;
  timePeriod: BenchmarkTimePeriodConstants;
  timePeriodFromTime: string;
  timePeriodToTime: string;
  benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants;
  benchmarkDays: Array<DaysConstants>;
};

export type ProgramSearchSourceModel = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  measures: Array<{ value: DemoTypeConstants; isSelected: boolean; isRanked: boolean; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  demoGroupBy: DemoGroupByConstants;
  demoRank: DemoRankConstants;
  demoSelection: DemoSelectionConstants;
  dateTime: DateTimeSource;
  average: Average;
  revenue: Revenue;
  benchmark: BenchmarkSource;
  display: Display;
  title: Title;
  output: Output;
};

export enum DisplaySortedByConstants {
  CountryChannel = 4,
  CountryDate = 0,
  Title = 1,
  StartDate = 2,
  Rank = 3,
  Distributor = 5,
  ShowType = 6,
  ProductionCountry = 8,
  Revenue = 7
}

export enum DisplayTimeConstants {
  TotalMinutes = 1,
  HoursMinutes = 2,
  HoursMinutesSeconds = 3
}

export type DisplayColumn = { id: string; name: string; };

export type BenchmarkDemoTypeOption = {
  value: DemoTypeConstants;
  name: string;
  isSelected: boolean;
  isEnabled?: boolean;
};

export type BenchmarkQuarterOption = {
  id: number;
  name: string;
  isSelected: boolean;
  isEnabled?: boolean;
};

export type Revenue = {
  revenueAmount: RevenueAmountConstants;
  revenueDuration: RevenueDurationConstants;
  revenueCollapse: RevenueCollapseConstants;
};

export type Benchmark = {
  demoCount: number;
  demoTypes: Array<BenchmarkDemoTypeOption>;
  dates: BenchmarkDatesConstants;
  selectedQuarters: Array<number>;
  fromDate: number;
  toDate: number;
  timePeriod: BenchmarkTimePeriodConstants;
  timePeriodFromTime: string;
  timePeriodToTime: string;
  benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants;
  benchmarkDays: Array<DaysConstants>;
};

export type Display = {
  selectedDisplayColumns: Array<string>;
  displaySortedBy: DisplaySortedByConstants;
  displayTime: DisplayTimeConstants;
};

export type DateTime = {
  fromDate?: number;
  toDate?: number;
  days: Array<DaysConstants>;
  transmissionDuration: number;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};
