import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const countriesSlice = createSlice( {
  name: "countries",
  initialState: [] as Array<number>,
  reducers: {
    ChangeCountryIsSelected( state, action: PayloadAction<{ countryId: number; isSelected: boolean; clearExisting: boolean; defaultStations: Array<number>; defaultDemos: Array<number>; }> ) {
      const { countryId, isSelected, clearExisting } = action.payload;
      if ( isSelected ) {
        if ( clearExisting ) return [ countryId ];
        return [ ...state, countryId ];
      }
      else {
        return state.filter( m => m !== countryId );
      }
    }
  }
} );

export const { ChangeCountryIsSelected } = countriesSlice.actions;

export default countriesSlice.reducer;