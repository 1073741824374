/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

const styles = {
  selected: css`
    color: #CC0000;
  `,
  inputStyle: css`
    vertical-align: middle;
  `,
  labelStyle: css`
    vertical-align: middle;
  `
};

export const CheckSelector = ( { name, isSelected, onCheckChanged }: { name: string; isSelected?: boolean; isEnabled?: boolean; onCheckChanged?: ( isSelected: boolean ) => void; } ): JSX.Element => {
  return (
    <div>
      <label css={ isSelected ? styles.selected : undefined }>
        <input css={ styles.inputStyle } aria-label={ name } type="checkbox" checked={ isSelected } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => onCheckChanged?.( e.currentTarget.checked ) } />
        <span css={ styles.labelStyle }>{ name }</span>
      </label>
    </div>
  );
};