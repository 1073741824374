import { Average, BenchmarkDatesConstants, BenchmarkDayOfWeekTypeConstants, BenchmarkTimePeriodConstants, DaysConstants, DemoGroupByConstants, DemoSelectionConstants, DemoTypeConstants, Output, Title } from "model/Model";
import { Selectable } from "Utilities";

export type Model = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  measures: Array<Selectable<{ value: DemoTypeConstants; }>>;
  demoGroupBy: DemoGroupByConstants;
  demoSelection: DemoSelectionConstants;
  dateTime: DateTime;
  preview: Preview; // TODO: this isn't really stateful
  average: Average;
  title: Title;
  revenue: Revenue;
  benchmark: Benchmark;
  display: DisplaySource;
  output: Output;
  unselectedIndices: Array<number>;
};

export type DateTimeSource = {
  fromDate: string;
  toDate: string;
  days: Array<DaysConstants>;
  transmissionDuration: number;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};

export type BenchmarkSource = {
  dates: BenchmarkDatesConstants;
  selectedQuarters: Array<number>;
  fromDate: string;
  toDate: string;
  timePeriod: BenchmarkTimePeriodConstants;
  timePeriodFromTime: string;
  timePeriodToTime: string;
  benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants;
  benchmarkDays: Array<DaysConstants>;
};

export type ProgramPerformanceSourceModel = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  measures: Array<Selectable<{ value: DemoTypeConstants; }>>;
  demoGroupBy: DemoGroupByConstants;
  demoSelection: DemoSelectionConstants;
  dateTime: DateTimeSource;
  average: Average;
  title: Title;
  revenue: Revenue;
  benchmark: BenchmarkSource;
  display: DisplaySource;
  output: Output;
  unselectedIndices: Array<number>;
};

export enum RevenueCalculationConstants {
  None = 0,
  Sum = 1,
  Average = 2
}

export enum DisplayPrimarySheetConstants {
  LeadIn = "LEADIN",
  LeadOut = "LEADOUT",
  Competition = "COMPETITION",
  Rank = "RANK",
  PercentageDifferenceVsTarget = "DIFVTARGET",
  PercentageDifferenceVsBenchmark = "DIFVBENCH"
}

export enum DisplayCompetitionBreakoutConstants {
  None = 101,
  FiftyPercentOrGreater = 50,
  FortyPercentOrGreater = 40,
  ThirtyPercentOrGreater = 30,
  TwentyPercentOrGreater = 20,
  All = 0
}

export enum DisplayLeadInLeadOutBreakoutConstants {
  None = 101,
  FiftyPercentOrGreater = 50,
  FortyPercentOrGreater = 40,
  ThirtyPercentOrGreater = 30,
  TwentyPercentOrGreater = 20,
  All = 0
}

export enum DisplayDetailSheetConstants {
  LeadIn = "LI",
  leadOut = "LO",
  Competition = "COMP"
}

export enum DisplayTimeConstants {
  TotalMinutes = 1,
  HoursMinutes = 2,
  HoursMinutesSeconds = 3
}

export type Revenue = {
  revenueCalculation: RevenueCalculationConstants;
};

export type BenchmarkQuarterOption = Selectable<{ id: number; name: string; isEnabled?: boolean; }>;

export type Benchmark = {
  dates: BenchmarkDatesConstants;
  selectedQuarters: Array<number>;
  fromDate: number;
  toDate: number;
  timePeriod: BenchmarkTimePeriodConstants;
  timePeriodFromTime: string;
  timePeriodToTime: string;
  benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants;
  benchmarkDays: Array<DaysConstants>;
};

export type DisplayPrimarySheetOption = Selectable<{ value: DisplayPrimarySheetConstants; name: string; isEnabled?: boolean; }>;
export type DisplayDetailSheetOption = Selectable<{ value: DisplayDetailSheetConstants; name: string; isEnabled?: boolean; }>;

export type DisplaySource = {
  selectedDisplayPrimarySheet: Array<DisplayPrimarySheetConstants>;
  displayCompetitionBreakout: DisplayCompetitionBreakoutConstants;
  displayLiLoBreakout: DisplayLeadInLeadOutBreakoutConstants;
  selectedDisplayDetailSheet: Array<DisplayDetailSheetConstants>;
  displayTime: DisplayTimeConstants;
};

export type Display = {
  displayPrimarySheet: Array<DisplayPrimarySheetOption>;
  displayCompetitionBreakout: DisplayCompetitionBreakoutConstants;
  displayLiLoBreakout: DisplayLeadInLeadOutBreakoutConstants;
  displayDetailSheet: Array<DisplayDetailSheetOption>;
  displayTime: DisplayTimeConstants;
};

export type PreviewResult = {
  rows: Array<PreviewRow>;
  captions: Array<{ caption: string; columnName: string; }>;
};

export type PreviewRow = {
  id: number; // TODO: this an index but could we use the guid?
  originalTitle: string;
  season: string;
  episodeNumber: string;
  originalRepeat: string;
  premier: string;
  localTitle: string;
  station: string;
  date: string;
  days: string;
  start: string;
  end: string;
  dur: string;
  values: { [ key: string ]: string | number; };
};

export type Preview = {
  isOpen: boolean;
  isLoading: boolean;
  captions: Array<{ caption: string; columnName: string; }>;
  rows: Array<Selectable<PreviewRow>>;
  demoTypes: Array<DemoTypeConstants>;
  demos: Array<{ id: number; demos: Array<number>; }>;
};

export type DateTime = {
  fromDate?: number;
  toDate?: number;
  days: Array<DaysConstants>;
  transmissionDuration: number;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};
