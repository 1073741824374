/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo } from "react";
import { Country, Station } from "model/Model";
import { chunk, Selectable } from "Utilities";
import { css } from "@emotion/react";
import { useReferenceData } from "contexts/ReferenceDataContext";

const styles = {
  stationSelector: css`
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      fieldset {
        vertical-align: top;
  
        .stationColumn {
          display: block;
          float: left;
        }
      }
  
      label {
        display: block;
        padding: 0;
        margin: 0;
        padding-right: 10px;
        span {
          vertical-align: middle;
        }
      }
  
      input {
        padding: 0;
        margin: 0 5px 0 0;
        width: auto;
        vertical-align: middle;
      }
    }
  `,

  stationColumn: css`
    display:block;
    float:left;
  `
};

export const StationSelector = ( { canSelectAllCountriesInGroup, selectedCountries, onChangeStationIsSelected }: { canSelectAllCountriesInGroup: boolean; onChangeStationIsSelected: ( countryId: number, stationId: number, isSelected: boolean ) => void; selectedCountries: Array<Country & { allStationsSelected: boolean; noStationsSelected: boolean; stations: Array<Selectable<Station>>; selectedStations: Array<Selectable<Station>>; }>; } ): JSX.Element => ( <>
  { selectedCountries.map( ( country ) => ( <CountryGroup key={ country.id } { ...country } canSelectAllCountriesInGroup={ canSelectAllCountriesInGroup } onSelectStationHandler={ onChangeStationIsSelected } /> ) ) }
</> );

const CountryGroup = ( { id, revenueSymbol, name, allStationsSelected, noStationsSelected, stations, canSelectAllCountriesInGroup, onSelectStationHandler }: { id: number; revenueSymbol: string; name: string; allStationsSelected: boolean; noStationsSelected: boolean; stations: Array<Selectable<Station>>; canSelectAllCountriesInGroup: boolean; onSelectStationHandler: ( countryId: number, stationId: number, isSelected: boolean ) => void; } ) => {
  const onSelectAllStationsHandler = useCallback( () => {
    for ( const station of stations ) {
      if ( station.isSelected === allStationsSelected ) onSelectStationHandler( id, station.id, !allStationsSelected );
    }
  }, [ allStationsSelected, id, onSelectStationHandler, stations ] );

  const stationGroups = useMemo( () => stations.map( s => s.optionGroup || "" ).filter( ( value, index, array ) => array.indexOf( value ) === index ), [ stations ] );

  return ( <>
    <div css={ styles.stationSelector }>
      <fieldset>
        <legend>
          { canSelectAllCountriesInGroup && <label><input type="checkbox" checked={ allStationsSelected } onChange={ onSelectAllStationsHandler } ref={ input => { if ( input ) input.indeterminate = !noStationsSelected && !allStationsSelected; } } /><span>{ name }</span></label> }
          { !canSelectAllCountriesInGroup && <span>{ name }</span> }
        </legend>
        {
          stationGroups.map( ( group, i ) => {
            const stationsForGroup = stations.filter( m => m.optionGroup === group );
            const allStationsForGroupSelected = stationsForGroup.filter( m => m.isSelected ).length === stationsForGroup.length;
            if ( stationsForGroup.length !== 0 ) return <StationGroup key={ group } countryId={ id } revenueSymbol={ revenueSymbol } group={ group } allSelected={ allStationsForGroupSelected } noneSelected={ noStationsSelected } stations={ stationsForGroup } totalStationCount={ stations.length } onSelectStationHandler={ onSelectStationHandler } />;
            return <></>;
          } ) }
      </fieldset>
    </div>
  </> );
};

const StationGroup = ( { countryId, revenueSymbol, group, allSelected, noneSelected, stations, totalStationCount, onSelectStationHandler }: { countryId: number; revenueSymbol: string; group: string; allSelected: boolean; noneSelected: boolean; stations: Array<Selectable<Station>>; totalStationCount: number; onSelectStationHandler: ( countryId: number, stationId: number, isSelected: boolean ) => void; } ) => {
  const referenceData = useReferenceData();

  const onSelectAllStationsHandler = useCallback( () => {
    for ( const station of stations ) {
      if ( station.isSelected === allSelected ) onSelectStationHandler( countryId, station.id, !allSelected );
    }
  }, [ allSelected, countryId, onSelectStationHandler, stations ] );

  const chunkSize = useMemo( () => {
    // FUTURE: duplicated the weird logic used in live can we improve this?
    if ( totalStationCount > 70 ) {
      return Math.floor( totalStationCount / 4 );
    }

    return Math.max( Math.floor( totalStationCount / 8 ), 1 );
  }, [ totalStationCount ] );

  return ( <>
    <fieldset>
      <legend>
        <label><input type="checkbox" checked={ allSelected } onChange={ onSelectAllStationsHandler } ref={ input => { if ( input ) input.indeterminate = !noneSelected && !allSelected; } } /><span>{ group }</span></label>
      </legend>
      { chunk( stations, chunkSize ).map( ( s, i ) => {
        return (
          <div key={ i } css={ styles.stationColumn } >
            {
              s.map( station => ( <label key={ station.id } title={ station.tooltip } ><input type="checkbox" onChange={ ( e ) => onSelectStationHandler( countryId, station.id, e.currentTarget.checked ) } checked={ station.isSelected } /><span>{ station.name }{ referenceData.includeIdInName && ` [${ station.id }]` }{ station.hasRevenue && ` ${ revenueSymbol }` }</span></label> ) )
            }
          </div>
        );
      } ) }
    </fieldset>
  </> );
};
