import { DisplayTimeConstants, Model, Preview, PreviewResult } from "../../Model";
import { PreviewRow } from "apps/ProgramPerformance/Model";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Selectable, formatDateAsIso8601 } from "Utilities";
import { postJson } from "helpers/api";
import { getSessionCredentials } from "contexts/UserContext";
import { DaysConstants, DemoTypeConstants } from "model/Model";

type RequestModel = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  demoSelection: string;
  measures: Array<Selectable<{ value: DemoTypeConstants; }>>;
  totalChars: number;
  minLength: number;
  fromDate: string;
  toDate: string;
  days: Array<DaysConstants>;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
  displayTime: DisplayTimeConstants; // TODO: wow this changes the displayed duration....should it?
  titleNames: Array<{ title: string; isSelected: boolean; }>;
};

let previewHandle: NodeJS.Timeout | number | undefined;
const callPreviewApiEndpoint = async ( model: RequestModel ): Promise<PreviewResult> => {
  if ( previewHandle ) {
    console.log( "Aborting existing preview" );
    globalThis.clearTimeout( previewHandle as number | undefined );
    previewHandle = undefined;
  }

  const { token, userId } = getSessionCredentials();

  console.log( `Generating preview for ${ JSON.stringify( model ) }` );

  const result = await postJson<PreviewResult>( "/Apps/ProgramPerformanceTargetPreview", userId, token, model );

  return result;
};

export const GetPreview = createAsyncThunk(
  "GetPreview",
  async ( _, thunkAPI ) => {
    const existingState = thunkAPI.getState() as Model;

    const parameters: RequestModel = {
      countries: existingState.countries,
      stations: existingState.stations,
      demos: existingState.demos,
      demoSelection: existingState.demoSelection,
      totalChars: existingState.title.numberOfCharactersToCompare,
      minLength: existingState.dateTime.transmissionDuration,
      fromDate: formatDateAsIso8601( existingState.dateTime.fromDate ),
      toDate: formatDateAsIso8601( existingState.dateTime.toDate ),
      days: existingState.dateTime.days,
      fromTime: existingState.dateTime.fromTime,
      toTime: existingState.dateTime.toTime,
      useDayParts: existingState.dateTime.useDayParts,
      dayParts: existingState.dateTime.dayParts,
      measures: existingState.measures,
      displayTime: existingState.display.displayTime,
      titleNames: existingState.title.titleNames
    };

    const demoTypes = existingState.measures.filter( m => m.isSelected ).map( m => m.value );
    const demos = existingState.demos; // TODO: demos might not be the correct option here as the response might have used different demos

    const response = await callPreviewApiEndpoint( parameters );

    return {
      demoTypes,
      demos,
      rows: response.rows,
      captions: response.captions
    };
  }
);

const previewSlice = createSlice( {
  name: "preview",
  initialState: {
    isOpen: false,
    isLoading: false,
    rows: [],
    captions: [],
    demoTypes: [],
    demos: []
  } as Preview,
  reducers: {
    ChangePreviewIsOpen( state, action: PayloadAction<boolean> ) {
      if ( action.payload ) {
        state.isOpen = true;
      } else {
        state.isOpen = false;
        state.isLoading = false;
        state.rows = [];
        state.captions = [];
        state.demoTypes = [];
        state.demos = [];
      }
    }
  },
  extraReducers: builder => {
    builder.addCase( GetPreview.pending, ( state ) => {
      console.log( "GetPreviewStartedMessage" );
      return { ...state, isLoading: true, rows: [], demoTypes: [], demos: [] };
    } );

    builder.addCase( GetPreview.fulfilled, ( state, action ) => {
      console.log( "GetPreviewSuccessMessage" );
      const { demoTypes, demos, rows, captions } = action.payload;
      return { ...state, isLoading: false, rows: rows.map( m => ( { ...m, isSelected: true } ) as Selectable<PreviewRow> ), captions: captions, demoTypes, demos };
    } );

    builder.addCase( GetPreview.rejected, ( state, action ) => {
      console.log( `GetPreviewFailedAction: ${ action.error }` );
      return { ...state, isLoading: false, rows: [], demoTypes: [], demos: [] };
    } );
  }
} );

export const { ChangePreviewIsOpen } = previewSlice.actions;

export default previewSlice.reducer;