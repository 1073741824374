/** @jsxImportSource @emotion/react */
import React from "react";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { RevenueCalculationConstants } from "../../Model";

const revenueCalculationItems = [
  { name: "None", value: RevenueCalculationConstants.None },
  { name: "Sum", value: RevenueCalculationConstants.Sum },
  { name: "Average", value: RevenueCalculationConstants.Average }
];

export const RevenueSelector = ( { revenueCalculation, onChangeRevenueCalculation }: { revenueCalculation: RevenueCalculationConstants; onChangeRevenueCalculation: ( value: RevenueCalculationConstants ) => void; } ): JSX.Element => {
  return ( <>
    <RadioGroupSelector label="Revenue" radioGroup="revenue" helpId="revenue_calculation" items={ revenueCalculationItems } value={ revenueCalculation } onChangeItemSelected={ onChangeRevenueCalculation } />
  </> );
};
