/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { DisplaySortedByConstants } from "../../Model";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const DisplayDescription = ( { displaySortedBy, warningMessage }: { displaySortedBy: DisplaySortedByConstants; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [ ( <span key={ 0 } css={ styles.displayGroupSpace }>Sorted by</span> ) ];

    const sortedByText = ( ( value ): string => {
      switch ( value ) {
        case DisplaySortedByConstants.CountryDistributor: return "Country/Distributor.";
        case DisplaySortedByConstants.DistributorCountry: return "Distributor/Country.";
        default: return "";
      }
    } )( displaySortedBy );

    results.push( ( <span key="display_sorted_by_option" css={ styles.displayGroupSpace }>{ sortedByText }</span> ) );
    return results;
  }, [ displaySortedBy ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  return <>{ parts }</>;
};
