/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { DisplayCompetitionBreakoutConstants, DisplayDetailSheetOption, DisplayLeadInLeadOutBreakoutConstants, DisplayPrimarySheetOption, DisplayTimeConstants } from "apps/ProgramPerformance/Model";
import { Selectable } from "Utilities";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const DisplayDescription = ( { displayPrimarySheet, displayDetailSheet, displayCompetitionBreakout, displayLiLoBreakout, displayTime, warningMessage }: { displayPrimarySheet: Array<Selectable<DisplayPrimarySheetOption>>; displayDetailSheet: Array<Selectable<DisplayDetailSheetOption>>; displayCompetitionBreakout: DisplayCompetitionBreakoutConstants; displayLiLoBreakout: DisplayLeadInLeadOutBreakoutConstants; displayTime: DisplayTimeConstants; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    const hasAllPrimarySheetOptions = displayPrimarySheet.every( m => m.isSelected );
    const hasAllDetailSheetOptions = displayDetailSheet.every( m => m.isSelected );

    if ( hasAllPrimarySheetOptions && hasAllDetailSheetOptions ) {
      results.push( ( <span key={ 1 } css={ styles.displayGroupSpace }>{ "Primary and Detail sheets include all options." }</span> ) );
    } else {
      const primarySheetDescription = hasAllPrimarySheetOptions ? "Primary sheet includes All." : `Primary sheet includes ${ displayPrimarySheet.filter( m => m.isSelected ).map( m => m.name ).join( ", " ) }.`;
      const detailSheetDescription = hasAllDetailSheetOptions ? "Detail sheet includes All." : `Detail sheet includes ${ displayDetailSheet.filter( m => m.isSelected ).map( m => m.name ).join( ", " ) }.`;

      results.push( ( <span key={ 1 } css={ styles.displayGroupSpace }>{ primarySheetDescription }</span> ) );
      results.push( ( <span key={ 2 } css={ styles.displayGroupSpace }>{ detailSheetDescription }</span> ) );
    }

    const competitionBreakoutDescription = ( ( value ) => {
      switch ( value ) {
        case DisplayCompetitionBreakoutConstants.None: return "No Competition Breakout.";
        case DisplayCompetitionBreakoutConstants.FiftyPercentOrGreater: return "Competition Breakout at 50% or more.";
        case DisplayCompetitionBreakoutConstants.FortyPercentOrGreater: return "Competition Breakout at 40% or more.";
        case DisplayCompetitionBreakoutConstants.ThirtyPercentOrGreater: return "Competition Breakout 30% or more.";
        case DisplayCompetitionBreakoutConstants.TwentyPercentOrGreater: return "Competition Breakout 20% or more.";
        case DisplayCompetitionBreakoutConstants.All: return "All Competition Breakout.";
        default: return "";
      }
    } )( displayCompetitionBreakout );

    results.push( ( <span key={ 3 } css={ styles.displayGroupSpace }>{ competitionBreakoutDescription }</span> ) );

    const leadInLeadOutDescription = ( ( value ) => {
      switch ( value ) {
        case DisplayLeadInLeadOutBreakoutConstants.None: return "No LI-LO Breakout.";
        case DisplayLeadInLeadOutBreakoutConstants.FiftyPercentOrGreater: return "LI-LO Breakout at 50% or more.";
        case DisplayLeadInLeadOutBreakoutConstants.FortyPercentOrGreater: return "LI-LO Breakout at 40% or more.";
        case DisplayLeadInLeadOutBreakoutConstants.ThirtyPercentOrGreater: return "LI-LO Breakout 30% or more.";
        case DisplayLeadInLeadOutBreakoutConstants.TwentyPercentOrGreater: return "LI-LO Breakout 20% or more.";
        case DisplayLeadInLeadOutBreakoutConstants.All: return "All LI-LO Breakout.";
        default: return "";
      }
    } )( displayLiLoBreakout );

    results.push( ( <span key={ 4 } css={ styles.displayGroupSpace }>{ leadInLeadOutDescription }</span> ) );

    const timeDescription = ( ( value ) => {
      switch ( value ) {
        case DisplayTimeConstants.TotalMinutes: return "Duration as Total Minutes.";
        case DisplayTimeConstants.HoursMinutes: return "Duration as HH:MM.";
        case DisplayTimeConstants.HoursMinutesSeconds: return "Duration as HH:MM:SS.";
        default: return "";
      }
    } )( displayTime );

    results.push( ( <span key={ 5 } css={ styles.displayGroupSpace }>{ timeDescription }</span> ) );

    return results;
  }, [ displayCompetitionBreakout, displayDetailSheet, displayLiLoBreakout, displayPrimarySheet, displayTime ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
