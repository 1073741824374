/** @jsxImportSource @emotion/react */
import React from "react";
import { BenchmarkDatesConstants, BenchmarkDayOfWeekTypeConstants, BenchmarkTimePeriodConstants, DaysConstants } from "model/Model";
import { BenchmarkDatesSelector } from "./benchmark-dates-selector/BenchmarkDatesSelector";
import { BenchmarkTimePeriodSelector } from "./benchmark-time-period-selector/BenchmarkTimePeriodSelector";
import { BenchmarkQuarterOption } from "../../Model";
import { BenchmarkDaySelector } from "./benchmark-day-selector/BenchmarkDaySelector";
import { css } from "@emotion/react";

const styles = {
  benchmarkSelector: css``,

  stack: css`
    display: inline-block;
    vertical-align: top;
  `
};

export const BenchmarkSelector = ( { dates, quarters, fromDate, toDate, timePeriod, timePeriodFromTime, timePeriodToTime, benchmarkDayOfWeekType, benchmarkDays, onChangeBenchmarkDates, onChangeBenchmarkQuarterIsSelected, onChangeBenchmarkFromDate, onChangeBenchmarkToDate, onChangeBenchmarkTimePeriod, onChangeBenchmarkTimePeriodFromTime, onChangeBenchmarkTimePeriodToTime, onChangeBenchmarkDayOfWeekType, onChangeBenchmarkDaysSelected }: { dates: BenchmarkDatesConstants; quarters: Array<BenchmarkQuarterOption>; fromDate: number; toDate: number; timePeriod: BenchmarkTimePeriodConstants; timePeriodFromTime: string; timePeriodToTime: string; benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants; benchmarkDays: Array<DaysConstants>; onChangeBenchmarkDates: ( value: number ) => void; onChangeBenchmarkQuarterIsSelected: ( value: number, isSelected: boolean ) => void; onChangeBenchmarkFromDate: ( value: number ) => void; onChangeBenchmarkToDate: ( value: number ) => void; onChangeBenchmarkTimePeriod: ( value: BenchmarkTimePeriodConstants ) => void; onChangeBenchmarkTimePeriodFromTime: ( value: string ) => void; onChangeBenchmarkTimePeriodToTime: ( value: string ) => void; onChangeBenchmarkDayOfWeekType: ( value: BenchmarkDayOfWeekTypeConstants ) => void; onChangeBenchmarkDaysSelected: ( days: Array<DaysConstants> ) => void; } ): JSX.Element => ( <>
  <div css={ styles.benchmarkSelector }>
    <div css={ styles.stack }>
      <BenchmarkTimePeriodSelector helpId="benchmark_time_period" timePeriod={ timePeriod } isEnabled={ dates !== BenchmarkDatesConstants.None } timePeriodFromTime={ timePeriodFromTime } timePeriodToTime={ timePeriodToTime } onChangeTimePeriod={ onChangeBenchmarkTimePeriod } onChangeTimePeriodFromTime={ onChangeBenchmarkTimePeriodFromTime } onChangeTimePeriodToTime={ onChangeBenchmarkTimePeriodToTime } />
      <BenchmarkDatesSelector helpId="benchmark_dates" dates={ dates } quarters={ quarters } fromDate={ fromDate } toDate={ toDate } onChangeDates={ onChangeBenchmarkDates } onChangeQuarterIsSelected={ onChangeBenchmarkQuarterIsSelected } onChangeFromDate={ onChangeBenchmarkFromDate } onChangeToDate={ onChangeBenchmarkToDate } />
    </div>
    <div css={ styles.stack }>
      <BenchmarkDaySelector helpId="" isEnabled={ true } benchmarkDayOfWeekType={ benchmarkDayOfWeekType } benchmarkDays={ benchmarkDays } onChangeBenchmarkDayOfWeekType={ onChangeBenchmarkDayOfWeekType } onChangeBenchmarkDaysSelected={ onChangeBenchmarkDaysSelected } />
    </div>
  </div>
</> );
