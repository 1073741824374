import { Average } from "../../Model";
import { AverageCollapsePeriodConstants, AverageTitleConstants, YesNoConstants } from "model/Model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChangeAverageBreakoutOriginalRepeat, ChangeAverageBreakoutSeasons, ChangeAverageCollapsePeriod, ChangeAverageCombineWithin, ChangeAverageLocalRepeat, ChangeAverageTitle } from "reducers/averageSlice";

const averageSlice = createSlice( {
  name: "average",
  initialState: {
    collapsePeriod: AverageCollapsePeriodConstants.IndividualAirings,
    combineWithin: 30,
    minimumAveragedTransmissions: "ALL",
    sumThousands: YesNoConstants.No,
    title: AverageTitleConstants.OriginalThenLocal,
    breakoutSeasons: YesNoConstants.No,
    breakoutOriginalRepeat: YesNoConstants.No,
    localRepeat: YesNoConstants.No
  } as Average,
  reducers: {
    ChangeAverageMinimumAveragedTransmissions( state, action: PayloadAction<string> ) { state.minimumAveragedTransmissions = action.payload; },
    ChangeAverageSumThousands( state, action: PayloadAction<YesNoConstants> ) { state.sumThousands = action.payload; }
  },
  extraReducers: builder => {
    builder.addCase( ChangeAverageBreakoutOriginalRepeat, ( state, action ) => { state.breakoutOriginalRepeat = action.payload; } );
    builder.addCase( ChangeAverageBreakoutSeasons, ( state, action ) => { state.breakoutSeasons = action.payload; } );
    builder.addCase( ChangeAverageCollapsePeriod, ( state, action ) => { state.collapsePeriod = action.payload; } );
    builder.addCase( ChangeAverageCombineWithin, ( state, action ) => { state.combineWithin = action.payload; } );
    builder.addCase( ChangeAverageLocalRepeat, ( state, action ) => { state.localRepeat = action.payload; } );
    builder.addCase( ChangeAverageTitle, ( state, action ) => { state.title = action.payload; } );
  }
} );

export const { ChangeAverageMinimumAveragedTransmissions, ChangeAverageSumThousands } = averageSlice.actions;

export default averageSlice.reducer;