/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo } from "react";
import { formatDateAsIso8601, Selectable } from "Utilities";
import { CountryConstants } from "constants/CountryConstants";
import { DisplayColumn, DisplaySortedByConstants, DisplayTimeConstants, DisplayTopConstants, DisplayUniqueConstants, Model, ProgramAverageTimeSpentSourceModel, QualifyClassOneOption, QualifyClassTwoOption, QualifyDistributorOption, QualifyFormatOption, QualifyProductionCountryOption, QualifyProductionCountryPositionOption, QualifyTypeOption, ReportTypeConstants } from "./Model";
import { Country, DayPart, DaysConstants, Demo, DemoGroupByConstants, DemoRankConstants, DemoSelectionConstants, DemoTypeConstants, LocalGenre, Measure, OutputFormatConstants, OutputViewConstants, Station } from "model/Model";
import { CountryDescription } from "components/country-selector/CountryDescription";
import { CountrySelector } from "components/country-selector/CountrySelector";
import { StationsDescription } from "components/station-selector/StationsDescription";
import { StationSelector } from "components/station-selector/StationSelector";
import { ExpanderPanel } from "components/expander-panel/ExpanderPanel";
import { ReportTypeSelector } from "apps/ProgramAverageTimeSpent/components/report-type-selector/ReportTypeSelector";
import { HelpText } from "components/help-text/HelpText";
import { OutputDescription } from "components/output-selector/OutputDescription";
import { OutputSelector } from "components/output-selector/OutputSelector";
import { QualifySelector } from "./components/qualify-selector/QualifySelector";
import { DisplaySelector } from "./components/display-selector/DisplaySelector";
import { DateTimeSelector } from "./components/date-time-selector/DateTimeSelector";
import { DateTimeDescription } from "./components/date-time-selector/DateTimeDescription";
import { DisplayDescription } from "./components/display-selector/DisplayDescription";
import { QualifyDescription } from "./components/qualify-selector/QualifyDescription";
import { ProgramAverageTimeSpentDemoSelector } from "./components/program-average-time-spent-demo-selector/ProgramAverageTimeSpentDemoSelector";
import { ProgramAverageTimeSpentDemoDescription } from "./components/program-average-time-spent-demo-selector/ProgramAverageTimeSpentDemoDescription";
import { ReportTypeDescription } from "./components/report-type-selector/ReportTypeDescription";
import { validateHaveStationInEachCountry, validateHaveDemoInEachCountry, validateHaveDaypartInEachCountry, validateFromAndToDates, validateHaveDays, validateFromAndToTime, validateCanada70NoShareOrPutCutOff, validateJapan38DemosAndStations, validateCountryStartDate, validateCountryDemoDates } from "ValidationHelpers";
import { ApplicationHeader } from "components/application-header/ApplicationHeader";
import { ChangeDemoRank } from "reducers/demoRankSlice";
import { ChangeDemoGroupBy } from "reducers/demoGroupBySlice";
import { ChangeDemoSelection } from "reducers/demoSelectionSlice";
import { ChangeFromDate } from "reducers/fromDateReducers";
import { ChangeToDate } from "reducers/toDateReducer";
import { ChangeDemoTypePosition, ChangeDemoTypeSelected, ChangeDemoTypeRanked } from "reducers/measuresWithIsRankedSlice";
import { ChangeOutputFormat, ChangeOutputView } from "reducers/outputSlice";
import { ChangeFromTime } from "reducers/fromTimeReducer";
import { ChangeToTime } from "reducers/toTimeReducer";
import { ChangeUseDayParts } from "reducers/useDayPartsReducer";
import { ChangeDaysSelected } from "reducers/daysReducer";
import { ChangeTransmissionDuration } from "reducers/transmissionDurationReducer";
import { ChangeDisplayColumnIsSelected, ChangeDisplaySortedBy, ChangeDisplayTime, ChangeDisplayTop, ChangeDisplayUnique } from "./components/display-selector/displaySlice";
import { ChangeReportType } from "./components/report-type-selector/reportTypeSlice";
import { ChangeQualifyClassOneIsSelected, ChangeQualifyClassTwoIsSelected, ChangeQualifyDistributorIsSelected, ChangeQualifyFormatIsSelected, ChangeQualifyLocalGenreDisplay, ChangeQualifyProductionCountryIsSelected, ChangeQualifyProductionCountryPositionIsSelected, ChangeQualifyTypeIsSelected } from "./components/qualify-selector/qualifySlice";
import { ChangeCountryIsSelected } from "reducers/countriesSlice";
import { ChangeDemoIsSelected, ChangeDemoSelectedOrder } from "reducers/demosSlice";
import { ChangeStationIsSelected } from "reducers/stationsSlice";
import { ChangeDayPartIsSelected } from "reducers/dayPartReducer";
import { ChangeLocalGenreIsSelected } from "reducers/localGenreReducer";
import { useAppDispatch, useAppState } from "./app/hooks";
import { useApplicationContext } from "components/application-context/ApplicationContext";
import { ApplicationContextType } from "./Loader";
import { QualifyFormatConstants } from "constants/QualifyFormatConstants";
import { ReportProgressMessageArgs, useNotifications } from "contexts/NotificationsContext";
import { useImmer } from "use-immer";
import { getSessionCredentials } from "contexts/UserContext";
import { RunningState, downloadFile, emptyRunning, openLink } from "model/RunningState";
import { Runner } from "components/runner/Runner";
import { deleteJson, postJson, putJson } from "helpers/api";
import { QualifyLocalGenreDisplayConstants } from "constants/QualifyLocalGenreDisplayConstants";
import { useNavigate } from "react-router";
import { usePersonalization } from "contexts/PersonalizationContext";

const applicationUrl = "/Apps/ProgramAverageTimeSpent";

type PanelState = {
  selectedPanel: string;
  country: boolean;
  reportType: boolean;
  channel: boolean;
  demo: boolean;
  dateTime: boolean;
  qualify: boolean;
  display: boolean;
  output: boolean;
};

const validate = ( applicationContext: ApplicationContextType, state: Model, showDateValidation: boolean ) => {
  const validation: {
    isValidForSubmit: boolean;
    warnings: Array<string>;
    errors: Array<string>;
    country?: string;
    station?: string;
    demo?: string;
    measure?: string;
    dateTime?: string;
    reportType?: string;
    average?: string;
    benchmark?: string;
    display?: string;
    qualify?: string;
    revenue?: string;
    output?: string;
  } = {
    isValidForSubmit: true,
    warnings: [],
    errors: []
  };

  if ( state.countries.length === 0 ) {
    validation.isValidForSubmit = false;
  }

  validateHaveStationInEachCountry( state.countries, state.stations, validation );

  validateHaveDemoInEachCountry( state.countries, state.demos, validation );

  validateFromAndToDates( state.dateTime.fromDate, state.dateTime.toDate, validation );

  validateHaveDays( state.dateTime.days, validation );

  if ( state.dateTime.useDayParts ) {
    validateHaveDaypartInEachCountry( state.countries, state.dateTime.dayParts, validation );
  } else {
    validateFromAndToTime( state.dateTime.fromTime, state.dateTime.toTime, validation );
  }

  // NICE: qualify should require either 'all' or one of the options

  if ( showDateValidation ) {
    const selectedCountries = applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) );
    validateCountryStartDate( selectedCountries, state.dateTime.fromDate, validation );
    validateCountryDemoDates( selectedCountries, state.demos, state.dateTime.fromDate, state.dateTime.toDate, validation );
  }

  if ( state.countries.find( m => m === CountryConstants.Japan38 ) || state.countries.find( m => m === CountryConstants.Japan39 ) ) {
    validateJapan38DemosAndStations( applicationContext.countries, state.stations, state.demos, validation );
  }

  if ( state.countries.find( m => m === CountryConstants.Canada70 ) ) {
    validateCanada70NoShareOrPutCutOff( state.dateTime.toDate, state.measures, validation );
  }

  // program average time spent 2 year validation with continue
  if ( state.dateTime.fromDate && state.dateTime.toDate ) {
    const endDateMinusTwoYears = new Date( state.dateTime.toDate.valueOf() );
    endDateMinusTwoYears.setMonth( endDateMinusTwoYears.getMonth() - 24 );
    if ( new Date( state.dateTime.fromDate ) < endDateMinusTwoYears ) {
      validation.warnings.push( "You have selected over 2 years.  Are you sure?\n" );
    }
  }

  return validation;
};

const getDefaultPanelState = () => {
  const panelStateJson = localStorage.getItem( `ps:${ applicationUrl }` );
  if ( panelStateJson ) {
    return JSON.parse( panelStateJson ) as PanelState;
  } else {
    return { selectedPanel: "", country: false, reportType: false, channel: false, demo: false, dateTime: false, qualify: false, display: false, output: false };
  }
};

export const Index = (): JSX.Element => {
  const notifications = useNotifications();
  const state = useAppState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const applicationContext = useApplicationContext<ApplicationContextType>();
  const personalization = usePersonalization();

  const { token, userId } = getSessionCredentials();

  const [ running, setRunning ] = useImmer<RunningState>( emptyRunning );
  const [ panels, setPanels ] = useImmer<PanelState>( getDefaultPanelState() );
  const allExpanded = panels.country && panels.reportType && panels.channel && panels.demo && panels.dateTime && panels.qualify && panels.display && panels.output;

  useEffect( () => {
    // savePanelState
    localStorage.setItem( `ps:${ applicationUrl }`, JSON.stringify( panels ) );
  }, [ panels ] );

  const missingStation: Selectable<Station> = useMemo( () => ( { name: "MISSING", id: -1, isTranslated: false, isSelected: false, hasRevenue: false } ), [] );
  const missingDemo: Selectable<Demo> = useMemo( () => ( { name: "MISSING", abbreviated: "MISSING", id: -1, isTotalAudienceDemo: false, isAllCommercialDemo: false, isSelected: false } ), [] );
  const missingDayPart: Selectable<DayPart> = useMemo( () => ( { time: "MISSING", name: "MISSING", id: -1, isSelected: false } ), [] );
  const missingLocalGenre: Selectable<LocalGenre> = useMemo( () => ( { name: "MISSING", id: -1, isSelected: false } ), [] );
  const missingMeasure: Measure = useMemo( () => ( { value: DemoTypeConstants.Put, name: "MISSING" } ), [] );

  // always include the ATS measure
  const measures = useMemo( () => state.measures.map( m => ( { ...m, ...applicationContext.measures.find( n => n.value === m.value ) ?? missingMeasure } ) ), [ applicationContext.measures, missingMeasure, state.measures ] );

  const displayColumns: Array<Selectable<DisplayColumn>> = useMemo( () => applicationContext.displayColumns.map( m => ( { ...m, isSelected: !!state.display.selectedDisplayColumns.find( n => n === m.id ) } ) ), [ applicationContext.displayColumns, state.display.selectedDisplayColumns ] );
  const types: Array<Selectable<QualifyTypeOption>> = useMemo( () => applicationContext.etsTypes.map( m => ( { ...m, isSelected: !!state.qualify.selectedTypes.find( n => n === m.value ) } ) ), [ applicationContext.etsTypes, state.qualify.selectedTypes ] );
  const classOnes: Array<Selectable<QualifyClassOneOption>> = useMemo( () => applicationContext.classOnes.map( m => ( { ...m, isSelected: !!state.qualify.selectedClassOnes.find( n => n === m.value ) } ) ), [ applicationContext.classOnes, state.qualify.selectedClassOnes ] );
  const classTwos: Array<Selectable<QualifyClassTwoOption>> = useMemo( () => applicationContext.classTwos.map( m => ( { ...m, isSelected: !!state.qualify.selectedClassTwos.find( n => n === m.value ) } ) ), [ applicationContext.classTwos, state.qualify.selectedClassTwos ] );
  const formats: Array<Selectable<QualifyFormatOption>> = useMemo( () => applicationContext.formats.map( m => ( { ...m, isSelected: !!state.qualify.selectedFormats.find( n => n === m.value ) } ) ), [ applicationContext.formats, state.qualify.selectedFormats ] );
  const distributors: Array<Selectable<QualifyDistributorOption>> = useMemo( () => applicationContext.distributors.map( m => ( { ...m, isSelected: !!state.qualify.selectedDistributors.find( n => n === m.value ) } ) ), [ applicationContext.distributors, state.qualify.selectedDistributors ] );
  const productionCountries: Array<Selectable<QualifyProductionCountryOption>> = useMemo( () => applicationContext.productionCountries.map( m => ( { ...m, isSelected: !!state.qualify.selectedProductionCountries.find( n => n === m.value ) } ) ), [ applicationContext.productionCountries, state.qualify.selectedProductionCountries ] );
  const productionCountryPositions: Array<Selectable<QualifyProductionCountryPositionOption>> = useMemo( () => applicationContext.productionCountryPositions.map( m => ( { ...m, isSelected: !!state.qualify.selectedProductionCountryPositions.find( n => n === m.value ) } ) ), [ applicationContext.productionCountryPositions, state.qualify.selectedProductionCountryPositions ] );

  const countryGroups = useMemo( () => applicationContext.countryGroups.map( group => {
    const countries: Array<Selectable<Country>> = applicationContext.countries.filter( m => m.group === group ).map( m => ( { ...m, isSelected: !!state.countries.find( n => n === m.id ) } ) );
    const selectedCountryCount = countries.filter( m => m.isSelected ).length;

    return ( {
      name: group,
      countries: countries,
      isSelected: countries.length === selectedCountryCount
    } );
  } ), [ applicationContext.countries, applicationContext.countryGroups, state.countries ] );

  const countries = useMemo( () => applicationContext.countries.map( m => ( { ...m, isSelected: !!state.countries.find( c => c === m.id ) } ) ), [ applicationContext.countries, state.countries ] );

  const selectedCountries = useMemo( () => applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) ).map( country => {
    const selectedStationIds = state.stations.find( c => c.id === country.id )?.stations ?? [];
    const selectedDemoIds = state.demos.find( c => c.id === country.id )?.demos ?? [];
    const selectedDayPartIds = state.dateTime.dayParts.find( c => c.id === country.id )?.dayParts ?? [];
    const selectedLocalGenreIds = state.qualify.selectedLocalGenres.find( c => c.id === country.id )?.localGenres ?? [];

    const stations: Array<Selectable<Station>> = country.stations.map( m => ( { ...m, isSelected: !!selectedStationIds.find( n => n === m.id ) } ) );
    const demos: Array<Selectable<Demo>> = country.demos.map( m => ( { ...m, isSelected: !!selectedDemoIds.find( n => n === m.id ) } ) );
    const dayParts: Array<Selectable<DayPart>> = country.dayParts.map( m => ( { ...m, isSelected: !!selectedDayPartIds.find( n => n === m.id ) } ) );
    const localGenres: Array<Selectable<LocalGenre>> = ( country.localGenres ?? [] ).map( m => ( { ...m, isSelected: !!selectedLocalGenreIds.find( n => n === m.id ) } ) );

    const selectedStations: Array<Selectable<Station>> = selectedStationIds.map( m => stations.find( n => n.id === m ) ?? missingStation );
    const selectedDemos: Array<Selectable<Demo>> = selectedDemoIds.map( m => demos.find( n => n.id === m ) ?? missingDemo );
    const selectedDayParts: Array<Selectable<DayPart>> = selectedDayPartIds.map( m => dayParts.find( n => n.id === m ) ?? missingDayPart );
    const selectedLocalGenres: Array<Selectable<LocalGenre>> = selectedLocalGenreIds.map( m => localGenres.find( n => n.id === m ) ?? missingLocalGenre );
    const selectedRevenueStations: Array<Selectable<Station>> = stations.filter( m => m.hasRevenue && m.isSelected );

    return ( {
      ...country,
      stations,
      demos,
      dayParts,
      localGenres,
      selectedStations,
      selectedDemos,
      selectedDayParts,
      selectedLocalGenres,
      allStationsSelected: selectedStations.length === country.stations.length && country.stations.length !== 0,
      allDemosSelected: selectedDemos.length === country.demos.length && country.demos.length !== 0,
      noStationsSelected: selectedStations.length === 0 && country.stations.length !== 0,
      noDemosSelected: selectedDemos.length === 0 && country.demos.length !== 0,
      hasSelectedRevenueStations: selectedRevenueStations.length > 0
    } );
  } ), [ applicationContext.countries, missingDayPart, missingDemo, missingLocalGenre, missingStation, state.countries, state.dateTime.dayParts, state.demos, state.qualify.selectedLocalGenres, state.stations ] );

  const hasSelectedCountries = state.countries.length > 0;

  const isWorldSelected = applicationContext.countries.length === state.countries.length;

  const display = useMemo( () => ( { ...state.display, displayColumns } ), [ displayColumns, state.display ] );

  const qualify = useMemo( () => ( { ...state.qualify, types, classOnes, classTwos, formats, distributors, productionCountries, productionCountryPositions } ), [ classOnes, classTwos, distributors, formats, productionCountries, productionCountryPositions, state.qualify, types ] );

  const validation = useMemo( () => validate( applicationContext, state, personalization.dateWarnings ), [ applicationContext, personalization.dateWarnings, state ] );

  const onChangeCountryIsSelected = useCallback( ( countryId: number, isSelected: boolean ) => {
    const country = applicationContext.countriesById[ countryId ];
    dispatch( ChangeCountryIsSelected( { countryId, isSelected, clearExisting: false, defaultStations: country.defaultStations, defaultDemos: country.defaultDemos } ) );
  }, [ applicationContext.countriesById, dispatch ] );

  const onChangeStationIsSelected = useCallback( ( countryId: number, stationId: number, isSelected: boolean ) => dispatch( ChangeStationIsSelected( { countryId, stationId, isSelected } ) ), [ dispatch ] );

  const demoEvents = useMemo( () => ( {
    onChangeDemoRank: ( demoRank: DemoRankConstants ) => dispatch( ChangeDemoRank( demoRank ) ),
    onChangeDemoGroupBy: ( demoGroupBy: DemoGroupByConstants ) => dispatch( ChangeDemoGroupBy( demoGroupBy ) ),
    onChangeDemoSelection: ( demoSelection: DemoSelectionConstants ) => dispatch( ChangeDemoSelection( demoSelection ) ),
    onChangeDemoTypePosition: ( srcValue: DemoTypeConstants, dstValue: DemoTypeConstants ) => dispatch( ChangeDemoTypePosition( { srcValue, dstValue } ) ),
    onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => dispatch( ChangeDemoTypeSelected( { value, isSelected } ) ),
    onChangeDemoTypeRanked: ( value: DemoTypeConstants, isRanked: boolean ) => dispatch( ChangeDemoTypeRanked( { value, isRanked } ) ),
    onChangeDemoIsSelected: ( countryId: number, demoId: number, isSelected: boolean ) => dispatch( ChangeDemoIsSelected( { countryId, demoId, isSelected, clearExisting: false } ) ),
    onChangeDemoSelectedOrder: ( countryId: number, srcDemoId: number, dstDemoId: number ) => dispatch( ChangeDemoSelectedOrder( { countryId, srcDemoId, dstDemoId } ) )
  } ), [ dispatch ] );

  const dateTimeEvents = useMemo( () => ( {
    onChangeFromDate: ( fromDate: number ) => dispatch( ChangeFromDate( fromDate ) ),
    onChangeToDate: ( toDate: number ) => dispatch( ChangeToDate( toDate ) ),
    onChangeDays: ( days: Array<DaysConstants> ) => { dispatch( ChangeDaysSelected( days ) ); },
    onChangeTransmissionDuration: ( transmissionDuration: number ) => { dispatch( ChangeTransmissionDuration( transmissionDuration ) ); },
    onChangeFromTime: ( fromTime: string ) => { dispatch( ChangeFromTime( fromTime ) ); },
    onChangeToTime: ( toTime: string ) => { dispatch( ChangeToTime( toTime ) ); },
    onChangeUseDayParts: ( useDayParts: boolean ) => { dispatch( ChangeUseDayParts( useDayParts ) ); },
    onChangeDayPartIsSelected: ( countryId: number, dayPartId: number, isSelected: boolean ) => dispatch( ChangeDayPartIsSelected( { countryId, dayPartId, isSelected } ) )
  } ), [ dispatch ] );

  const reportTypeEvents = useMemo( () => ( {
    onChangeReportType: ( reportType: ReportTypeConstants ) => { dispatch( ChangeReportType( reportType ) ); }
  } ), [ dispatch ] );

  const qualifyEvents = useMemo( () => ( {
    onChangeQualifyTypeIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeQualifyTypeIsSelected( { value, isSelected } ) ),
    onChangeQualifyClassOneIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeQualifyClassOneIsSelected( { value, isSelected } ) ),
    onChangeQualifyClassTwoIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeQualifyClassTwoIsSelected( { value, isSelected } ) ),
    onChangeQualifyFormatIsSelected: ( value: QualifyFormatConstants, isSelected: boolean ) => dispatch( ChangeQualifyFormatIsSelected( { value, isSelected } ) ),
    onChangeQualifyDistributorIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeQualifyDistributorIsSelected( { value, isSelected } ) ),
    onChangeQualifyProductionCountryIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeQualifyProductionCountryIsSelected( { value, isSelected } ) ),
    onChangeQualifyProductionCountryPositionIsSelected: ( value: number, isSelected: boolean ) => dispatch( ChangeQualifyProductionCountryPositionIsSelected( { value, isSelected } ) ),
    onChangeQualifyLocalGenreDisplay: ( value: QualifyLocalGenreDisplayConstants ) => dispatch( ChangeQualifyLocalGenreDisplay( value ) ),
    onChangeQualifyLocalGenreIsSelected: ( countryId: number, value: number, isSelected: boolean ) => dispatch( ChangeLocalGenreIsSelected( { countryId, localGenreId: value, isSelected } ) )
  } ), [ dispatch ] );

  const displayEvents = useMemo( () => ( {
    onChangeDisplayColumnIsSelected: ( id: string, isSelected: boolean ) => dispatch( ChangeDisplayColumnIsSelected( { id, isSelected } ) ),
    onChangeDisplaySortedBy: ( value: DisplaySortedByConstants ) => dispatch( ChangeDisplaySortedBy( value ) ),
    onChangeDisplayTime: ( value: DisplayTimeConstants ) => dispatch( ChangeDisplayTime( value ) ),
    onChangeDisplayTop: ( value: DisplayTopConstants ) => dispatch( ChangeDisplayTop( value ) ),
    onChangeDisplayUnique: ( value: DisplayUniqueConstants ) => dispatch( ChangeDisplayUnique( value ) )
  } ), [ dispatch ] );

  const outputEvents = useMemo( () => ( {
    onChangeOutputFormat: ( value: OutputFormatConstants ) => dispatch( ChangeOutputFormat( value ) ),
    onChangeOutputView: ( value: OutputViewConstants ) => dispatch( ChangeOutputView( value ) )
  } ), [ dispatch ] );

  const getSourceModel = useCallback( (): ProgramAverageTimeSpentSourceModel => {
    const model: ProgramAverageTimeSpentSourceModel = {
      countries: state.countries,
      dateTime: {
        ...state.dateTime,
        fromDate: formatDateAsIso8601( state.dateTime.fromDate ),
        toDate: formatDateAsIso8601( state.dateTime.toDate )
      },
      demos: state.demos,
      display: state.display,
      measures: state.measures,
      output: state.output,
      stations: state.stations,
      demoGroupBy: state.demoGroupBy,
      demoRank: state.demoRank,
      demoSelection: state.demoSelection,
      qualify: state.qualify,
      reportType: state.reportType
    };

    return model;
  }, [ state.countries, state.dateTime, state.demoGroupBy, state.demoRank, state.demoSelection, state.demos, state.display, state.measures, state.output, state.qualify, state.reportType, state.stations ] );

  const doSubmit = useCallback( async () => {
    if ( validation.errors.length > 0 ) {
      const message = validation.errors.join( "\n" );
      alert( message );
      return;
    }

    if ( validation.warnings.length > 0 ) {
      const message = validation.warnings.join( "\n" );
      if ( !globalThis.window.confirm( message ) ) return;
    }

    setRunning( emptyRunning );

    const model = getSourceModel();

    const result = await postJson<{ id: number; }>( applicationUrl, userId, token, model );
    if ( result ) setRunning( m => { m.id = result.id; } );
  }, [ getSourceModel, setRunning, token, userId, validation.errors, validation.warnings ] );

  const doCancel = useCallback( async () => {
    if ( !running.id ) return;

    await deleteJson( applicationUrl, userId, token, { id: running.id } );

    setRunning( m => { m.cancellationRequested = true; } );
  }, [ running.id, setRunning, token, userId ] );

  useEffect( () => {
    if ( applicationContext.templateOrHistoryId === 0 ) setPanels( m => { m.selectedPanel = ""; m.country = true; m.reportType = false; m.channel = false; m.demo = false; m.dateTime = false; m.qualify = false; m.display = false; m.output = false; } );
    setRunning( emptyRunning );
  }, [ applicationContext.templateOrHistoryId, setPanels, setRunning ] );

  const reportProgressMessageHandler = useCallback( ( args: ReportProgressMessageArgs ) => {
    console.log( args );

    if ( !args ) return;
    if ( args.id !== running.id ) return;

    setRunning( m => {
      m.percentComplete = args.percentComplete;
      m.message = args.message;
      m.url = args.url;
      m.contentType = args.contentType;
      m.status = args.status;
      if ( args.status !== "Started" ) {
        m.id = undefined;
      }
    } );

    if ( args.status === "Completed" ) {
      if ( args.contentType !== "text/html" ) {
        downloadFile( args.url, token ); // perform a download
        setRunning( emptyRunning );
      } else if ( args.contentType === "text/html" ) {
        openLink( args.url, "AverageTimeSpent", state.output.view );
        // setRunning( emptyRunning ); can only do this if we know that the tab has been opened
      }
      return;
    }
  }, [ running.id, setRunning, state.output.view, token ] );

  useEffect( () => {
    notifications.setOnReportProgressMessageHandler( () => reportProgressMessageHandler );
  }, [ notifications, reportProgressMessageHandler ] );

  const toggleExpandedPanels = useCallback( (): void => {
    setPanels( m => {
      const isAllExpanded = allExpanded;
      m.country = !isAllExpanded;
      m.reportType = !isAllExpanded;
      m.channel = !isAllExpanded;
      m.demo = !isAllExpanded;
      m.dateTime = !isAllExpanded;
      m.qualify = !isAllExpanded;
      m.display = !isAllExpanded;
      m.output = !isAllExpanded;
    } );
  }, [ allExpanded, setPanels ] );

  const onSaveTemplate = useCallback( async ( templateName: string, sharedWith: Array<number> ) => {
    const payload = {
      templateName,
      sharedWith,
      model: getSourceModel()
    };

    // positive values are history rows so always create a new template from those
    if ( applicationContext.templateOrHistoryId >= 0 ) {
      const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
      navigate( `/ProgramAverageTimeSpent/${ response.itemId }` );
    }
    else {
      const updateExistingTemplate = applicationContext.templateName === templateName;
      if ( updateExistingTemplate ) {
        await putJson( `/UserTemplate/${ applicationContext.applicationId }/${ applicationContext.templateOrHistoryId }`, userId, token, payload );
      }
      else {
        const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
        navigate( `/ProgramAverageTimeSpent/${ response.itemId }` );
      }
    }

  }, [ applicationContext.applicationId, applicationContext.templateName, applicationContext.templateOrHistoryId, getSourceModel, navigate, token, userId ] );

  return ( <>
    <ApplicationHeader applicationId={ applicationContext.applicationId } isAllExpanded={ allExpanded } title={ applicationContext.name } isEnabled={ hasSelectedCountries } onToggleExpanded={ toggleExpandedPanels } />
    <ExpanderPanel title="Country:" isExpanded={ panels.country } onExpanded={ ( isExpanded ) => setPanels( m => { m.country = isExpanded; } ) } description={ <CountryDescription warningMessage={ validation.country } isWorldSelected={ isWorldSelected } countryGroups={ countryGroups } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <CountrySelector canSelectAllCountriesInGroup={ false } countryGroups={ countryGroups } countries={ countries } isWorldSelected={ isWorldSelected } onChangeCountryIsSelected={ onChangeCountryIsSelected } />
    </ExpanderPanel>
    <ExpanderPanel title="ReportType:" isExpanded={ panels.reportType && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.reportType = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <ReportTypeDescription warningMessage={ validation.reportType } reportType={ state.reportType.reportType } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <ReportTypeSelector helpId="reportType" reportType={ state.reportType.reportType } { ...reportTypeEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Channel:" isExpanded={ panels.channel && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.channel = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <StationsDescription warningMessage={ validation.station } selectedCountries={ selectedCountries } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <span css={ { fontWeight: "bold", marginLeft: "4px" } }>Channels <HelpText helpId="channel" /></span>
      <StationSelector canSelectAllCountriesInGroup selectedCountries={ selectedCountries } onChangeStationIsSelected={ onChangeStationIsSelected } />
    </ExpanderPanel>
    <ExpanderPanel title="Demo:" isExpanded={ panels.demo && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.demo = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <ProgramAverageTimeSpentDemoDescription warningMessage={ validation.demo } demoGroupBy={ state.demoGroupBy } demoRank={ state.demoRank } demoSelection={ state.demoSelection } measures={ measures } selectedCountries={ selectedCountries } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <ProgramAverageTimeSpentDemoSelector demoGroupBy={ state.demoGroupBy } demoRank={ state.demoRank } demoSelection={ state.demoSelection } measures={ measures } selectedCountries={ selectedCountries } { ...demoEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Date/Time:" isExpanded={ panels.dateTime && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.dateTime = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <DateTimeDescription warningMessage={ validation.dateTime } { ...state.dateTime } selectedCountries={ selectedCountries } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DateTimeSelector { ...state.dateTime } selectedCountries={ selectedCountries } { ...dateTimeEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Qualify:" isExpanded={ panels.qualify && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.qualify = isExpanded; } ) } subHeading="Local Genre:" isEnabled={ hasSelectedCountries } description={ <QualifyDescription warningMessage={ validation.qualify } selectedCountries={ selectedCountries } { ...qualify } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <QualifySelector { ...qualify } selectedCountries={ selectedCountries } { ...qualifyEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Display:" isExpanded={ panels.display && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.display = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <DisplayDescription warningMessage={ validation.display } { ...display } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DisplaySelector { ...display } { ...displayEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Output:" isExpanded={ panels.output && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.output = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <OutputDescription warningMessage={ validation.output } { ...state.output } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <OutputSelector { ...state.output } defaultFormat={ OutputFormatConstants.Xlsx } isTemplateOrHistoryOwner={ applicationContext.templateOrHistoryOwnerId === userId } { ...outputEvents } onSaveTemplate={ onSaveTemplate } />
    </ExpanderPanel>
    <Runner running={ running } isValidForSubmit={ validation.isValidForSubmit } doSubmit={ doSubmit } doCancel={ doCancel } view={ state.output.view } applicationWindow="AverageTimeSpent" />
  </> );
};
