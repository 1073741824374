import { Benchmark } from "../../Model";
import { BenchmarkDatesConstants, BenchmarkDayOfWeekTypeConstants, BenchmarkTimePeriodConstants } from "model/Model";
import { createSlice } from "@reduxjs/toolkit";
import { ChangeBenchmarkDates, ChangeBenchmarkDayOfWeekType, ChangeBenchmarkDaysSelected, ChangeBenchmarkDemo, ChangeBenchmarkDemoType, ChangeBenchmarkFromDate, ChangeBenchmarkQuarterIsSelected, ChangeBenchmarkTimePeriod, ChangeBenchmarkTimePeriodFromTime, ChangeBenchmarkTimePeriodToTime, ChangeBenchmarkToDate } from "actions/benchmark/actions";
import { getFirstOfMonthWithFortyFiveDayOffset } from "Utilities";

const benchmarkSlice = createSlice( {
  name: "benchmark",
  initialState: {
    demoCount: 0,
    demoTypes: [],
    dates: BenchmarkDatesConstants.BroadcastQuarter,
    selectedQuarters: [],
    fromDate: getFirstOfMonthWithFortyFiveDayOffset().valueOf(),
    toDate: getFirstOfMonthWithFortyFiveDayOffset().valueOf(),
    timePeriod: BenchmarkTimePeriodConstants.Daypart,
    timePeriodFromTime: "00:00",
    timePeriodToTime: "24:00",
    benchmarkDayOfWeekType: BenchmarkDayOfWeekTypeConstants.SameAsSelectedDays,
    benchmarkDays: []
  } as Benchmark,
  reducers: {},
  extraReducers: builder => {
    builder.addCase( ChangeBenchmarkDemo, ( state, action ) => { state.demoCount = action.payload; } );
    builder.addCase( ChangeBenchmarkDates, ( state, action ) => { state.dates = action.payload; } );
    builder.addCase( ChangeBenchmarkFromDate, ( state, action ) => { state.fromDate = action.payload; } );
    builder.addCase( ChangeBenchmarkToDate, ( state, action ) => { state.toDate = action.payload; } );
    builder.addCase( ChangeBenchmarkTimePeriod, ( state, action ) => { state.timePeriod = action.payload; } );
    builder.addCase( ChangeBenchmarkTimePeriodFromTime, ( state, action ) => { state.timePeriodFromTime = action.payload; } );
    builder.addCase( ChangeBenchmarkTimePeriodToTime, ( state, action ) => { state.timePeriodToTime = action.payload; } );
    builder.addCase( ChangeBenchmarkDayOfWeekType, ( state, action ) => { state.benchmarkDayOfWeekType = action.payload; } );
    builder.addCase( ChangeBenchmarkDaysSelected, ( state, action ) => { state.benchmarkDays = action.payload; } );

    builder.addCase( ChangeBenchmarkDemoType, ( state, action ) => {
      const index = state.demoTypes.findIndex( m => m.value === action.payload.value );
      if ( index >= 0 ) {
        state.demoTypes[ index ].isSelected = action.payload.isSelected;
      }
    } );

    builder.addCase( ChangeBenchmarkQuarterIsSelected, ( state, action ) => {
      if ( action.payload.isSelected ) {
        state.selectedQuarters.push( action.payload.value );
      } else {
        state.selectedQuarters = state.selectedQuarters.filter( m => m !== action.payload.value );
      }
    } );
  }
} );

export default benchmarkSlice.reducer;