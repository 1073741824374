/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from "react";
import { Country, DayPart, DaysConstants, Demo, DemoGroupByConstants, DemoRankConstants, DemoSelectionConstants, DemoTypeConstants, Measure, OutputFormatConstants, OutputViewConstants, QualifyClassOneOption, QualifyClassTwoOption, QualifyDisplayOption, QualifyDistributorOption, QualifyTypeOption, Station } from "model/Model";
import { ChannelViewSourceModel, DisplayReportIncrementConstants, MinimumDurationConstants, Model } from "./Model";
import { CountryDescription } from "components/country-selector/CountryDescription";
import { CountrySelector } from "components/country-selector/CountrySelector";
import { StationsDescription } from "components/station-selector/StationsDescription";
import { StationSelector } from "components/station-selector/StationSelector";
import { DemoSelector } from "components/demo-selector/DemoSelector";
import { DisplaySelector } from "./components/display-selector/DisplaySelector";
import { ExpanderPanel } from "components/expander-panel/ExpanderPanel";
import { DemoTypeSelector } from "components/demo-type-selector/DemoTypeSelector";
import { HelpText } from "components/help-text/HelpText";
import { OutputDescription } from "components/output-selector/OutputDescription";
import { OutputSelector } from "components/output-selector/OutputSelector";
import { DisplayDescription } from "./components/display-selector/DisplayDescription";
import { DemoDescription } from "./components/demo-description/DemoDescription";
import { DateTimeDescription } from "./components/date-time-selector/DateTimeDescription";
import { DateTimeSelector } from "./components/date-time-selector/DateTimeSelector";
import { validateCanada70NoShareOrPutCutOff, validateCountryDemoDates, validateCountryStartDate, validateFromAndToDates, validateFromAndToTime, validateHaveDaypartInEachCountry, validateHaveDays, validateHaveDemoInEachCountry, validateHaveStationInEachCountry, validateJapan38DemosAndStations } from "ValidationHelpers";
import { Selectable, formatDateAsIso8601 } from "Utilities";
import { CountryConstants } from "constants/CountryConstants";
import { ApplicationHeader } from "components/application-header/ApplicationHeader";
import { ChangeDemoRank } from "reducers/demoRankSlice";
import { ChangeDemoGroupBy } from "reducers/demoGroupBySlice";
import { ChangeDemoSelection } from "reducers/demoSelectionSlice";
import { ChangeFromDate } from "reducers/fromDateReducers";
import { ChangeToDate } from "reducers/toDateReducer";
import { ChangeDemoTypePosition, ChangeDemoTypeSelected } from "reducers/measuresSlice";
import { ChangeOutputFormat, ChangeOutputView } from "reducers/outputSlice";
import { ChangeFromTime } from "reducers/fromTimeReducer";
import { ChangeToTime } from "reducers/toTimeReducer";
import { ChangeUseDayParts } from "reducers/useDayPartsReducer";
import { ChangeDaysSelected } from "reducers/daysReducer";
import { ChangeDisplayReportIncrement, ChangeDisplayTypeIsSelected, ChangeDisplayClassOneIsSelected, ChangeDisplayClassTwoIsSelected, ChangeDisplayDistributorIsSelected, ChangeDisplayOptionIsSelected } from "./components/display-selector/displaySlice";
import { ChangeMinimumDuration } from "./components/minimum-length-radio-selector/minimumDurationSlice";
import { ChangeCountryIsSelected } from "reducers/countriesSlice";
import { ChangeDemoIsSelected, ChangeDemoSelectedOrder } from "reducers/demosSlice";
import { ChangeStationIsSelected } from "reducers/stationsSlice";
import { ChangeDayPartIsSelected } from "reducers/dayPartReducer";
import { useAppDispatch, useAppState } from "./app/hooks";
import { useApplicationContext } from "components/application-context/ApplicationContext";
import { ApplicationContextType } from "./Loader";
import { getSessionCredentials } from "contexts/UserContext";
import { ReportProgressMessageArgs, useNotifications } from "contexts/NotificationsContext";
import { useImmer } from "use-immer";
import { RunningState, downloadFile, emptyRunning } from "model/RunningState";
import { Runner } from "components/runner/Runner";
import { deleteJson, postJson, putJson } from "helpers/api";
import { useNavigate } from "react-router";

const applicationUrl = "/Apps/ChannelView";

type PanelState = {
  selectedPanel: string;
  country: boolean;
  channel: boolean;
  demo: boolean;
  display: boolean;
  dateTime: boolean;
  output: boolean;
};

const validate = ( applicationContext: ApplicationContextType, state: Model ) => {
  const validation: {
    isValidForSubmit: boolean;
    warnings: Array<string>;
    errors: Array<string>;
    country?: string;
    station?: string;
    demo?: string;
    measures?: string;
    dateTime?: string;
    output?: string;
    display?: string;
  } = {
    isValidForSubmit: true,
    warnings: [],
    errors: []
  };

  if ( state.countries.length === 0 ) {
    validation.isValidForSubmit = false;
  }

  validateHaveStationInEachCountry( state.countries, state.stations, validation );

  validateHaveDemoInEachCountry( state.countries, state.demos, validation );

  validateFromAndToDates( state.dateTime.fromDate, state.dateTime.toDate, validation );

  validateHaveDays( state.dateTime.days, validation );

  if ( state.dateTime.useDayParts ) {
    validateHaveDaypartInEachCountry( state.countries, state.dateTime.dayParts, validation );
  } else {
    validateFromAndToTime( state.dateTime.fromTime, state.dateTime.toTime, validation );
  }

  // NICE: validate display
  // NICE: validate output

  const selectedCountries = applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) );
  validateCountryStartDate( selectedCountries, state.dateTime.fromDate, validation );

  validateCountryDemoDates( selectedCountries, state.demos, state.dateTime.fromDate, state.dateTime.toDate, validation );

  if ( state.countries.find( m => m === CountryConstants.Japan38 ) || state.countries.find( m => m === CountryConstants.Japan39 ) ) {
    validateJapan38DemosAndStations( applicationContext.countries, state.stations, state.demos, validation );
  }

  if ( state.countries.find( m => m === CountryConstants.Japan38 ) || state.countries.find( m => m === CountryConstants.Japan39 ) ) {
    validateJapan38DemosAndStations( applicationContext.countries, state.stations, state.demos, validation );
  }

  if ( state.countries.filter( m => m === CountryConstants.Canada70 ).length === 1 ) {
    validateCanada70NoShareOrPutCutOff( state.dateTime.toDate, state.measures, validation );
  }

  return validation;
};

const getDefaultPanelState = () => {
  const panelStateJson = localStorage.getItem( `ps:${ applicationUrl }` );
  if ( panelStateJson ) {
    return JSON.parse( panelStateJson ) as PanelState;
  } else {
    return { selectedPanel: "", allExpanded: false, country: false, channel: false, demo: false, display: false, dateTime: false, output: false };
  }
};

export const Index = (): JSX.Element => {
  const notifications = useNotifications();
  const state = useAppState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const applicationContext = useApplicationContext<ApplicationContextType>();

  const { token, userId } = getSessionCredentials();

  const [ panels, setPanels ] = useImmer<PanelState>( getDefaultPanelState() );
  const [ running, setRunning ] = useImmer<RunningState>( emptyRunning );
  const allExpanded = panels.country && panels.channel && panels.demo && panels.display && panels.dateTime && panels.output;

  useEffect( () => {
    // savePanelState
    localStorage.setItem( `ps:${ applicationUrl }`, JSON.stringify( panels ) );
  }, [ panels ] );

  const missingStation: Selectable<Station> = useMemo( () => ( { name: "MISSING", id: -1, isTranslated: false, isSelected: false, hasRevenue: false } ), [] );
  const missingDemo: Selectable<Demo> = useMemo( () => ( { name: "MISSING", abbreviated: "MISSING", id: -1, isTotalAudienceDemo: false, isAllCommercialDemo: false, isSelected: false } ), [] );
  const missingDayPart: Selectable<DayPart> = useMemo( () => ( { time: "MISSING", name: "MISSING", id: -1, isSelected: false } ), [] );
  const missingMeasure: Measure = useMemo( () => ( { value: DemoTypeConstants.Put, name: "MISSING" } ), [] );

  const measures = useMemo( () => state.measures.map( m => ( { ...m, ...applicationContext.measures.find( n => n.value === m.value ) ?? missingMeasure } ) ), [ applicationContext.measures, missingMeasure, state.measures ] );

  const types: Array<Selectable<QualifyTypeOption>> = useMemo( () => applicationContext.etsTypes.map( m => ( { ...m, isSelected: !!state.display.selectedTypes.find( n => n === m.value ) } ) ), [ applicationContext.etsTypes, state.display.selectedTypes ] );
  const classOnes: Array<Selectable<QualifyClassOneOption>> = useMemo( () => applicationContext.classOnes.map( m => ( { ...m, isSelected: !!state.display.selectedClassOnes.find( n => n === m.value ) } ) ), [ applicationContext.classOnes, state.display.selectedClassOnes ] );
  const classTwos: Array<Selectable<QualifyClassTwoOption>> = useMemo( () => applicationContext.classTwos.map( m => ( { ...m, isSelected: !!state.display.selectedClassTwos.find( n => n === m.value ) } ) ), [ applicationContext.classTwos, state.display.selectedClassTwos ] );
  const distributors: Array<Selectable<QualifyDistributorOption>> = useMemo( () => applicationContext.distributors.map( m => ( { ...m, isSelected: !!state.display.selectedDistributors.find( n => n === m.value ) } ) ), [ applicationContext.distributors, state.display.selectedDistributors ] );
  const displayOptions: Array<Selectable<QualifyDisplayOption>> = useMemo( () => applicationContext.channelViewDisplayOptions.map( m => ( { ...m, isSelected: !!state.display.selectedDisplayOptions.find( n => n === m.value ) } ) ), [ applicationContext.channelViewDisplayOptions, state.display.selectedDisplayOptions ] );

  const selectedMeasures = useMemo( () => measures.filter( m => m.isSelected ), [ measures ] );

  const countryGroups = useMemo( () => applicationContext.countryGroups.map( group => {
    const countries: Array<Selectable<Country>> = applicationContext.countries.filter( m => m.group === group ).map( m => ( { ...m, isSelected: !!state.countries.find( n => n === m.id ) } ) );
    const selectedCountryCount = countries.filter( m => m.isSelected ).length;

    return ( {
      name: group,
      countries: countries,
      isSelected: countries.length === selectedCountryCount
    } );
  } ), [ applicationContext.countries, applicationContext.countryGroups, state.countries ] );

  const countries = useMemo( () => applicationContext.countries.map( m => ( { ...m, isSelected: !!state.countries.find( c => c === m.id ) } ) ), [ applicationContext.countries, state.countries ] );

  const selectedCountries = useMemo( () => applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) ).map( country => {
    const selectedStationIds = state.stations.find( c => c.id === country.id )?.stations ?? [];
    const selectedDemoIds = state.demos.find( c => c.id === country.id )?.demos ?? [];
    const selectedDayPartIds = state.dateTime.dayParts.find( c => c.id === country.id )?.dayParts ?? [];

    const stations: Array<Selectable<Station>> = country.stations.map( m => ( { ...m, isSelected: !!selectedStationIds.find( n => n === m.id ) } ) );
    const demos: Array<Selectable<Demo>> = country.demos.map( m => ( { ...m, isSelected: !!selectedDemoIds.find( n => n === m.id ) } ) );
    const dayParts: Array<Selectable<DayPart>> = country.dayParts.map( m => ( { ...m, isSelected: !!selectedDayPartIds.find( n => n === m.id ) } ) );

    const selectedStations: Array<Selectable<Station>> = selectedStationIds.map( m => stations.find( n => n.id === m ) ?? missingStation );
    const selectedDemos: Array<Selectable<Demo>> = selectedDemoIds.map( m => demos.find( n => n.id === m ) ?? missingDemo );
    const selectedDayParts: Array<Selectable<DayPart>> = selectedDayPartIds.map( m => dayParts.find( n => n.id === m ) ?? missingDayPart );

    return ( {
      ...country,
      stations,
      demos,
      dayParts,
      selectedStations,
      selectedDemos,
      selectedDayParts,
      allStationsSelected: selectedStations.length === country.stations.length && country.stations.length !== 0,
      allDemosSelected: selectedDemos.length === country.demos.length && country.demos.length !== 0,
      noStationsSelected: selectedStations.length === 0 && country.stations.length !== 0,
      noDemosSelected: selectedDemos.length === 0 && country.demos.length !== 0
    } );
  } ), [ applicationContext.countries, missingDayPart, missingDemo, missingStation, state.countries, state.dateTime.dayParts, state.demos, state.stations ] );

  const hasSelectedCountries = state.countries.length > 0;
  const isWorldSelected = applicationContext.countries.length === state.countries.length;
  const validation = useMemo( () => validate( applicationContext, state ), [ applicationContext, state ] );

  const onChangeCountryIsSelected = useCallback( ( countryId: number, isSelected: boolean ) => {
    const country = applicationContext.countriesById[ countryId ];
    dispatch( ChangeCountryIsSelected( { countryId, isSelected, clearExisting: false, defaultStations: country.defaultStations, defaultDemos: country.defaultDemos } ) );
  }, [ applicationContext.countriesById, dispatch ] );

  const onChangeStationIsSelected = useCallback( ( countryId: number, stationId: number, isSelected: boolean ) => dispatch( ChangeStationIsSelected( { countryId, stationId, isSelected } ) ), [ dispatch ] );

  const demoEvents = useMemo( () => ( {
    onChangeDemoRank: ( demoRank: DemoRankConstants ) => dispatch( ChangeDemoRank( demoRank ) ),
    onChangeDemoGroupBy: ( demoGroupBy: DemoGroupByConstants ) => dispatch( ChangeDemoGroupBy( demoGroupBy ) ),
    onChangeDemoSelection: ( demoSelection: DemoSelectionConstants ) => dispatch( ChangeDemoSelection( demoSelection ) ),
    onChangeDemoTypePosition: ( srcValue: DemoTypeConstants, dstValue: DemoTypeConstants ) => dispatch( ChangeDemoTypePosition( { srcValue, dstValue } ) ),
    onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => dispatch( ChangeDemoTypeSelected( { value, isSelected } ) ),
    onChangeDemoIsSelected: ( countryId: number, demoId: number, isSelected: boolean ) => dispatch( ChangeDemoIsSelected( { countryId, demoId, isSelected, clearExisting: false } ) ),
    onChangeDemoSelectedOrder: ( countryId: number, srcDemoId: number, dstDemoId: number ) => dispatch( ChangeDemoSelectedOrder( { countryId, srcDemoId, dstDemoId } ) )
  } ), [ dispatch ] );

  const displayEvents = useMemo( () => ( {
    onChangeDisplayReportIncrement: ( value: DisplayReportIncrementConstants ) => dispatch( ChangeDisplayReportIncrement( value ) ),
    onChangeDisplayTypeIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeDisplayTypeIsSelected( { value, isSelected } ) ),
    onChangeDisplayClassOneIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeDisplayClassOneIsSelected( { value, isSelected } ) ),
    onChangeDisplayClassTwoIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeDisplayClassTwoIsSelected( { value, isSelected } ) ),
    onChangeDisplayDistributorIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeDisplayDistributorIsSelected( { value, isSelected } ) ),
    onChangeDisplayOptionIsSelected: ( value: string, isSelected: boolean ) => dispatch( ChangeDisplayOptionIsSelected( { value, isSelected } ) )
  } ), [ dispatch ] );

  const dateTimeEvents = useMemo( () => ( {
    onChangeFromDate: ( fromDate: number ) => dispatch( ChangeFromDate( fromDate ) ),
    onChangeToDate: ( toDate: number ) => dispatch( ChangeToDate( toDate ) ),
    onChangeDays: ( days: Array<DaysConstants> ) => dispatch( ChangeDaysSelected( days ) ),
    onChangeMinimumDuration: ( minimumLength: MinimumDurationConstants ) => dispatch( ChangeMinimumDuration( minimumLength ) ),
    onChangeFromTime: ( fromTime: string ) => dispatch( ChangeFromTime( fromTime ) ),
    onChangeToTime: ( toTime: string ) => dispatch( ChangeToTime( toTime ) ),
    onChangeUseDayParts: ( useDayParts: boolean ) => dispatch( ChangeUseDayParts( useDayParts ) ),
    onChangeDayPartIsSelected: ( countryId: number, dayPartId: number, isSelected: boolean ) => dispatch( ChangeDayPartIsSelected( { countryId, dayPartId, isSelected } ) )
  } ), [ dispatch ] );

  const getSourceModel = useCallback( (): ChannelViewSourceModel => {
    const model: ChannelViewSourceModel =
    {
      countries: state.countries,
      dateTime: {
        ...state.dateTime,
        fromDate: formatDateAsIso8601( state.dateTime.fromDate ),
        toDate: formatDateAsIso8601( state.dateTime.toDate )
      },
      demos: state.demos,
      demoSelection: state.demoSelection,
      display: state.display,
      measures: state.measures,
      output: state.output,
      stations: state.stations
    };
    return model;
  }, [ state.countries, state.dateTime, state.demoSelection, state.demos, state.display, state.measures, state.output, state.stations ] );

  const doSubmit = useCallback( async () => {
    if ( validation.errors.length > 0 ) {
      const message = validation.errors.join( "\n" );
      alert( message );
      return;
    }

    if ( validation.warnings.length > 0 ) {
      const message = validation.warnings.join( "\n" );
      if ( !globalThis.window.confirm( message ) ) return;
    }

    setRunning( emptyRunning );

    const model = getSourceModel();

    const result = await postJson<{ id: number; }>( applicationUrl, userId, token, model );
    if ( result ) setRunning( m => { m.id = result.id; } );
  }, [ getSourceModel, setRunning, token, userId, validation.errors, validation.warnings ] );

  const doCancel = useCallback( async () => {
    if ( !running.id ) return;

    await deleteJson( applicationUrl, userId, token, { id: running.id } );

    setRunning( m => { m.cancellationRequested = true; } );
  }, [ running.id, setRunning, token, userId ] );

  useEffect( () => {
    if ( applicationContext.templateOrHistoryId === 0 ) setPanels( m => { m.selectedPanel = ""; m.country = true; m.channel = false; m.demo = false; m.dateTime = false; m.display = false; m.output = false; } );
    setRunning( emptyRunning );
  }, [ applicationContext.templateOrHistoryId, setPanels, setRunning ] );

  const reportProgressMessageHandler = useCallback( ( args: ReportProgressMessageArgs ) => {
    console.log( args );

    if ( !args ) return;
    if ( args.id !== running.id ) return;

    setRunning( m => {
      m.percentComplete = args.percentComplete;
      m.message = args.message;
      m.url = args.url;
      m.contentType = args.contentType;
      m.status = args.status;
      if ( args.status !== "Started" ) {
        m.id = undefined;
      }
    } );

    if ( args.status === "Completed" && args.contentType !== "text/html" ) {
      // perform a download
      downloadFile( args.url, token );
      setRunning( emptyRunning );
      return;
    }
  }, [ running.id, setRunning, token ] );

  useEffect( () => {
    notifications.setOnReportProgressMessageHandler( () => reportProgressMessageHandler );
  }, [ notifications, reportProgressMessageHandler ] );

  const toggleExpandedPanels = useCallback( () => {
    setPanels( m => {
      const isAllExpanded = allExpanded;
      m.country = !isAllExpanded;
      m.channel = !isAllExpanded;
      m.demo = !isAllExpanded;
      m.dateTime = !isAllExpanded;
      m.display = !isAllExpanded;
      m.output = !isAllExpanded;
    } );
  }, [ allExpanded, setPanels ] );

  const onSaveTemplate = useCallback( async ( templateName: string, sharedWith: Array<number> ) => {
    const payload = {
      templateName,
      sharedWith,
      model: getSourceModel()
    };

    // positive values are history rows so always create a new template from those
    if ( applicationContext.templateOrHistoryId >= 0 ) {
      const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
      navigate( `/ChannelView/${ response.itemId }` );
    }
    else {
      const updateExistingTemplate = applicationContext.templateName === templateName;
      if ( updateExistingTemplate ) {
        await putJson( `/UserTemplate/${ applicationContext.applicationId }/${ applicationContext.templateOrHistoryId }`, userId, token, payload );
      } else {
        const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
        navigate( `/ChannelView/${ response.itemId }` );
      }
    }
  }, [ applicationContext.applicationId, applicationContext.templateName, applicationContext.templateOrHistoryId, getSourceModel, navigate, token, userId ] );

  return ( <>
    <ApplicationHeader applicationId={ applicationContext.applicationId } isAllExpanded={ allExpanded } title={ applicationContext.name } isEnabled={ hasSelectedCountries } onToggleExpanded={ toggleExpandedPanels } />
    <ExpanderPanel isExpanded={ panels.country } onExpanded={ ( isExpanded ) => setPanels( m => { m.country = isExpanded; } ) } title="Country:" description={ <CountryDescription isWorldSelected={ isWorldSelected } warningMessage={ validation.country } countryGroups={ countryGroups } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <CountrySelector canSelectAllCountriesInGroup={ false } countries={ countries } countryGroups={ countryGroups } isWorldSelected={ isWorldSelected } onChangeCountryIsSelected={ onChangeCountryIsSelected } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.channel && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.channel = isExpanded; } ) } title="Channel:" isEnabled={ hasSelectedCountries } description={ <StationsDescription selectedCountries={ selectedCountries } warningMessage={ validation.station } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <span css={ { fontWeight: "bold", marginLeft: "4px" } }>Channels <HelpText helpId="channel" /></span>
      <StationSelector canSelectAllCountriesInGroup={ true } selectedCountries={ selectedCountries } onChangeStationIsSelected={ onChangeStationIsSelected } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.demo && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.demo = isExpanded; } ) } title="Demo:" isEnabled={ hasSelectedCountries } description={ <DemoDescription selectedCountries={ selectedCountries } selectedMeasures={ selectedMeasures } warningMessage={ validation.demo } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DemoTypeSelector helpId="demoType" measures={ measures } { ...demoEvents } />
      <DemoSelector helpId="demo" demoSelection={ state.demoSelection } selectedCountries={ selectedCountries }  { ...demoEvents } />
    </ExpanderPanel >
    <ExpanderPanel isExpanded={ panels.dateTime && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.dateTime = isExpanded; } ) } title="Date/Time:" isEnabled={ hasSelectedCountries } description={ <DateTimeDescription { ...state.dateTime } warningMessage={ validation.dateTime } selectedCountries={ selectedCountries } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DateTimeSelector { ...state.dateTime } selectedCountries={ selectedCountries } { ...dateTimeEvents } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.display && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.display = isExpanded; } ) } title="Display:" isEnabled={ hasSelectedCountries } description={ <DisplayDescription classOnes={ classOnes } classTwos={ classTwos } displayOptions={ displayOptions } distributors={ displayOptions } reportIncrement={ state.display.reportIncrement } types={ types } warningMessage={ validation.display } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DisplaySelector classOnes={ classOnes } classTwos={ classTwos } displayOptions={ displayOptions } distributors={ distributors } reportIncrement={ state.display.reportIncrement } types={ types } { ...displayEvents } />
    </ExpanderPanel>
    <ExpanderPanel isExpanded={ panels.output && hasSelectedCountries } onExpanded={ ( isExpanded ) => setPanels( m => { m.output = isExpanded; } ) } title="Output:" isEnabled={ hasSelectedCountries } description={ <OutputDescription { ...state.output } warningMessage={ validation.output } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) } >
      <OutputSelector includeCsv={ false } includeHtml={ false } format={ state.output.format } view={ state.output.view } defaultFormat={ OutputFormatConstants.Xlsx } isTemplateOrHistoryOwner={ applicationContext.templateOrHistoryOwnerId === userId } onSaveTemplate={ onSaveTemplate } onChangeOutputFormat={ ( value: OutputFormatConstants ) => dispatch( ChangeOutputFormat( value ) ) } onChangeOutputView={ ( value: OutputViewConstants ) => dispatch( ChangeOutputView( value ) ) } />
    </ExpanderPanel>
    <Runner running={ running } isValidForSubmit={ validation.isValidForSubmit } doSubmit={ doSubmit } doCancel={ doCancel } view={ state.output.view } applicationWindow="ChannelView" />
  </> );
};
