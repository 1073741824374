/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { HelpText } from "components/help-text/HelpText";
import React from "react";

const styles = {
  checkGroupSelector: css`
    display:inline-block;
    
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        padding: 0;
        margin: 0;
        padding-right: 10px;
      }
  
      input {
        padding: 0;
        margin: 0 5px 0 0 ;
        width: auto;
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
      }
    }
    `,

  optionContainer: css`
    overflow-x: auto;
    height: 350px;
  `
};

export const CheckGroupSelector = <T,>( { label, helpId, options, isFixedHeight = false, onChange }: { label: string; helpId: string; options: Array<{ value: T; name: string; isSelected: boolean; isEnabled?: boolean; }>; isFixedHeight?: boolean; onChange( value: T, isSelected: boolean ): void; } ): JSX.Element => {
  return (
    <div css={ styles.checkGroupSelector }>
      <fieldset>
        <legend><label>{ label } <HelpText helpId={ helpId } /></label></legend>
        <div css={ isFixedHeight ? styles.optionContainer : undefined }>
          { options.map( ( m, i ) => ( <CheckSelector key={ i } { ...m } isEnabled={ m.isEnabled } onChange={ onChange } /> ) ) }
        </div>
      </fieldset>
    </div>
  );
};

const CheckSelector = <T,>( { value, name, isSelected, isEnabled = true, onChange }: { value: T; name: string; isSelected: boolean; isEnabled?: boolean; onChange: ( value: T, isSelected: boolean ) => void; } ): JSX.Element => {
  return ( <>
    <div data-key={ value }>
      <div>
        { isEnabled && <>
          <input aria-label={ name } type="checkbox" disabled={ !isEnabled } checked={ isSelected } onChange={ ( e: React.FormEvent<HTMLInputElement> ) => isEnabled && onChange( value, e.currentTarget.checked ) } />
        </> }
        <span onSelect={ ( e ) => { e.preventDefault(); return false; } }>{ name }</span>
      </div>
    </div>
  </> );
};
