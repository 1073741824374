import { createAction, createReducer } from "@reduxjs/toolkit";
import { getFirstOfMonthWithFortyFiveDayOffset } from "Utilities";

export const ChangeToDate = createAction<number>( "ChangeToDate" );

const toDateReducers = createReducer(
  getFirstOfMonthWithFortyFiveDayOffset.valueOf() as number | undefined,
  builder => {
    builder.addCase( ChangeToDate, ( _, action ) => action.payload ? action.payload.valueOf() : undefined );
  }
);

export default toDateReducers;