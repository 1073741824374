import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RevenueAmountConstants, RevenueCollapseConstants, RevenueDurationConstants } from "model/Model";
import { Revenue } from "../Model";

const revenueSlice = createSlice( {
  name: "revenue",
  initialState: {
    revenueAmount: RevenueAmountConstants.None,
    revenueDuration: RevenueDurationConstants.None,
    revenueCollapse: RevenueCollapseConstants.Sum
  } as Revenue,
  reducers: {
    ChangeRevenueAmount( state, action: PayloadAction<RevenueAmountConstants> ) { state.revenueAmount = action.payload; },
    ChangeRevenueDuration( state, action: PayloadAction<RevenueDurationConstants> ) { state.revenueDuration = action.payload; },
    ChangeRevenueCollapse( state, action: PayloadAction<RevenueCollapseConstants> ) { state.revenueCollapse = action.payload; }
  }
} );

export const { ChangeRevenueAmount, ChangeRevenueDuration, ChangeRevenueCollapse } = revenueSlice.actions;

export default revenueSlice.reducer;