/** @jsxImportSource @emotion/react */
import { HelpText } from "components/help-text/HelpText";
import { TimePicker } from "components/time-picker/TimePicker";
import { css } from "@emotion/react";
import { twoDigits } from "Utilities";
import { useCallback, useMemo } from "react";

const styles = {
  timeSelector: css`
    display: inline-block;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
          
        &.clickable {
          cursor: pointer;
        }

        input {
          margin-left: 0;
        }
      }
    }
  `
};

export enum IncrementConstants {
  FifteenMinutes = 15,
  ThirtyMinutes = 30
}

export const TimesSelector = ( { fromTime, toTime, increment = IncrementConstants.ThirtyMinutes, startTimeFrom = 0, startTimeTo = 1770, endTimeFrom = 30, endTimeTo = 1800, isSelected, isEnabled = true, helpId, onChangeUseDayParts, onChangeFromTime, onChangeToTime }: { fromTime?: string; toTime?: string; increment?: IncrementConstants; startTimeFrom?: number; startTimeTo?: number; endTimeFrom?: number; endTimeTo?: number; isSelected?: boolean; isEnabled?: boolean; helpId: string; onChangeUseDayParts( useDayParts: boolean ): void; onChangeFromTime( time: string ): void; onChangeToTime( time: string ): void; } ): JSX.Element => {
  const getTimeList = useCallback( ( from: number, to: number, incrementConstant: IncrementConstants ): Array<string> => {
    const result: Array<string> = [];

    let increment = 0;
    switch ( incrementConstant ) {
      case IncrementConstants.FifteenMinutes: increment = 15; break;
      case IncrementConstants.ThirtyMinutes: increment = 30; break;
      default: console.error( "unknown increment" );
    }

    for ( let t = from; t <= to; t += increment ) {
      result.push( `${ twoDigits( Math.floor( t / 60 ) ) }:${ twoDigits( t % 60 ) }` );
    }
    return result;
  }, [] );

  const fromTimeList = useMemo( () => getTimeList( startTimeFrom, startTimeTo, increment ), [ getTimeList, increment, startTimeFrom, startTimeTo ] );
  const toTimeList = useMemo( () => getTimeList( endTimeFrom, endTimeTo, increment ), [ endTimeFrom, endTimeTo, getTimeList, increment ] );

  return ( <>
    <div css={ styles.timeSelector }>
      <fieldset>
        <legend><label><input type="radio" disabled={ !isEnabled } checked={ isSelected === undefined || isSelected } onChange={ () => onChangeUseDayParts( false ) } ></input>Times<HelpText helpId={ helpId } /></label></legend>
        <div>
          <TimePicker heading={ "Start Time" } isEnabled={ isEnabled && ( isSelected ?? false ) } timesList={ fromTimeList } currentTime={ fromTime ?? "00:00" } onChangeTime={ ( t ) => { onChangeFromTime( t ); } } ></TimePicker>
        </div>
        <div>
          <TimePicker heading={ "End Time" } isEnabled={ isEnabled && ( isSelected ?? false ) } timesList={ toTimeList } currentTime={ toTime ?? "24:00" } onChangeTime={ ( t ) => { onChangeToTime( t ); } } ></TimePicker>
        </div>
      </fieldset>
    </div>
  </> );
};
