import { Personalization } from "apps/ApplicationPanels/Model";
import { createContext, useContext, useMemo, useState } from "react";

type PersonalizationContextType = {
  dateFormat: "dd/MM/yyyy" | "MM/dd/yyyy";
  setDateFormat: ( dateFormat: "dd/MM/yyyy" | "MM/dd/yyyy" ) => void;

  dateWarnings: boolean;
  setDateWarnings: ( dateWarnings: boolean ) => void;

  syncCalendar: boolean;
  setSyncCalendar: ( syncCalendar: boolean ) => void;

  displayForeignTitle: boolean;
  setDisplayForeignTitle: ( displayForeignTitle: boolean ) => void;

  alternateRowColorInExcel: boolean;
  setAlternateRowColorInExcel: ( alternateRowColorInExcel: boolean ) => void;
};

const PersonalizationContext = createContext<PersonalizationContextType>( {} as PersonalizationContextType );

export const PersonalizationProvider = ( { personalization, children }: { personalization: Personalization; children: React.ReactNode; } ) => {
  const [ dateFormat, setDateFormat ] = useState<"dd/MM/yyyy" | "MM/dd/yyyy">( personalization.dateFormat );
  const [ dateWarnings, setDateWarnings ] = useState( personalization.dateWarnings );
  const [ syncCalendar, setSyncCalendar ] = useState( personalization.syncCalendar );
  const [ displayForeignTitle, setDisplayForeignTitle ] = useState( personalization.displayForeignTitle );
  const [ alternateRowColorInExcel, setAlternateRowColorInExcel ] = useState( personalization.alternateRowColorInExcel );

  const state = useMemo<PersonalizationContextType>( () => ( {
    dateFormat, setDateFormat,
    dateWarnings, setDateWarnings,
    syncCalendar, setSyncCalendar,
    displayForeignTitle, setDisplayForeignTitle,
    alternateRowColorInExcel, setAlternateRowColorInExcel
  } ), [ alternateRowColorInExcel, dateFormat, dateWarnings, displayForeignTitle, syncCalendar ] );

  return <PersonalizationContext.Provider value={ state }>{ children }</PersonalizationContext.Provider>;
};

export const usePersonalization = () => useContext( PersonalizationContext );
