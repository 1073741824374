/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { RevenueCalculationConstants } from "apps/ProgramPerformance/Model";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  warning: css`
    color: #cc0000;
  `
};

export const RevenueDescription = ( { revenueCalculation, warningMessage }: { revenueCalculation: RevenueCalculationConstants; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    const description = ( ( revenueCalculation ) => {
      switch ( revenueCalculation ) {
        case RevenueCalculationConstants.None: return "None.";
        case RevenueCalculationConstants.Sum: return "Sum.";
        case RevenueCalculationConstants.Average: return "Average.";
        default: return "";
      }
    } )( revenueCalculation );

    results.push( ( <span css={ styles.displayGroupSpace } key={ "revenue_description" } >{ description }</span> ) );

    return results;
  }, [ revenueCalculation ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
