/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ResultFile, Grid } from "./Model";
import { CSSProperties, useEffect, useState } from "react";
import { getIconById } from "components/application-icon/ApplicationIcon";
import { getApplicationDisplayName } from "Utilities";

// cspell: disable
const styles = {
  outer: css`
    display:flex;
    flex-direction: column;
    overflow: auto;
    width: calc(100vw - 10px);
    height: calc(100vh - 50px);
    line-height: 12px;
  `,

  table: css`
    border-collapse: collapse;
    table-layout: fixed;
    
    td {
      color: #000000;
      background: white;
      font-size: 8pt;
      font-weight: 400;
      font-style: normal;
      font-family: "Calibri","sans-serif";
      border: none;
      text-align: left;
      vertical-align: top;
      overflow: hidden;
      white-space-collapse: preserve!important;
    }
  `,
  channelAndStationsRow: css`
    white-space: normal !important;
  `,
  gridsWrapper: css`
    height: calc(100vh - 120px); 
    overflow: auto;
  `,
  tabWrapper: css`
    margin-top: 10px; 
    background-color: #808080; 
    padding-bottom: 10px;`,
  tabButton: css`
    background-color: rgb(220, 230, 241); 
    border: 1px solid silver;    
    cursor: pointer;
    
    &:hover{
      background-color: #e1e1e1;
    }  
  `,
  tabActive: css`
    background-color: white;
    border-top: 1px solid white;
  `
};
// cspell: enable

const GridTable = ( { grid }: { grid: Grid; } ) => {
  return ( <table border={ 0 } cellPadding={ 0 } cellSpacing={ 0 } width={ grid.w } css={ styles.table }>
    <colgroup>
      { grid.cols.map( ( col, i ) => <col key={ i } width={ col.w } span={ col.s } /> ) }
    </colgroup>
    <tbody>
      { grid.rows.map( ( row, r ) => <tr key={ r } style={ { height: `${ row.h }px` } } >
        {
          row.cells.map( ( cell, c ) => {
            const style: CSSProperties = {};
            if ( cell.va ) style.verticalAlign = cell.va;
            if ( cell.tc ) style.color = cell.tc;
            if ( cell.fs ) style.fontSize = cell.fs;
            if ( cell.fw ) style.fontWeight = cell.fw;
            if ( cell.fv ) style.fontStyle = cell.fv;
            if ( cell.ta ) style.textAlign = cell.ta as never;
            if ( cell.ws ) style.whiteSpace = cell.ws as never;
            if ( cell.bc ) style.background = cell.bc;
            if ( cell.bt ) style.borderTop = cell.bt;
            if ( cell.bb ) style.borderBottom = cell.bb;
            if ( cell.bl ) style.borderLeft = cell.bl;
            if ( cell.br ) style.borderRight = cell.br;
            if ( cell.ww ) style.wordWrap = cell.ww as never;
            if ( cell.h ) style.height = `${ cell.h }px`;
            if ( cell.w ) style.width = `${ cell.w }px`;

            return ( <td key={ c } style={ style } colSpan={ cell.colspan } rowSpan={ cell.rowspan }>
              { cell.url ? <FormatLinkWithImage url={ cell.url } value={ cell.v } /> : cell.v }
            </td> );
          } ) }
      </tr> ) }
    </tbody>
  </table> );
};

export const FormatLinkWithImage = ( { url, value }: { url: string; value?: string; } ) => {
  // TODO: this could be a prop somewhere
  const isEidr = url.startsWith( "https://ui.eidr.org" );
  const isImdb = url.startsWith( "http://www.imdb.com" );

  if ( isEidr ) return ( <a rel="noopener noreferrer" target="_blank" href={ url } ><img src={ getIconById( "eidr" ) } alt="View at eidr" /></a> );
  if ( isImdb ) return ( <a rel="noopener noreferrer" target="_blank" href={ url } ><img src={ getIconById( "imdb" ) } alt="View at imdb" /></a> );

  return ( <a rel="noopener noreferrer" target="_blank" href={ url } >{ value }</a> );
};

export const Index = ( { resultFile, applicationId }: { resultFile?: ResultFile; applicationId?: number; } ) => {
  const [ visibleGrid, setVisibleGrid ] = useState( 1 );

  useEffect( () => {
    if ( applicationId ) document.title = getApplicationDisplayName( applicationId );
  }, [ applicationId ] );

  return ( <>
    <div css={ styles.outer }>
      { /* TODO: show a message to the user about not having access to the file */ }
      { !resultFile && <><div>Unable to open file.</div></> }
      { resultFile && <>
        <div css={ [ resultFile.type !== "grid" ? styles.gridsWrapper : undefined, css`padding: 5px;` ] }>
          { resultFile.type === "grid" && visibleGrid === 1 && <GridTable grid={ resultFile.grid1 } /> }
          { resultFile.type === "two-grids" && <>
            { visibleGrid === 1 && <GridTable grid={ resultFile.grid1 } /> }
            { visibleGrid === 2 && <GridTable grid={ resultFile.grid2 } /> }
          </> }
          { resultFile.type === "three-grids" && <>
            { visibleGrid === 1 && <GridTable grid={ resultFile.grid1 } /> }
            { visibleGrid === 2 && <GridTable grid={ resultFile.grid2 } /> }
            { visibleGrid === 3 && <GridTable grid={ resultFile.grid3 } /> }
          </> }
        </div>
        { resultFile.type !== "grid" && <>
          <div css={ styles.tabWrapper } >
            { resultFile.type === "two-grids" && <><button css={ [ styles.tabButton, visibleGrid === 1 ? styles.tabActive : undefined ] } onClick={ () => setVisibleGrid( 1 ) }>{ resultFile.grid1.title }</button><button css={ [ styles.tabButton, visibleGrid === 2 ? styles.tabActive : undefined ] } onClick={ () => setVisibleGrid( 2 ) }>{ resultFile.grid2.title }</button></> }
            { resultFile.type === "three-grids" && <><button css={ [ styles.tabButton, visibleGrid === 1 ? styles.tabActive : undefined ] } onClick={ () => setVisibleGrid( 1 ) }>{ resultFile.grid1.title }</button><button css={ [ styles.tabButton, visibleGrid === 2 ? styles.tabActive : undefined ] } onClick={ () => setVisibleGrid( 2 ) }>{ resultFile.grid2.title }</button><button css={ [ styles.tabButton, visibleGrid === 3 ? styles.tabActive : undefined ] } onClick={ () => setVisibleGrid( 3 ) }>{ resultFile.grid3.title }</button></> }
          </div>
        </> }
      </> }
    </div>
  </> );
};