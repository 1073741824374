/** @jsxImportSource @emotion/react */
import React, { useCallback } from "react";
import { PreviewRow } from "../../Model";
import { css } from "@emotion/react";
import { Selectable } from "Utilities";
import { LoadingDisplay } from "components/loading-display/LoadingDisplay";

const styles = {
  scrollableArea: css`
    max-height: 370px;
    overflow-y: auto;
  
    table {
      position: relative;
      border-collapse: separate;
      border-spacing: 0;
      width: 100%;
      font-size: 9px;
  
      thead {
        th {
          position: sticky;
          top: 0;
          border-bottom: solid silver 1px;
          border-right: solid silver 1px;
          background-color: AliceBlue;
        }
      }
  
      tbody {
        td {
          border-bottom: solid silver 1px;
          border-right: solid silver 1px;
        }
      }
    }
  `,
  left: css`
    padding-left: 5px;
  `,

  center: css`
    text-align: center;    
  `,

  right: css`
    text-align: right;
    padding-right: 5px;
  `,

  alternating: css`
    td {
      background-color: WhiteSmoke;
    }
  `,
  loadingDisplay: css`
    margin-top:20px;
  `
};

export const Preview = ( { isLoading, rows, captions, unselectedIndices, onSelectItem }: { isLoading: boolean; rows: Array<Selectable<PreviewRow>>; captions: Array<{ caption: string; columnName: string; }>; unselectedIndices: Array<number>; onSelectItem: ( id: number, isSelected: boolean ) => void; } ): JSX.Element => {
  const allSelected = unselectedIndices.length === 0;

  const selectNone = useCallback( () => {
    for ( const row of rows ) {
      onSelectItem( row.id, false );
    }
  }, [ onSelectItem, rows ] );

  const selectAll = useCallback( () => {
    for ( const id of unselectedIndices ) {
      onSelectItem( id, true );
    }
  }, [ onSelectItem, unselectedIndices ] );

  return (
    <>
      { isLoading && <div css={ styles.loadingDisplay }><LoadingDisplay text="Searching..." fullScreen={ false } height={ 15 } width={ 15 } borderWidth={ 10 } /></div> }
      { !isLoading && rows.length === 0 && <div>No results found</div> }
      { !isLoading && rows.length > 0 &&
        <div css={ styles.scrollableArea }>
          <table>
            <thead>
              <tr>
                <th css={ css`width: 50px; text-align: center;` }>
                  { allSelected && <button type="button" onClick={ selectNone }>None</button> }
                  { !allSelected && <button type="button" onClick={ selectAll }>All</button> }
                </th>
                {
                  captions.map( c => { return <th>{ c.caption }</th>; } )
                }
              </tr>
            </thead>
            <tbody>
              { rows.map( ( row, i ) => <PreviewTableRow key={ i } isSelected={ !unselectedIndices.includes( row.id ) } row={ row } captions={ captions } isAlternating={ i % 2 === 0 } onSelectItem={ onSelectItem } /> ) }
            </tbody>
          </table>
        </div> }
    </>
  );
};

const PreviewTableRow = ( { row, captions, isSelected, isAlternating, onSelectItem }: { row: PreviewRow; captions: Array<{ caption: string; columnName: string; }>; isSelected: boolean; isAlternating: boolean; onSelectItem: ( id: number, isSelected: boolean ) => void; } ) => {
  return (
    <tr css={ ( isAlternating ? styles.alternating : undefined ) }>
      <td css={ styles.center } ><input aria-label="selected" type="checkbox" checked={ isSelected } onChange={ () => onSelectItem( row.id, !isSelected ) } /></td>
      {
        captions.map( c => {
          switch ( c.columnName ) {
            case "originalTitle": return <td css={ styles.left }>{ row.originalTitle }</td>;
            case "season": return <td css={ styles.left }>{ row.season }</td>;
            case "episodeNumber": return <td css={ styles.left }>{ row.episodeNumber }</td>;
            case "originalRepeat": return <td css={ styles.left }>{ row.originalRepeat }</td>;
            case "premier": return <td css={ styles.left }>{ row.premier }</td>;
            case "localTitle": return <td css={ styles.left }>{ row.localTitle }</td>;
            case "station": return <td css={ styles.left }>{ row.station }</td>;
            case "mmddyyyy": return <td css={ styles.center }>{ row.date }</td>;
            case "days": return <td css={ styles.center }>{ row.days }</td>;
            case "startTime": return <td css={ styles.right }>{ row.start }</td>;
            case "endTime": return <td css={ styles.right }>{ row.end }</td>;
            case "duration": return <td css={ styles.right }>{ row.dur }</td>;
            default: return <td css={ styles.right }>{ row.values[ c.columnName ] }</td>;
          }
        } )
      }
    </tr>
  );
};
