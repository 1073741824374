/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { MinimumDurationConstants } from "../../Model";

const styles = {
  minimumLengthSelector: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        display: block;
        padding: 0;
        margin: 0;
  
        input {
          width: auto;
          padding: 0;
          margin: 0;
          margin-right: 4px;
        }
      }
    }
  `
};

export const MinimumLengthRadioSelector = ( { minimumDuration, enabled = true, onChangeMinimumDuration }: { minimumDuration: MinimumDurationConstants; enabled?: boolean; onChangeMinimumDuration: ( minimumLength: MinimumDurationConstants ) => void; } ): JSX.Element => (
  <div css={ styles.minimumLengthSelector } >
    <fieldset>
      <legend><label>Minimum length</label></legend>
      <label><input type="radio" disabled={ !enabled } radioGroup="minimumDuration" checked={ minimumDuration === MinimumDurationConstants.All } onChange={ () => onChangeMinimumDuration( MinimumDurationConstants.All ) } />All (4+ Min)</label>
      <label><input type="radio" disabled={ !enabled } radioGroup="minimumDuration" checked={ minimumDuration === MinimumDurationConstants.TenMinutes } onChange={ () => onChangeMinimumDuration( MinimumDurationConstants.TenMinutes ) } />10 minutes</label>
      <label><input type="radio" disabled={ !enabled } radioGroup="minimumDuration" checked={ minimumDuration === MinimumDurationConstants.FifteenMinutes } onChange={ () => onChangeMinimumDuration( MinimumDurationConstants.FifteenMinutes ) } />15 minutes</label>
      <label><input type="radio" disabled={ !enabled } radioGroup="minimumDuration" checked={ minimumDuration === MinimumDurationConstants.ThirtyMinutes } onChange={ () => onChangeMinimumDuration( MinimumDurationConstants.ThirtyMinutes ) } />30 minutes</label>
      <label><input type="radio" disabled={ !enabled } radioGroup="minimumDuration" checked={ minimumDuration === MinimumDurationConstants.SixtyMinutes } onChange={ () => onChangeMinimumDuration( MinimumDurationConstants.SixtyMinutes ) } />60 minutes</label>
    </fieldset>
  </div> );
