/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
    margin-right: 5px;
  `,

  country: css`
    display: inline-block;
    width: 180px;
  `,

  warning: css`
    color: #cc0000;
    `
};

export const DemoDescription = ( { selectedCountries, selectedMeasures, warningMessage }: { selectedCountries: Array<{ id: number; name: string; allDemosSelected: boolean; selectedDemos: Array<{ name: string; }>; }>; selectedMeasures: Array<{ name: string; }>; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    for ( const country of selectedCountries ) {
      const demoParts = [];

      if ( country.allDemosSelected ) {
        demoParts.push( "All demos" );
      } else {
        for ( const demo of country.selectedDemos ) {
          demoParts.push( demo.name );
        }
      }

      if ( demoParts.length === 0 ) {
        demoParts.push( "None" );
      }

      const demoTag = selectedCountries.length > 1 ? ( <span css={ styles.displayGroupSpace }>{ country.name }</span> ) : null;
      results.push( ( <div key={ country.id } >{ demoTag }<span>{ demoParts.join( ", " ) }</span></div> ) );
    }

    const measureParts: Array<string> = selectedMeasures.map( m => m.name );

    const hasSelectedMeasures = measureParts.length > 0;

    if ( hasSelectedMeasures ) {
      const demoTag = ( <span css={ styles.displayGroupSpace }>{ measureParts.join( ", " ) }</span> );

      results.push( ( <span key={ "demoDisplay" }><span css={ styles.displayGroupSpace }>Display</span>{ demoTag }</span> ) );
    } else {
      results.push( ( <div key={ "demoDisplay" }>&nbsp;</div> ) );
    }

    return results;
  }, [ selectedCountries, selectedMeasures ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length === 0 ) return <>None</>;
  return <>{ parts }</>;
};
