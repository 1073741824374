/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { Index } from "apps/DistributorFinder/Index";
import { HelpContext } from "components/help-text/HelpText";
import { ApplicationContext, ApplicationContextBaseType } from "components/application-context/ApplicationContext";
import { CountriesById, appendCountriesById, appendMeasuresById, appendTemplateNameAndSharedWith, appendUsersAndGroupsById, enrichCountries } from "model/InitialModel";
import { HelpText, getApplicationDisplayName } from "Utilities";
import { getStore } from "apps/DistributorFinder/app/store";
import { Applications } from "constants/application";
import { Params, useLoaderData } from "react-router";
import { DisplayColumn, DistributorFinderSourceModel, QualifyFormatConstants, QualifyType } from "./Model";
import { getSessionCredentials } from "contexts/UserContext";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { helpTextCache } from "constants/helpTextCache";
import { TemplateOrHistory } from "model/Model";
import { fetchHelpTextData, getJson } from "helpers/api";

const applicationId = Applications.DistributorFinder;

export type ApplicationContextType = ApplicationContextBaseType
  &
{
  readonly countriesById: CountriesById;
  readonly displayColumns: Array<DisplayColumn>;
  readonly etsTypes: Array<QualifyType>;
  readonly formats: Array<{ value: QualifyFormatConstants; name: string; }>;
};

type LoaderData = { id: number; helpText: HelpText; initialData: TemplateOrHistory<DistributorFinderSourceModel>; };

export const distributorFinderLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const id = parseInt( params.id as string || "-1" );

  let helpText: HelpText = helpTextCache[ applicationId ];
  if ( !helpText ) {
    helpText = await fetchHelpTextData( { userId, token, applicationId } );
    helpTextCache[ applicationId ] = helpText;
  }

  const initialData = await getJson<TemplateOrHistory<DistributorFinderSourceModel>>( "/Apps/DistributorFinder", userId, token, { id } );

  validateSourceModel( initialData.query );

  return { id, helpText, initialData };
};

const validateSourceModel = ( initialData: DistributorFinderSourceModel ) => {
  if ( !initialData ) throw new Error( "Source data has not been loaded" );

  if ( !initialData.distributor ) throw new Error( "SourceData.title has not been loaded" );
  if ( initialData.distributor.compareOperator === undefined ) throw new Error( "SourceData.distributor.compareOperator has not been loaded" );
  if ( initialData.distributor.distributorNames === undefined ) throw new Error( "SourceData.distributor.distributorNames has not been loaded" );
  if ( !Array.isArray( initialData.distributor.distributorNames ) ) throw new Error( "SourceData.distributor.distributorNames has not been loaded" );

  if ( initialData.dateTime === undefined ) throw new Error( "SourceData.dateTime has not been loaded" );

  if ( initialData.qualify === undefined ) throw new Error( "SourceData.qualify has not been loaded" );
  if ( initialData.qualify.selectedFormats === undefined ) throw new Error( "SourceData.qualify.selectedFormats has not been loaded" );
  if ( !Array.isArray( initialData.qualify.selectedFormats ) ) throw new Error( "SourceData.qualify.selectedFormats has not been loaded" );
  if ( initialData.qualify.selectedTypes === undefined ) throw new Error( "SourceData.qualify.selectedTypes has not been loaded" );
  if ( !Array.isArray( initialData.qualify.selectedTypes ) ) throw new Error( "SourceData.qualify.selectedTypes has not been loaded" );

  if ( initialData.display === undefined ) throw new Error( "SourceData.display has not been loaded" );
  if ( initialData.display.displaySortedBy === undefined ) throw new Error( "SourceData.display.displaySortedBy has not been loaded" );
  if ( initialData.display.selectedDisplayColumns === undefined ) throw new Error( "SourceData.display.selectedDisplayColumns has not been loaded" );
  if ( !Array.isArray( initialData.display.selectedDisplayColumns ) ) throw new Error( "SourceData.display.selectedDisplayColumns has not been loaded" );

  if ( initialData.output === undefined ) throw new Error( "SourceData.output hasn't loaded correctly" );
  if ( initialData.output.format === undefined ) throw new Error( "SourceData.output.format hasn't loaded correctly" );
  if ( initialData.output.view === undefined ) throw new Error( "SourceData.output.view hasn't loaded correctly" );
};

export const DistributorFinder = () => {
  const loaderData = useLoaderData() as LoaderData;
  const referenceData = useReferenceData();
  const store = getStore( loaderData.initialData.query );

  if ( !referenceData.displayColumns[ applicationId ] ) throw new Error( `No referenceData.displayColumns for application ${ applicationId }` );

  const applicationContext: ApplicationContextType = useMemo( () => appendCountriesById(
    appendTemplateNameAndSharedWith(
      loaderData.initialData.userId,
      loaderData.initialData.name,
      loaderData.initialData.sharedWith,
      appendUsersAndGroupsById(
        appendMeasuresById(
          {
            applicationId,
            templateOrHistoryId: loaderData.id,
            countryGroups: referenceData.countryGroups,
            measures: referenceData.measures,
            includeIdInName: referenceData.includeIdInName,
            sharingUsersAndGroups: referenceData.sharingUsersAndGroups,
            displayColumns: referenceData.displayColumns[ applicationId ],
            countries: enrichCountries( referenceData.countries ),
            name: getApplicationDisplayName( applicationId ),
            etsTypes: referenceData.etsTypes,
            formats: referenceData.formats
          }
        )
      )
    )
  ), [ loaderData.id, loaderData.initialData.name, loaderData.initialData.sharedWith, loaderData.initialData.userId, referenceData.countries, referenceData.countryGroups, referenceData.displayColumns, referenceData.etsTypes, referenceData.formats, referenceData.includeIdInName, referenceData.measures, referenceData.sharingUsersAndGroups ] );

  return (
    <ApplicationContext.Provider value={ applicationContext } key={ loaderData.id }>
      <HelpContext.Provider value={ loaderData.helpText } >
        <Provider store={ store }>
          <Index />
        </Provider>
      </HelpContext.Provider>
    </ApplicationContext.Provider>
  );
};