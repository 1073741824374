/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { DisplaySortedByConstants, DisplayTimeConstants } from "apps/ProgramSearch/Model";
import { css } from "@emotion/react";

const styles = {
  displayGroupSpace: css`
  margin-right: 5px;
`,

  warning: css`
  color: #cc0000;
`
};

export const DisplayDescription = ( { displaySortedBy, displayTime, warningMessage }: { displaySortedBy: DisplaySortedByConstants; displayTime: DisplayTimeConstants; warningMessage?: string; } ): JSX.Element => {
  const parts = useMemo( () => {
    const results: Array<JSX.Element> = [];

    const sortedByText = ( ( displaySortedBy ) => {
      switch ( displaySortedBy ) {
        case DisplaySortedByConstants.CountryChannel: return "Country/Channel";
        case DisplaySortedByConstants.CountryDate: return "Country/Date";
        case DisplaySortedByConstants.Title: return "Title";
        case DisplaySortedByConstants.StartDate: return "Start Date";
        case DisplaySortedByConstants.Rank: return "Rank";
        case DisplaySortedByConstants.Distributor: return "Distributor";
        case DisplaySortedByConstants.ShowType: return "Show Type";
        case DisplaySortedByConstants.ProductionCountry: return "Production Country";
        case DisplaySortedByConstants.Revenue: return "Revenue";
        default: return "";
      }
    } )( displaySortedBy );
    results.push( ( <span key="display_sorted_by_option" css={ styles.displayGroupSpace }>{ sortedByText },</span> ) );

    const timeOptionText = ( ( displayTime ) => {
      switch ( displayTime ) {
        case DisplayTimeConstants.TotalMinutes: return "Total Minutes";
        case DisplayTimeConstants.HoursMinutes: return "Hours:Minutes";
        case DisplayTimeConstants.HoursMinutesSeconds: return "Hours:Minutes:Seconds";
        default: return "";
      }
    } )( displayTime );

    results.push( ( <span key="display_time_option" css={ styles.displayGroupSpace }>{ timeOptionText }.</span> ) );
    return results;
  }, [ displaySortedBy, displayTime ] );

  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length > 0 ) return <>{ parts }</>;
  return <></>;
};
