/** @jsxImportSource @emotion/react */
import { Global, css } from "@emotion/react";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, isRouteErrorResponse, Navigate, Outlet, Params, RouterProvider, useLoaderData, useLocation, useNavigation, useRouteError } from "react-router-dom";
import { ApplicationPanels } from "apps/ApplicationPanels/Loader";
import { AvailableData, availableDataLoader } from "apps/AvailableData/Loader";
import { ChannelView, channelViewLoader } from "apps/ChannelView/Loader";
import { DistributorFinder, distributorFinderLoader } from "apps/DistributorFinder/Loader";
import { ProgramFinder, programFinderLoader } from "apps/ProgramFinder/Loader";
import { ProgramPerformance, programPerformanceLoader } from "apps/ProgramPerformance/Loader";
import { ProgramRanking, programRankingLoader } from "apps/ProgramRanking/Loader";
import { ProgramAverageTimeSpent, programAverageTimeSpentLoader } from "apps/ProgramAverageTimeSpent/Loader";
import { ProgramSearch, programSearchLoader } from "apps/ProgramSearch/Loader";
import { QuarterHour, quarterHourLoader } from "apps/QuarterHour/Loader";
import { RevenueEstimator, revenueEstimatorLoader } from "apps/RevenueEstimator/Loader";
import { WeeklyChannelView, weeklyChannelViewLoader } from "apps/WeeklyChannelView/Loader";
import { Result, resultLoader } from "apps/Result/Loader";
import { UserProvider, getSessionCredentials } from "contexts/UserContext";
import { Login } from "apps/Login/Index";
import { height as pageHeaderHeight, PageHeader } from "components/page-header/PageHeader";
import { ReferenceDataProvider } from "contexts/ReferenceDataContext";
import { HelpText, ReferenceData } from "Utilities";
import { Personalization } from "apps/ApplicationPanels/Model";
import { Applications } from "constants/application";
import { ApplicationPanel } from "apps/ApplicationPanels/ApplicationPanel";
import { ContextMenuProvider } from "apps/ApplicationPanels/contexts/ContextMenuContext";
import { NotificationsProvider } from "contexts/NotificationsContext";
import { fetchHelpTextData, getJson } from "helpers/api";
import { PersonalizationProvider } from "contexts/PersonalizationContext";
import { LoadingDisplay } from "components/loading-display/LoadingDisplay";

// TODO: copying to applications isn't right - sometimes it misses entire applications, and it fails always

const globalCss = css`
body {
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 12px;
  line-height: 24px;
  font-family: "Segoe UI", Arial, sans-serif;
  margin: 0;
  user-select: none;    
}

fieldset {
    input {
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
}

legend {
  font-size: 12px;
  line-height: 24px;
  font-weight: bold;
}
`;

const root = ReactDOM.createRoot(
  document.getElementById( "root" ) as HTMLElement
);

// TODO: can't get this to affect the width
const applicationBarWidth = 90;

const styles = {
  wrapper: css`display: flex;`,
  applicationPanels: css`height: calc( 100vh - ${ pageHeaderHeight }px ); flex: 0 0 ${ applicationBarWidth }px;`,
  outlet: css`height: calc( 100vh - ${ pageHeaderHeight }px ); overflow-y: auto; width: calc( 100vw - ${ applicationBarWidth }px ); position: relative;`
};

type LoaderData = {
  applicationId: number;
  helpText: HelpText;
  applications: ApplicationPanel[];
  referenceData: ReferenceData;
  personalization: Personalization;
};

const validateHelpText = ( helpText: HelpText | undefined ) => {
  if ( !helpText ) throw new Error( "HelpText hasn't loaded correctly" );
  if ( !helpText.personalization ) throw new Error( "HelpText.Personalization hasn't loaded correctly" );
  return true;
};

const validatePersonalization = ( personalization: Personalization | undefined ) => {
  if ( !personalization ) throw new Error( "personalization is undefined" );
  return true;
};

const validateApplications = ( applications: Array<ApplicationPanel | undefined> | undefined ) => {
  if ( !applications ) throw new Error( "Applications haven't loaded correctly" );
  if ( !Array.isArray( applications ) ) throw new Error( "Applications haven't loaded correctly" );

  return true;
};

const validateReferenceData = ( referenceData: ReferenceData | undefined ) => {
  if ( !referenceData ) throw new Error( "ReferenceData hasn't loaded correctly" );
  if ( referenceData.includeIdInName === undefined ) throw new Error( "ReferenceData.includeIdInName hasn't loaded correctly" );

  /*: Array<string>*/
  if ( referenceData.countryGroups === undefined ) throw new Error( "ReferenceData.countryGroups hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.countryGroups ) ) throw new Error( "ReferenceData.countryGroups hasn't loaded correctly" );

  /*: Record<string, Array<{ id: string; name: string; }>>*/
  if ( referenceData.displayColumns === undefined ) throw new Error( "ReferenceData.displayColumns hasn't loaded correctly" );

  /*: Array<{ value: number; name: string; }>;*/
  if ( referenceData.benchmarkDemoTypes === undefined ) throw new Error( "ReferenceData.benchmarkDemoTypes hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.benchmarkDemoTypes ) ) throw new Error( "ReferenceData.benchmarkDemoTypes hasn't loaded correctly" );

  /*: Array<CountryDto>;*/
  if ( referenceData.countries === undefined ) throw new Error( "ReferenceData.countries hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.countries ) ) throw new Error( "ReferenceData.countries hasn't loaded correctly" );

  /*: Array<{ value: number; name: string; }>;*/
  if ( referenceData.measures === undefined ) throw new Error( "ReferenceData.measures hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.measures ) ) throw new Error( "ReferenceData.measures hasn't loaded correctly" );

  if ( referenceData.etsTypes === undefined ) throw new Error( "ReferenceData.etsTypes hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.etsTypes ) ) throw new Error( "ReferenceData.etsTypes hasn't loaded correctly" );

  if ( referenceData.classOnes === undefined ) throw new Error( "ReferenceData.classOnes hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.classOnes ) ) throw new Error( "ReferenceData.classOnes hasn't loaded correctly" );

  if ( referenceData.classTwos === undefined ) throw new Error( "ReferenceData.classTwos hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.classTwos ) ) throw new Error( "ReferenceData.classTwos hasn't loaded correctly" );

  if ( referenceData.formats === undefined ) throw new Error( "ReferenceData.formats hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.formats ) ) throw new Error( "ReferenceData.formats hasn't loaded correctly" );

  if ( referenceData.distributors === undefined ) throw new Error( "ReferenceData.distributors hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.distributors ) ) throw new Error( "ReferenceData.distributors hasn't loaded correctly" );

  if ( referenceData.productionCountries === undefined ) throw new Error( "ReferenceData.productionCountries hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.productionCountries ) ) throw new Error( "ReferenceData.productionCountries hasn't loaded correctly" );

  /*: Array<{ id: number; name: string; type: string; }>;*/
  if ( referenceData.sharingUsersAndGroups === undefined ) throw new Error( "ReferenceData.sharingUsersAndGroups hasn't loaded correctly" );
  if ( !Array.isArray( referenceData.sharingUsersAndGroups ) ) throw new Error( "ReferenceData.sharingUsersAndGroups hasn't loaded correctly" );

  /*: Array<{ userId: number; companyId: number; display: string; }>;*/
  if ( referenceData.switchUsers && !Array.isArray( referenceData.switchUsers ) ) throw new Error( "ReferenceData.switchUsers hasn't loaded correctly" );

  /*: Array<{ companyId: number; display: string; }>;*/
  if ( referenceData.switchCompanies && !Array.isArray( referenceData.switchCompanies ) ) throw new Error( "ReferenceData.switchCompanies hasn't loaded correctly" );

  return true;
};

export const outerLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const url = new URL( request.url );
  const applicationId = locationToApplicationId( url.pathname );

  const helpText = await fetchHelpTextData( { userId, token, applicationId: Applications.Personalization } );
  const personalization = await getJson<Personalization>( "/Personalization", userId, token );
  // convert the old style personalization value to a new one
  if ( personalization.dateFormat as string === "M/d/yyyy" ) personalization.dateFormat = "MM/dd/yyyy";
  if ( personalization.dateFormat as string === "d/M/yyyy" ) personalization.dateFormat = "dd/MM/yyyy";

  const applications = await getJson<ApplicationPanel[]>( "/ApplicationPanel", userId, token );
  const referenceData = await getJson<ReferenceData>( "/ReferenceData", userId, token );

  // TODO: fix this in the API
  for ( const c of referenceData.countries ) {
    for ( const d of c.demos ) {
      d.isTotalAudienceDemo ||= ( d as { isTotalAudience?: boolean; } ).isTotalAudience || false;
    }
  }

  validateHelpText( helpText );
  validatePersonalization( personalization );
  validateApplications( applications );
  validateReferenceData( referenceData );

  return { applicationId, helpText, referenceData, applications, personalization };
};

const locationToApplicationId = ( url: string ): number => {
  if ( url === "/" ) return 999999;
  if ( new RegExp( "^/result.*", "i" ).test( url ) ) return Applications.Result;
  if ( new RegExp( "^/channelView/.*", "i" ).test( url ) ) return Applications.ChannelView;
  if ( new RegExp( "^/distributorFinder/.*", "i" ).test( url ) ) return Applications.DistributorFinder;
  if ( new RegExp( "^/programFinder/.*", "i" ).test( url ) ) return Applications.ProgramFinder;
  if ( new RegExp( "^/programPerformance/.*", "i" ).test( url ) ) return Applications.ProgramPerformance;
  if ( new RegExp( "^/programRanking/.*", "i" ).test( url ) ) return Applications.ProgramRanking;
  if ( new RegExp( "^/programAverageTimeSpent/.*", "i" ).test( url ) ) return Applications.ProgramAverageTimeSpent;
  if ( new RegExp( "^/programSearch/.*", "i" ).test( url ) ) return Applications.ProgramSearch;
  if ( new RegExp( "^/quarterHour/.*", "i" ).test( url ) ) return Applications.QuarterHour;
  if ( new RegExp( "^/revenueEstimator/.*", "i" ).test( url ) ) return Applications.RevenueEstimator;
  if ( new RegExp( "^/weeklyChannelView/.*", "i" ).test( url ) ) return Applications.WeeklyChannelView;
  if ( new RegExp( "^/availableData/.*", "i" ).test( url ) ) return Applications.AvailableData;

  console.log( "Unknown location:", url );
  return 999999;
};

const LoggedInErrorBoundary = () => {
  const error = useRouteError();

  return ( <>
    <PageHeader />
    <div css={ css`padding: 10px;` }>
      <h2>Critical error - please report this to support so that we can fix it</h2>
      <p>Try refreshing the page, or logging out and then logging back in again to continue.</p>
      { isRouteErrorResponse( error ) && <div>{ JSON.stringify( error ) }</div> }
    </div>
  </> );
};

const Outer = () => {
  const user = getSessionCredentials();
  const loaderData = useLoaderData() as LoaderData;
  const location = useLocation();

  useEffect( () => {
    if ( !user.token ) return;
    if ( !loaderData ) return;
    if ( loaderData.applicationId !== 999999 ) return;
  }, [ loaderData, user.token ] );

  if ( !user.token ) {
    return ( <Navigate to={ "/login" } /> );
  } else if ( loaderData.applicationId === 999999 && loaderData.applications && loaderData.applications.length > 0 ) {
    switch ( loaderData.applications[ 0 ].applicationId ) {
      case Applications.ChannelView: return <Navigate to="/channelView/0" />;
      case Applications.DistributorFinder: return <Navigate to="/distributorFinder/0" />;
      case Applications.ProgramFinder: return <Navigate to="/programFinder/0" />;
      case Applications.ProgramPerformance: return <Navigate to="/programPerformance/0" />;
      case Applications.ProgramRanking: return <Navigate to="/programRanking/0" />;
      case Applications.ProgramAverageTimeSpent: return <Navigate to="/programAverageTimeSpent/0" />;
      case Applications.ProgramSearch: return <Navigate to="/programSearch/0" />;
      case Applications.QuarterHour: return <Navigate to="/quarterHour/0" />;
      case Applications.RevenueEstimator: return <Navigate to="/revenueEstimator/0" />;
      case Applications.WeeklyChannelView: return <Navigate to="/weeklyChannelView/0" />;
    }
    return <></>;
  } else if ( loaderData.applicationId === 999999 ) {
    return <Navigate to="/availableData/0" />;
  } else if ( loaderData.applicationId === Applications.Result ) {
    return ( <>
      <UserProvider>
        <PersonalizationProvider personalization={ loaderData.personalization } >
          <ReferenceDataProvider referenceData={ loaderData.referenceData } >
            <NotificationsProvider token={ user.token }>
              <PageHeader />
              <Outlet />
            </NotificationsProvider>
          </ReferenceDataProvider>
        </PersonalizationProvider>
      </UserProvider>
    </> );
  } else {
    return ( <>
      <UserProvider>
        <PersonalizationProvider personalization={ loaderData.personalization } >
          <ReferenceDataProvider referenceData={ loaderData.referenceData } >
            <NotificationsProvider token={ user.token }>
              <ContextMenuProvider>
                <PageHeader />
                <div css={ styles.wrapper }>
                  <div css={ styles.applicationPanels }>
                    <ApplicationPanels helpText={ loaderData.helpText } applications={ loaderData.applications } applicationId={ locationToApplicationId( location.pathname ) } />
                  </div>
                  <div id="main" css={ styles.outlet }>
                    <Outlet />
                  </div>
                </div>
              </ContextMenuProvider>
            </NotificationsProvider>
          </ReferenceDataProvider>
        </PersonalizationProvider>
      </UserProvider>
    </> );
  }
};

const ResultLoadingPlaceholder = () => {
  const navigation = useNavigation();
  console.log( navigation.location?.pathname );
  if ( navigation.location?.pathname.toLowerCase() !== "/result" ) {
    return null;
  }

  return ( <><LoadingDisplay text={ "Building report..." } fullScreen={ true } width={ 100 } height={ 100 } borderWidth={ 16 } /></> );
};

const router = createBrowserRouter( [
  {
    path: "/",
    loader: outerLoader,
    errorElement: <LoggedInErrorBoundary />,
    element: <Outer />,
    children: [
      { path: "/channelView/:id",             /**/ loader: channelViewLoader,             /**/ element: <ChannelView /> },
      { path: "/distributorFinder/:id",       /**/ loader: distributorFinderLoader,       /**/ element: <DistributorFinder /> },
      { path: "/programFinder/:id",           /**/ loader: programFinderLoader,           /**/ element: <ProgramFinder /> },
      { path: "/programPerformance/:id",      /**/ loader: programPerformanceLoader,      /**/ element: <ProgramPerformance /> },
      { path: "/programRanking/:id",          /**/ loader: programRankingLoader,          /**/ element: <ProgramRanking /> },
      { path: "/programAverageTimeSpent/:id", /**/ loader: programAverageTimeSpentLoader, /**/ element: <ProgramAverageTimeSpent /> },
      { path: "/programSearch/:id",           /**/ loader: programSearchLoader,           /**/ element: <ProgramSearch /> },
      { path: "/quarterHour/:id",             /**/ loader: quarterHourLoader,             /**/ element: <QuarterHour /> },
      { path: "/revenueEstimator/:id",        /**/ loader: revenueEstimatorLoader,        /**/ element: <RevenueEstimator /> },
      { path: "/weeklyChannelView/:id",       /**/ loader: weeklyChannelViewLoader,       /**/ element: <WeeklyChannelView /> },
      { path: "/availableData/:id",           /**/ loader: availableDataLoader,           /**/ element: <AvailableData /> },
      { path: "/result",                      /**/ loader: resultLoader,                  /**/ element: <Result /> }
    ]
  },
  {
    path: "/login",
    element: <Login />
  }
] );

root.render(
  <>
    <React.StrictMode>
      <Global styles={ globalCss } />
      <RouterProvider router={ router } fallbackElement={ <ResultLoadingPlaceholder /> } />
    </React.StrictMode>
  </>
);

reportWebVitals();
