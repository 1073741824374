import { combineReducers } from "redux";
import { DateTime } from "../../Model";
import fromDateReducers from "reducers/fromDateReducers";
import toDateReducers from "reducers/toDateReducer";

const dateTimeReducers = combineReducers<DateTime>( {
  fromDate: fromDateReducers,
  toDate: toDateReducers
} );

export default dateTimeReducers;
