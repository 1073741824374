/** @jsxImportSource @emotion/react */
import { useMemo } from "react";
import { Selectable } from "Utilities";
import { css } from "@emotion/react";
import { useReferenceData } from "contexts/ReferenceDataContext";

const styles = {
  warning: css`
    color: #cc0000;
  `
};

export const CountryDescription = ( { isWorldSelected, countryGroups, warningMessage }: { isWorldSelected: boolean; countryGroups: Array<Selectable<{ name: string; countries: Array<Selectable<{ id: number; name: string; }>>; }>>; warningMessage?: string; } ): JSX.Element => {
  const referenceData = useReferenceData();

  const parts = useMemo( () => {
    const results = [];
    for ( const group of countryGroups ) {
      if ( group.isSelected ) {
        results.push( group.name );
      } else {
        for ( const country of group.countries.filter( m => m.isSelected ) ) {
          results.push( country.name + ( referenceData.includeIdInName ? `-${ country.id }` : "" ) );
        }
      }
    }
    return results;
  }, [ countryGroups, referenceData.includeIdInName ] );

  if ( isWorldSelected ) return <>World</>;
  if ( warningMessage ) return <span css={ styles.warning }>{ warningMessage }</span>;
  if ( parts.length === 0 ) return <>None</>;
  return <>{ parts.join( ", " ) }</>;
};
