import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Display, DisplayReportIncrementConstants } from "../../Model";

const displaySlice = createSlice( {
  name: "display",
  initialState: {
    reportIncrement: DisplayReportIncrementConstants.ThirtyMinutes,
    types: [],
    selectedTypes: [],
    classOnes: [],
    selectedClassOnes: [],
    classTwos: [],
    selectedClassTwos: [],
    distributors: [],
    selectedDistributors: [],
    displayOptions: [],
    selectedDisplayOptions: []
  } as Display,
  reducers: {
    ChangeDisplayReportIncrement( state, action: PayloadAction<DisplayReportIncrementConstants> ) { state.reportIncrement = action.payload; },

    ChangeDisplayTypeIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedTypes: [ ...state.selectedTypes, value ] };
      }
      return { ...state, selectedTypes: state.selectedTypes.filter( m => m !== value ) };
    },

    ChangeDisplayClassOneIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedClassOnes: [ ...state.selectedClassOnes, value ] };
      }
      return { ...state, selectedClassOnes: state.selectedClassOnes.filter( m => m !== value ) };
    },

    ChangeDisplayClassTwoIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedClassTwos: [ ...state.selectedClassTwos, value ] };
      }
      return { ...state, selectedClassTwos: state.selectedClassTwos.filter( m => m !== value ) };
    },

    ChangeDisplayDistributorIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedDistributors: [ ...state.selectedDistributors, value ] };
      }
      return { ...state, selectedDistributors: state.selectedDistributors.filter( m => m !== value ) };
    },

    ChangeDisplayOptionIsSelected( state, action: PayloadAction<{ value: string; isSelected: boolean; }> ) {
      const { value, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedDisplayOptions: [ ...state.selectedDisplayOptions, value ] };
      }
      return { ...state, selectedDisplayOptions: state.selectedDisplayOptions.filter( m => m !== value ) };
    }
  }
} );

export const { ChangeDisplayReportIncrement, ChangeDisplayTypeIsSelected, ChangeDisplayClassOneIsSelected, ChangeDisplayClassTwoIsSelected, ChangeDisplayDistributorIsSelected, ChangeDisplayOptionIsSelected } = displaySlice.actions;

export default displaySlice.reducer;