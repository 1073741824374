import { } from "model/Model";
import { ReportType, ReportTypeConstants } from "apps/ProgramAverageTimeSpent/Model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const reportTypeSlice = createSlice( {
  name: "reportType",
  initialState: {
    reportType: ReportTypeConstants.ImportsAndLocal
  } as ReportType,
  reducers: {
    ChangeReportType( state, action: PayloadAction<ReportTypeConstants> ) { state.reportType = action.payload; }
  }
} );

export const { ChangeReportType } = reportTypeSlice.actions;

export default reportTypeSlice.reducer;