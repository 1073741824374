import { createAction, createReducer } from "@reduxjs/toolkit";
import { getFirstOfMonthWithFortyFiveDayOffset } from "Utilities";

export const ChangeFromDate = createAction<number>( "ChangeFromDate" );

const fromDateReducers = createReducer(
  getFirstOfMonthWithFortyFiveDayOffset.valueOf() as number | undefined,
  builder => {
    builder.addCase( ChangeFromDate, ( _, action ) => action.payload ? action.payload.valueOf() : undefined );
  }
);

export default fromDateReducers;