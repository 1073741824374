/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { Index } from "apps/ProgramPerformance/Index";
import { CountriesById, appendCountriesById, appendDayPartsByCountryIdAndId, appendDemosByCountryIdAndId, appendMeasuresById, appendStationsByCountryIdAndId, appendTemplateNameAndSharedWith, appendUsersAndGroupsById, enrichCountries } from "model/InitialModel";
import { HelpContext } from "components/help-text/HelpText";
import { ApplicationContext, ApplicationContextBaseType } from "components/application-context/ApplicationContext";
import { HelpText, getApplicationDisplayName } from "Utilities";
import { getStore } from "apps/ProgramPerformance/app/store";
import { Applications } from "constants/application";
import { Params, useLoaderData } from "react-router";
import { ProgramPerformanceSourceModel } from "./Model";
import { getSessionCredentials } from "contexts/UserContext";
import { useReferenceData } from "contexts/ReferenceDataContext";
import { helpTextCache } from "constants/helpTextCache";
import { DemoSelectionConstants, TemplateOrHistory } from "model/Model";
import { fetchHelpTextData, getJson } from "helpers/api";

const applicationId = Applications.ProgramPerformance;

export type ApplicationContextType = ApplicationContextBaseType
  &
{
  readonly countriesById: CountriesById;
};

type LoaderData = { id: number; helpText: HelpText; initialData: TemplateOrHistory<ProgramPerformanceSourceModel>; };

export const programPerformanceLoader = async ( { request, params }: { request: Request; params: Params<string>; } ): Promise<LoaderData | null> => {
  const { token, userId } = getSessionCredentials();
  if ( token === "" ) return null;

  const id = parseInt( params.id as string || "-1" );

  let helpText: HelpText = helpTextCache[ applicationId ];
  if ( !helpText ) {
    helpText = await fetchHelpTextData( { userId, token, applicationId } );
    helpTextCache[ applicationId ] = helpText;
  }

  const initialData = await getJson<TemplateOrHistory<ProgramPerformanceSourceModel>>( "/Apps/ProgramPerformance", userId, token, { id } );

  initialData.query.demoSelection = initialData.query.demoSelection || DemoSelectionConstants.selectedDemos; // default to selectedDemos for historical reports

  return { id, helpText, initialData };
};

export const ProgramPerformance = () => {
  const loaderData = useLoaderData() as LoaderData;
  const referenceData = useReferenceData();
  const store = getStore( loaderData.initialData.query );

  const applicationContext: ApplicationContextType = useMemo( () =>
    appendTemplateNameAndSharedWith(
      loaderData.initialData.userId,
      loaderData.initialData.name,
      loaderData.initialData.sharedWith,
      appendCountriesById(
        appendStationsByCountryIdAndId(
          appendDemosByCountryIdAndId(
            appendDayPartsByCountryIdAndId(
              appendUsersAndGroupsById(
                appendMeasuresById(
                  {
                    applicationId,
                    templateOrHistoryId: loaderData.id,
                    countryGroups: referenceData.countryGroups,
                    includeIdInName: referenceData.includeIdInName,
                    measures: referenceData.measures,
                    sharingUsersAndGroups: referenceData.sharingUsersAndGroups,
                    countries: enrichCountries( referenceData.countries ),
                    name: getApplicationDisplayName( applicationId )
                  }
                )
              )
            )
          )
        )
      )
    ), [ loaderData.id, loaderData.initialData.name, loaderData.initialData.sharedWith, loaderData.initialData.userId, referenceData.countries, referenceData.countryGroups, referenceData.includeIdInName, referenceData.measures, referenceData.sharingUsersAndGroups ] );

  return (
    <ApplicationContext.Provider value={ applicationContext } key={ loaderData.id }>
      <HelpContext.Provider value={ loaderData.helpText } >
        <Provider store={ store }>
          <Index />
        </Provider>
      </HelpContext.Provider>
    </ApplicationContext.Provider>
  );
};