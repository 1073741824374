import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Display, DisplaySortedByConstants } from "../../Model";

const displaySlice = createSlice( {
  name: "display",
  initialState: {
    selectedDisplayColumns: [],
    displaySortedBy: DisplaySortedByConstants.DistributorCountry
  } as Display,
  reducers: {
    ChangeDisplayColumnIsSelected( state, action: PayloadAction<{ id: string; isSelected: boolean; }> ) {
      const { id, isSelected } = action.payload;
      if ( isSelected ) {
        return { ...state, selectedDisplayColumns: [ ...state.selectedDisplayColumns, id ] };
      }
      return { ...state, selectedDisplayColumns: state.selectedDisplayColumns.filter( m => m !== id ) };
    },

    ChangeDisplaySortedBy( state, action: PayloadAction<DisplaySortedByConstants> ) { state.displaySortedBy = action.payload; }
  }
} );

export const { ChangeDisplayColumnIsSelected, ChangeDisplaySortedBy } = displaySlice.actions;

export default displaySlice.reducer;