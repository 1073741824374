/** @jsxImportSource @emotion/react */
import { DaysConstants } from "model/Model";
import React, { useCallback } from "react";
import { HelpText } from "components/help-text/HelpText";
import { Check } from "../BootstrapIcons";
import { css } from "@emotion/react";

const styles = {
  daySelector: css`
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 6px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
  
      label {
        input {
          margin-left: 10px;
          margin-right: 5px;
          height: 20px;
          vertical-align: unset;
        }
        span {
          display:inline-block;
          vertical-align: top;
          padding-top:2px;
        }     
    }
  `,

  check: css`
    &:hover {
      cursor: pointer;
    }
  `
};

const arrayEquals = ( a: Array<DaysConstants>, b: Array<DaysConstants> ) => {
  return Array.isArray( a )
    && Array.isArray( b )
    && a.length === b.length
    && a.every( ( val, index ) => val === b[ index ] );
};

const allDays = [ DaysConstants.monday, DaysConstants.tuesday, DaysConstants.wednesday, DaysConstants.thursday, DaysConstants.friday, DaysConstants.saturday, DaysConstants.sunday ];
const weekDays = [ DaysConstants.monday, DaysConstants.tuesday, DaysConstants.wednesday, DaysConstants.thursday, DaysConstants.friday ];
const weekends = [ DaysConstants.saturday, DaysConstants.sunday ];

export const DaysSelector = ( { isEnabled = true, helpId, days, onChangeDays }: { helpId: string; days: Array<DaysConstants>; onChangeDays: ( days: Array<DaysConstants> ) => void; isEnabled?: boolean; } ): JSX.Element => {
  const handleMultipleChange = useCallback( ( values: Array<DaysConstants> ) => {
    const selected = [ ...values ];
    onChangeDays( selected.sort() );
  }, [ onChangeDays ] );

  const handleChange = useCallback( ( value: DaysConstants ) => {
    let selected = [ ...days ];
    if ( selected.includes( value ) ) {
      selected = selected.filter( ( v ) => { return v !== value; } );
    }
    else {
      selected.push( value );
    }

    onChangeDays( selected.sort() );
  }, [ days, onChangeDays ] );

  const handleTickClick = useCallback( () => {
    if ( arrayEquals( days, allDays ) ) {
      onChangeDays( Array<DaysConstants>() );
    } else {
      onChangeDays( [ ...allDays ] );
    }
  }, [ days, onChangeDays ] );

  return ( <>
    <div css={ styles.daySelector }>
      <fieldset>
        <legend><span css={ ( isEnabled ? styles.check : undefined ) } onClick={ () => isEnabled && handleTickClick() }><Check /></span><label>Days <HelpText helpId={ helpId } /></label></legend>
        <label><input disabled={ !isEnabled } type="radio" name="days" radioGroup="days" checked={ arrayEquals( days, allDays ) } value="1,2,3,4,5,6,7" onChange={ () => handleMultipleChange( allDays ) } /><span>Mon - Sun</span></label>
        <label><input disabled={ !isEnabled } type="radio" name="days" radioGroup="days" checked={ arrayEquals( days, weekDays ) } value="1,2,3,4,5" onChange={ () => { handleMultipleChange( weekDays ); } } /><span>Mon - Fri</span></label>
        <label><input disabled={ !isEnabled } type="radio" name="days" radioGroup="days" checked={ arrayEquals( days, weekends ) } value="6,7" onChange={ () => { handleMultipleChange( [ DaysConstants.saturday, DaysConstants.sunday ] ); } } /><span>Sat - Sun</span></label>
        <label><input disabled={ !isEnabled } type="checkbox" checked={ days.includes( DaysConstants.monday ) } onChange={ () => handleChange( DaysConstants.monday ) } /*ref={ input => { if ( input ) input.indeterminate = !noneSelected && !props.allDemosSelected; } } */ /><span>Mon</span></label>
        <label><input disabled={ !isEnabled } type="checkbox" checked={ days.includes( DaysConstants.tuesday ) } onChange={ () => handleChange( DaysConstants.tuesday ) } /*ref={ input => { if ( input ) input.indeterminate = !noneSelected && !props.allDemosSelected; } } */ /><span>Tue</span></label>
        <label><input disabled={ !isEnabled } type="checkbox" checked={ days.includes( DaysConstants.wednesday ) } onChange={ () => handleChange( DaysConstants.wednesday ) } /*ref={ input => { if ( input ) input.indeterminate = !noneSelected && !props.allDemosSelected; } } */ /><span>Wed</span></label>
        <label><input disabled={ !isEnabled } type="checkbox" checked={ days.includes( DaysConstants.thursday ) } onChange={ () => handleChange( DaysConstants.thursday ) } /*ref={ input => { if ( input ) input.indeterminate = !noneSelected && !props.allDemosSelected; } } */ /><span>Thu</span></label>
        <label><input disabled={ !isEnabled } type="checkbox" checked={ days.includes( DaysConstants.friday ) } onChange={ () => handleChange( DaysConstants.friday ) } /*ref={ input => { if ( input ) input.indeterminate = !noneSelected && !props.allDemosSelected; } } */ /><span>Fri</span></label>
        <label><input disabled={ !isEnabled } type="checkbox" checked={ days.includes( DaysConstants.saturday ) } onChange={ () => handleChange( DaysConstants.saturday ) } /*ref={ input => { if ( input ) input.indeterminate = !noneSelected && !props.allDemosSelected; } } */ /><span>Sat</span></label>
        <label><input disabled={ !isEnabled } type="checkbox" checked={ days.includes( DaysConstants.sunday ) } onChange={ () => handleChange( DaysConstants.sunday ) } /*ref={ input => { if ( input ) input.indeterminate = !noneSelected && !props.allDemosSelected; } } */ /><span>Sun</span></label>
      </fieldset>
    </div >
  </> );
};
