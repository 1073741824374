import { DaysConstants, DemoSelectionConstants, DemoTypeConstants, Output } from "model/Model";
import { Selectable } from "Utilities";

export type Model = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demoSelection: DemoSelectionConstants;
  demos: Array<{ id: number; demos: Array<number>; }>;
  measures: Array<Selectable<{ value: DemoTypeConstants; }>>;
  dateTime: DateTime;
  output: Output;
  display: Display;
};

// the source contains string based dates which will get turned into numeric dates
export type DateTimeSource = {
  fromDate: string;
  toDate: string;
  days: Array<DaysConstants>;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
  minimumDuration: MinimumDurationConstants;
};

export type ChannelViewSourceModel = {
  countries: Array<number>;
  stations: Array<{ id: number; stations: Array<number>; }>;
  demos: Array<{ id: number; demos: Array<number>; }>;
  demoSelection: DemoSelectionConstants;
  measures: Array<Selectable<{ value: DemoTypeConstants; }>>;
  dateTime: DateTimeSource;
  display: Display;
  output: Output;
};

export enum DisplayReportIncrementConstants {
  OneMinute = 1,
  FiveMinutes = 5,
  TenMinutes = 10,
  FifteenMinutes = 15,
  ThirtyMinutes = 30
}

export enum MinimumDurationConstants {
  All = 4,
  TenMinutes = 10,
  FifteenMinutes = 15,
  ThirtyMinutes = 30,
  SixtyMinutes = 60
}

export type DisplayTypeOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;

export type DisplayClassOneOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;

export type DisplayClassTwoOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;

export type DisplayDistributorOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;

export type DisplayOption = Selectable<{ value: string; name: string; isEnabled?: boolean; }>;

export type Display = {
  reportIncrement: DisplayReportIncrementConstants;
  selectedTypes: Array<string>;
  selectedClassOnes: Array<string>;
  selectedClassTwos: Array<string>;
  selectedDistributors: Array<string>;
  selectedDisplayOptions: Array<string>;
};

export type DateTime = {
  fromDate?: number;
  toDate?: number;
  days: Array<DaysConstants>;
  minimumDuration: MinimumDurationConstants;
  fromTime: string;
  toTime: string;
  useDayParts: boolean;
  dayParts: Array<{ id: number; dayParts: Array<number>; }>;
};