import { createAction, createReducer } from "@reduxjs/toolkit";
import { DurationConstants } from "../../Model";

export const ChangeDuration = createAction<DurationConstants>( "ChangeDuration" );

const durationReducers = createReducer(
  DurationConstants.All as DurationConstants,
  builder => {
    builder.addCase( ChangeDuration, ( _, action ) => { return action.payload; } );
  }
);

export default durationReducers;