/** @jsxImportSource @emotion/react */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ApplicationContext } from "components/application-context/ApplicationContext";
import { HelpText } from "components/help-text/HelpText";
import { RadioGroupDisplayConstants } from "components/radio-group-selector/RadioGroupDisplayConstants";
import { RadioGroupSelector } from "components/radio-group-selector/RadioGroupSelector";
import { OutputFormatConstants, OutputViewConstants } from "model/Model";
import { css } from "@emotion/react";

const styles = {
  outputSelector: css`
    line-height: 22px;
    display: inline-block;
    vertical-align: top;
  
    fieldset {
      border: solid black 1px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      display: inline;
      margin-left: 4px;
      margin-right: 4px;
  
      label {
        display: block;
      }
  
      legend {
        width: auto;
        margin: 0;
        margin-left: 4px;
        padding-left: 4px;
        padding-right: 4px;
  
        &.clickable {
          cursor: pointer;
        }
      }
    }
  `,
  companyItem: css`
    padding-top: 20px;
    font-weight: bold;
  `,

  userAndGroupSelector: css`
    position: absolute;
    border: solid black 1px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 4px 4px silver;

    input {
      min-width: 100px;
    }

    ul {
      margin: 0;
      padding: 0;

      hr {
        margin: 0;
        margin-bottom: 0.5rem;
      }

      li:first-of-type {
        padding-top: 0;
      }

      li {
        list-style-type: none;
        margin: 0;

        input {
          min-width: auto;
          width: auto;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  `,

  userItem: css`
    margin-top: 0px;
  `,

  buttonWrapper: css`
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  `,
  button: css`
   margin: 0 5px;
  `
};

const sideBarWidth = 250;

const UserAndGroupSelector = ( { userIds, onChangeUserIds }: { userIds: Array<number>; onChangeUserIds: ( value: Array<number> ) => void; } ) => {
  const applicationContext = useContext( ApplicationContext );

  return (
    <ul>
      { applicationContext.sharingUsersAndGroups.map( ( m, i ) => {
        const isCompany = m.type === "company";
        const selected = !!userIds.find( n => n === m.id );

        return ( <React.Fragment key={ i }>
          <li css={ isCompany ? styles.companyItem : styles.userItem }>
            <label>
              <input aria-label={ m.name } type="checkbox" checked={ selected } onChange={ ( e ) => onChangeUserIds( e.currentTarget.checked ? [ ...userIds, m.id ] : userIds.filter( n => n !== m.id ) ) } /> { m.name }
            </label>
          </li>
          { m.type === "company" && <li><hr /></li> }
        </React.Fragment> );
      } ) }
    </ul>
  );
};

const TemplateSharing = ( { onSaveTemplate }: { onSaveTemplate: ( name: string, sharedWith: Array<number> ) => void; } ) => {
  const applicationContext = useContext( ApplicationContext );
  const [ templateName, setTemplateName ] = useState( applicationContext.templateName );
  const [ sharedWith, setSharedWith ] = useState( applicationContext.sharedWith );

  const onChangeTemplateName = useCallback( ( value: string ) => setTemplateName( value ), [] );

  const onChangeSharedWith = useCallback( ( value: Array<number> ) => setSharedWith( value ), [] );

  const onSaveClick = useCallback( () => {
    console.log( "Save template" );
    onSaveTemplate?.( templateName, sharedWith );
  }, [ onSaveTemplate, sharedWith, templateName ] );

  const selectedUserNames = useMemo( () => {
    const selectedUserNames = [];
    for ( const userId of sharedWith ) {
      const user = applicationContext.sharingUsersAndGroups.filter( m => m.id === userId );
      if ( user.length === 1 ) {
        selectedUserNames.push( user[ 0 ].name );
      }
    }
    return selectedUserNames;
  }, [ sharedWith, applicationContext.sharingUsersAndGroups ] );

  const [ isListVisible, setIsListVisible ] = useState( false );
  const [ selectorBounds, setSelectorBounds ] = useState<{ top: number; left: number; maxHeight: number; height: number | undefined; }>( { top: 0, left: 0, maxHeight: 0, height: undefined } );

  const txtRef = useRef<HTMLInputElement>( null );
  const divRef = useRef<HTMLDivElement>( null );

  const mouseDownHandler = useCallback( ( e: MouseEvent ) => {
    if ( e.target === txtRef.current ) return; // allow clicking on the textbox as well as in the panel
    if ( !divRef?.current?.contains( e.target as Node ) ) {
      setIsListVisible( false );
    }
  }, [] );

  const showSelectorHandler = useCallback( () => {
    if ( !txtRef?.current ) return;
    const headerOffset = 45;

    const bounds = txtRef.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowScroll = document.getElementById( "main" )?.scrollTop ?? 0; // note the window does not scroll the 'main' element does

    if ( bounds.bottom > windowHeight / 2 ) {
      // display the selector above
      const revisedSelectorBounds = { left: bounds.left - sideBarWidth, top: windowScroll, maxHeight: bounds.top - headerOffset, height: bounds.top - headerOffset };
      setSelectorBounds( revisedSelectorBounds );
    } else {
      // display the selector below
      const revisedSelectorBounds = { left: bounds.left - sideBarWidth, top: bounds.bottom - headerOffset, maxHeight: windowHeight - bounds.bottom - 5, height: undefined };
      setSelectorBounds( revisedSelectorBounds );
    }
    setIsListVisible( true );
  }, [] );

  useEffect( () => {
    document.addEventListener( "mousedown", mouseDownHandler );
    return () => document.removeEventListener( "mousedown", mouseDownHandler );
  } );

  return (
    <div css={ styles.outputSelector }>
      <fieldset>
        <legend><label>Template <HelpText helpId="reportTemplate" /></label></legend>
        <div css={ css`display: inline-block;` }>
          <label>Name <HelpText helpId="" /></label>
          <input aria-label="Name" type="text" value={ templateName } onChange={ ( e ) => onChangeTemplateName( e.currentTarget.value ) } />
        </div>
        <div css={ css`display: inline-block; margin-left: 5px;` }>
          <label>Share <HelpText helpId="reportShare" /></label>
          <input aria-label="Share" ref={ txtRef } type="text" readOnly={ true } value={ selectedUserNames.length <= 2 ? selectedUserNames.join( ", " ) : `${ selectedUserNames.length } items checked` } onClick={ showSelectorHandler } />
        </div>
        { isListVisible && <div ref={ divRef } css={ [ styles.userAndGroupSelector, css`padding-left: 5px; padding-right: 5px; left: ${ selectorBounds.left }px; top: ${ selectorBounds.top }px; max-height: ${ selectorBounds.maxHeight }px; height: ${ selectorBounds.height }px;` ] }><UserAndGroupSelector userIds={ sharedWith } onChangeUserIds={ onChangeSharedWith } /></div> }
        <div css={ styles.buttonWrapper }>
          <button css={ styles.button } type="button" disabled={ templateName?.length === 0 } onClick={ onSaveClick }>Save</button>
        </div>
      </fieldset>
    </div>
  );
};

export const OutputSelector = ( { includeXlsx = true, includeCsv = true, includeHtml = true, includeOutputView = true, format, view, defaultFormat, isTemplateOrHistoryOwner, onSaveTemplate, onChangeOutputFormat, onChangeOutputView }: { includeXlsx?: boolean; includeCsv?: boolean; includeHtml?: boolean; includeOutputView?: boolean; format: OutputFormatConstants; view: OutputViewConstants; defaultFormat: OutputFormatConstants; isTemplateOrHistoryOwner: boolean; onSaveTemplate: ( name: string, sharedWith: Array<number> ) => void; onChangeOutputFormat: ( value: OutputFormatConstants ) => void; onChangeOutputView: ( value: OutputViewConstants ) => void; } ): JSX.Element => {
  const outputFormatItems = useMemo( () => [ { value: OutputFormatConstants.Xlsx, name: "Excel", isEnabled: includeXlsx }, { value: OutputFormatConstants.Html, name: "HTML", isEnabled: includeHtml }, { value: OutputFormatConstants.Csv, name: "CSV", isEnabled: includeCsv } ], [ includeCsv, includeHtml, includeXlsx ] );
  const outputViewItems = useMemo( () => [ { value: OutputViewConstants.ReportTab, name: "Report tab" }, { value: OutputViewConstants.ApplicationTab, name: "Application tab" }, { value: OutputViewConstants.NewTab, name: "New tab" } ], [] );

  // check that the default format is allowed
  if ( defaultFormat === OutputFormatConstants.Csv && !includeCsv ) {
    throw new Error( "Default format is CSV but that is not allowed" );
  }
  if ( defaultFormat === OutputFormatConstants.Xlsx && !includeXlsx ) {
    throw new Error( "Default format is XLSX but that is not allowed" );
  }
  if ( defaultFormat === OutputFormatConstants.Html && !includeHtml ) {
    throw new Error( "Default format is HTML but that is not allowed" );
  }

  useEffect( () => {
    if ( format === OutputFormatConstants.Csv && !includeCsv ) {
      onChangeOutputFormat( defaultFormat );
      return;
    }

    if ( format === OutputFormatConstants.Xlsx && !includeXlsx ) {
      onChangeOutputFormat( defaultFormat );
      return;
    }

    if ( format === OutputFormatConstants.Html && !includeHtml ) {
      onChangeOutputFormat( defaultFormat );
      return;
    }

    if ( !outputFormatItems.find( m => m.value === format ) ) {
      onChangeOutputFormat( defaultFormat );
    }
  }, [ defaultFormat, format, includeCsv, includeHtml, includeXlsx, onChangeOutputFormat, outputFormatItems ] );

  return ( <>
    <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="Report" helpId="" value={ format } radioGroup="output-format" items={ outputFormatItems } onChangeItemSelected={ onChangeOutputFormat } />
    { includeOutputView && <RadioGroupSelector radioGroupDisplayOption={ RadioGroupDisplayConstants.Stacked } label="View" helpId="reportView" isEnabled={ format === OutputFormatConstants.Html } value={ view } radioGroup="output-view" items={ outputViewItems } onChangeItemSelected={ onChangeOutputView } /> }
    { isTemplateOrHistoryOwner && <TemplateSharing onSaveTemplate={ onSaveTemplate } /> }
  </> );
};
