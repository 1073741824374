import { getNotificationsUrl } from "helpers/api";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { usePersonalization } from "./PersonalizationContext";

type MessageType = "UserTemplateRenamedMessage" | "UserTemplateDeletedMessage" | "UserTemplateCreatedMessage" | "SharedTemplateDeletedMessage" | "TestMessage";

// these must match the server-sent-events - a shame they all have to live here
export type UserTemplateRenamedMessageArgs = {
  applicationId: number;
  templateId: number;
  name: string;
};

export type UserTemplateMovedMessageArgs = {
  applicationId: number;
  templateId: number;
  parentId: number;
};

export type UserTemplateUpdatedMessageArgs = {
  applicationId: number;
  templateId: number;
  name: string;
};

export type UserTemplateDeletedMessageArgs = {
  applicationId: number;
  templateId: number;
};

export type UserTemplateCreatedMessageArgs = {
  applicationId: number;
  templateId: number;
  name: string;
  parentId: number;
  isFolder: boolean;
};

export type UserHistoryCreatedMessageArgs = {
  applicationId: number;
  historyId: number;
  name: string;
};

export type UserPersonalizationUpdatedMessageArgs = {
  dateFormat: "MM/dd/yyyy" | "dd/MM/yyyy";
  syncCalendar: boolean;
  dateWarnings: boolean;
  displayForeignTitle: boolean;
  alternateRowColorInExcel: boolean;
};

export type SharedTemplateDeletedMessageArgs = {
  applicationId: number;
  templateId: number;
};

export type ReportProgressMessageArgs = {
  id: number;
  status: "Started" | "Completed" | "Failed" | "Cancelled";
  percentComplete: number;
  message: string;
  url: string;
  contentType: string;
};

type TemplateMessageHandlers = {
  onUserTemplateUpdatedMessageHandler: ( ( args: UserTemplateUpdatedMessageArgs ) => void ) | undefined;
  onUserTemplateRenamedMessageHandler: ( ( args: UserTemplateRenamedMessageArgs ) => void ) | undefined;
  onUserTemplateMovedMessageHandler: ( ( args: UserTemplateMovedMessageArgs ) => void ) | undefined;
  onUserTemplateDeletedMessageHandler: ( ( args: UserTemplateDeletedMessageArgs ) => void ) | undefined;
  onUserTemplateCreatedMessageHandler: ( ( args: UserTemplateCreatedMessageArgs ) => void ) | undefined;
  onSharedTemplateDeletedMessageHandler: ( ( args: SharedTemplateDeletedMessageArgs ) => void ) | undefined;
  onUserHistoryCreatedMessageHandler: ( ( args: UserHistoryCreatedMessageArgs ) => void ) | undefined;
};

type NotificationsContextType = {
  setTemplateMessageHandlers: ( templateMessageHandlers: TemplateMessageHandlers | undefined ) => void;
  setOnReportProgressMessageHandler: ( handler?: ( args: ReportProgressMessageArgs ) => void ) => void;
  // FUTURE: lots more events
};

const NotificationsContext = createContext<NotificationsContextType>( {} as NotificationsContextType );

// need a bunch of implementations
// TODO: introduce a msg that updates the personalization (based upon it being changed server side)

export const NotificationsProvider = ( { token, children }: { token: string; children: React.ReactNode; } ) => {
  const personalization = usePersonalization();
  const [ eventSourceObj, setEventSourceObj ] = useState<EventSource | null>( null );
  const [ templateMessageHandlers, setTemplateMessageHandlers ] = useState<TemplateMessageHandlers | undefined>( undefined );
  const [ onReportProgressMessageHandler, setOnReportProgressMessageHandler ] = useState<( ( args: ReportProgressMessageArgs ) => void ) | undefined>( undefined );
  //const [ onTestMessageHandler, setOnTestMessageHandler ] = useState<( ( args: TestMessageArgs ) => void ) | undefined>( undefined );

  const eventSourceEventPing = useCallback( ( ev: MessageEvent<unknown> ) => {
    console.log( "SSE ping:", ev );
  }, [] );

  // this use effect connects the event source - this will persist across other changes
  useEffect( () => {
    if ( !token ) return;

    console.log( "connect notifications" );

    const url = getNotificationsUrl( token );
    console.log( "connect notifications: ", url );

    const eventSource = new globalThis.EventSource( url );
    setEventSourceObj( eventSource );

    return () => {
      console.log( "disconnect notifications" );
      eventSource.close();
      eventSource.removeEventListener( "ping", eventSourceEventPing );
    };
  }, [ eventSourceEventPing, token ] );

  const onUserPersonalizationUpdatedMessageHandler = useCallback( ( args: UserPersonalizationUpdatedMessageArgs ) => {
    personalization.setDateFormat( args.dateFormat );
    personalization.setDateWarnings( args.dateWarnings );
    personalization.setSyncCalendar( args.syncCalendar );
    personalization.setDisplayForeignTitle( args.displayForeignTitle );
    personalization.setAlternateRowColorInExcel( args.alternateRowColorInExcel );
  }, [ personalization ] );

  const eventSourceMessage = useCallback( ( ev: MessageEvent<{ type: MessageType; }> ) => {
    const data = JSON.parse( ev.data as unknown as string );
    console.log( "SSE msg:", data );
    switch ( data.type ) {
      case "UserTemplateUpdatedMessage": templateMessageHandlers?.onUserTemplateUpdatedMessageHandler?.( data as never ); break;
      case "UserTemplateRenamedMessage": templateMessageHandlers?.onUserTemplateRenamedMessageHandler?.( data as never ); break;
      case "UserTemplateMovedMessage": templateMessageHandlers?.onUserTemplateMovedMessageHandler?.( data as never ); break;
      case "UserTemplateDeletedMessage": templateMessageHandlers?.onUserTemplateDeletedMessageHandler?.( data as never ); break;
      case "UserTemplateCreatedMessage": templateMessageHandlers?.onUserTemplateCreatedMessageHandler?.( data as never ); break;
      case "SharedTemplateDeletedMessage": templateMessageHandlers?.onSharedTemplateDeletedMessageHandler?.( data as never ); break;
      case "UserPersonalizationUpdatedMessage": onUserPersonalizationUpdatedMessageHandler?.( data as never ); break;
      case "ReportProgressMessage": onReportProgressMessageHandler?.( data as never ); break;
      case "UserHistoryCreatedMessage": templateMessageHandlers?.onUserHistoryCreatedMessageHandler?.( data as never ); break;

      default:
        console.error( `Unhandled notification of type: ${ data.type }`, ev );
        break;
    }
  }, [ onReportProgressMessageHandler, onUserPersonalizationUpdatedMessageHandler, templateMessageHandlers ] );

  // this effect binds the event handlers on the events source - the handlers might change but the event source stays the same
  useEffect( () => {
    if ( !eventSourceObj ) return;
    eventSourceObj.onmessage = eventSourceMessage;
  }, [ eventSourceMessage, eventSourceObj ] );

  const state = useMemo<NotificationsContextType>( () => ( {
    setTemplateMessageHandlers,
    setOnReportProgressMessageHandler
  } ), [] );

  return <NotificationsContext.Provider value={ state }>{ children }</NotificationsContext.Provider>;
};

export const useNotifications = () => useContext( NotificationsContext );
