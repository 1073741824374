/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from "react";
import { AverageDayAverageConstants, AverageTimeAverageConstants, Model, QuarterHourSourceModel } from "./Model";
import { Country, DayPart, DaysConstants, Demo, DemoGroupByConstants, DemoSelectionConstants, DemoTypeConstants, Measure, OutputFormatConstants, OutputViewConstants, Station } from "model/Model";
import { CountryDescription } from "components/country-selector/CountryDescription";
import { CountrySelector } from "components/country-selector/CountrySelector";
import { StationsDescription } from "components/station-selector/StationsDescription";
import { StationSelector } from "components/station-selector/StationSelector";
import { ExpanderPanel } from "components/expander-panel/ExpanderPanel";
import { OutputDescription } from "components/output-selector/OutputDescription";
import { OutputSelector } from "components/output-selector/OutputSelector";
import { HelpText } from "components/help-text/HelpText";
import { AverageSelector } from "./components/average-selector/AverageSelector";
import { Selectable, formatDateAsIso8601 } from "Utilities";
import { DateTimeSelector } from "./components/date-time-selector/DateTimeSelector";
import { DateTimeDescription } from "./components/date-time-selector/DateTimeDescription";
import { AverageDescription } from "./components/average-selector/AverageDescription";
import { QuarterHourDemoSelector } from "./components/quarter-hour-demo-selector/QuarterHourDemoSelector";
import { DemoDescription } from "./components/quarter-hour-demo-selector/QuarterHourDemoDescription";
import { validateCanada70NoShareOrPutCutOff, validateCountryDemoDates, validateCountryStartDate, validateFromAndToDates, validateFromAndToTime, validateHaveDaypartInEachCountry, validateHaveDays, validateHaveDemoInEachCountry, validateHaveStationInEachCountry, validateJapan38DemosAndStations } from "ValidationHelpers";
import { CountryConstants } from "constants/CountryConstants";
import { ApplicationHeader } from "components/application-header/ApplicationHeader";
import { ChangeDemoGroupBy } from "reducers/demoGroupBySlice";
import { ChangeDemoSelection } from "reducers/demoSelectionSlice";
import { ChangeFromDate } from "reducers/fromDateReducers";
import { ChangeToDate } from "reducers/toDateReducer";
import { ChangeDemoTypePosition, ChangeDemoTypeSelected } from "reducers/measuresSlice";
import { ChangeOutputFormat, ChangeOutputView } from "reducers/outputSlice";
import { ChangeFromTime } from "reducers/fromTimeReducer";
import { ChangeToTime } from "reducers/toTimeReducer";
import { ChangeUseDayParts } from "reducers/useDayPartsReducer";
import { ChangeAverageDayAverage, ChangeAverageTimeAverage } from "./components/average-selector/averageSlice";
import { ChangeDaysSelected } from "reducers/daysReducer";
import { ChangeCountryIsSelected } from "reducers/countriesSlice";
import { ChangeDemoIsSelected, ChangeDemoSelectedOrder } from "reducers/demosSlice";
import { ChangeStationIsSelected } from "reducers/stationsSlice";
import { ChangeDayPartIsSelected } from "reducers/dayPartReducer";
import { useAppDispatch, useAppState } from "./app/hooks";
import { useApplicationContext } from "components/application-context/ApplicationContext";
import { getSessionCredentials } from "contexts/UserContext";
import { ApplicationContextType } from "apps/ChannelView/Loader";
import { Applications } from "constants/application";
import { ReportProgressMessageArgs, useNotifications } from "contexts/NotificationsContext";
import { useImmer } from "use-immer";
import { RunningState, downloadFile, emptyRunning, openLink } from "model/RunningState";
import { Runner } from "components/runner/Runner";
import { deleteJson, postJson, putJson } from "helpers/api";
import { useNavigate } from "react-router";
import { usePersonalization } from "contexts/PersonalizationContext";

const applicationUrl = "/Apps/QuarterHour";

type PanelState = {
  selectedPanel: string;
  country: boolean;
  channel: boolean;
  demo: boolean;
  dateTime: boolean;
  average: boolean;
  output: boolean;
};

const validate = ( applicationContext: ApplicationContextType, state: Model, showDateValidation: boolean ) => {
  const validation: {
    isValidForSubmit: boolean;
    warnings: Array<string>;
    errors: Array<string>;
    country?: string;
    station?: string;
    demo?: string;
    dateTime?: string;
    average?: string;
    output?: string;
  } = {
    isValidForSubmit: true,
    warnings: [],
    errors: []
  };

  if ( state.countries.length === 0 ) {
    validation.isValidForSubmit = false;
  }

  validateHaveStationInEachCountry( state.countries, state.stations, validation );

  validateHaveDemoInEachCountry( state.countries, state.demos, validation );

  validateFromAndToDates( state.dateTime.fromDate, state.dateTime.toDate, validation );

  validateHaveDays( state.dateTime.days, validation );

  if ( state.dateTime.useDayParts ) {
    validateHaveDaypartInEachCountry( state.countries, state.dateTime.dayParts, validation );
  } else {
    validateFromAndToTime( state.dateTime.fromTime, state.dateTime.toTime, validation );
  }

  // NICE: validate display
  // NICE: validate output
  if ( showDateValidation ) {
    const selectedCountries = applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) );
    validateCountryStartDate( selectedCountries, state.dateTime.fromDate, validation );

    validateCountryDemoDates( selectedCountries, state.demos, state.dateTime.fromDate, state.dateTime.toDate, validation );
  }

  if ( state.countries.find( m => m === CountryConstants.Japan38 ) || state.countries.find( m => m === CountryConstants.Japan39 ) ) {
    validateJapan38DemosAndStations( applicationContext.countries, state.stations, state.demos, validation );
  }

  if ( state.countries.filter( m => m === CountryConstants.Canada70 ).length === 1 ) {
    validateCanada70NoShareOrPutCutOff( state.dateTime.toDate, state.measures, validation );
  }

  return validation;
};

const getDefaultPanelState = () => {
  const panelStateJson = localStorage.getItem( `ps:${ applicationUrl }` );
  if ( panelStateJson ) {
    return JSON.parse( panelStateJson ) as PanelState;
  } else {
    return { selectedPanel: "", country: false, channel: false, demo: false, dateTime: false, average: false, output: false };
  }
};

export const Index = (): JSX.Element => {
  const notifications = useNotifications();
  const state = useAppState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const applicationContext = useApplicationContext<ApplicationContextType>();
  const personalization = usePersonalization();

  const { token, userId } = getSessionCredentials();

  const [ running, setRunning ] = useImmer<RunningState>( emptyRunning );
  const [ panels, setPanels ] = useImmer<PanelState>( getDefaultPanelState() );
  const allExpanded = panels.country && panels.channel && panels.demo && panels.dateTime && panels.average && panels.output;

  useEffect( () => {
    // savePanelState
    localStorage.setItem( `ps:${ applicationUrl }`, JSON.stringify( panels ) );
  }, [ panels ] );

  const missingStation: Selectable<Station> = useMemo( () => ( { name: "MISSING", id: -1, isTranslated: false, isSelected: false, hasRevenue: false } ), [] );
  const missingDemo: Selectable<Demo> = useMemo( () => ( { name: "MISSING", abbreviated: "MISSING", id: -1, isTotalAudienceDemo: false, isAllCommercialDemo: false, isSelected: false } ), [] );
  const missingDayPart: Selectable<DayPart> = useMemo( () => ( { time: "MISSING", name: "MISSING", id: -1, isSelected: false } ), [] );
  const missingMeasure: Measure = useMemo( () => ( { value: DemoTypeConstants.Put, name: "MISSING" } ), [] );

  const measures = useMemo( () => state.measures.map( m => ( { ...m, ...applicationContext.measures.find( n => n.value === m.value ) ?? missingMeasure } ) ), [ applicationContext.measures, missingMeasure, state.measures ] );

  const canSelectAllCountriesInGroup = false;

  const countryGroups = useMemo( () => applicationContext.countryGroups.map( group => {
    const countries: Array<Selectable<Country>> = applicationContext.countries.filter( m => m.group === group ).map( m => ( { ...m, isSelected: !!state.countries.find( n => n === m.id ) } ) );
    const selectedCountryCount = countries.filter( m => m.isSelected ).length;

    return ( {
      name: group,
      countries: countries,
      isSelected: countries.length === selectedCountryCount
    } );
  } ), [ applicationContext.countries, applicationContext.countryGroups, state.countries ] );

  const countries = useMemo( () => applicationContext.countries.map( m => ( { ...m, isSelected: !!state.countries.find( c => c === m.id ) } ) ), [ applicationContext.countries, state.countries ] );

  const selectedCountries = useMemo( () => applicationContext.countries.filter( m => state.countries.find( c => c === m.id ) ).map( country => {
    const selectedStationIds = state.stations.find( c => c.id === country.id )?.stations ?? [];
    const selectedDemoIds = state.demos.find( c => c.id === country.id )?.demos ?? [];
    const selectedDayPartIds = state.dateTime.dayParts.find( c => c.id === country.id )?.dayParts ?? [];

    const stations: Array<Selectable<Station>> = country.stations.map( m => ( { ...m, isSelected: !!selectedStationIds.find( n => n === m.id ) } ) );
    const demos: Array<Selectable<Demo>> = country.demos.map( m => ( { ...m, isSelected: !!selectedDemoIds.find( n => n === m.id ) } ) );
    const dayParts: Array<Selectable<DayPart>> = country.dayParts.map( m => ( { ...m, isSelected: !!selectedDayPartIds.find( n => n === m.id ) } ) );

    const selectedStations: Array<Selectable<Station>> = selectedStationIds.map( m => stations.find( n => n.id === m ) ?? missingStation );
    const selectedDemos: Array<Selectable<Demo>> = selectedDemoIds.map( m => demos.find( n => n.id === m ) ?? missingDemo );
    const selectedDayParts: Array<Selectable<DayPart>> = selectedDayPartIds.map( m => dayParts.find( n => n.id === m ) ?? missingDayPart );
    return ( {
      ...country,
      stations,
      demos,
      dayParts,
      selectedStations,
      selectedDemos,
      selectedDayParts,
      allStationsSelected: selectedStations.length === country.stations.length && country.stations.length !== 0,
      allDemosSelected: selectedDemos.length === country.demos.length && country.demos.length !== 0,
      noStationsSelected: selectedStations.length === 0 && country.stations.length !== 0,
      noDemosSelected: selectedDemos.length === 0 && country.demos.length !== 0
    } );
  } ), [ applicationContext.countries, missingDayPart, missingDemo, missingStation, state.countries, state.dateTime.dayParts, state.demos, state.stations ] );

  const hasSelectedCountries = state.countries.length > 0;

  const isWorldSelected = applicationContext.countries.length === state.countries.length;

  const validation = useMemo( () => validate( applicationContext, state, personalization.dateWarnings ), [ applicationContext, personalization.dateWarnings, state ] );

  const onChangeCountryIsSelected = useCallback( ( countryId: number, isSelected: boolean ) => {
    console.log( `Change Selected Country ${ applicationContext.countries.length }` );

    const country = applicationContext.countriesById[ countryId ];
    console.log( ` Found country from application context: ${ country }` );

    dispatch( ChangeCountryIsSelected( { countryId, isSelected, clearExisting: false, defaultStations: country.defaultStations, defaultDemos: country.defaultDemos } ) );
  }, [ applicationContext.countries.length, applicationContext.countriesById, dispatch ] );

  const onChangeStationIsSelected = useCallback( ( countryId: number, stationId: number, isSelected: boolean ) => dispatch( ChangeStationIsSelected( { countryId, stationId, isSelected } ) ), [ dispatch ] );

  const demoEvents = useMemo( () => ( {
    onChangeDemoGroupBy: ( demoGroupBy: DemoGroupByConstants ) => dispatch( ChangeDemoGroupBy( demoGroupBy ) ),
    onChangeDemoSelection: ( demoSelection: DemoSelectionConstants ) => dispatch( ChangeDemoSelection( demoSelection ) ),
    onChangeDemoTypePosition: ( srcValue: DemoTypeConstants, dstValue: DemoTypeConstants ) => dispatch( ChangeDemoTypePosition( { srcValue, dstValue } ) ),
    onChangeDemoTypeSelected: ( value: DemoTypeConstants, isSelected: boolean ) => dispatch( ChangeDemoTypeSelected( { value, isSelected } ) ),
    onChangeDemoIsSelected: ( countryId: number, demoId: number, isSelected: boolean ) => dispatch( ChangeDemoIsSelected( { countryId, demoId, isSelected, clearExisting: false } ) ),
    onChangeDemoSelectedOrder: ( countryId: number, srcDemoId: number, dstDemoId: number ) => dispatch( ChangeDemoSelectedOrder( { countryId, srcDemoId, dstDemoId } ) )
  } ), [ dispatch ] );

  const dateTimeEvents = useMemo( () => ( {
    onChangeFromDate: ( fromDate: number ) => dispatch( ChangeFromDate( fromDate ) ),
    onChangeToDate: ( fromDate: number ) => dispatch( ChangeToDate( fromDate ) ),
    onChangeDays: ( days: Array<DaysConstants> ) => { dispatch( ChangeDaysSelected( days ) ); },
    onChangeFromTime: ( fromTime: string ) => { dispatch( ChangeFromTime( fromTime ) ); },
    onChangeToTime: ( toTime: string ) => { dispatch( ChangeToTime( toTime ) ); },
    onChangeUseDayParts: ( useDayParts: boolean ) => { dispatch( ChangeUseDayParts( useDayParts ) ); },
    onChangeDayPartIsSelected: ( countryId: number, dayPartId: number, isSelected: boolean ) => dispatch( ChangeDayPartIsSelected( { countryId, dayPartId, isSelected } ) )
  } ), [ dispatch ] );

  const averageEvents = useMemo( () => ( {
    onChangeAverageDayAverage: ( value: AverageDayAverageConstants ) => { dispatch( ChangeAverageDayAverage( value ) ); },
    onChangeAverageTimeAverage: ( value: AverageTimeAverageConstants ) => { dispatch( ChangeAverageTimeAverage( value ) ); }
  } ), [ dispatch ] );

  const outputEvents = useMemo( () => ( {
    onChangeOutputFormat: ( value: OutputFormatConstants ) => dispatch( ChangeOutputFormat( value ) ),
    onChangeOutputView: ( value: OutputViewConstants ) => dispatch( ChangeOutputView( value ) )
  } ), [ dispatch ] );

  const getSourceModel = useCallback( (): QuarterHourSourceModel => {
    const model: QuarterHourSourceModel = {
      countries: state.countries,
      dateTime: {
        ...state.dateTime,
        fromDate: formatDateAsIso8601( state.dateTime.fromDate ),
        toDate: formatDateAsIso8601( state.dateTime.toDate )
      },
      demos: state.demos,
      measures: state.measures,
      output: state.output,
      stations: state.stations,
      average: state.average,
      demoGroupBy: state.demoGroupBy,
      demoSelection: state.demoSelection
    };

    return model;
  }, [ state.average, state.countries, state.dateTime, state.demoGroupBy, state.demoSelection, state.demos, state.measures, state.output, state.stations ] );

  const doSubmit = useCallback( async (): Promise<void> => {
    if ( validation.errors.length > 0 ) {
      const message = validation.errors.join( "\n" );
      alert( message );
      return;
    }

    if ( validation.warnings.length > 0 ) {
      const message = validation.warnings.join( "\n" );
      if ( !globalThis.window.confirm( message ) ) return;
    }

    setRunning( emptyRunning );

    const model = getSourceModel();

    const result = await postJson<{ id: number; }>( applicationUrl, userId, token, model );
    if ( result ) setRunning( m => { m.id = result.id; } );

  }, [ getSourceModel, setRunning, token, userId, validation.errors, validation.warnings ] );

  const doCancel = useCallback( async () => {
    if ( !running.id ) return;

    await deleteJson( applicationUrl, userId, token, { id: running.id } );

    setRunning( m => { m.cancellationRequested = true; } );
  }, [ running.id, setRunning, token, userId ] );

  useEffect( () => {
    if ( applicationContext.templateOrHistoryId === 0 ) setPanels( m => { m.selectedPanel = ""; m.country = true; m.channel = false; m.demo = false; m.dateTime = false; m.average = false; m.output = false; } );
    setRunning( emptyRunning );
  }, [ applicationContext.templateOrHistoryId, setPanels, setRunning ] );

  const reportProgressMessageHandler = useCallback( ( args: ReportProgressMessageArgs ) => {
    console.log( args );

    if ( !args ) return;
    if ( args.id !== running.id ) return;

    setRunning( m => {
      m.percentComplete = args.percentComplete;
      m.message = args.message;
      m.url = args.url;
      m.contentType = args.contentType;
      m.status = args.status;
      if ( args.status !== "Started" ) {
        m.id = undefined;
      }
    } );

    if ( args.status === "Completed" ) {
      if ( args.contentType !== "text/html" ) {
        downloadFile( args.url, token ); // perform a download
        setRunning( emptyRunning );
      } else if ( args.contentType === "text/html" ) {
        openLink( args.url, "QuarterHour", state.output.view );
        // setRunning( emptyRunning ); can only do this if we know that the tab has been opened
      }
      return;
    }
  }, [ running.id, setRunning, state.output.view, token ] );

  useEffect( () => {
    notifications.setOnReportProgressMessageHandler( () => reportProgressMessageHandler );
  }, [ notifications, reportProgressMessageHandler ] );

  const toggleExpandedPanels = useCallback( (): void => {
    setPanels( m => {
      const isAllExpanded = allExpanded;
      m.country = !isAllExpanded;
      m.channel = !isAllExpanded;
      m.demo = !isAllExpanded;
      m.dateTime = !isAllExpanded;
      m.average = !isAllExpanded;
      m.output = !isAllExpanded;
    } );
  }, [ allExpanded, setPanels ] );

  const onSaveTemplate = useCallback( async ( templateName: string, sharedWith: Array<number> ) => {
    const payload = {
      templateName,
      sharedWith,
      model: getSourceModel()
    };

    // positive values are history rows so always create a new template from those
    if ( applicationContext.templateOrHistoryId >= 0 ) {
      const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
      navigate( `/QuarterHour/${ response.itemId }` );
    }
    else {
      const updateExistingTemplate = applicationContext.templateName === templateName;
      if ( updateExistingTemplate ) {
        await putJson( `/UserTemplate/${ applicationContext.applicationId }/${ applicationContext.templateOrHistoryId }`, userId, token, payload );
      } else {
        const response = await postJson<{ itemId: number; }>( `/UserTemplate/${ applicationContext.applicationId }`, userId, token, payload );
        navigate( `/QuarterHour/${ response.itemId }` );
      }
    }

  }, [ applicationContext.applicationId, applicationContext.templateName, applicationContext.templateOrHistoryId, getSourceModel, navigate, token, userId ] );

  return ( <>
    <ApplicationHeader applicationId={ Applications.QuarterHour } isAllExpanded={ allExpanded } title={ applicationContext.name } isEnabled={ hasSelectedCountries } onToggleExpanded={ toggleExpandedPanels } />
    <ExpanderPanel title="Country:" isExpanded={ panels.country } onExpanded={ ( isExpanded ) => setPanels( m => { m.country = isExpanded; } ) } description={ <CountryDescription warningMessage={ validation.country } isWorldSelected={ isWorldSelected } countryGroups={ countryGroups } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <CountrySelector canSelectAllCountriesInGroup={ canSelectAllCountriesInGroup } countryGroups={ countryGroups } countries={ countries } isWorldSelected={ isWorldSelected } onChangeCountryIsSelected={ onChangeCountryIsSelected } />
    </ExpanderPanel>
    <ExpanderPanel title="Channel:" isExpanded={ panels.channel } onExpanded={ ( isExpanded ) => setPanels( m => { m.channel = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <StationsDescription warningMessage={ validation.station } selectedCountries={ selectedCountries } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <span css={ { fontWeight: "bold", marginLeft: "4px" } }>Channels <HelpText helpId="channel" /></span>
      <StationSelector canSelectAllCountriesInGroup={ canSelectAllCountriesInGroup } selectedCountries={ selectedCountries } onChangeStationIsSelected={ onChangeStationIsSelected } />
    </ExpanderPanel>
    <ExpanderPanel title="Demo:" isExpanded={ panels.demo } onExpanded={ ( isExpanded ) => setPanels( m => { m.demo = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <DemoDescription warningMessage={ validation.demo } measures={ measures } demoGroupBy={ state.demoGroupBy } demoSelection={ state.demoSelection } selectedCountries={ selectedCountries } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <QuarterHourDemoSelector demoGroupBy={ state.demoGroupBy } { ...demoEvents } demoSelection={ state.demoSelection } measures={ measures } selectedCountries={ selectedCountries } />
    </ExpanderPanel>
    <ExpanderPanel title="Date/Time:" isExpanded={ panels.dateTime } onExpanded={ ( isExpanded ) => setPanels( m => { m.dateTime = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <DateTimeDescription warningMessage={ validation.dateTime } { ...state.dateTime } selectedCountries={ selectedCountries } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <DateTimeSelector { ...state.dateTime } selectedCountries={ selectedCountries } { ...dateTimeEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Average:" isExpanded={ panels.average } onExpanded={ ( isExpanded ) => setPanels( m => { m.average = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <AverageDescription warningMessage={ validation.average } { ...state.average } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <AverageSelector { ...state.average } dayPartsIsEnabled={ state.dateTime.useDayParts } { ...averageEvents } />
    </ExpanderPanel>
    <ExpanderPanel title="Output:" isExpanded={ panels.output } onExpanded={ ( isExpanded ) => setPanels( m => { m.output = isExpanded; } ) } isEnabled={ hasSelectedCountries } description={ <OutputDescription warningMessage={ validation.output } { ...state.output } /> } selectedExpanderPanel={ panels.selectedPanel } onSelected={ ( title ) => setPanels( m => { m.selectedPanel = title; } ) }>
      <OutputSelector { ...state.output } defaultFormat={ OutputFormatConstants.Html } isTemplateOrHistoryOwner={ applicationContext.templateOrHistoryOwnerId === userId } onSaveTemplate={ onSaveTemplate } { ...outputEvents } />
    </ExpanderPanel>
    <Runner running={ running } isValidForSubmit={ validation.isValidForSubmit } doSubmit={ doSubmit } doCancel={ doCancel } view={ state.output.view } applicationWindow="QuarterHour" />
  </> );
};
